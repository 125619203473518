<div *ngIf="nightDataList">
    <div class="report-section">
        <!-- Separator -->
        <div class="row row--guttered-h">
            <div class="separator"></div>
        </div>
        <!-- Separator -->

        <!-- Section title -->
        <div class="row row--guttered-h section-title-container">
            <h2 class="title-h2 section-title" (click)="toggleReportSection($event)">{{ sectionName | translate }}</h2>
        </div>
        <!-- Section title -->

        <div class="section-content" *ngIf="displayedNightDataList">
            <!-- Data -->
            <div class="row row--guttered row--smaller content">
                <app-night-report-tab-line *ngFor="let nightData of displayedNightDataList"
                                           [nightData]="nightData">
                </app-night-report-tab-line>
            </div>
            <!-- Data -->
        </div>

        <div class="section-content" *ngIf="displayedNightDataSubList">
            <!-- Data -->
            <div *ngFor="let nightDataSubList of displayedNightDataSubList; let index = index"
                 class="row row--guttered-v row row--guttered-h row--smaller content"
                 [class.children-tab-lines-other-than-first-faded]="index == 1 || index == 2">
                <app-night-report-tab-line *ngFor="let nightData of nightDataSubList"
                                           [nightData]="nightData"></app-night-report-tab-line>
            </div>
            <!-- Data -->
        </div>
    </div>
</div>
