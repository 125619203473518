<main class="main">
    <ng-container>
        <app-validatable-input class="validatable-container"
                               *ngIf="serialNumber"
                               [validatableObject]="serialNumber"
                               [useLabelAsPlaceholder]="true"
                               [transformToUpperCase]="true"></app-validatable-input>

        <app-validatable-checkbox *ngIf="isSecretCodeRequired"
                                  [validatableObject]="isSecretCodeRequired">
        </app-validatable-checkbox>

        <app-validatable-input class="validatable-container"
                               *ngIf="secretCode && isSecretCodeRequired?.value"
                               [validatableObject]="secretCode"
                               [useLabelAsPlaceholder]="true"></app-validatable-input>
    </ng-container>
</main>
