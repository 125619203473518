<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'savForm_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div *ngIf="!formFilled">
        <div class="row row--guttered-v row--tiny">
            {{ 'savForm_info_patient_title' | translate }}
        </div>
        <ng-container>
            <div class="row row--guttered-h row--tiny content">
                <div class="two-field-container-per-row">
                    <app-validatable-input class="validatable-container"
                                        *ngIf="firstName"
                                        [validatableObject]="firstName"></app-validatable-input>

                    <app-validatable-input class="validatable-container"
                                        *ngIf="lastName"
                                        [validatableObject]="lastName"></app-validatable-input>
                </div>
            </div>
        </ng-container>

        <div class="row row--guttered-v row--tiny">
           {{ 'savForm_shipping_info_title' | translate }}
        </div>

        <ng-container>
            <div class="row row--guttered-h row--tiny content">
                <app-validatable-input class="validatable-container"
                                    *ngIf="country"
                                    [validatableObject]="country"></app-validatable-input>
                <app-validatable-input class="validatable-container"
                                    *ngIf="address"
                                    [validatableObject]="address"></app-validatable-input>

                <div class="two-field-container-per-row">
                    <app-validatable-input class="validatable-container"
                                        *ngIf="city"
                                        [validatableObject]="city"></app-validatable-input>

                    <app-validatable-input class="validatable-container"
                                        *ngIf="zipCode"
                                        [validatableObject]="zipCode"></app-validatable-input>
                </div>

                <div class="field-container buttons-container">
                    <app-button
                        [loading]="isSendingForm"
                        (clicked)="sendSavFormAsync()"
                    >
                        {{ 'common_send' | translate }}
                    </app-button>
                </div>
            </div>
        </ng-container>
    </div>

    <div *ngIf="formFilled" class="row row--guttered row--smaller content">
        <p>{{ 'savForm_filled_title' | translate }}</p>
        <p>{{ 'savForm_filled_subTitle' | translate }}</p>
    </div>
</main>
