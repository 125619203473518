import { Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core"
import { NightData } from "../../../models/Nights/NightData"

@Component({
    selector: "app-night-report-tab-line",
    templateUrl: "./night-report-tab-line.component.html",
    styleUrls: ["./night-report-tab-line.component.sass"],
})
export class NightReportTabLineComponent {
    @Input("nightData") nightData: NightData

    @ViewChild("descriptionElement") descriptionElement: ElementRef

    @HostListener("document:click", ["$event.target"])
    onClick(element: HTMLElement) {
        if (this.isMobile()) {
            if (!element.classList.contains("information-wrapper__icon")) {
                this.hideMobilePopup()
            }
        }
    }

    public showMobilePopup = false

    public static currentPopup: NightReportTabLineComponent = null

    public isMobile(): boolean {
        return window.innerWidth < 1329
    }

    public displayMobilePopup(): void {
        if (!this.showMobilePopup) {
            this.showMobilePopup = true
            if (
                NightReportTabLineComponent.currentPopup !== null &&
                NightReportTabLineComponent.currentPopup !== this
            ) {
                NightReportTabLineComponent.currentPopup.hideMobilePopup()
            }

            NightReportTabLineComponent.currentPopup = this

            const arrowElement = this.descriptionElement.nativeElement.children[2]
            const informationElement = this.descriptionElement.nativeElement.children[0]

            const spaceLeft = informationElement.getBoundingClientRect().left

            arrowElement.style.setProperty("--popup-arrow-left", "calc(" + spaceLeft + "px - 2rem - 5px)")
        } else {
            this.hideMobilePopup()
        }
    }

    public hideMobilePopup(): void {
        this.showMobilePopup = false

        NightReportTabLineComponent.currentPopup = null
    }
}
