<div class="night-report-tab-line" *ngIf="nightData">
    <div class="night-report-tab-line__description-wrapper">
        <div class="description-wrapper__name-wrapper">
            <div class="name-wrapper__name">
                {{nightData.name}}
            </div>

            <div class="name-wrapper__unit" *ngIf="nightData.unit">
                &nbsp;({{nightData.unit}})
            </div>

            <div #descriptionElement class="name-wrapper__information-wrapper" *ngIf="nightData.description">
                <img src="../../../assets/images/icon_information_dark.svg" class="information-wrapper__icon" (click)="displayMobilePopup()">

                <div class="information-wrapper__popup" [ngClass]="(isMobile() && !showMobilePopup) ? 'information-wrapper__popup--hidden' : ''">
                    <p class="popup__name">
                        {{nightData.extendedName ? nightData.extendedName : nightData.name}}
                    </p>

                    <p class="popup__description" [innerHTML]="nightData.description | replaceLineBreaks"></p>
                </div>
                <div class="information-wrapper__popup-arrow" [ngClass]="(isMobile() && !showMobilePopup) ? 'information-wrapper__popup-arrow--hidden' : ''"></div>
            </div>

        </div>

        <div class="description-wrapper__extended-name">
            {{nightData.extendedName}}
        </div>
    </div>

    <div class="night-report-tab-line__value-wrapper">
        <div *ngIf="nightData.value">
            {{nightData.value}}
        </div>
        <div class="value-wrapper__benchmark-wrapper" [ngClass]="{'benchmark-wrapper--reverse': nightData.benchmark.startFromGood}" *ngIf="nightData.benchmark">
            <div class="benchmark-wrapper__benchmark-range benchmark-range--red" *ngIf="nightData.benchmark.effectiveRange.rangeVeryBad" [ngClass]="{'benchmark-range--active': nightData.benchmark.evaluationStatus == 'very-bad'}">
                <img src="../../../assets/images/icon_valid__red_dark.png" alt="" class="benchmark-range__range-image">
                <div class="benchmark-range__range-line"></div>
                <span class="benchmark-range__range-text">{{nightData.benchmark.effectiveRange.rangeVeryBad.rangeVeryBadText.replace('/h', '')}}</span>
            </div>
            <div class="benchmark-wrapper__benchmark-range benchmark-range--orange" [ngClass]="{'benchmark-range--active': nightData.benchmark.evaluationStatus == 'bad'}">
                <img src="../../../assets/images/icon_valid__orange_dark.png" alt="" class="benchmark-range__range-image">
                <div class="benchmark-range__range-line"></div>
                <span class="benchmark-range__range-text">{{nightData.benchmark.effectiveRange.rangeBad.rangeBadText.replace('/h', '')}}</span>
            </div>
            <div class="benchmark-wrapper__benchmark-range benchmark-range--yellow" [ngClass]="{'benchmark-range--active': nightData.benchmark.evaluationStatus == 'mid'}">
                <img src="../../../assets/images/icon_valid__yellow_dark.png" alt="" class="benchmark-range__range-image">
                <div class="benchmark-range__range-line"></div>
                <span class="benchmark-range__range-text">{{nightData.benchmark.effectiveRange.rangeMid.rangeMidText.replace('/h', '')}}</span>
            </div>
            <div class="benchmark-wrapper__benchmark-range benchmark-range--green" [ngClass]="{'benchmark-range--active': nightData.benchmark.evaluationStatus == 'good'}">
                <img src="../../../assets/images/icon_valid__green_dark.png" alt="" class="benchmark-range__range-image">
                <div class="benchmark-range__range-line"></div>
                <span class="benchmark-range__range-text">{{nightData.benchmark.effectiveRange.rangeGood.rangeGoodText.replace('/h', '')}}</span>
            </div>
        </div>
    </div>
</div>
