import * as _ from "lodash"
import { constants as constantsBase } from "./constants.base"
import { Constants } from "../models/config/Constants"

const constants: Constants = _.cloneDeep(constantsBase)

constants.env = "production"

constants.api.marketing.baseUri = "https://apiwebsite-dot-sunrise-237914.appspot.com"
constants.api.marketing.securityKey = "AIzaSyBgE1pDd6IQhL9nd75Y14iPoOuepveFy2I"
constants.api.product.baseUri = "https://sunrise-api-m7cheyciwq-ew.a.run.app"
constants.api.product.securityKey = "AIzaSyBgE1pDd6IQhL9nd75Y14iPoOuepveFy2I"

constants.rawDataViewer.baseUri = "https://sunrise-raw-data-viewer-m7cheyciwq-ew.a.run.app/"

constants.dataAccess.staticFiles.googleCloudStorage.baseUri =
    "https://storage.googleapis.com/sunrise-237914-assets/platform/files"

constants.platform.firebaseUris = ["https://sunrise-c0d43.firebaseapp.com", "https://sunrise-c0d43.web.app"]
constants.platform.baseUri = "https://portal.hellosunrise.com"

export { constants }
