import { Component, EventEmitter, Input, Output } from "@angular/core"
import { generateUniqueId } from "../../../utilities/RandomUtilities"

@Component({
    selector: "app-switch-button",
    templateUrl: "./switch-button.component.html",
    styleUrls: ["./switch-button.component.sass"],
})
export class SwitchButtonComponent {
    @Input() label: string
    @Input() checked = false

    @Output() checkboxChecked: EventEmitter<boolean> = new EventEmitter<boolean>()

    public inputId: string

    constructor() {
        this.inputId = generateUniqueId()
    }

    public checkboxChanged(event: any) {
        this.checkboxChecked.emit(event.target.checked)
    }
}
