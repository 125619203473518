import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "autocompleteInputFilter",
})
export class AutocompleteInputFilterPipe implements PipeTransform {
    transform(proposals: string[], filterText: string): string[] {
        filterText = filterText.toLowerCase()

        return proposals.filter((proposal) => proposal.toLowerCase().includes(filterText)).sort()
    }
}
