<div *ngIf="object" class="field-container" [class.required]="object.required" [class.field-container--errored]="object.hasError">
    <label class="label">{{ object.label | translate }}</label>

    <input class="field"
           type="text"
           placeholder="{{ placeHolder ? placeHolder : useLabelAsPlaceholder ? (object.label | translate) : ('common_input_placeholder' | translate:{'label': (object.label) | translate | lowercase}) }}"
           [disabled]="disabled"
           [(ngModel)]="object.value"
           (ngModelChange)="object.value = transformToUpperCase ? object.value.toUpperCase() : object.value"
           (keyup)="keyupHandlerAsync($event)"
           (focusout)="unfocusHandlerAsync()">

    <small *ngIf="object.showHint" class="hint-text">{{ object.hintString }}</small>
    <small *ngIf="object.hasError" class="error-text">{{ object.errorString }}</small>
</div>
