import { ActivatedRoute } from "@angular/router"

export class RoutesUtilities {
    /**
     * Get the query param value from a given key
     * @param {ActivatedRoute} route the uri containing a query param
     * @param {string} param the key of the param
     * @returns {string} the value of the param
     */
    public static getRouteParam(route: ActivatedRoute, param: string): string {
        return route.snapshot.params[param]
    }

    public static checkUrlFragment(route: ActivatedRoute, fragmentLabel: string, indexFragment: number): boolean {
        return route.snapshot.url[indexFragment].path == fragmentLabel
    }
}
