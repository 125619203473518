import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { NightAnalysisTemplate } from "../../../../models/Nights/NightAnalysisTemplate"
import { NightAnalysisTemplateTypeValues } from "../../../../models/Nights/NightAnalysisTemplateTypeValues"
import { PortalNotificationType } from "../../../../models/PortalNotification"
import { AuthService } from "../../../../services/auth.service"
import { NotificationsService } from "../../../../services/notifications.service"
import { SunriseApiService } from "../../../../services/sunrise-api.service"
import { BaseComponent } from "../../../base.component"

@Component({
    selector: "app-templates",
    templateUrl: "./templates-list.component.html",
    styleUrls: ["./templates-list.component.sass"],
})
export class TemplatesListComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    public isLoading = true
    public impressionTemplates: NightAnalysisTemplate[] = []
    public recommendationTemplates: NightAnalysisTemplate[] = []

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.translate.onLangChange.subscribe(() => {
            if (this.impressionTemplates.length > 0 || this.recommendationTemplates.length > 0) {
                this.reloadPage()
            }
        })
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        const personalTemplates: NightAnalysisTemplate[] = await this.sunriseApiService.getDoctorTemplatesAsync()

        if (personalTemplates) {
            this.impressionTemplates = personalTemplates.filter(
                (template) => template.type == NightAnalysisTemplateTypeValues.Impression,
            )
            this.recommendationTemplates = personalTemplates.filter(
                (template) => template.type == NightAnalysisTemplateTypeValues.Recommendation,
            )
        }

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Events listener
    // -------------------------------------------------------------------------

    public toggleTemplate(event: any): void {
        const templateRow: HTMLElement = event["currentTarget"]["parentElement"]

        templateRow.classList.toggle("template-row--no-content")
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public navigateBack(): void {
        this.router.navigate(["main/account"])
    }

    public navigateToCreateTemplate(): void {
        this.router.navigate(["main/account/create-template"])
    }

    public navigateToUpdateTemplate(templateToUpdate: NightAnalysisTemplate): void {
        this.router.navigate([`main/account/update-template/${templateToUpdate.nightAnalysisTemplateId}`])
    }

    public async deleteTemplate(templateToDelete: NightAnalysisTemplate): Promise<void> {
        try {
            await this.sunriseApiService.deleteTemplateAsync(templateToDelete.nightAnalysisTemplateId)

            if (templateToDelete.type == NightAnalysisTemplateTypeValues.Impression) {
                this.impressionTemplates = this.impressionTemplates.filter(
                    (template) => template.nightAnalysisTemplateId != templateToDelete.nightAnalysisTemplateId,
                )
            }

            if (templateToDelete.type == NightAnalysisTemplateTypeValues.Recommendation) {
                this.recommendationTemplates = this.recommendationTemplates.filter(
                    (template) => template.nightAnalysisTemplateId != templateToDelete.nightAnalysisTemplateId,
                )
            }

            this.showNotification("template_deleted", PortalNotificationType.Success)
        } catch (error) {
            this.showErrorOccurred()
        }
    }
}
