<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{'createSavForm_pageTitle' | translate}}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <ng-container>
        <div class="row row--guttered row--tiny content">

            <app-validatable-input class="validatable-container"
                                *ngIf="emailAddress"
                                [validatableObject]="emailAddress">
            </app-validatable-input>

            <div class="two-field-container-per-row">
                <app-validatable-input class="validatable-container"
                                    *ngIf="serialNumber"
                                    [validatableObject]="serialNumber"></app-validatable-input>

                <app-validatable-select class="validatable-container"
                                    [validatableObject]="selectedLanguage"
                                    [options]="formLanguages"
                                    [selectedVariable]="'value'"
                                    [displayedVariable]="'name'"
                                    (objectChangeEvent)="formLanguageChangeEvent($event)">
                </app-validatable-select>
            </div>

            <div class="field-container buttons-container">
                <app-button
                    [loading]="isCreatingForm"
                    (clicked)="createSavFormAsync()"
                >
                    {{ 'common_create' | translate }}
                </app-button>
            </div>

            <div *ngIf="savUri">
                <span class="example-label__title">{{ 'createSavForm_created_link_label' | translate }}</span>
                <span class="example-label">{{savUri}}</span>
            </div>
        </div>
    </ng-container>
</main>
