import { ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { constants } from "../../../config/constants"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-connected-navbar",
    templateUrl: "./connected-navbar.component.html",
})
export class ConnectedNavbarComponent extends BaseComponent {
    // -------------------------------------------------------------------------
    // Variables
    // -------------------------------------------------------------------------

    public hasPrescriberRole: boolean
    public hasAccountHandlerRole: boolean
    public isAccountConfirmed = true

    @ViewChild("buttonsWrapper") public buttonsWrapperElement: ElementRef
    @ViewChild("burgerButton") public burgerButtonElement: ElementRef

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        protected readonly changeDetector: ChangeDetectorRef,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    public async ngOnInit(): Promise<void> {
        this.hasPrescriberRole = this.sunriseApiService.accountHasRole(constants.roles.prescriber)
        this.hasAccountHandlerRole = this.sunriseApiService.accountHasRole(constants.roles.accountHandler)

        this.isAccountConfirmed = await this.isAccountConfirmedAsync()
    }

    public ngAfterViewInit() {
        this.updateBaseLanguage()
        this.changeDetector.detectChanges()

        super.ngAfterViewInit()
    }

    // -------------------------------------------------------------------------
    // UI utils
    // -------------------------------------------------------------------------

    public switchBurgerMenu(): void {
        this.burgerButtonElement.nativeElement.classList.toggle("navbar__burger--active")
        this.buttonsWrapperElement.nativeElement.classList.toggle("navbar__buttons-wrapper--active")
    }

    public switchSubLinks(element: any): void {
        const link = element.currentTarget

        link.querySelector(".link__sub-links").classList.toggle("link__sub-links--active")
    }
}
