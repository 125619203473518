import { Component } from "@angular/core"
import { PortalNotification, PortalNotificationType } from "../../models/PortalNotification"
import { NotificationsService } from "../../services/notifications.service"
import { slideInOutAnimation } from "../tools/animations/slideInOutAnimation"

@Component({
    selector: "app-notifications-container",
    templateUrl: "./notifications-container.component.html",
    styleUrls: ["./notifications-container.component.sass"],
    animations: [slideInOutAnimation],
})
export class NotificationsContainerComponent {
    public notifications: PortalNotification[] = []

    public ePortalNotificationType: typeof PortalNotificationType = PortalNotificationType

    constructor(protected readonly notificationService: NotificationsService) {
        notificationService.notification.subscribe((portalNotification) => this.displayNotification(portalNotification))
    }

    public displayNotification(portalNotification: PortalNotification): void {
        this.notifications.unshift(portalNotification)

        setTimeout(() => {
            this.removeNotification(portalNotification)
        }, portalNotification.displayTime * 1000)
    }

    public removeNotification(portalNotification: PortalNotification): void {
        const iNotification: number = this.notifications.indexOf(portalNotification)

        if (iNotification >= 0) this.notifications.splice(iNotification, 1)
    }

    public linkClicked(portalNotification: PortalNotification) {
        this.removeNotification(portalNotification)

        portalNotification.linkCallback()
    }
}
