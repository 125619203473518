import { Directive } from "@angular/core"
import { MAT_DATE_FORMATS } from "@angular/material/core"

export const DATE_FORMAT_DDMMYYYY = {
    parse: {
        dateInput: "DD/MM/YYYY",
    },
    display: {
        dateInput: "DD/MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
}

@Directive({
    selector: "[datePickerDDMMYYYYFormat]",
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: DATE_FORMAT_DDMMYYYY,
        },
    ],
})
export class DatePickerDDMMYYYYFormatDirective {}

export const DATE_FORMAT_MMDDYYYY = {
    parse: {
        dateInput: "MM/DD/YYYY",
    },
    display: {
        dateInput: "MM/DD/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
}

@Directive({
    selector: "[datePickerMMDDYYYYFormat]",
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: DATE_FORMAT_MMDDYYYY,
        },
    ],
})
export class DatePickerMMDDYYYYFormatDirective {}
