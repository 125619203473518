import { Component, HostListener, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { NightAnalysisActionValues } from "../../models/Nights/NightAnalysisActionValues"
import { SleepStudy } from "../../models/Nights/SleepStudy"
import { Office } from "../../models/Office"
import { SortOrder } from "../../models/SortOrder"
import { StudyListFilter } from "../../models/StudyListFilter"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { getPatientFullName } from "../../utilities/getPatientFullName"
import { getDateFormatLabel } from "../../utilities/UnitsAndFormatsUtilities"
import { SortedList } from "../sortedLists"

@Component({
    selector: "app-studies-list",
    templateUrl: "./studies-list.component.html",
})
export class StudiesListComponent extends SortedList implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    public studiesList: SleepStudy[] = []
    public doctorOffices: Office[]

    public currentFilter: StudyListFilter = StudyListFilter.AllStudies
    public searchFilter = ""
    public filterByOffice: string

    public nbStudiesToBeApproved = 0
    public nbStudiesToBeInterpreted = 0
    public nbStudiesToBeSigned = 0
    public nbStudiesSigned = 0

    public displayV2Columns: boolean

    public isLoading = true
    public isMobile: boolean

    public dateFormatLabel: string

    /* Enum */
    public eFilterType: typeof StudyListFilter = StudyListFilter

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)
        this.translate.onLangChange.subscribe(() => this.updateLanguage())

        this.isMobile = window.innerWidth < 1330
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        await this.sunriseApiService.setRolesAsync(this.authService.isAdmin())

        if (!this.canConsultReports) {
            this.navigateToMainPage()
        } else {
            this.isLoading = true

            await this.fillDoctorOfficesAsync()
            await this.fillInStudiesList()

            this.refreshNumberOfFilteredStudies()

            this.initSortingValues("nightEndDateTime", SortOrder.Descending)

            this.isLoading = false
        }
    }

    // -------------------------------------------------------------------------
    // Translation helpers
    // -------------------------------------------------------------------------

    protected updateLanguage(): void {
        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)
    }

    // -------------------------------------------------------------------------
    // Navigation delegates
    // -------------------------------------------------------------------------

    public navigateToNight(nightId: string, nightVersionId: string): void {
        const path: string = `main/nights/${nightId}${nightVersionId != undefined ? `/version/${nightVersionId}` : ""}`

        this.router.navigate([path])
    }

    // -------------------------------------------------------------------------
    // Filter patients
    // -------------------------------------------------------------------------

    public async updateMainFilterAsync(filterType: string): Promise<void> {
        if (this.isLoading || filterType == this.currentFilter) return

        this.currentFilter = filterType as StudyListFilter
    }

    public refreshNumberOfFilteredStudies() {
        if (!this.displayV2Columns) {
            return
        }

        this.nbStudiesToBeApproved = this.studiesList.filter(
            (study) => study.nightIsAnalyzed && !study.nightAnalysisStep && study.nightIsInterpretable,
        ).length
        this.nbStudiesToBeInterpreted = this.studiesList.filter(
            (study) => study.nightAnalysisStep?.actionType == NightAnalysisActionValues.Validation,
        ).length
        this.nbStudiesToBeSigned = this.studiesList.filter(
            (study) => study.nightAnalysisStep?.actionType == NightAnalysisActionValues.Interpretation,
        ).length

        this.nbStudiesSigned = this.studiesList.filter(
            (study) => study.nightAnalysisStep?.actionType == NightAnalysisActionValues.Signature,
        ).length
    }

    // -------------------------------------------------------------------------
    // Event listener
    // -------------------------------------------------------------------------

    @HostListener("window:resize", [])
    public onResize() {
        this.isMobile = window.innerWidth < 1330
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async fillDoctorOfficesAsync(): Promise<void> {
        try {
            const doctorOffices: Office[] = await this.sunriseApiService.getDoctorOfficesAsync()

            if (doctorOffices.length > 0) {
                this.doctorOffices = doctorOffices.sort((officeA, officeB) => {
                    return officeA.officeName >= officeB.officeName ? 1 : -1
                })

                this.filterByOffice = "all"
            }
        } catch {
            // If an error occurred, do nothing
            this.doctorOffices = []
        }
    }

    public async fillInStudiesList(): Promise<void> {
        try {
            this.studiesList = await this.sunriseApiService.getSleepStudiesAsync()

            this.studiesList.forEach((night) => this.fillSleepStudyInformation(night))

            this.updateDisplayV2Columns()
        } catch (e) {
            this.showErrorOccurred()
        }
    }

    private fillSleepStudyInformation(study: SleepStudy): void {
        study.fullName = getPatientFullName(study.firstName, study.lastName)

        let status = "-"

        if (study.nightIsInterpretable && study.nightIsAnalyzed) {
            status = "analysis_status_1_toBeApproved"

            if (study.nightAnalysisStep) {
                if (study.nightAnalysisStep.actionType == NightAnalysisActionValues.Validation) {
                    status = "analysis_status_2_toBeInterpreted"
                }

                if (study.nightAnalysisStep.actionType == NightAnalysisActionValues.Interpretation) {
                    status = "analysis_status_3_toBeSigned"
                }

                if (study.nightAnalysisStep.actionType == NightAnalysisActionValues.Signature) {
                    status = "analysis_status_4_signed"
                }
            }
        }

        study.analysisStatus = status
    }

    private updateDisplayV2Columns(): void {
        const nbInterpretablesStudies: number = this.studiesList.filter((_) => _.nightIsInterpretable).length

        this.displayV2Columns = this.isUSPractitioner || nbInterpretablesStudies > 0
    }
}
