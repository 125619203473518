<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'resetPassword_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--tiny content">
        <app-password-field *ngIf="password"
                            [validatableObject]="password"
                            (enterPressed)="resetPassword()"></app-password-field>

        <app-password-field *ngIf="passwordConfirmation"
                            [validatableObject]="passwordConfirmation"
                            (enterPressed)="resetPassword()"></app-password-field>

        <div class="field-container buttons-container" [class.field-container--errored]="error != ''">
            <app-button
                [loading]="isResetingPassword"
                (clicked)="resetPassword()"
            >
                {{ 'resetPassword_action_reset' | translate }}
            </app-button>
            <small class="error-text">{{error}}</small>
        </div>
    </div>
</main>
