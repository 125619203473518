import { Component, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { AccountActionType } from "../../models/AccountActionType"
import { UnitsAndFormats } from "../../models/Formats/UnitsAndFormats"
import { PortalNotificationType } from "../../models/PortalNotification"
import { UnitsAndFormatsPreferences } from "../../models/UnitsAndFormatsPreferences"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import {
    convertCentimetersToInches,
    convertInchesToCentimeters,
    convertKilogramsToPounds,
    convertPoundsToKilograms,
} from "../../utilities/UnitsAndFormatsUtilities"
import { MaxValueRule } from "../../validation/rules/MaxValueRule"
import { MinValueRule } from "../../validation/rules/MinValueRule"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { NumberRule } from "../../validation/rules/NumberRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { AccountFieldsComponent } from "../account-fields/account-fields.component"
import { BaseComponent } from "../base.component"
import { SensorsToPatientAssignationComponent } from "../tools/sensors-to-patient-assignation/sensors-to-patient-assignation.component"

@Component({
    selector: "app-edit-patient-account",
    templateUrl: "./edit-patient-account.component.html",
})
export class EditPatientAccountComponent extends BaseComponent implements OnInit {
    private patientId: string
    private doctorPreferences: UnitsAndFormatsPreferences

    public isLoading = true
    public isEditingPatient = false

    public initialPatient: any

    public genders: object[] = []

    public patientIdentifier: ValidatableObject
    public gender: ValidatableObject
    public collarSize: ValidatableObject
    public height: ValidatableObject
    public weight: ValidatableObject
    public shouldCompleteQuestionnaire: ValidatableObject

    @ViewChild("personalInformationFields") public personalInformationFields: AccountFieldsComponent
    @ViewChild("sensorAssignationFields") public sensorAssignationFields: SensorsToPatientAssignationComponent

    /* Enum */
    public eAccountActionType: typeof AccountActionType = AccountActionType

    public constructor(
        protected translate: TranslateService,
        protected router: Router,
        protected authService: AuthService,
        protected sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly route: ActivatedRoute,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.translate.onLangChange.subscribe(async () => this.fillGendersAsync())
    }

    public async ngOnInit(): Promise<void> {
        this.patientId = RoutesUtilities.getRouteParam(this.route, "id")
        this.doctorPreferences = this.authService.getPreferences()
    }

    public async afterSignUpFieldsInit(): Promise<void> {
        await this.initValidatableObjectsAsync()
        await this.fillGendersAsync()

        this.initialPatient = await this.sunriseApiService.getPatientAsync(this.patientId)

        this.initialPatient.firstName = !_.isEmpty(this.initialPatient.firstName) ? this.initialPatient.firstName : null
        this.initialPatient.lastName = !_.isEmpty(this.initialPatient.lastName) ? this.initialPatient.lastName : null

        this.initPatientInformation(this.initialPatient)

        this.isLoading = false
    }

    private async initValidatableObjectsAsync(): Promise<void> {
        const preferences: UnitsAndFormatsPreferences = this.authService.getPreferences()

        let collarSizeLabel = "signUp_input_collarSize_cm_label"
        let heightLabel = "signUp_input_height_cm_label"
        let weightLabel = "signUp_input_weight_kg_label"

        let maxCollarSize = 100
        let maxHeight = 250
        let maxWeight = 250

        if (preferences.preferredLengthUnit != UnitsAndFormats.Centimeters) {
            collarSizeLabel = "signUp_input_collarSize_in_label"
            heightLabel = "signUp_input_height_in_label"

            maxCollarSize = 39
            maxHeight = 98
        }

        if (preferences.preferredWeightUnit != UnitsAndFormats.Kilograms) {
            weightLabel = "signUp_input_weight_lb_label"
            maxWeight = 550
        }

        this.patientIdentifier = new ValidatableObject(this.translate, "signUp_input_patient_identifier_label")
        this.patientIdentifier.addRule(new NotEmptyRule(this.translate))

        this.gender = new ValidatableObject(this.translate, "signUp_input_gender_label")

        this.collarSize = new ValidatableObject(this.translate, collarSizeLabel)
        this.collarSize.addRule(new NumberRule(this.translate))
        this.collarSize.addRule(new MinValueRule(this.translate, 0))
        this.collarSize.addRule(new MaxValueRule(this.translate, maxCollarSize))

        this.height = new ValidatableObject(this.translate, heightLabel)
        this.height.addRule(new NumberRule(this.translate))
        this.height.addRule(new MinValueRule(this.translate, 0))
        this.height.addRule(new MaxValueRule(this.translate, maxHeight))

        this.weight = new ValidatableObject(this.translate, weightLabel)
        this.weight.addRule(new NumberRule(this.translate))
        this.weight.addRule(new MinValueRule(this.translate, 0))
        this.weight.addRule(new MaxValueRule(this.translate, maxWeight))

        this.shouldCompleteQuestionnaire = new ValidatableObject(
            this.translate,
            "createPatientAccount_shouldCompleteQuestionnaire",
        )
    }

    private initPatientInformation(patient: any) {
        this.patientIdentifier.value = patient.customIdentifier
        this.personalInformationFields.firstName.value = patient.firstName
        this.personalInformationFields.lastName.value = patient.lastName
        this.personalInformationFields.birthdate.value = patient.birthDate
        this.personalInformationFields.country.value = patient.countryNumber
        this.personalInformationFields.countryChangeEvent(patient.countryNumber)
        this.personalInformationFields.phoneNumber.value = patient.phoneNumber
        this.gender.value = patient.gender

        this.collarSize.value =
            this.doctorPreferences.preferredLengthUnit == UnitsAndFormats.Inches
                ? convertCentimetersToInches(patient.collarSize)
                : patient.collarSize
        this.height.value =
            this.doctorPreferences.preferredLengthUnit == UnitsAndFormats.Inches
                ? convertCentimetersToInches(patient.height)
                : patient.height
        this.weight.value =
            this.doctorPreferences.preferredWeightUnit == UnitsAndFormats.Pounds
                ? convertKilogramsToPounds(patient.weight)
                : patient.weight
        this.shouldCompleteQuestionnaire.value = patient.shouldCompleteQuestionnaire
    }
    private async fillGendersAsync(): Promise<void> {
        this.genders = [
            { name: "male", displayedName: await this.__("signUp_input_gender_male_label") },
            { name: "female", displayedName: await this.__("signUp_input_gender_female_label") },
        ]
    }

    public async editPatientAccountAsync(): Promise<void> {
        const isValid: boolean = await this.validateObjectsAsync()

        if (isValid) {
            const patient: any = this.createPatientObject()

            this.isEditingPatient = true

            try {
                await this.sunriseApiService.updatePatientAccount(patient)

                this.showNotification("editPatientAccount_confirmation", PortalNotificationType.Success)

                this.navigateToMainPage()
            } catch (error) {
                this.showErrorOccurred()
            } finally {
                this.isEditingPatient = false
            }
        }
    }
    private async validateObjectsAsync(): Promise<boolean> {
        let isValid: boolean = await this.patientIdentifier.validateAsync()
        isValid = (await this.personalInformationFields.validateObjectsAsync()) && isValid
        isValid = (await this.gender.validateAsync()) && isValid
        isValid = (await this.collarSize.validateAsync()) && isValid
        isValid = (await this.height.validateAsync()) && isValid
        isValid = (await this.weight.validateAsync()) && isValid

        return isValid
    }

    private createPatientObject(): any {
        const patientFields = [
            { name: "customIdentifier", validatableObject: this.patientIdentifier },
            { name: "firstName", validatableObject: this.personalInformationFields.firstName },
            { name: "lastName", validatableObject: this.personalInformationFields.lastName },
            { name: "birthDate", validatableObject: this.personalInformationFields.birthdate },
            { name: "countryNumber", validatableObject: this.personalInformationFields.country },
            { name: "phoneNumber", validatableObject: this.personalInformationFields.phoneNumber },
            { name: "gender", validatableObject: this.gender },
            { name: "collarSize", validatableObject: this.collarSize },
            { name: "height", validatableObject: this.height },
            { name: "weight", validatableObject: this.weight },
            { name: "shouldCompleteQuestionnaire", validatableObject: this.shouldCompleteQuestionnaire },
        ]

        const editedPatient: any = { userId: this.patientId }

        for (const field of patientFields) {
            let convertedValue = field.validatableObject.value

            // Check if the field needs conversion
            if (field.name == "height" && this.doctorPreferences.preferredLengthUnit == UnitsAndFormats.Inches) {
                convertedValue = convertInchesToCentimeters(field.validatableObject.value)
            } else if (field.name == "weight" && this.doctorPreferences.preferredWeightUnit == UnitsAndFormats.Pounds) {
                convertedValue = convertPoundsToKilograms(field.validatableObject.value)
            } else if (
                field.name == "collarSize" &&
                this.doctorPreferences.preferredLengthUnit == UnitsAndFormats.Inches
            ) {
                convertedValue = convertInchesToCentimeters(field.validatableObject.value)
            } else if (
                field.name == "birthDate" &&
                field.validatableObject.value &&
                typeof field.validatableObject.value !== "string"
            ) {
                convertedValue = {
                    year: field.validatableObject.value.year(),
                    month: field.validatableObject.value.month() + 1,
                    day: field.validatableObject.value.date(),
                }
            } else if (field.name == "shouldCompleteQuestionnaire") {
                convertedValue = field.validatableObject.value ?? false
            }

            // Check if the field value has been changed compared to initialPatient
            if (this.initialPatient[field.name] !== convertedValue) {
                editedPatient[field.name] = convertedValue
            }
        }

        return editedPatient
    }
}
