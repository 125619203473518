import { Component, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { encrypt } from "crypto-js/aes"
import { constants } from "../../../../config/constants"
import { AnalysisStep } from "../../../../models/Nights/AnalysisStep"
import { AuthService } from "../../../../services/auth.service"
import { NotificationsService } from "../../../../services/notifications.service"
import { SunriseApiService } from "../../../../services/sunrise-api.service"
import { NightReportEmbedPDF } from "../../night-report-embed-pdf/night-report-embed-pdf.component"
import { NightReportTemplateComponent } from "../night-report-template.component"

@Component({
    selector: "app-night-report-template-v2",
    templateUrl: "./night-report-template-v2.component.html",
    styleUrls: ["../../night-report.component.sass"],
})
export class NightReportTemplateV2Component extends NightReportTemplateComponent {
    @ViewChild("nightReportEmbedPDF") public nightReportEmbedPDF: NightReportEmbedPDF

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    public openRawDataViewer(scoringRule: string): void {
        const data: object = {
            nightId: this.night.nightId,
            nightVersionId: this.nightVersionId,
            userFullName: this.patientName,
            doctorName: this.night.doctorName,
            scoringRule: scoringRule,
            token: this.authService.getToken(),
            refreshToken: this.authService.getRefreshToken(),
        }

        const encryptedData = encrypt(JSON.stringify(data), constants.cryptoJs.key)
        const token: string = encodeURIComponent(encryptedData.toString())

        const path = `${constants.rawDataViewer.baseUri}?token=${token}`

        window.open(path)
    }

    public onNightAnalysisChanged(analysisStep: AnalysisStep): void {
        if (analysisStep && (analysisStep == AnalysisStep.Interpretation || analysisStep == AnalysisStep.Signature)) {
            this.nightReportEmbedPDF.refreshSleepReportPDF()
        }
    }
}
