import { Pipe, PipeTransform } from "@angular/core"
import * as _ from "lodash"
import { Moment } from "moment"
import { Patient } from "../../models/Patient"
import { PatientListFilter } from "../../models/PatientListFilter"

@Pipe({
    name: "patientListFilter",
})
export class PatientListFilterPipe implements PipeTransform {
    transform(
        patients: Patient[],
        filterText: string,
        searchFilter: PatientListFilter,
        filterByDates: any,
        filterStartDate: any,
        filterEndDate: any,
        filterByNewNights: any,
        filterByOffice: string,
    ): Patient[] {
        filterText = filterText.toLowerCase()

        return patients.filter(
            (patient) =>
                this.filterByPatientListFilter(patient, searchFilter) &&
                this.filterByInputText(patient, filterText) &&
                this.filterByDates(patient, filterByDates, filterStartDate, filterEndDate) &&
                this.filterByNewNights(patient, filterByNewNights) &&
                this.filterByOffice(patient, filterByOffice),
        )
    }

    private filterByPatientListFilter(patient: Patient, searchFilter: PatientListFilter): boolean {
        return (
            // All patients: do not filter the patient list.
            searchFilter === PatientListFilter.AllPatients ||
            // Filter on patients that has performed a night.
            (searchFilter === PatientListFilter.WithNights && patient.hasPerformedNight) ||
            // Filter on patients that has activated their account.
            (searchFilter === PatientListFilter.Activated && patient.lastLoginDate && !patient.hasPerformedNight) ||
            // Filter on patients that has not activated their account.
            (searchFilter === PatientListFilter.NonActivated && !patient.lastLoginDate)
        )
    }

    private filterByInputText(patient: Patient, inputText: string): boolean {
        // Filter on first name, last name or customIdentifier.
        return (
            `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(inputText.toLowerCase()) ||
            `${patient.lastName} ${patient.firstName}`.toLowerCase().includes(inputText.toLowerCase()) ||
            patient.customIdentifier?.toLowerCase().includes(inputText.toLowerCase())
        )
    }

    private filterByDates(patient: Patient, filterByDates: any, filterStartDate: any, filterEndDate: any): boolean {
        // Filter by date
        if (filterByDates && filterStartDate && filterEndDate) {
            const startDate: Date = (filterStartDate as Moment).toDate()
            const endDate: Date = (filterEndDate as Moment).toDate()

            // add one day to end date
            endDate.setDate(endDate.getDate() + 1)

            const nightsEndDate: string[] = patient.nightsEndDate

            const foundDate: string = nightsEndDate.find((strDate) => {
                const date: Date = new Date(strDate)

                return date > startDate && date < endDate
            })

            return !_.isNil(foundDate)
        }

        return true
    }

    private filterByNewNights(patient: Patient, filterByNewNights: any): boolean {
        // Filter by new nights
        return filterByNewNights ? (patient.newNights as boolean) : true
    }

    private filterByOffice(patient: Patient, filterByOffice: string): boolean {
        return (
            filterByOffice == undefined ||
            filterByOffice == "all" ||
            (filterByOffice == "none" && patient.office == undefined) ||
            patient.office?.officeId == filterByOffice
        )
    }
}
