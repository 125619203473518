import { NgFor } from "@angular/common"
import { Directive, EmbeddedViewRef, inject, Input, OnChanges, TemplateRef, ViewContainerRef } from "@angular/core"

@Directive({
    selector: "[ngForEmpty]",
    hostDirectives: [
        {
            directive: NgFor,
            inputs: ["ngForOf:ngForEmptyOf"],
        },
    ],
})
export class NgForEmptyDirective<T> implements OnChanges {
    @Input() ngForEmptyOf: T[] | undefined
    @Input() ngForEmptyElse!: TemplateRef<any>

    private vcr = inject(ViewContainerRef)
    private ref?: EmbeddedViewRef<unknown>

    private lastNgForEmptyOf: number | undefined

    public ngOnChanges(): void {
        const ngForEmptyOf: number = this.ngForEmptyOf.length

        if (ngForEmptyOf != this.lastNgForEmptyOf) {
            this.ref?.destroy()

            if (!this.ngForEmptyOf || ngForEmptyOf === 0) {
                this.ref = this.vcr.createEmbeddedView(this.ngForEmptyElse)
            }
        }

        this.lastNgForEmptyOf = ngForEmptyOf
    }
}
