import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { AboutComponent } from "./about/about.component"
import { AccountConfirmedComponent } from "./account-confirmed/account-confirmed.component"
import { AccountDeletionRequestConfirmedComponent } from "./account-deletion-request-confirmed/account-deletion-request-confirmed.component"
import { AdminSupportComponent } from "./admin-support/admin-support.component"
import { CreatePatientAccountComponent } from "./create-patient-account/create-patient-account.component"
import { CreatePrefilledAccountComponent } from "./create-prefilled-account/create-prefilled-account.component"
import { CreateSavFormComponent } from "./create-sav-form/create-sav-form.component"
import { DownloadReportErrorComponent } from "./download-report-error/download-report-error.component"
import { EditPatientAccountComponent } from "./edit-patient-account/edit-patient-account.component"
import { HomeComponent } from "./home/home.component"
import { IfuEuAndRowComponent } from "./legal/ifu/ifu-eu-and-row/ifu-eu-and-row.component"
import { IfuUsComponent } from "./legal/ifu/ifu-us/ifu-us.component"
import { PrivacyPoliciesComponent } from "./legal/privacy-policies/privacy-policies.component"
import { MaintenanceComponent } from "./maintenance/maintenance.component"
import { DemoSettingsComponent } from "./my-account/demo-settings/demo-settings.component"
import { MyAccountComponent } from "./my-account/my-account.component"
import { NotificationsComponent } from "./my-account/notifications/notifications.component"
import { PersonalInfoComponent } from "./my-account/personal-info/personal-info.component"
import { SecurityComponent } from "./my-account/security/security.component"
import { SignatureComponent } from "./my-account/signature/signature.component"
import { CreateTemplateComponent } from "./my-account/templates/create-template/create-template.component"
import { TemplatesListComponent } from "./my-account/templates/templates-list/templates-list.component"
import { UpdateTemplateComponent } from "./my-account/templates/update-template/update-template.component"
import { UnitsAndFormatsPreferencesComponent } from "./my-account/units-formats-preferences/units-formats-preferences.component"
import { UpdatePasswordComponent } from "./my-account/update-password/update-password.component"
import { NightReportComponent } from "./night-report/night-report.component"
import { NightsListComponent } from "./nights-list/nights-list.component"
import { PasswordConfirmedComponent } from "./password-confirmed/password-confirmed.component"
import { PatientsListComponent } from "./patients-list/patients-list.component"
import { PresciptionComponent } from "./presciption/presciption.component"
import { ResetPasswordComponent } from "./reset-password/reset-password.component"
import { SavProviderComponent } from "./sav-provider/sav-provider.component"
import { SetPasswordComponent } from "./set-password/set-password.component"
import { SharingManagementComponent } from "./sharing-management/sharing-management.component"
import { ShowPatientDetailsComponent } from "./show-patient-details/show-patient-details.component"
import { SignInComponent } from "./sign-in/sign-in.component"
import { SignUpComponent } from "./sign-up/sign-up.component"
import { StudiesListComponent } from "./studies-list/studies-list.component"
import { LoginActiveGuardService } from "../services/authGuards/login-active-guard.service"
import { LoginInactiveGuardService } from "../services/authGuards/login-inactive-guard.service"

const routes: Routes = [
    // Routes available for all users
    { path: "about", component: AboutComponent },
    { path: "confirm-account/:params", component: AccountConfirmedComponent },
    { path: "account-deletion-request-confirmed/:lang", component: AccountDeletionRequestConfirmedComponent },
    { path: "download-report-error/:error/:lang", component: DownloadReportErrorComponent },
    { path: "maintenance", component: MaintenanceComponent },
    { path: "reset-confirmed/:lang", component: PasswordConfirmedComponent },
    { path: "resetPassword/:lang", component: ResetPasswordComponent },
    { path: "sav-provider/:lang", component: SavProviderComponent },
    { path: "legal/privacy-policies", component: PrivacyPoliciesComponent },
    { path: "legal/ifu/eu-and-row", component: IfuEuAndRowComponent },
    { path: "legal/ifu/us", component: IfuUsComponent },

    // Routes available for disconnected users
    { path: "", component: HomeComponent, canActivate: [LoginInactiveGuardService] },
    { path: "login", component: SignInComponent, canActivate: [LoginInactiveGuardService] },
    { path: "signup", component: SignUpComponent, canActivate: [LoginInactiveGuardService] },

    // Routes available for connected users
    { path: "main", redirectTo: "main/patients", pathMatch: "full" },
    { path: "main/account", component: MyAccountComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/account/demo-settings", component: DemoSettingsComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/account/my", component: PersonalInfoComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/account/notifications", component: NotificationsComponent, canActivate: [LoginActiveGuardService] },
    {
        path: "main/account/preferences",
        component: UnitsAndFormatsPreferencesComponent,
        canActivate: [LoginActiveGuardService],
    },
    { path: "main/account/security", component: SecurityComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/account/signature", component: SignatureComponent, canActivate: [LoginActiveGuardService] },
    {
        path: "main/account/create-template",
        component: CreateTemplateComponent,
        canActivate: [LoginActiveGuardService],
    },
    {
        path: "main/account/update-template/:templateId",
        component: UpdateTemplateComponent,
        canActivate: [LoginActiveGuardService],
    },
    { path: "main/account/templates-list", component: TemplatesListComponent, canActivate: [LoginActiveGuardService] },
    {
        path: "main/account/update-password",
        component: UpdatePasswordComponent,
        canActivate: [LoginActiveGuardService],
    },
    { path: "main/admin/support", component: AdminSupportComponent, canActivate: [LoginActiveGuardService] },
    {
        path: "main/admin/create-prefilled-account",
        component: CreatePrefilledAccountComponent,
        canActivate: [LoginActiveGuardService],
    },
    { path: "main/admin/create-sav-form", component: CreateSavFormComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/create-patient", component: CreatePatientAccountComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/sharing-management", component: SharingManagementComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/maintenance", component: MaintenanceComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/nights/:nightId", component: NightReportComponent, canActivate: [LoginActiveGuardService] },
    {
        path: "main/nights/:nightId/version/:versionId",
        component: NightReportComponent,
        canActivate: [LoginActiveGuardService],
    },
    { path: "main/patients", component: PatientsListComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/patients/:id/nights", component: NightsListComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/patients/:id/edit", component: EditPatientAccountComponent, canActivate: [LoginActiveGuardService] },
    {
        path: "main/patients/:id/details",
        component: ShowPatientDetailsComponent,
        canActivate: [LoginActiveGuardService],
    },
    { path: "main/prescription", component: PresciptionComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/set-password", component: SetPasswordComponent, canActivate: [LoginActiveGuardService] },
    { path: "main/studies", component: StudiesListComponent, canActivate: [LoginActiveGuardService] },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
