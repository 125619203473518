<main id="nightReport-main" class="main">
    <!-- Loading night -->
    <div class="row row--guttered row--smaller content" *ngIf="isLoading">
        {{ 'nightReport_loading' | translate }}
    </div>
    <!-- Loading night -->

    <!-- Default report template -->
    <app-night-report-template-v1 *ngIf="templateReport == eTemplateReport.V1"
                                  [night]="night"
                                  [nightVersions]="nightVersions"
                                  [patient]="patient"
                                  [isLoading]="isLoading"
                                  [sampleReport]="isSampleReport"
                                  [nightVersionId]="nightVersionId"></app-night-report-template-v1>
    <!-- Default report template -->

    <!-- US report template -->
    <app-night-report-template-v2 *ngIf="templateReport == eTemplateReport.V2"
                                  #nightReportTemplateV2Component
                                  [night]="night"
                                  [nightVersions]="nightVersions"
                                  [patient]="patient"
                                  [isLoading]="isLoading"
                                  [sampleReport]="isSampleReport"
                                  [nightVersionId]="nightVersionId"></app-night-report-template-v2>
    <!-- US report template -->
</main>
