import { Component } from "@angular/core"
import { SimpleModalComponent } from "@looorent/ngx-simple-modal"

export interface ConfirmModel {
    message?: string
}

@Component({
    selector: "confirm",
    template: `
        <div class="modal-content">
            <div class="modal-header">
                <h4>{{ message | translate }}</h4>
            </div>
            <div class="modal-footer">
                <button class="button button--small" type="button" (click)="close()">
                    {{ "common_no" | translate }}
                </button>
                <button class="button button--small" type="button" style="margin-left: 0.5rem;" (click)="confirm()">
                    {{ "common_yes" | translate }}
                </button>
            </div>
        </div>
    `,
})
export class ConfirmComponent extends SimpleModalComponent<ConfirmModel, string> implements ConfirmModel {
    message = "common_confirm"

    public async confirm(): Promise<void> {
        this.result = "true"

        await this.close()
    }
}
