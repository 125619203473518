import { Injectable } from "@angular/core"
import { CanActivate, Router } from "@angular/router"
import { AuthService } from "../auth.service"

/**
 * LoginInactiveGuardService secures routes to authorize only un-logged users, implement CanActivate interface
 */
@Injectable({
    providedIn: "root",
})
export class LoginInactiveGuardService implements CanActivate {
    // ---------------------------------------------------------------------------
    // Constructor
    // ---------------------------------------------------------------------------

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {}

    // ---------------------------------------------------------------------------
    // Implemented methods
    // ---------------------------------------------------------------------------

    /**
     * Determine if a route can be activated or not according conditions
     * @return boolean
     */
    public canActivate(): boolean {
        const isAuthenticated: boolean = this.authService.checkUserIsAuthenticated()

        if (isAuthenticated) {
            this.navigateToMain()
        }

        return !isAuthenticated
    }

    // ---------------------------------------------------------------------------
    // Navigation helpers
    // ---------------------------------------------------------------------------

    private navigateToMain(): void {
        this.router.navigate(["/main"])
    }
}
