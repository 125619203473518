<main class="main">
    <div class="row row--guttered row--flex">
        <h1 class="title-h1" *ngIf="patient">{{ patientName }}</h1>

        <app-button *ngIf="patient && !isAdmin && hasCreatedPatientAccount"
                    (clicked)="navigateToEditPatient()"
        >{{ 'nightsList_editPatient' | translate }}</app-button>

        <app-button *ngIf="patient && !isAdmin && !hasCreatedPatientAccount"
                    (clicked)="navigateToShowPatientDetails()"
        >{{ 'nightsList_showPatientDetails' | translate }}</app-button>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div *ngIf="patient" class="row row--guttered">
        <a [routerLink]="['/main/patients']">{{ 'patientsList_page_title' | translate }}</a> / {{ patientName }}
    </div>

    <!-- Nights List -->
    <div class="row row--guttered row--smaller content" [style.padding-top]="0">
        <div class="row row--guttered-v">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'nightsList_allNights_section' | translate }}</div>
            </div>

            <div class="field-container field-container--checkbox">
                <input id="newNightsCheck" type="checkbox" (click)="toggleShowNewNightsOnly()">
                <label class="label" for="newNightsCheck">
                    {{ 'patientsList_only_new_nights_label' | translate }}
                    <span class="notification-dot"></span>
                </label>
                <span class="checkmark"></span>
            </div>

            <div *ngIf="!isLoading" class="list-row list-row--header">
                <app-sortable-list-header
                    class="col"
                    [columnName]="'nightsList_date_header_label'"
                    [columnSubtitle]="dateFormatLabel.toUpperCase()"
                    [columnValue]="'endDateTimeValue'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    [columnName]="'nightsList_sleep_duration_header_label'"
                    [columnValue]="'sleepDurationInHours'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    [columnName]="'nightsList_ordi_header_label'"
                    [columnValue]="'ordi'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    *ngIf="!showV2ScoreColumns"
                    [columnName]="'nightsList_ai_header_label'"
                    [columnValue]="'ai'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    *ngIf="showV2ScoreColumns"
                    [columnName]="'nightsList_ahi_header_label'"
                    [columnValue]="'ahi'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    *ngIf="!isAdmin && showV2ScoreColumns"
                    [columnName]="'studiesList_study_status'"
                    [columnValue]="'analysisStatus'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <div class="col col--see-more"></div>
            </div>

            <div *ngFor="let night of nights | listSort: sortColumnValue : sortOrder; let last = last">
                <div *ngIf="night.isAnalyzed && !night.reportHiddenOnPlatform" class="list-row list-row--clickable" [ngClass]="{'list-row--no-border': last}" (click)="navigateToNight(night)" [style.display]="!showNewNightsOnly || night.newNight ? 'flex' : 'none'">
                    <div class="notification-dot" [style.display]="night.newNight ? 'block' : 'none'"></div>
                    <div class="col">{{ formatDate(night.endDateTimeValue) }}</div>
                    <div class="col">{{ formatDuration(night.sleepDurationInHours) }}</div>
                    <div class="col" *ngIf="!showV2ScoreColumns">{{ formatScore(night.ordi) }}</div>
                    <div class="col" *ngIf="showV2ScoreColumns">{{ formatScore(night.ordi, night.scoringRule) }}</div>
                    <div class="col" *ngIf="!showV2ScoreColumns">{{ formatScore(night.ai) }}</div>
                    <div class="col" *ngIf="showV2ScoreColumns">{{ formatScore(night.ahi, night.scoringRule) }}</div>
                    <div class="col" *ngIf="!isAdmin && showV2ScoreColumns" [ngClass]="night.analysisStatus == 'analysis_status_signed' ? 'signed' : ''">
                        {{ night.analysisStatus | translate }}
                        <img *ngIf="night.analysisStatus == 'analysis_status_signed'" style="padding-left: 0.5rem;" src="../assets/images/icon_valid_light__green.svg"/>
                    </div>
                    <div class="col col--see-more"><a class="internal">{{ 'common_see_more' | translate }}</a><img style="padding-left: 0.5rem;" src="../assets/images/right-arrow.svg"/></div>
                </div>

                <div *ngIf="!night.isAnalyzed" class="list-row list-row--disabled" [ngClass]="{'list-row--no-border': last}" [style.display]="!showNewNightsOnly || night.newNight ? 'flex' : 'none'">
                    <div class="col">{{ formatDate(night.endDateTime.date) }}</div>
                    <div class="col">-</div>
                    <div class="col">-</div>
                    <div class="col">-</div>
                    <div class="col" *ngIf="!isAdmin && showV2ScoreColumns">-</div>
                    <div class="col col--see-more">{{ 'nightsList_processing_label' | translate }}</div>

                </div>
            </div>

            <div class="list-row list-row--no-border" *ngIf="patient" [style.display]="isLoading ? 'flex' : 'none'">{{ 'nightsList_loading' | translate: { firstName: patient.firstName, lastName: patient.lastName } }}</div>

            <div class="list-row list-row--no-border" [style.display]="!hasNights && !isLoading ? 'flex' : 'none'">{{ 'nightsList_empty' | translate }}</div>
        </div>
        <!-- ! Nights List -->

        <!-- Assignations List -->
        <div *ngIf="isUSPractitioner || isAdmin" class="row row--guttered-v">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'nightsList_assignedSensorsList_section' | translate }}</div>
            </div>

            <div class="list-row list-row--header">
                <div class="col">{{ 'nightsList_assignedSensorsList_sensor' | translate }}</div>
                <div class="col">{{ 'nightsList_assignedSensorsList_numberOfUses' | translate }}</div>
                <div class="col">{{ 'nightsList_assignedSensorsList_hasPinCode' | translate }}</div>
                <div class="col col--see-more"></div>
            </div>

            <div *ngFor="let sensorAssignation of assignedSensors; let last = last" class="list-row" [ngClass]="{'list-row--no-border': last}">
                <div class="col">{{ sensorAssignation.sensorSerialNumber }}</div>
                <div class="col">{{ sensorAssignation.numberOfUsesByTheUser }}</div>
                <div class="col">{{ (sensorAssignation.hasPinCode ? 'common_yes' : 'common_no') | translate }}</div>

                <div class="col col--see-more" *ngIf="isAdmin || !isUSPractitioner"></div>
                <div class="col col--see-more" *ngIf="!isAdmin && isUSPractitioner && sensorAssignation.isRemoving">
                    <a class="button-like button-like--disabled button-like--no-margin">{{ 'nightsList_assignedSensorsList_deleting' | translate }}</a>
                </div>
                <div class="col col--see-more" *ngIf="!isAdmin && isUSPractitioner && !sensorAssignation.isRemoving && sensorAssignation.numberOfUsesByTheUser == 0">
                    <a class="button-like button-like--no-margin" (click)="confirmAssignationDeletionAsync(sensorAssignation.sensorToUserId)">{{ 'nightsList_assignedSensorsList_deleteSensor' | translate }}</a>
                </div>
                <div class="col col--see-more" *ngIf="!isAdmin && isUSPractitioner && !sensorAssignation.isRemoving && sensorAssignation.numberOfUsesByTheUser > 0">
                    <a class="button-like button-like--disabled button-like--no-margin">{{ 'nightsList_assignedSensorsList_deleteSensor' | translate }}</a>
                    <div class="button-like--tooltip">{{ "nightsList_assignedSensorsList_cantDeleteSensor" | translate }}</div>
                </div>
            </div>

            <div class="list-row list-row--no-border" *ngIf="!assignedSensors || assignedSensors.length == 0">
                {{ 'nightsList_assignedSensorsList_noSensors' | translate }}
            </div>
        </div>
        <!-- ! Assignations List -->

        <!-- Add Assignation -->
        <div class="row row--guttered-v" *ngIf="!isAdmin && isUSPractitioner">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'nightsList_assignSensor_section' | translate }}</div>
            </div>

            <app-sensors-to-patient-assignation #sensorAssignationFields></app-sensors-to-patient-assignation>

            <app-button
                [loading]="isAssigning"
                (clicked)="assignSensorToPatientAsync()"
                [customClasses]="'button--small'"
            >
                {{ 'nightsList_assignSensor_button' | translate }}
            </app-button>
        </div>
         <!-- ! Add Assignation -->
    </div>
</main>
