<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'accountConfirmation_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <!-- Account confirmed -->
    <div *ngIf="!errorOccurred" class="row row--guttered row--smaller content">
        <p #confirmationTitle></p>
        <p #confirmationSubtitle></p>

        <div>
            <app-button
                *ngIf="accountType == 'doctor'"
                (clicked)="navigateToLogin()"
                [customClasses]="'sign-in-button button--margin-top'"
            >
                {{ 'signIn_action_signIn' | translate }}
            </app-button>
        </div>
    </div>

    <!-- Error occurred -->
    <div *ngIf="errorOccurred" class="row row--guttered row--smaller content">
        <p>{{ 'accountConfirmation_error_title' | translate }}</p>
        <p>{{ 'accountConfirmation_error_subtitle' | translate }}</p>
    </div>
</main>
