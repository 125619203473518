import { Injectable } from "@angular/core"
import { NightSignal } from "../models/Nights/NightSignal"
import { NightSignalType } from "../models/Nights/NightSignalType"

@Injectable({
    providedIn: "root",
})
export class NightReportService {
    public getNightSignalValues(signals: NightSignal[], signalType: NightSignalType): any[] {
        if (!signals) return

        const nightSignal: NightSignal = signals.find((signal) => signal.signalType == signalType)

        if (!nightSignal) return

        return nightSignal.data
    }
}
