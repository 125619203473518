import { Directive, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as FileSaver from "file-saver"
import { constants } from "../../../config/constants"
import { ChartType } from "../../../models/Nights/ChartType"
import { Night } from "../../../models/Nights/Night"
import { NightData } from "../../../models/Nights/NightData"
import { Patient } from "../../../models/Patient"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { getPatientFullNameOrId } from "../../../utilities/getPatientFullName"
import { BaseComponent } from "../../base.component"

@Directive()
export class NightReportTemplateComponent extends BaseComponent implements OnInit {
    @Input("night") night: Night
    @Input("nightVersions") nightVersions: any
    @Input("patient") patient: Patient

    @Input("nightVersionId") nightVersionId: string
    @Input("isLoading") isLoading: boolean

    @Input("sampleReport") isSampleReport: boolean

    /* Checks */
    public isExportingReport = false

    /* Roles */
    public isResearcher: boolean
    public isAdmin: boolean

    /* Enum */
    public eChartType: typeof ChartType = ChartType

    public dropDownVersionItems: { nightVersionId: string; text: string }[] = []
    public dropDownVersionSelectedItem: { nightVersionId: string; text: string }

    public componentsVersionsContent: string

    public patientName: string = ""

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    public async ngOnInit(): Promise<void> {
        if (!this.canConsultReports) {
            this.navigateToMainPage()
        } else {
            this.isAdmin = this.sunriseApiService.isAdmin()
            this.isResearcher = this.sunriseApiService.accountHasRole(constants.roles.researcher)

            this.formatVersionsNumbers()
        }
    }

    public ngAfterViewInit(): void {
        this.patientName = getPatientFullNameOrId(
            this.patient.firstName,
            this.patient.lastName,
            this.patient.customIdentifier,
        )
    }

    public async createDropDownItemsAsync(): Promise<void> {
        this.dropDownVersionItems = []
        this.dropDownVersionSelectedItem = undefined

        if (this.nightVersions) {
            for (const nightVersion of this.nightVersions) {
                const text: string = !nightVersion.doctorName
                    ? await this.__("dropDownNights_label_initial")
                    : `${await this.__("dropDownNights_label_prefilled")} ${nightVersion["doctorName"]} ${
                          nightVersion.formattedCreationDate ? ` - ${nightVersion.formattedCreationDate}` : ""
                      } (${nightVersion.scoringRule})`

                this.dropDownVersionItems.push({
                    nightVersionId: nightVersion.nightVersionId,
                    text,
                })
            }

            this.dropDownVersionItems.sort((a, b) => {
                if (a.nightVersionId == undefined) return 1
                if (b.nightVersionId == undefined) return -1
                return b.nightVersionId.localeCompare(a.nightVersionId)
            })

            this.dropDownVersionSelectedItem = this.dropDownVersionItems.find(
                (item) => item.nightVersionId == this.nightVersionId,
            )
        }
    }

    // -------------------------------------------------------------------------
    // Night sections
    // -------------------------------------------------------------------------

    public getNightData(nightDataId: string): NightData | NightData[] {
        const foundNightData: NightData | NightData[] = Object.values(this.night.nightDataTemplate)
            .map((nightDataSection) =>
                Array.isArray(nightDataSection[0]) ? (nightDataSection as NightData[][]).flat() : nightDataSection,
            )
            .flat()
            .find((nightData) => nightData["nightDataId"] == nightDataId)

        return foundNightData
    }

    // -------------------------------------------------------------------------
    // Export pdf
    // -------------------------------------------------------------------------

    public async exportReport(): Promise<void> {
        if (this.isExportingReport) return

        this.isExportingReport = true

        try {
            let nightReportPDF: { blob: Blob; filename: string }

            if (this.isSampleReport) {
                nightReportPDF = await this.sunriseApiService.getNightReportSampleAsBlobAsync()
            } else if (this.nightVersionId != null && this.nightVersionId != "0") {
                nightReportPDF = await this.sunriseApiService.getNightVersionReportAsBlobAsync(
                    this.night.nightId,
                    this.nightVersionId,
                    this.patient.id,
                )
            } else {
                nightReportPDF = await this.sunriseApiService.getNightReportAsBlobAsync(
                    this.isAdmin,
                    this.night.nightId,
                    this.patient.id,
                )
            }

            FileSaver.saveAs(nightReportPDF.blob, nightReportPDF.filename)
        } catch (error) {
            this.showErrorOccurred()
        } finally {
            this.isExportingReport = false
        }
    }

    // -------------------------------------------------------------------------
    // Navigation delegates
    // -------------------------------------------------------------------------

    public navigateBack(): void {
        this.sunriseApiService.resetPatient()

        this.router.navigate([`main/patients/${this.patient.id}/nights`])
    }

    public navigateToNightVersion(nightVersionId: string): void {
        if (nightVersionId) {
            this.router.navigate([`main/nights/${this.night.nightId}/version/${nightVersionId}`])
        } else {
            this.router.navigate([`main/nights/${this.night.nightId}`])
        }
    }
    // -------------------------------------------------------------------------
    // Versions number
    // -------------------------------------------------------------------------

    private formatVersionsNumbers(): void {
        let componentsVersionsContent = `Sunrise Sensor V${this.night.versionSensorFirmware}`

        if (this.night.versionApp != null) {
            componentsVersionsContent += ` - Sunrise App V${this.night.versionApp}`
        }

        if (this.night.versionAlgorithm != null) {
            componentsVersionsContent += ` - Sunrise Algorithm V${this.night.versionAlgorithm}`
        }

        if (this.night.versionApiProcessing != null) {
            componentsVersionsContent += ` - Sunrise API V${this.night.versionApiProcessing} (processing)/V${this.night.versionApiDisplay} (display)`
        } else {
            componentsVersionsContent += ` - Sunrise API V${this.night.versionApiDisplay} (display)`
        }

        this.componentsVersionsContent = componentsVersionsContent
    }
}
