import { HttpClient, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { constants } from "../config/constants"

/**
 * SunriseApiMktService facilitates the communication with the Sunrise MKT API.
 */
@Injectable({
    providedIn: "root",
})
export class SunriseApiMktService {
    private static readonly API_URI: string = `${constants.api.marketing.baseUri}/api/v1`
    private static readonly API_KEY: string = constants.api.marketing.securityKey

    constructor(private http: HttpClient) {}

    public async getZohoOrderInformationAsync(
        serialNumber: string,
        emailAddress: string,
        endNightDate: Date,
    ): Promise<any> {
        const path = "support/orderInformation"
        const body: object = { serialNumber, emailAddress, endNightDate }

        return await this.postAsync(path, body)
    }

    public async addZohoSensorLinkedNCF(serialNumber: string, ncfNumber: string): Promise<any> {
        const path = "support/updateSensorLinkedNCF"
        const body: object = { serialNumber, ncfNumber }

        return await this.postAsync(path, body)
    }

    public async deleteZohoSensorLinkedNCF(serialNumber: string): Promise<any> {
        const path = "support/updateSensorLinkedNCF"
        const body: object = { serialNumber, removeExistingNCF: true }

        return await this.postAsync(path, body)
    }

    public async sendNewSensorAsync(orderSubject: string, currentSensor: string): Promise<any> {
        const path = "support/resendSensor"
        const body: object = { orderSubject, currentSensor }

        return await this.postAsync(path, body)
    }

    public async answerUserOnIntercomAsync(conversationId: string, message: string): Promise<any> {
        const path = "support/sendUserAnswer"
        const body: object = { conversationId, message }

        return await this.postAsync(path, body)
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    private getApiUri(path: string, params: object = {}): string {
        if (!params["lang"]) {
            params["lang"] = localStorage.getItem("currentLanguage")
        }

        params["key"] = SunriseApiMktService.API_KEY

        const paramsString: string = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&")

        return `${SunriseApiMktService.API_URI}/${path}?${paramsString}`
    }

    /**
     * Make a POST request
     * @param {string} path API path to call
     * @param {any} body Body of the request
     * @param {object} params Params of the query
     * @return the parsed API response
     * @throw API errors to be handled by the caller
     */
    private async postAsync(path: string, body: any, params: object = {}): Promise<any> {
        const uri: string = this.getApiUri(path, params)

        const response: HttpResponse<object> = await new Promise((resolve, reject) => {
            this.http.post(uri, body, { observe: "response" }).subscribe({
                next: (res) => resolve(res),
                error: (err) => reject(err),
            })
        })

        return response.body
    }
}
