<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'about_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--smaller content">
        <p>
            {{ 'about_versions' | translate }}
            <br>{{ 'about_diagnostic_aid_version' | translate: { 'version': '1.28.10' } }}
            <br>{{ 'about_platform_version' | translate: { 'version': '1.25.10' } }}
        </p>

        <p><img src="../assets/images/sunrise_udi.png" alt="" style="max-height: 80px;"></p>

        <p>{{ 'about_versions_date' | translate: { 'date': '2024-04-22' } }}</p>

        <p>{{ 'about_HW_SW_requirements' | translate }}</p>

        <p>
            <img style="width: 40px;height: auto;" src="../assets/images/ifu.jpg">
            {{ 'about_instructions' | translate }}<br>
            {{ 'about_instructions_availability' | translate }}
        </p>

        <p>{{ 'about_contact' | translate }} <a href="mailto:hello@hellosunrise.com">hello&#64;hellosunrise.com</a></p>

        <p style="display: flex; align-items: center;">
            <img style="width: 40px; height: auto; margin-right: 0.5rem;" src="../assets/images/medical_device.png">
        </p>

        <p style="display: flex; align-items: center;">
            <img style="width: 40px; height: auto; margin-right: 0.5rem;" src="../assets/images/ref.jpg">
            SUN1XX
        </p>

        <p style="display: flex; align-items: center;">
            <img style="width: 40px; height: auto; margin-right: 0.5rem;" src="../assets/images/ce.png">
            0297
        </p>

        <p style="display: flex; align-items: center;">
            <img style="width: 40px; height: auto; margin-right: 0.5rem;" src="../assets/images/manufacture.jpg">
            Sunrise SA, Chaussée de Marche 598/02, 5101 Namur, Belgium
        </p>
    </div>
</main>
