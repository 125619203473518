export type PortalNotification = {
    title?: string
    description?: string

    linkTitle?: string
    linkCallback?: () => void

    notificationType?: PortalNotificationType

    displayTime?: number
}

export enum PortalNotificationType {
    Success,
    Information,
    Warning,
    Error,
}
