import { TranslateService } from "@ngx-translate/core"
import { ValidatableRule } from "./ValidatableRule"

export class MinLengthRule extends ValidatableRule {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        translate: TranslateService,
        private readonly minLength: number,
        errorStringTemplate?: string,
    ) {
        super(translate, errorStringTemplate || "i18n:common_validation_error_minLength", { minLength })
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    protected validateRule(value: any): boolean {
        return value.length >= this.minLength
    }
}
