import { Component, Input } from "@angular/core"

@Component({
    selector: "app-readonly-field",
    templateUrl: "./readonly-field.component.html",
})
export class ReadonlyFieldComponent {
    // -------------------------------------------------------------------------
    // Instance variables
    // -------------------------------------------------------------------------

    @Input("label") label: string
    @Input("value") value: any
}
