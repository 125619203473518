import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"
import {
    init as initSentry,
    BrowserTracing as SentryBrowserTracing,
    Replay as SentryReplay,
    routingInstrumentation as sentryRoutingInstrumentation,
} from "@sentry/angular-ivy"
import { AppModule } from "./app/app.module"
import { constants } from "./config/constants"

if (constants.env === "production" || constants.env === "testing") {
    enableProdMode()
}

if (constants.sentry.isEnabled) {
    initSentry({
        dsn: "https://e4fc661c45a028e52035bd40adb67b42@o4505396416806912.ingest.sentry.io/4505902058307584",
        environment: constants.env,
        integrations: [
            new SentryBrowserTracing({
                tracePropagationTargets: [
                    "localhost",
                    /sunrise-api-dev-nhvaepm3xa-ew.a.run.app/,
                    /sunrise-api-testing-nhvaepm3xa-ew.a.run.app/,
                    /sunrise-api-m7cheyciwq-ew.a.run.app/,
                    /sunrise-api-us-m7cheyciwq-uc.a.run.app/,
                ],
                routingInstrumentation: sentryRoutingInstrumentation,
            }),
            new SentryReplay(),
        ],
        replaysOnErrorSampleRate: 1,
        replaysSessionSampleRate: 1,
        tracesSampleRate: 1,
    })
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
