<div class="report-section report-section--minified ">
    <!-- Separator -->
    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <!-- Section title -->
    <div class="row row--guttered-h section-title-container">
        <h2 class="title-h2 section-title" (click)="toggleReportSection($event)">{{ 'nightReport_child_questionnaire_section' | translate }}</h2>
    </div>
    <!-- Section title -->

    <div class="section-content">
        <div *ngIf="answerDate"
             class="row row--guttered-h">
            <p class='question-answer'>
                {{ 'nightReport_answers_date' | translate: {'date': answerDate} }}
            </p>
        </div>

        <!-- Data -->
        <div class="row row--guttered row--smaller content" *ngIf="night?.questionnaire?.sections && night?.questionnaire?.sections[0]?.questions">
            <div class="question" *ngFor="let question of night.questionnaire.sections[0].questions">
                <h3 class="title-h3 question-title">{{ question.displayedTitle }}</h3>
                <ng-container
                    *ngIf="isRangeQuestion(question.questionType); then rangeQuestion; else radioQuestion">
                </ng-container>
                <ng-template #rangeQuestion>
                    <div class="range-container">
                        <div class="range-wrapper"
                             *ngFor="let range of getAdaptedRanges(question); let index = index">
                            <img src="{{ (isAnswer(question, night.questionnaireAnswers, index)) ? '../assets/images/checkbox__checked_light.png' : '../assets/images/checkbox__unchecked_light.png' }}"/>

                            <p>{{ range }}</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template #radioQuestion>
                    <div *ngIf="isRadioQuestion(question); else textQuestion">
                        <p class='question-answer'>
                            {{ this.getRadioAnswer(question, night.questionnaireAnswers) }}
                        </p>
                    </div>
                </ng-template>
                <ng-template #textQuestion>
                    <p class='question-answer'>
                        {{ (this.getAnswer(question, night.questionnaireAnswers) == 'true' ? ('common_yes' | translate) : ( this.getAnswer(question, night.questionnaireAnswers) == 'false' ? ('common_no' | translate) :  this.getAnswer(question, night.questionnaireAnswers))) + ' '
                    + (this.getAnswer(question, night.questionnaireAnswers) != '-' && question.textFormat == 'minutes' ? ('common_minutes_lower' | translate) : '') }}
                    </p>
                </ng-template>
            </div>
        </div>
        <!-- Data -->
    </div>
</div>
