export class HttpUtilities {
    public static getErrorCodeFromHttpError(httpError: any): string {
        let errorCode: string

        // get error code from exception
        try {
            errorCode = httpError.error.errors[0].code
        } catch {
            // nothing to do, errorCode is undefined
        }

        return errorCode
    }
}
