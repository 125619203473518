import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-download-report-error",
    templateUrl: "./download-report-error.component.html",
    styleUrls: ["./download-report-error.component.sass"],
})
export class DownloadReportErrorComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.useLanguage(RoutesUtilities.getRouteParam(this.activatedRoute, "lang"))
        const error: string = RoutesUtilities.getRouteParam(this.activatedRoute, "error")

        await this.fillDownloadError(error)
    }

    private async fillDownloadError(error: string) {
        const downloadErrorLabel: HTMLElement = document.getElementById("download-error-label")
        if (downloadErrorLabel) {
            switch (error) {
                case "not_found":
                    downloadErrorLabel.innerText = await this.__("downloadReportError_notFound")
                    break
                case "token_expired":
                    downloadErrorLabel.innerText = await this.__("downloadReportError_tokenExpired")
                    break
                default:
                    downloadErrorLabel.innerText = await this.__("downloadReportError_default")
            }
        }
    }
}
