<!-- Notifications container -->
<app-notifications-container></app-notifications-container>

<!-- Image viewer -->
<app-image-viewer></app-image-viewer>

<!-- Navbar -->
<app-navbar id="navBar"></app-navbar>

<!-- Pages -->
<div class="router">
    <router-outlet></router-outlet>
</div>

<!-- Footer -->
<footer class="footer" id="footer">
    <div class="separator"></div>

    <div class="footer-items">
        <a href="{{ staticFilesBaseUri }}/sunrise-platform-terms_of_use.pdf" class="footer-item">{{ 'footer_terms_of_use' | translate }}</a>
        <a href="/legal/privacy-policies" class="footer-item">{{ 'legal_privacyPolicies' | translate }}</a>
        <a href="/legal/ifu/eu-and-row" class="footer-item">{{ 'footer_user_manual' | translate }}</a>
        <a href="/legal/ifu/us" class="footer-item">{{ 'footer_user_manual_usa' | translate }}</a>
        <a href="/about" class="footer-item">{{ 'footer_about' | translate }}</a>
        <span class="footer-item">© {{ copyrightYear }} Sunrise</span>
    </div>
</footer>
