import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-account-confirmed",
    templateUrl: "./account-confirmed.component.html",
    styleUrls: ["./account-confirmed.component.sass"],
})
export class AccountConfirmedComponent extends BaseComponent implements OnInit {
    @ViewChild("confirmationTitle") titleText: ElementRef
    @ViewChild("confirmationSubtitle") subtitleText: ElementRef

    public accountType: string
    public errorOccurred = false

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly activatedRoute: ActivatedRoute,
        protected readonly changesDetector: ChangeDetectorRef,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        try {
            const base64params: string = RoutesUtilities.getRouteParam(this.activatedRoute, "params")
            const params: any = JSON.parse(atob(base64params))

            if (params) {
                this.useLanguage(params.lang)

                this.accountType = params["accountType"]
                const alreadyConfirmed = params["alreadyConfirmed"]

                this.changesDetector.detectChanges()

                if (!alreadyConfirmed) {
                    this.titleText.nativeElement.innerText = await this.__("accountConfirmation_confirmed_title")
                } else {
                    this.titleText.nativeElement.innerText = await this.__("accountConfirmation_alreadyConfirmed_title")
                }

                if (this.accountType == "doctor") {
                    this.subtitleText.nativeElement.innerText = await this.__("accountConfirmation_doctor_subtitle")
                } else {
                    this.subtitleText.nativeElement.innerText = await this.__("accountConfirmation_patient_subtitle")
                }
            }
        } catch {
            this.errorOccurred = true
        }
    }

    public navigateToLogin(): void {
        this.router.navigate(["/login"])
    }
}
