import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core"

@Component({
    selector: "autocomplete-input",
    templateUrl: "./autocomplete-input.component.html",
    styleUrls: ["./autocomplete-input.component.sass"],
})
export class AutocompleteInputComponent {
    @Input("placeholder") placeholder: string = ""
    @Input("proposals") proposals: string[]
    @Input("showAddButton") showAddButton: boolean = false

    @Output("validateInput") validateInput: EventEmitter<string> = new EventEmitter<string>()

    @ViewChild("inputElement") inputElement: ElementRef
    @ViewChild("proposalsContainer") proposalsContainerElement: ElementRef

    public preselectedProposal: Element | undefined

    public emitInput(value: string): void {
        this.validateInput.emit(value)
        this.inputElement.nativeElement.focus()

        this.clearPreselectedProposal()
    }

    public inputChangeEvent(): void {
        this.inputElement.nativeElement.classList.remove("input--invalid")

        this.clearPreselectedProposal()
    }

    public inputEnterPressed(value: string): void {
        if (this.preselectedProposal && !this.preselectedProposal.classList.contains("container__item--default")) {
            this.emitInput(this.preselectedProposal.innerHTML)
        } else {
            this.emitInput(value)
        }
    }

    public preselectNextProposal(): void {
        if (this.preselectedProposal) {
            const nextSibling: Element | undefined = this.preselectedProposal.nextElementSibling

            if (nextSibling) {
                this.preselectedProposal.classList.remove("container__item--selected")
                nextSibling.classList.add("container__item--selected")
                nextSibling.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })

                this.preselectedProposal = nextSibling
            }
        } else {
            if (this.proposalsContainerElement) {
                const firstProposal: Element =
                    this.proposalsContainerElement.nativeElement.querySelector(".container__item")

                if (firstProposal) {
                    firstProposal.classList.add("container__item--selected")
                    firstProposal.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })

                    this.preselectedProposal = firstProposal
                }
            }
        }
    }

    public preselectPreviousProposal(): void {
        if (this.preselectedProposal) {
            const previousSibling: Element | undefined = this.preselectedProposal.previousElementSibling

            if (previousSibling) {
                this.preselectedProposal.classList.remove("container__item--selected")
                previousSibling.classList.add("container__item--selected")
                previousSibling.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })

                this.preselectedProposal = previousSibling
            }
        }
    }

    public preselectProposal(event: any): void {
        if (this.preselectedProposal) {
            this.preselectedProposal.classList.remove("container__item--selected")
        }

        event.target.classList.add("container__item--selected")

        this.preselectedProposal = event.target
    }

    public clearPreselectedProposal(): void {
        if (this.preselectedProposal) {
            this.preselectedProposal.classList.remove("container__item--selected")

            this.preselectedProposal = undefined
        }
    }
}
