<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'downloadReportError_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--smaller content">
        <p id="download-error-label"></p>
    </div>
</main>
