import { HttpBackend, HttpClient, HttpClientModule } from "@angular/common/http"
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
} from "@angular/material-moment-adapter"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { Router } from "@angular/router"
import { NgxIntlTelInputModule } from "@justin-s/ngx-intl-tel-input"
import { SimpleModalModule } from "@looorent/ngx-simple-modal"
import { TranslateLoader, TranslateModule } from "@ngx-translate/core"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"
import { createErrorHandler as sentryCreateErrorHandler, TraceService as SentryTraceService } from "@sentry/angular-ivy"
import { HighchartsChartModule } from "highcharts-angular"
import { CookieService } from "ngx-cookie-service"
import { CarouselModule } from "ngx-owl-carousel-o"
import { AboutComponent } from "./about/about.component"
import { AccountConfirmedComponent } from "./account-confirmed/account-confirmed.component"
import { AccountDeletionRequestConfirmedComponent } from "./account-deletion-request-confirmed/account-deletion-request-confirmed.component"
import { AccountFieldsComponent } from "./account-fields/account-fields.component"
import { AdminSupportComponent } from "./admin-support/admin-support.component"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { CreatePatientAccountComponent } from "./create-patient-account/create-patient-account.component"
import { CreatePrefilledAccountComponent } from "./create-prefilled-account/create-prefilled-account.component"
import { CreateSavFormComponent } from "./create-sav-form/create-sav-form.component"
import { DownloadReportErrorComponent } from "./download-report-error/download-report-error.component"
import { EditPatientAccountComponent } from "./edit-patient-account/edit-patient-account.component"
import { HomeComponent } from "./home/home.component"
import { ImageViewerComponent } from "./image-viewer/image-viewer.component"
import { IfuEuAndRowComponent } from "./legal/ifu/ifu-eu-and-row/ifu-eu-and-row.component"
import { IfuUsComponent } from "./legal/ifu/ifu-us/ifu-us.component"
import { PrivacyPoliciesComponent } from "./legal/privacy-policies/privacy-policies.component"
import { MaintenanceComponent } from "./maintenance/maintenance.component"
import { DemoSettingsComponent } from "./my-account/demo-settings/demo-settings.component"
import { MyAccountComponent } from "./my-account/my-account.component"
import { NotificationsComponent } from "./my-account/notifications/notifications.component"
import { PersonalInfoComponent } from "./my-account/personal-info/personal-info.component"
import { SecurityComponent } from "./my-account/security/security.component"
import { SignatureComponent } from "./my-account/signature/signature.component"
import { CreateTemplateComponent } from "./my-account/templates/create-template/create-template.component"
import { TemplateFormComponent } from "./my-account/templates/template-form/template-form.component"
import { TemplatesListComponent } from "./my-account/templates/templates-list/templates-list.component"
import { UpdateTemplateComponent } from "./my-account/templates/update-template/update-template.component"
import { UnitsAndFormatsPreferencesComponent } from "./my-account/units-formats-preferences/units-formats-preferences.component"
import { UpdatePasswordComponent } from "./my-account/update-password/update-password.component"
import { ConnectedNavbarComponent } from "./navbar/connected-navbar/connected-navbar.component"
import { DisconnectedNavbarComponent } from "./navbar/disconnected-navbar/disconnected-navbar.component"
import { NavbarComponent } from "./navbar/navbar/navbar.component"
import { NightReportAnalysisComponent } from "./night-report/night-report-analysis/night-report-analysis.component"
import { NightReportChartComponent } from "./night-report/night-report-chart/night-report-chart.component"
import { NightReportEmbedPDF } from "./night-report/night-report-embed-pdf/night-report-embed-pdf.component"
import { NightReportRawDataComponent } from "./night-report/night-report-raw-data/night-report-raw-data.component"
import { NightReportSectionComponent } from "./night-report/night-report-section/night-report-section.component"
import { NightReportTabLineComponent } from "./night-report/night-report-tab-line/night-report-tab-line.component"
import { NightReportTemplateV1Component } from "./night-report/night-report-templates/night-report-template-v1/night-report-template-v1.component"
import { NightReportTemplateV2Component } from "./night-report/night-report-templates/night-report-template-v2/night-report-template-v2.component"
import { NightReportComponent } from "./night-report/night-report.component"
import { ChildQuestionnaireComponent } from "./night-report/questionnaires-sections/child-questionnaire/child-questionnaire.component"
import { EssQuestionnaireComponent } from "./night-report/questionnaires-sections/ess-questionnaire/ess-questionnaire.component"
import { IsiQuestionnaireComponent } from "./night-report/questionnaires-sections/isi-questionnaire/isi-questionnaire.component"
import { MctqQuestionnaireComponent } from "./night-report/questionnaires-sections/mctq-questionnaire/mctq-questionnaire.component"
import { NightsListComponent } from "./nights-list/nights-list.component"
import { NotificationsContainerComponent } from "./notifications-container/notifications-container.component"
import { PasswordConfirmedComponent } from "./password-confirmed/password-confirmed.component"
import { PasswordFieldComponent } from "./password-field/password-field.component"
import { PatientListFilterPipe } from "./patients-list/patients-list-filter-pipe"
import { PatientsListComponent } from "./patients-list/patients-list.component"
import { PresciptionComponent } from "./presciption/presciption.component"
import { ResetPasswordComponent } from "./reset-password/reset-password.component"
import { SavProviderComponent } from "./sav-provider/sav-provider.component"
import { SetPasswordComponent } from "./set-password/set-password.component"
import { SharingManagementFilterPipe } from "./sharing-management/sharing-management-filter.pipe"
import { SharingManagementComponent } from "./sharing-management/sharing-management.component"
import { ReadonlyFieldComponent } from "./show-patient-details/show-patient-details-field/readonly-field.component"
import { ShowPatientDetailsComponent } from "./show-patient-details/show-patient-details.component"
import { SignInComponent } from "./sign-in/sign-in.component"
import { SignUpComponent } from "./sign-up/sign-up.component"
import { SortableListHeaderComponent } from "./sortable-list/sortable-list-header-component/sortable-list-header.component"
import { StudyListFilterPipe } from "./studies-list/studies-list-filter-pipe"
import { StudiesListComponent } from "./studies-list/studies-list.component"
import { ButtonComponent } from "./tools/button/button.component"
import { DelayButtonComponent } from "./tools/delay-button/delay-button.component"
import { DropDownMenuComponent } from "./tools/drop-down-menu/drop-down-menu.component"
import { AutocompleteInputFilterPipe } from "./tools/inputs/autocomplete-input/autocomplete-input-filter.pipe"
import { AutocompleteInputComponent } from "./tools/inputs/autocomplete-input/autocomplete-input.component"
import { PhoneNumberInputComponent } from "./tools/inputs/phone-number-input/phone-number-input.component"
import { ValidatableCheckboxComponent } from "./tools/inputs/validatable-checkbox/validatable-checkbox.component"
import { ValidatableDateComponent } from "./tools/inputs/validatable-date/validatable-date.component"
import { ValidatableInputComponent } from "./tools/inputs/validatable-input/validatable-input.component"
import { ValidatableSelectComponent } from "./tools/inputs/validatable-select/validatable-select.component"
import { ValidatableTextAreaComponent } from "./tools/inputs/validatable-textarea/validatable-textarea.component"
import { SensorsToPatientAssignationComponent } from "./tools/sensors-to-patient-assignation/sensors-to-patient-assignation.component"
import { SwitchButtonComponent } from "./tools/switch-button/switch-button.component"
import { RawDataCacheLocalForageDataAccess } from "../dataAccess/cache/localForage/RawDataCacheLocalForageDataAccess"
import { ConfirmComponent } from "../dialogs/confirm.component"
import { PromptComponent } from "../dialogs/prompt.component"
import {
    DatePickerDDMMYYYYFormatDirective,
    DatePickerMMDDYYYYFormatDirective,
} from "../directives/date-picker-format/date-picker-format.directive"
import { DragDropFileDirective } from "../directives/drag-drop-file/drag-drop-file.directive"
import { ImageWithViewerDirective } from "../directives/image-with-viewer/image-with-viewer.directive"
import { NgForEmptyDirective } from "../directives/ng-for-empty/ng-for-empty.directive"
import { SunriseApiMktService } from "../services/sunrise-api-mkt.service"
import { SunriseApiService } from "../services/sunrise-api.service"
import { UrlService } from "../services/url.service"
import { ListSortPipe } from "../utilities/pipes/list-sort-pipe"
import { ReplaceLineBreaks } from "../utilities/ReplaceLineBreaks"

@NgModule({
    declarations: [
        AboutComponent,
        AccountConfirmedComponent,
        AccountDeletionRequestConfirmedComponent,
        AccountFieldsComponent,
        AdminSupportComponent,
        AppComponent,
        AutocompleteInputComponent,
        AutocompleteInputFilterPipe,
        ButtonComponent,
        ChildQuestionnaireComponent,
        ConfirmComponent,
        ConnectedNavbarComponent,
        PrivacyPoliciesComponent,
        CreatePatientAccountComponent,
        CreatePrefilledAccountComponent,
        CreateSavFormComponent,
        CreateTemplateComponent,
        DatePickerDDMMYYYYFormatDirective,
        DatePickerMMDDYYYYFormatDirective,
        DelayButtonComponent,
        DemoSettingsComponent,
        DisconnectedNavbarComponent,
        DownloadReportErrorComponent,
        DragDropFileDirective,
        DropDownMenuComponent,
        EditPatientAccountComponent,
        EssQuestionnaireComponent,
        HomeComponent,
        IfuEuAndRowComponent,
        IfuUsComponent,
        ImageViewerComponent,
        ImageWithViewerDirective,
        IsiQuestionnaireComponent,
        ListSortPipe,
        MaintenanceComponent,
        MctqQuestionnaireComponent,
        MyAccountComponent,
        NavbarComponent,
        NgForEmptyDirective,
        NightsListComponent,
        NightReportAnalysisComponent,
        NightReportChartComponent,
        NightReportComponent,
        NightReportEmbedPDF,
        NightReportRawDataComponent,
        NightReportSectionComponent,
        NightReportTabLineComponent,
        NightReportTemplateV1Component,
        NightReportTemplateV2Component,
        NotificationsComponent,
        NotificationsContainerComponent,
        PasswordConfirmedComponent,
        PasswordFieldComponent,
        PatientListFilterPipe,
        PatientsListComponent,
        PersonalInfoComponent,
        PhoneNumberInputComponent,
        PromptComponent,
        PresciptionComponent,
        ReadonlyFieldComponent,
        ReplaceLineBreaks,
        ResetPasswordComponent,
        SavProviderComponent,
        SecurityComponent,
        SensorsToPatientAssignationComponent,
        SetPasswordComponent,
        SharingManagementComponent,
        SharingManagementFilterPipe,
        ShowPatientDetailsComponent,
        SignatureComponent,
        SignInComponent,
        SignUpComponent,
        SortableListHeaderComponent,
        StudyListFilterPipe,
        StudiesListComponent,
        SwitchButtonComponent,
        TemplateFormComponent,
        TemplatesListComponent,
        UnitsAndFormatsPreferencesComponent,
        UpdatePasswordComponent,
        UpdateTemplateComponent,
        ValidatableInputComponent,
        ValidatableCheckboxComponent,
        ValidatableDateComponent,
        ValidatableSelectComponent,
        ValidatableTextAreaComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CarouselModule,
        FormsModule,
        HighchartsChartModule,
        HttpClientModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        SimpleModalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
    ],
    providers: [
        CookieService,
        SunriseApiService,
        SunriseApiMktService,
        UrlService,
        { provide: "RawDataCacheDataAccess", useClass: RawDataCacheLocalForageDataAccess },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: "en_US" },
        {
            provide: ErrorHandler,
            useValue: sentryCreateErrorHandler(),
        },
        {
            provide: SentryTraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            deps: [SentryTraceService],
            multi: true,
            useFactory: () => () => {},
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
    return new TranslateHttpLoader(new HttpClient(httpBackend))
}
