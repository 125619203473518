import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { constants } from "../../config/constants"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { RegExpRule } from "../../validation/rules/RegExpRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-create-sav-form",
    templateUrl: "./create-sav-form.component.html",
})
export class CreateSavFormComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isLoading = true
    public isCreatingForm = false

    public emailAddress: ValidatableObject
    public serialNumber: ValidatableObject

    public formLanguages: object[] = [
        { name: "English", value: "en" },
        { name: "French", value: "fr" },
    ]
    public selectedLanguage: ValidatableObject

    public savUri: string

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        await this.initValidatableObjectsAsync()

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public async createSavFormAsync(): Promise<void> {
        const isValid: boolean = await this.validateObjectsAsync()
        this.isCreatingForm = true

        if (isValid) {
            try {
                const uri: string = await this.sunriseApiService.createSavFormAsync(
                    this.emailAddress.value,
                    this.serialNumber.value,
                )
                this.savUri = uri.replace("{{lang}}", this.selectedLanguage.value)
            } catch {
                this.savUri = null

                this.showErrorOccurred()
            }
        }

        this.isCreatingForm = false
    }

    // -------------------------------------------------------------------------
    // Objects validation
    // -------------------------------------------------------------------------

    public async initValidatableObjectsAsync(): Promise<void> {
        this.emailAddress = new ValidatableObject(this.translate, "createSavForm_emailAddress_input")
        this.emailAddress.addRule(new NotEmptyRule(this.translate))
        this.emailAddress.addRule(
            new RegExpRule(
                this.translate,
                constants.validation.patterns.email,
                "i18n:signUp_input_emailAddress_error_invalid",
            ),
        )

        this.serialNumber = new ValidatableObject(this.translate, "createSavForm_serialNumber_input")
        this.serialNumber.addRule(new NotEmptyRule(this.translate))

        this.selectedLanguage = new ValidatableObject(this.translate, "createSavForm_language_input")
        this.selectedLanguage.addRule(new NotEmptyRule(this.translate))
        this.selectedLanguage.value = this.formLanguages[0]["value"]
    }

    /**
     * Validate validatable objects.
     * Force validating all the fields and do not stop at first error.
     * @return true if all the fields are valid, false otherwise
     */
    public async validateObjectsAsync(): Promise<boolean> {
        let isValid: boolean

        isValid = await this.emailAddress.validateAsync()
        isValid = (await this.serialNumber.validateAsync()) && isValid
        isValid = (await this.selectedLanguage.validateAsync()) && isValid

        return isValid
    }

    // -------------------------------------------------------------------------
    // Event helpers
    // -------------------------------------------------------------------------

    public formLanguageChangeEvent(lang: string): void {
        if (lang) {
            this.selectedLanguage.value = lang
        }
    }
}
