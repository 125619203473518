<div *ngIf="password" class="field-container" [class.required]="required" [class.field-container--errored]="password.hasError">
    <label class="label">{{ password.label | translate }}</label>

    <div class="field-password-container">
        <input class="field password-input"
               placeholder="{{ useLabelAsPlaceholder ? (password.label | translate) : ('common_input_placeholder' | translate:{'label': (password.label) | translate | lowercase}) }}"
               [type]="isPasswordHidden ? 'password' : 'text'"
               [(ngModel)]="password.value"
               (keyup)="keyupHandlerAsync($event)"
               (focusout)="unfocusHandlerAsync()"
        >
        <div class="password-visibility-button" (click)="onPasswordVisibilityButtonClicked()">
            <span class="password-visibility-text">{{ isPasswordHidden ? ('common_password_show' | translate) : ('common_password_hide' | translate) }}</span>
        </div>
    </div>

    <small *ngIf="password.showHint" class="hint-text">{{ password.hintString }}</small>
    <small class="error-text">{{ password.errorString }}</small>
</div>
