import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { PortalNotificationType } from "../../models/PortalNotification"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { RawDataService } from "../../services/raw-data.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html",
})
export class MyAccountComponent extends BaseComponent {
    public accountConfirmed = true

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        protected readonly rawDataService: RawDataService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.accountConfirmed = await this.isAccountConfirmedAsync()
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public startOnBoarding(): void {
        localStorage.removeItem("hasSeenOnboarding")

        this.router.navigate(["main/patients"])
    }

    public async logout(): Promise<void> {
        await this.rawDataService.deleteAllPreferencesLinkedToRawDataAsync()
        this.disconnectUser()
    }

    public async resendConfirmEmail(): Promise<void> {
        const userEmailAddress: string = this.authService.getUserEmailAddress()

        if (!userEmailAddress) {
            this.showErrorOccurred()
        } else {
            try {
                await this.sunriseApiService.resendConfirmationEmailAsync(userEmailAddress, false)

                this.showNotification("myAccount_confirmation_email_resent", PortalNotificationType.Success)
            } catch (error) {
                this.showErrorOccurred()
            }
        }
    }
}
