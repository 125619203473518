<div *ngIf="!isLoading">
    <div class="two-field-container-per-row">
        <app-validatable-input class="validatable-container"
                               *ngIf="firstName"
                               [validatableObject]="firstName"
                               [useLabelAsPlaceholder]="accountActionType == eAccountActionType.PatientAccountCreation"
                               (enterPressed)="enterPressedEvent()"></app-validatable-input>

        <app-validatable-input class="validatable-container"
                               *ngIf="lastName"
                               [validatableObject]="lastName"
                               [useLabelAsPlaceholder]="accountActionType == eAccountActionType.PatientAccountCreation"
                               (enterPressed)="enterPressedEvent()"></app-validatable-input>
    </div>

    <div class="two-field-container-per-row">
        <app-validatable-date class="validatable-container"
                              *ngIf="birthdate"
                              [validatableObject]="birthdate"
                              (enterPressed)="enterPressedEvent()"></app-validatable-date>
    </div>

    <app-validatable-input class="validatable-container"
                           *ngIf="emailAddress"
                           [validatableObject]="emailAddress"
                           [disabled]="accountActionType == eAccountActionType.DoctorAccountEdition"
                           [formatInput]="formatEmailAddress"
                           [useLabelAsPlaceholder]="accountActionType == eAccountActionType.PatientAccountCreation"
                           (enterPressed)="enterPressedEvent()"></app-validatable-input>

    <div class="two-field-container-per-row">
        <app-password-field class="validatable-container"
                            *ngIf="password"
                            [validatableObject]="password"
                            [required]="true"
                            [useLabelAsPlaceholder]="accountActionType == eAccountActionType.PatientAccountCreation"
                            (enterPressed)="enterPressedEvent()"></app-password-field>

        <app-password-field class="validatable-container"
                            *ngIf="passwordConfirmation"
                            [validatableObject]="passwordConfirmation"
                            [required]="true"
                            [useLabelAsPlaceholder]="accountActionType == eAccountActionType.PatientAccountCreation"
                            (enterPressed)="enterPressedEvent()"></app-password-field>
    </div>

    <div class="two-field-container-per-row">
        <app-validatable-select class="validatable-container"
                                *ngIf="country"
                                [validatableObject]="country"
                                [options]="countries"
                                [selectedVariable]="'countryNumber'"
                                [displayedVariable]="'translatedName'"
                                [disabled]="accountActionType == eAccountActionType.DoctorAccountEdition"
                                (objectChangeEvent)="countryChangeEvent($event)"></app-validatable-select>

        <app-phone-number-input class="validatable-container"
                                *ngIf="phoneNumberWithPrefix"
                                [validatableObject]="phoneNumberWithPrefix"
                                [selectedCountry]="selectedCountry"
                                (enterPressed)="enterPressedEvent()"></app-phone-number-input>

        <app-validatable-input class="validatable-container"
                               *ngIf="phoneNumber"
                               [validatableObject]="phoneNumber"
                               [useLabelAsPlaceholder]="accountActionType == eAccountActionType.PatientAccountCreation"
                               (enterPressed)="enterPressedEvent()"></app-validatable-input>
    </div>
</div>
