<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'template_create' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateToMyAccount()'>{{ 'header_account_menu' | translate }}</a> / <a (click)='navigateToTemplatesList()'>{{ 'templates_list' | translate }}</a> / {{ 'template_create' | translate }}
    </div>

    <app-template-form></app-template-form>
</main>
