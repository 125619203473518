import { TranslateService } from "@ngx-translate/core"
import { ValidatableRule } from "./ValidatableRule"

export class MaxValueRule extends ValidatableRule {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        translate: TranslateService,
        private readonly maxValue: number,
        errorStringTemplate?: string,
    ) {
        super(translate, errorStringTemplate || "i18n:common_validation_error_maxValue", { maxValue: maxValue })
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    protected validateRule(value: number): boolean {
        return value <= this.maxValue
    }
}
