import { Component, OnInit } from "@angular/core"
import { DomSanitizer, SafeUrl } from "@angular/platform-browser"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { PortalNotificationType } from "../../../models/PortalNotification"
import { Signature } from "../../../models/Signature"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-signature",
    templateUrl: "./signature.component.html",
})
export class SignatureComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    public isUploading = false
    public isLoading = true

    public currentSignature: Signature
    public signaturesHistory: Signature[] = []
    public newSignature: Signature

    public fileError: string

    public readonly allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"]
    private readonly maxFileSize = 1000000 // 1mo

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        protected readonly sanitizer: DomSanitizer,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        const signatures: Signature[] = await this.sunriseApiService.getSignaturesAsync()

        if (signatures) {
            this.currentSignature = signatures[0]
            this.signaturesHistory = signatures
        }

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Events listener
    // -------------------------------------------------------------------------

    public filesDroppedEvent(files: File[]): void {
        const isFileValid: boolean = this.validateFile(files)

        if (isFileValid) {
            this.displayNewSignature(files[0])
        }
    }

    public fileUploadedEvent(event: any): void {
        const files: File[] = Array.from(event.target.files)
        const isFileValid: boolean = this.validateFile(files)

        if (isFileValid) {
            this.displayNewSignature(files[0])
        }
    }

    // -------------------------------------------------------------------------
    // UI utils
    // -------------------------------------------------------------------------

    private displayNewSignature(file: File): void {
        const url: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file))

        this.newSignature = { url, file, creationDate: new Date().toISOString() }
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public async uploadSignatureAsync(): Promise<void> {
        this.isUploading = true

        try {
            await this.sunriseApiService.uploadSignatureAsync(this.newSignature)

            this.currentSignature = this.newSignature
            this.signaturesHistory.unshift(this.currentSignature)
            this.newSignature = undefined
            this.fileError = undefined

            this.showNotification("signatures_upload_success", PortalNotificationType.Success)
        } catch (error) {
            this.showErrorOccurred()
        } finally {
            this.isUploading = false
        }
    }

    public resetNewSignature(): void {
        if (!this.isUploading) {
            this.newSignature = undefined
        }
    }

    public navigateBack(): void {
        this.router.navigate(["main/account"])
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    private validateFile(files: File[]): boolean {
        let isFileValid = true
        this.fileError = undefined

        if (files.length > 1) {
            this.fileError = "signatures_onlyOneFile_error"

            isFileValid = false
        } else if (files.length == 1) {
            const file: File = files[0]

            if (!this.allowedFileTypes.includes(file.type)) {
                this.fileError = "signatures_wrongFileType_error"

                isFileValid = false
            } else if (file.size > this.maxFileSize) {
                this.fileError = "signatures_fileTooLarge_error"

                isFileValid = false
            }
        }

        return isFileValid
    }
}
