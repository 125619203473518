<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'footer_user_manual' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--smaller content">
        <div class="last-ifu">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'effective_IFUs' | translate }}</div>
            </div>
            <ul>
                <li *ngFor="let link of effectiveIfuFiles">
                    <a [href]="staticFilesBaseUri + link.url" target="_blank">{{ link.name }}</a>
                </li>
            </ul>
        </div>

        <div class="older-ifu">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'obsolete_IFUs' | translate }}</div>
            </div>
            <ul>
                <li *ngFor="let link of obsoleteIfuFiles">
                    <a [href]="staticFilesBaseUri + link.url" target="_blank">{{ link.name }}</a>
                </li>
            </ul>
        </div>
    </div>
</main>
