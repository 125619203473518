import { EventEmitter, Injectable } from "@angular/core"
import { io, Socket } from "socket.io-client"
import { constants } from "../config/constants"
import { ApiEventType } from "../models/ApiEventType"

@Injectable({
    providedIn: "root",
})
export class SocketIOService {
    private socket: Socket

    /**
     * Event emit each time a new night version is analyzed
     * @return { nightId, nightVersionId }
     */
    public nightVersionAnalyzedEvent: EventEmitter<{ nightId: string; nightVersionId: string }> = new EventEmitter<{
        nightId: string
        nightVersionId: string
    }>()

    public initSocketConnection(): void {
        this.socket = io(constants.api.product.baseUri)

        this.socket.on("api-event", (event) => {
            if (event.type == ApiEventType.NightVersionAnalyzed) {
                const nightId: string | undefined = event.data?.nightId
                const nightVersionId: string | undefined = event.data?.nightVersionId

                if (nightId !== undefined && nightVersionId !== undefined) {
                    this.nightVersionAnalyzedEvent.emit({ nightId, nightVersionId })
                }
            }
        })
    }

    public disconnect(): void {
        this.socket?.disconnect()
    }
}
