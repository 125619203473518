<main class="main">
    <!-- Filters -->
    <div class="col" style="width: 250px; margin-top: 1.5rem; margin-left: 1.5rem;" (click)="toggleFilterSection()"><a>Filtres</a></div>

    <div id="filterSection" class="report-section report-section--minified" style="width:100%; margin-left: 1.5rem;">
        <div class="section-content" style="height: auto;">
            <div class="section-filter-container">
                <div class='list-row--row row--2-cells' style="margin-top: 10px;">
                    <div class="row--cell" style="white-space: nowrap;">
                        <span>
                            <input type="checkbox" name="filterDate" id="filterDate" (click)="selectFilter('filterDate')"/>
                            <label for="filterDate">Date de fin de test : </label>
                        </span>
                        <input id='filterDateValue' placeholder="DD/MM/YYYY" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                    </div>
                    <div class="row--cell" style="white-space: nowrap;">
                        <span >
                            <input type="checkbox" name="filterBetween" id="filterBetween" (click)="selectFilter('filterBetween')"/>
                            <label for="filterBetween">Date de fin de test entre : </label>
                        </span>
                        <input id="filterStartDate" placeholder="DD/MM/YYYY" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                        <label> et </label>
                        <input id="filterEndDate" placeholder="DD/MM/YYYY" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                    </div>
                </div>

                <div class='list-row--row row--2-cells' style="margin-top: 10px;">
                    <div class="row--cell" style="white-space: nowrap;">
                        <span>
                            <input type="checkbox" name="filterSerialNumber" id="filterSerialNumber" (click)="selectFilter('filterSerialNumber')"/>
                            <label for="filterSerialNumber">Numéro de série du capteur : </label>
                        </span>
                        <input id='filterSerialNumberValue' placeholder="Numéro de série" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                    </div>
                    <div class="row--cell" style="white-space: nowrap;">
                        <span>
                            <input type="checkbox" name="filterTypeNcf" id="filterTypeNcf" (click)="selectFilter('filterTypeNcf')"/>
                            <label for="filterTypeNcf">Code bug : </label>
                        </span>
                        <input id='filterTypeNcfValue' placeholder="Code bug" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                    </div>
                </div>

                <div class='list-row--row row--2-cells'>
                    <div class="row--cell" style="white-space: nowrap;">
                        <span>
                            <input type="checkbox" name="filterPatientName" id="filterPatientName" (click)="selectFilter('filterPatientName')"/>
                            <label for="filterPatientName">Nom du patient : </label>
                        </span>
                        <input id="filterPatientNameValue" placeholder="Nom de famille du patient" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                    </div>

                    <div class="row--cell" style="white-space: nowrap;">
                        <span>
                            <input type="checkbox" name="filterPatientEmail" id="filterPatientEmail" (click)="selectFilter('filterPatientEmail')"/>
                            <label for="filterPatientEmail">Email du patient : </label>
                        </span>
                        <input id="filterPatientEmailValue" placeholder="Email du patient" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                    </div>
                </div>


                <div class='list-row--row row--2-cells' style="margin-top: 10px;">
                    <div class="row--cell" style="white-space: nowrap;">
                        <span>
                            <input type="checkbox" name="filterDoctorName" id="filterDoctorName" (click)="selectFilter('filterDoctorName')"/>
                            <label for="filterDoctorName">Nom du praticien : </label>
                        </span>
                        <input id='filterDoctorNameValue' placeholder="Nom du praticien" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                    </div>
                    <div class="row--cell" style="white-space: nowrap;">
                        <span>
                            <input type="checkbox" name="filterDoctorEmail" id="filterDoctorEmail" (click)="selectFilter('filterDoctorEmail')"/>
                            <label for="filterDoctorEmail">Email du praticien : </label>
                        </span>
                        <input id='filterDoctorEmailValue' placeholder="Email du praticien" disabled="true" (keyup)="keyupFiltersHandler($event)"/>
                    </div>
                </div>
                <div class='list-row--row' style="margin-top: 25px">
                    <input type="button" class="action-button" [disabled]="isLoading" value="Filtrer" (click)="startFilterNightsAsync(false)"/>

                    <input type="button" class="action-button" value="Effacer les filtres" (click)="removeFilterNightsAsync()" style="margin-left: 16px"/>

                    <input type="button" class="action-button" value="Exporter les nuits" (click)="startFilterNightsAsync(true)" style="margin-left: 16px"/>
                </div>
            </div>
        </div>
    </div>

    <!-- Filters -->
    <div class="list-row--row row--guttered-support content">

        <!-- Night List headers -->
        <div class="list-row--no-color list-row--headers" [style.display]="isLoading || isExportingNights ? 'none' : 'flex'">
            <div class="col" class="reliability-dot">Statut</div>
            <div class="col">Identifiant</div>
            <div class="col">Utilisateur</div>
            <div class="col">Praticien</div>
            <div class="col">Version</div>
            <div class="col">Numéro de série</div>
            <div class="col">Distributeur</div>
            <div class="col">Date</div>
            <div class="col">Support</div>
            <div class="col col--see-more"><span class="internal"></span></div>
        </div>


        <!-- Night List -->
        <div *ngFor="let n of nights" [style.display]="isLoading || isExportingNights ? 'none' : 'block'">
            <div style="padding-bottom: 1.8rem;">
                <div *ngIf="!n.reportHiddenOnPlatform" class="list-row list-row--support"
                    [style.background-color]="(n.adminInfos.vipUser ? 'rgb(130,215,255)' : ((!n.isAnalyzed && !n.isUnprocessed) ? 'rgba(0,0,0,0.2)': 'default'))"
                    [style.text-color]="!n.isAnalyzed ? 'rgba(0,0,0,1)': 'default'">
                    <span class="notification-dot" [style.display]="n.newNights ? 'block' : 'none'"></span>
                    <div class="reliability-dot reliability-dot--ok" *ngIf="n.isAnalyzed" [style.display]="n.actionsNeededFromSupport == true ? 'none' : 'default'">OK</div>
                    <div class="reliability-dot reliability-dot--ko" [style.display]="((n.isAnalyzed && n.actionsNeededFromSupport == true) || n.isUnprocessed) ? 'default' : 'none'">KO</div>
                    <div class="reliability-dot reliability-dot" *ngIf="(!n.isAnalyzed && !n.isUnprocessed)">-</div>
                    <div class="col">{{ n.nightId }}</div>
                    <div class="col">{{ (n.userInfos.firstName !== undefined || n.userInfos.lastName) ? n.userInfos.firstName + ' ' + n.userInfos.lastName : '-' }}</div>
                    <div class="col">{{ n.adminInfos.doctorNameSensorAssignation == undefined ? '-' : n.adminInfos.doctorNameSensorAssignation }}</div>
                    <div class="col">{{ n.adminInfos.sensorVersion }}</div>
                    <div class="col">{{ n.adminInfos.sensorSerialNumber }}</div>
                    <div class="col">{{ n.adminInfos.providerNameSensorAssignation == undefined ? '-' : n.adminInfos.providerNameSensorAssignation }}</div>
                    <div class="col">{{ formatDate(n.endDateTime.date) }}</div>
                    <div class="col" (click)="toggleReportSection(n.nightId)"><span class="a--underline">Informations Support</span></div>
                    <div class="col col--see-more" *ngIf="n.isAnalyzed"><a class="internal internal-arrow" (click)="navigateToNight(n)"><span>Voir le rapport</span></a>
                      </div>
                    <div class="col col--see-more" *ngIf="(!n.isAnalyzed && !n.isUnprocessed)"><span class="internal">En cours d'analyse…</span></div>
                    <div class="col col--see-more" *ngIf="(!n.isAnalyzed && n.isUnprocessed)"><span class="internal">Unprocessed</span></div>
                </div>


                <!-- Night Information -->
                <div id="{{n.nightId}}" class="report-section report-section--minified">
                    <div class="section-content" style="height: auto;">

                        <div class="section-content-container" style="width:25rem;">
                            <div class="section-support-title">Nuit</div>
                            <li class="list-row--row element-border--bottom">
                                Commentaire :<br/> <p class="section-value">{{ n.feedback.comment == undefined ? '-' : n.feedback.comment }}</p>
                            </li>
                            <li class="list-row--row element-border--bottom" style="margin-top: 35px" *ngIf="n.isAnalyzed">
                                Reliability Index :<br/>

                                    <p class="reliability-index-text--success"
                                    [hidden]="n.reliabilityIndex.nightIssues.numberOfIssues > 0">
                                    Aucun problème n'est survenu pendant le test de sommeil.</p>
                                <p class="reliability-index-text--error"
                                    [hidden]="(!n.reliabilityIndex.nightIssues.significantDisconnectionPeriod)">
                                    {{"Une perte de données a été détectée pendant une période totale de " + getRoundNumber(n.reliabilityIndex.disconnectionDurationInMins) + " minutes."}}
                                </p>
                                <p class="reliability-index-text--error"
                                    [hidden]="!n.reliabilityIndex.nightIssues.sensorFallenDetected">
                                    {{ "Nous avons remarqué que le capteur est tombé du menton du patient à " + getLocalTime(n.fallenTime) + "." }}
                                </p>
                                <p class="reliability-index-text--error"
                                    [hidden]="!n.reliabilityIndex.nightIssues.sensorFallenReported">
                                    Le patient a rapporté que son capteur est tombé durant la nuit</p>
                                <p class="reliability-index-text--error"
                                    [hidden]="!n.reliabilityIndex.nightIssues.maxRecordingDurationReached">
                                    L’enregistrement du test a duré plus de 15 heures.</p>
                                <p class="reliability-index-text--error"
                                    [hidden]="!n.reliabilityIndex.nightIssues.recordingTimeTooLow">
                                    Le temps total d'enregistrement est de moins de 4 heures.</p>
                                <p class="reliability-index-text--error"
                                    [hidden]="!n.reliabilityIndex.nightIssues.usedBabyPacifier">
                                    Il a été rapporté que le patient dormait avec une tétine.</p>
                            </li>

                            <li class="list-row--row" style="margin-top: 35px" *ngIf="hasAdminSupportRole && n.nightInformationForSupport">
                                Praticiens liés à l’utilisateur:
                                <span *ngIf="n.nightInformationForSupport.doctorCreatorEmail">
                                    <br/>→ <span class="section-value section-value-list">  {{ n.nightInformationForSupport.doctorCreatorEmail }} <span class="section-value info">(a créé l'utilisateur)</span></span>
                                </span>
                                <span *ngFor="let link of n.nightInformationForSupport.linksInformation">
                                    <span>
                                        <br/>→ <span class="section-value section-value-list" [ngClass]="{ 'greyed': link.isPending }">  {{ link.doctorEmail }}<span *ngIf="link.isPending" class="section-value info greyed"> (en attente)</span></span>
                                    </span>
                                </span>
                            </li>
                        </div>

                        <!-- User Information -->
                        <div class="section-content-container">
                            <div class="section-support-title">Utilisateur</div>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Adresse email: <span class="section-value">{{ n.userInfos.email == undefined ? '-' : n.userInfos.email }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Numéro de téléphone: <span class="section-value">{{ n.userInfos.phoneNumber == undefined ? '-' : n.userInfos.phoneNumber }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Sexe: <span class="section-value">{{ n.userInfos.gender == undefined ? '-' : n.userInfos.gender }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Pays: <span class="section-value">{{ n.userInfos.country == undefined ? '-' : n.userInfos.country.name.fr }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Langue: <span class="section-value">{{ n.userLang == undefined ? '-' : n.userLang }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny" *ngIf="n.nightInformationForSupport">
                                Nombre de capteurs utilisés par cet utilisateur: <span class="section-value">{{ n.nightInformationForSupport.numberOfUsedSensors }}</span>
                            </li>
                        </div>

                        <!-- Technical Information -->
                        <div class="section-content-container">
                            <div class="section-support-title">Infos techniques</div>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny" *ngIf="n.isAnalyzed">
                                Chute complète du capteur: <span class="section-value">{{ n.reliabilityIndex.nightIssues.sensorFallenDetected == true || n.reliabilityIndex.nightIssues.sensorFallenReported == true ? "Oui" : "Non" }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Adhésif supplémentaire: <span class="section-value">{{ n.adminInfos.extraAdhesiveUsed == undefined ? '-' : n.adminInfos.extraAdhesiveUsed == true ? 'Oui' : 'Non' }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Assignation du capteur: <span class="section-value">{{ n.adminInfos.doctorNameSensorAssignation == undefined ? '-' : n.adminInfos.doctorNameSensorAssignation }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Modèle du téléphone: <span class="section-value">{{ n.adminInfos.phoneModel == undefined ? '-' : n.adminInfos.phoneModel }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Version du téléphone: <span class="section-value">{{ n.deviceVersion == undefined ? '-' : n.deviceVersion }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Version de l'application: <span class="section-value">{{ n.versionApp == undefined ? '-' : n.versionApp}}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Version du firmware: <span class="section-value">{{ n.adminInfos?.sensorFirmwareVersion ?? '-' }}</span>
                            </li>
                            <li *ngIf="!n.adminInfos.filePath" class="list-row--row">
                                Manufacturer UUID: <span class="section-value">{{ n.adminInfos.sensorUUIDManufacturer }}</span>
                            </li>
                            <li *ngIf="n.adminInfos.filePath" class="list-row--row">
                                <span class="section-value" (click)="openUriInNewTab(n.adminInfos.filePath)"><a>Afficher le fichier brut</a></span>
                            </li>
                        </div>

                        <!-- Order Information -->
                        <div class="section-content-container" *ngIf="n.zohoOrderInformations">
                            <div class="section-support-title">Commande</div>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Sujet de la commande: <span class="section-value" (click)="openZohoOrderInNewTab(n.zohoOrderInformations.orderInformation.order_id)"><a>{{ n.zohoOrderInformations.orderInformation.order_subject == undefined ? '-' : n.zohoOrderInformations.orderInformation.order_subject }}</a></span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Contact commande: <span class="section-value">{{ n.zohoOrderInformations.orderInformation.contact_name == undefined ? '-' : n.zohoOrderInformations.orderInformation.contact_name }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Type de la commande: <span class="section-value">{{ n.zohoOrderInformations.orderInformation.order_type == undefined ? '-' : n.zohoOrderInformations.orderInformation.order_type }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Source de la commande: <span class="section-value">{{ n.zohoOrderInformations.orderInformation.order_source == undefined ? '-' : n.zohoOrderInformations.orderInformation.order_source }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny">
                                Nombre de capteurs de la commande: <span class="section-value">{{ n.zohoOrderInformations.orderInformation.number_of_sensors == undefined ? '-' : n.zohoOrderInformations.orderInformation.number_of_sensors }}</span>
                            </li>
                            <li class="list-row--row element-border--bottom element-border--bottom--tiny" *ngIf="n.zohoOrderInformations.orderInformation.clinical_study">
                                ID de l’étude clinique: <span class="section-value">{{ n.zohoOrderInformations.orderInformation.clinical_study == undefined ? '-' : n.zohoOrderInformations.orderInformation.clinical_study }}</span>
                            </li>
                            <li class="list-row--row">
                                Pays d'expédition: <span class="section-value">{{ n.zohoOrderInformations.orderInformation.shipping_country == undefined ? '-' : n.zohoOrderInformations.orderInformation.shipping_country }}</span>
                            </li>
                        </div>

                        <!-- Actions  -->
                        <div class="section-content-container" *ngIf="hasAdminSupportRole">
                            <div class="section-support-title">Actions</div>
                            <div class="list-row--row" *ngIf="n.nightInformationForSupport">
                                Historique des codes bugs:
                                <span *ngIf="!n.nightInformationForSupport?.codeBugsHistorical?.length">-</span>
                                <span *ngFor="let ncfType of n.nightInformationForSupport.codeBugsHistorical">
                                    <br/>→ <span class="section-value">{{ ncfType }}</span>
                                </span>
                            </div>
                            <div class="list-row--row" *ngIf="n.typeNCF">
                                Code bug de la nuit: <span class="section-value">{{ n.typeNCF}}</span>
                                <img src="assets/images/delete.png" (click)="deleteNightTypeNCFAsync(n.nightId, n.adminInfos.sensorSerialNumber)"
                                    style="height: 17px; margin-left: 10px; vertical-align: middle;"/>
                            </div>
                            <div class="list-row--row" *ngIf="!n.typeNCF">
                                <input id="CODEBUG_{{n.nightId}}" placeholder="Entrer le code bug"/>
                                <input type="button" class="action-button" style="margin-left: 10px" value="Créer" (click)="addNightTypeNCFAsync(n.nightId, n.adminInfos.sensorSerialNumber)"/>
                            </div>
                            <div class="list-row--row element-border--bottom" *ngIf="n.zohoOrderInformations && n.zohoOrderInformations.nightsInformation.sensor_ncf_number">
                                CS liée à la nuit: <span (click)="openNcfInNewTab(n.zohoOrderInformations.nightsInformation.sensor_ncf_number)"><a>{{ n.zohoOrderInformations.nightsInformation.sensor_ncf_number }}</a></span>
                                <img src="assets/images/delete.png" (click)="deleteNightNCFAsync(n.nightId, n.adminInfos.sensorSerialNumber)"
                                    style="height: 17px; margin-left: 20px; margin-top: 2px; vertical-align: top;"/>
                            </div>
                            <div class="list-row--row" *ngIf="!n.zohoOrderInformations || !n.zohoOrderInformations.nightsInformation.sensor_ncf_number">
                                <input id="NCF_{{n.nightId}}" placeholder="Issue CS"/>
                                <input type="button" class="action-button" style="margin-left: 10px" value="Créer" (click)="createNightNCFAsync(n.nightId, n.adminInfos.sensorSerialNumber)"/>
                            </div>

                            <div *ngIf="n.zohoOrderInformations && n.zohoOrderInformations.orderInformation.order_source == 'Amazon' && n.zohoOrderInformations.orderInformation.userOrder" class="list-row--row">
                                Commande de l'utilsateur trouvée: <span class="section-value" (click)="openZohoOrderInNewTab(n.zohoOrderInformations.orderInformation.userOrder.id)"><a>{{ n.zohoOrderInformations.orderInformation.userOrder.Subject }}</a></span>
                            </div>
                            <div *ngIf="n.zohoOrderInformations && n.zohoOrderInformations.orderInformation.order_source == 'Amazon' && !n.zohoOrderInformations.orderInformation.userOrder" class="list-row--row">
                                Commande de l'utilisateur non trouvée
                            </div>

                            <div class="list-row--row element-border--top" *ngIf="(!n.adminInfos.savProviderForm && (!n.zohoOrderInformations || !n.zohoOrderInformations.orderInformation || !n.zohoOrderInformations.orderInformation.order_SAS_subject))">
                                <div>
                                    <input type="checkbox" id="newSensor_{{n.nightId}}" name="SendNewSensor" (click)="sendNewSensorAsync(n, true)">
                                    <label for="SendNewSensor" (click)="sendNewSensorAsync(n)">Envoyer un nouveau capteur</label>
                                </div>
                            </div>

                            <div class="list-row--row element-border--top" *ngIf="n.adminInfos.savProviderForm">
                                <span *ngIf="n.adminInfos.savProviderForm && n.adminInfos.savProviderForm.fillDate == null">
                                    Etat du formulaire SAV: <span class="section-value">En attente</span>

                                    <div>
                                        <input type="button" class="action-button" value="Copier lien formulaire SAV" (click)="copyToClipBoard(n.adminInfos.savProviderForm.linkUrl, n.userLang)"/>
                                    </div>
                                </span>

                                <span *ngIf="n.adminInfos.savProviderForm && n.adminInfos.savProviderForm.fillDate != null">
                                    Etat du formulaire SAV: <span class="section-value">Complété</span>
                                </span>
                            </div>


                            <div class="list-row--row element-border--top" *ngIf="(n.zohoOrderInformations && n.zohoOrderInformations.orderInformation && n.zohoOrderInformations.orderInformation.order_SAS_subject)">
                                Commande SAV: <span class="section-value" (click)="openZohoOrderInNewTab(n.zohoOrderInformations.orderInformation.order_SAS_id)"><a>{{ n.zohoOrderInformations.orderInformation.order_SAS_subject }}</a></span>
                            </div>
                            <div class="list-row--row" *ngIf="n.isUnprocessed">
                                <div>
                                    <input type="checkbox" id="hidding_{{n.nightId}}" name="HiddingNight" (click)="hideNightAsync(n, true)">
                                    <label for="HiddingNight" (click)="hideNightAsync(n)">Enlever la nuit pour l’utilisateur/praticien</label>
                                </div>
                            </div>

                            <div class="list-row--row element-border--top">
                                Template du message:
                                <select #ncf (change)="selectTypeNcf($event, n)" style="padding: 5px;">
                                    <option value="default">-</option>
                                    <option *ngFor="let ncfTypeWithLang of getTypeNcfWithLangListFromTemplates()" [value]="ncfTypeWithLang">
                                        {{ncfTypeWithLang}}
                                    </option>
                                </select>

                                <img src="assets/images/Intercom_logo.png" (click)="openIntercomProfileInNewTabAsync(n)"
                                style="height: 2.5rem; margin-left: 20px; vertical-align: middle;"/>
                            </div>

                            <div *ngIf="n.selectedTemplateNcf">
                                <div class="list-row--row">
                                    <input id="sendCommentButton_{{n.nightId}}" type="button" class="action-button" value="Envoyer un mail au patient" (click)="sendUserMailAsync(n)"/>
                                </div>
                                <div class="list-row--row" *ngIf="!n.supportComment">
                                    <input id="modifyCommentButton_{{n.nightId}}" type="button" class="action-button" value="Ajouter un commentaire praticien" (click)="sendSupportCommentAsync(n)"/>
                                </div>
                            </div>

                            <div class="list-row--row">
                                Commentaire pour le praticien:<br/> <p class="section-value">{{ n.supportComment == undefined ? '-' : n.supportComment }}</p>
                            </div>
                            <div class="list-row--row" *ngIf="n.supportComment">
                            <input id="modifyCommentButton_{{n.nightId}}" type="button" class="action-button" value="Modifier le commentaire praticien" (click)="updateSupportCommentAsync(n)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="list-row list-row--no-border" [style.display]="isLoading ? 'block' : 'none'">{{ 'adminNightsList_loading_label' | translate }}</div>

        <div class="list-row list-row--no-border" [style.display]="isExportingNights ? 'block' : 'none'">Exporting nights...</div>

        <div class="list-row list-row--no-border" [style.display]="(!nights || nights.length == 0) && !isLoading && !isExportingNights ? 'block' : 'none'">{{ 'nightsList_empty' | translate }}</div>
    </div>
</main>
