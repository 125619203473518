<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'patientsList_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered" [class.display-none]="isMobile">
        <div class="filters-container">
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.AllPatients" (click)="updateMainFilterAsync(eFilterType.AllPatients)">
                <a>{{ 'patientsList_filter_allPatients' | translate }}</a>
                <span>{{ nbPatients }}</span>
            </div>
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.WithNights" (click)="updateMainFilterAsync(eFilterType.WithNights)">
                <a>{{ 'patientsList_filter_withNights' | translate }}</a>
                <span>{{ nbPatientsWithNight }}</span>
            </div>
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.Activated" (click)="updateMainFilterAsync(eFilterType.Activated)">
                <a>{{ 'patientsList_filter_activated' | translate }}</a>
                <span>{{ nbPatientsActivated }}</span>
            </div>
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.NonActivated" (click)="updateMainFilterAsync(eFilterType.NonActivated)">
                <a>{{ 'patientsList_filter_nonActivated' | translate }}</a>
                <span>{{ nbPatientsNonActivated }}</span>
            </div>
        </div>
    </div>

    <div class="row row--guttered row--smaller content" [style.paddingTop]="'2rem'" *ngIf="!isLoading">
        <div id="filters-container">
            <div class="field-container" [style.padding-bottom]="'1rem'" [class.display-none]="!isMobile">
                <select class="field" #selectedFilter (change)="updateMainFilterAsync(selectedFilter.value)">
                    <option [selected]="currentFilter == eFilterType.AllPatients" [value]="eFilterType.AllPatients">{{ 'patientsList_filter_allPatients' | translate }} ({{ nbPatients }})</option>
                    <option [selected]="currentFilter == eFilterType.WithNights" [value]="eFilterType.WithNights">{{ 'patientsList_filter_withNights' | translate }} ({{ nbPatientsWithNight }})</option>
                    <option [selected]="currentFilter == eFilterType.Activated" [value]="eFilterType.Activated">{{ 'patientsList_filter_activated' | translate }} ({{ nbPatientsActivated }})</option>
                    <option [selected]="currentFilter == eFilterType.NonActivated" [value]="eFilterType.NonActivated">{{ 'patientsList_filter_nonActivated' | translate }} ({{ nbPatientsNonActivated }})</option>
                </select>
            </div>

            <div [ngClass]="{'two-field-container-per-row' : filterByOffice && doctorOffices?.length > 0}">
                <div class="field-container">
                    <input class="field"
                           [(ngModel)]="searchFilter"
                           placeholder="{{ 'patientsList_filter_last_name' | translate }}"
                    />
                </div>

                <div *ngIf="doctorOffices?.length > 0" class="field-container">
                    <select class="field"
                            [(ngModel)]="filterByOffice">
                        <option value="all" selected>{{ "patientsList_all_offices" | translate }}</option>
                        <option *ngFor="let office of doctorOffices" [value]="office.officeId">{{ office.officeName }}</option>
                        <option value="none">{{ "signUp_input_sharedOffice_none_label" | translate }}</option>
                    </select>
                </div>
            </div>

            <app-validatable-checkbox *ngIf="filterByDates"
                                      [validatableObject]="filterByDates"
                                      [disabled]="currentFilter == eFilterType.Activated || currentFilter == eFilterType.NonActivated"></app-validatable-checkbox>

            <div class="two-field-container-per-row" *ngIf="filterByDates.value">
                <app-validatable-date class="validatable-container"
                                      *ngIf="filterStartDate"
                                      [validatableObject]="filterStartDate"></app-validatable-date>

                <app-validatable-date class="validatable-container"
                                      *ngIf="filterEndDate"
                                      [validatableObject]="filterEndDate"></app-validatable-date>
            </div>

            <app-validatable-checkbox *ngIf="filterByNewNights"
                                      [validatableObject]="filterByNewNights"
                                      [disabled]="currentFilter == eFilterType.Activated || currentFilter == eFilterType.NonActivated"></app-validatable-checkbox>
        </div>

        <div id="patients-container">
            <div class="list-row list-row--header">
                <app-sortable-list-header
                    class="col"
                    [columnName]="'common_last_name'"
                    [columnValue]="'lastName'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    [columnName]="'common_first_name'"
                    [columnValue]="'firstName'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    *ngIf="isUSPractitioner"
                    [columnName]="'patientsList_columnHeader_patientId'"
                    [columnValue]="'customIdentifier'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    [columnName]="'common_birth_date'"
                    [columnSubtitle]="dateFormatLabel.toUpperCase()"
                    [columnValue]="'birthDate'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <div class="col col--see-more"></div>
            </div>

            <div *ngIf="!isLoading">
                <div *ngFor="let patient of patientsList | patientListFilter: searchFilter : currentFilter : filterByDates.value : filterStartDate.value : filterEndDate.value : filterByNewNights.value : filterByOffice | listSort: sortColumnValue : sortOrder; let last = last"
                     class="list-row list-row--clickable"
                     (click)="navigateToPatient(patient)">
                    <div class="notification-dot" [style.display]="patient.newNights ? 'block' : 'none'"></div>
                    <div class="col">{{ (patient.lastName) ? capitalizeFirstLetter(patient.lastName) : '-' }}</div>
                    <div class="col">{{ (patient.firstName) ? capitalizeFirstLetter(patient.firstName) : '-' }}</div>
                    <div *ngIf="isUSPractitioner" class="col">{{ (patient.customIdentifier) ? patient.customIdentifier : '-' }}</div>
                    <div class="col">{{ (patient.birthDate) ? formatDate(patient.birthDate) : '-' }}</div>
                    <div class="col col--see-more"><a class="internal">{{ 'common_see_more' | translate }}</a></div>
                </div>

                <div class="list-row list-row--clickable list-row--no-border" (click)="navigateToSampleNight()">
                    <div class="col">John</div>
                    <div class="col">Doe</div>
                    <div *ngIf="isUSPractitioner" class="col">-</div>
                    <div class="col">01/01/1985</div>
                    <div class="col col--see-more">
                        <span class="see-more-with-example__example-label">{{ 'common_example' | translate }}</span>
                        <a class="internal">{{ 'common_see_more' | translate }}</a>
                    </div>
                </div>
            </div>

            <div *ngIf="isLoading" class="list-row list-row--no-border">{{ 'patientsList_loading' | translate }}</div>
        </div>
    </div>
</main>
