import { Injectable } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import * as Highcharts from "highcharts"
import * as _ from "lodash"
import { AuthService } from "./auth.service"
import { ChartData } from "../models/Nights/ChartData"
import { ChartType } from "../models/Nights/ChartType"
import { Night } from "../models/Nights/Night"
import { UnitsAndFormatsPreferences } from "../models/UnitsAndFormatsPreferences"
import { TranslationUtilities } from "../utilities/TranslationUtilities"
import { doesUserPrefersH12HourFormat } from "../utilities/UnitsAndFormatsUtilities"

@Injectable({
    providedIn: "root",
})
export class NightReportChartsService {
    private dateTimeLabel: string = this.getDateTimeLabelFormat(this.authService.getPreferences())

    private baseChartOptions: Highcharts.Options = {
        title: { text: "" },
        credits: { enabled: false },
        subtitle: { text: "" },
        legend: { align: "right", enabled: true },
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function (e) {
                        e.preventDefault()
                    },
                },
                stickyTracking: false,
                states: {
                    hover: {
                        enabled: false,
                        lineWidth: 1,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
            },
        },
        tooltip: { enabled: false },
        xAxis: {
            gridLineWidth: 0,
            type: "datetime",
            dateTimeLabelFormats: {
                minute: this.dateTimeLabel,
                hour: this.dateTimeLabel,
                day: this.dateTimeLabel,
            },
            labels: {
                distance: 10,
            },
        },
    }

    constructor(
        protected translateService: TranslateService,
        protected readonly authService: AuthService,
    ) {}

    public async getChartOptionsFromChartTypeAsync(chartType: ChartType, night: Night): Promise<Highcharts.Options> {
        // ; needed to separate with the ( next line
        const dateTimeLabel: string = this.getDateTimeLabelFormat(this.authService.getPreferences())

        ;(this.baseChartOptions.xAxis as Highcharts.XAxisOptions).dateTimeLabelFormats = {
            minute: dateTimeLabel,
            hour: dateTimeLabel,
            day: dateTimeLabel,
        }

        switch (chartType) {
            case ChartType.Arousals:
                return await this.getArousalsChartOptionsAsync(night.chartData)
            case ChartType.SleepStages:
                return await this.getSleepStagesChartOptionsAsync(night.chartData)
            case ChartType.SleepStagesProba:
                return await this.getSleepStagesProbaChartOptionsAsync(night.chartData)
            case ChartType.SleepStates:
                return await this.getSleepStatesChartOptionsAsync(night.chartData)
            case ChartType.RespiratoryEffort:
                return await this.getRespiratoryEffortChartOptionsAsync(night.chartData)
            case ChartType.HeadPosition:
                return await this.getHeadPositionChartOptionsAsync(night.chartData)
        }
    }

    /* Arousals */
    private async getArousalsChartOptionsAsync(chartData: ChartData): Promise<Highcharts.Options> {
        const yAxisTitle: string = await this.__("nightReport_charts_Yaxis_arousal_label")

        const chartLegendLabels = [
            await this.__("nightReport_charts_legend_disconnections_label"),
            await this.__("nightReport_charts_Yaxis_arousal_label"),
        ]

        const chartOptions: Highcharts.Options = _.cloneDeep(this.baseChartOptions)
        chartOptions.chart = {
            type: "column",
            shadow: false,
        }
        chartOptions.yAxis = {
            gridLineWidth: 0,
            labels: { enabled: false },
            max: 1,
            min: 0,
            title: {
                text: yAxisTitle,
                style: {
                    color: "gray",
                    fontSize: "11px",
                    "word-wrap": "break-word",
                },
            },
        }

        chartOptions.series = [
            {
                name: chartLegendLabels[0],
                type: "area",
                data: chartData.arousals_disconnections as any,
                turboThreshold: 10000,
                color: "rgba(0,0,0,.15)",
                fillColor: "rgba(0,0,0,.15)",
                lineWidth: 1,
                marker: { enabled: false },
                step: "left",
            },
            {
                name: chartLegendLabels[1],
                type: "area",
                data: chartData.arousals as any,
                turboThreshold: 10000,
                color: "#323296",
                fillColor: "#323296",
                lineWidth: 1,
                marker: { enabled: false },
            },
        ]

        return chartOptions
    }

    /* Sleep stages */
    // SS-2403 New version of sleep stages chart to show only wakefulness and asleep states
    // Previous method is commented bellow
    private async getSleepStagesChartOptionsAsync(chartData: ChartData): Promise<Highcharts.Options> {
        const chartYAxisLabels = [
            await this.__("nightReport_charts_legend_asleep_label"),
            await this.__("nightReport_charts_legend_wakefulness_label"),
        ]

        const chartLegendLabels = [
            await this.__("nightReport_charts_legend_disconnections_label"),
            await this.__("nightReport_charts_legend_sleepStages_label"),
        ]

        const chartOptions: Highcharts.Options = _.cloneDeep(this.baseChartOptions)
        chartOptions.chart = {
            type: "area",
            shadow: false,
        }

        chartData.sleepStages.forEach((sleepStage) => {
            const sleepStageNumber = sleepStage[1]
            if (sleepStageNumber == 3) {
                sleepStage[1] = 1
            } else if (sleepStageNumber != null) {
                sleepStage[1] = 0
            }
        })

        chartOptions.yAxis = {
            gridLineWidth: 0,
            title: { text: "" },
            categories: chartYAxisLabels,
            min: 0,
            max: 1,
        }
        chartOptions.series = [
            {
                name: chartLegendLabels[0],
                type: "area",
                data: chartData.sleepStages_disconnections as any,
                turboThreshold: 10000,
                color: "rgba(0,0,0,.15)",
                fillColor: "rgba(0,0,0,.15)",
                lineWidth: 1,
                marker: { enabled: false },
                step: "left",
            },
            {
                name: chartLegendLabels[1],
                type: "line",
                data: chartData.sleepStages as any,
                turboThreshold: 10000,
                color: "#323296",
                lineWidth: 2,
                marker: { enabled: false },
                step: "left",
            },
        ]

        return chartOptions
    }

    /*
    private async getSleepStagesChartOptionsAsync(chartData: ChartData): Promise<Highcharts.Options> {
        const chartYAxisLabels = [
            await this.__('nightReport_charts_legend_deep_label'),
            await this.__('nightReport_charts_legend_light_label'),
            await this.__('nightReport_charts_legend_rem_label'),
            await this.__('nightReport_charts_legend_wakefulness_label'),
        ]

        const chartLegendLabels = [
            await this.__('nightReport_charts_legend_disconnections_label'),
            await this.__('nightReport_charts_legend_sleepStages_label')
        ]

        const chartOptions: Highcharts.Options = _.cloneDeep(this.baseChartOptions)
        chartOptions.chart = {
            type: 'area',
            shadow: false,
        }
        chartOptions.yAxis = {
            gridLineWidth: 0,
            title: { text: '' },
            categories: chartYAxisLabels,
            min: 0,
            max: 3,
        }

        chartOptions.series = [{
            name: chartLegendLabels[0],
            type: 'area',
            data: chartData.sleepStages_disconnections as any,
            turboThreshold: 10000,
            color: "rgba(0,0,0,.15)",
            fillColor: "rgba(0,0,0,.15)",
            lineWidth: 1,
            marker: { enabled: false },
            step: 'left',
        },
        {
            name: chartLegendLabels[1],
            type: 'line',
            data: chartData.sleepStages as any,
            turboThreshold: 10000,
            color: "#323296",
            lineWidth: 2,
            marker: { enabled: false },
            step: 'left'
        },
        {
            type: 'line',
            data: chartData.sleepStages_rem as any,
            turboThreshold: 10000,
            color: "rgba(255,0,0,.30)",
            lineWidth: 8,
            marker: { enabled: false },
            connectNulls: false,
            states: {
                hover: {
                    enabled: false
                }
            },
            showInLegend: false
        }]
        return chartOptions
    }*/

    /* Sleep states */
    private async getSleepStatesChartOptionsAsync(chartData: ChartData): Promise<Highcharts.Options> {
        const yAxisLabels = [
            await this.__("nightReport_charts_legend_asleep_label"),
            await this.__("nightReport_charts_sleep_states_arousals_yAxis_label"),
            await this.__("nightReport_charts_legend_awake_label"),
        ]

        const chartLegendLabels = [
            await this.__("nightReport_charts_legend_disconnections_label"),
            await this.__("nightReport_charts_legend_sleepStates_label"),
        ]

        const chartOptions: Highcharts.Options = _.cloneDeep(this.baseChartOptions)
        chartOptions.chart = {
            type: "column",
            shadow: false,
        }
        chartOptions.yAxis = {
            gridLineWidth: 0,
            title: { text: "" },
            categories: yAxisLabels,
            min: 0,
            max: 2,
        }

        chartOptions.series = [
            {
                name: chartLegendLabels[0],
                type: "area",
                data: chartData.sleepStates_disconnections as any,
                turboThreshold: 10000,
                color: "rgba(0,0,0,.15)",
                fillColor: "rgba(0,0,0,.15)",
                lineWidth: 1,
                marker: { enabled: false },
                step: "left",
            },
            {
                name: chartLegendLabels[1],
                type: "area",
                data: chartData.sleepStates as any,
                turboThreshold: 10000,
                color: "#323296",
                fillColor: "#323296",
                lineWidth: 1,
                marker: { enabled: false },
                step: "left",
            },
        ]

        return chartOptions
    }

    /* Sleep stages proba */
    private async getSleepStagesProbaChartOptionsAsync(chartData: ChartData): Promise<Highcharts.Options> {
        const chartLegendLabels = [
            await this.__("nightReport_charts_legend_wakefulness_label"),
            await this.__("nightReport_charts_legend_light_label"),
            await this.__("nightReport_charts_legend_deep_label"),
            await this.__("nightReport_charts_legend_rem_label"),
            await this.__("nightReport_charts_legend_disconnections_label"),
        ]

        const YAxisTitle: string = await this.__("nightReport_charts_sleep_stages_probas_chart_yAxis_title")

        const series: any = [
            {
                name: chartLegendLabels[4],
                data: chartData.sleepStages_disconnectionsProba,
                color: "rgba(0,0,0,.15)",
                fillColor: "rgba(0,0,0,.15)",
                turboThreshold: 10000,
                lineWidth: 1,
                marker: { enabled: false },
            },
            {
                name: chartLegendLabels[0],
                data: chartData.sleepStages_awakeProba,
                color: "#00A05A",
                fillColor: "#00A05A",
                turboThreshold: 10000,
                lineWidth: 1,
                marker: { enabled: false },
            },
            {
                name: chartLegendLabels[1],
                data: chartData.sleepStages_lightProba,
                color: "#82D7FF",
                fillColor: "#82D7FF",
                turboThreshold: 10000,
                lineWidth: 1,
                marker: { enabled: false },
            },
            {
                name: chartLegendLabels[2],
                data: chartData.sleepStages_deepProba,
                color: "#323296",
                fillColor: "#323296",
                turboThreshold: 10000,
                lineWidth: 1,
                marker: { enabled: false },
            },
            {
                name: chartLegendLabels[3],
                data: chartData.sleepStages_remProba,
                color: "#FFC300",
                fillColor: "#FFC300",
                turboThreshold: 10000,
                lineWidth: 1,
                marker: { enabled: false },
            },
        ]

        const chartOptions: Highcharts.Options = _.cloneDeep(this.baseChartOptions)
        chartOptions.chart = { type: "area" }
        chartOptions.yAxis = {
            gridLineWidth: 0,
            title: {
                text: YAxisTitle,
                style: {
                    color: "gray",
                    fontSize: "12px",
                    "word-wrap": "break-word",
                },
            },
        }

        chartOptions.plotOptions.area = { stacking: "percent" }
        chartOptions.series = series

        return chartOptions
    }

    /* Respiratory effort */
    private async getRespiratoryEffortChartOptionsAsync(chartData: ChartData): Promise<Highcharts.Options> {
        const chartLegendLabels = [
            await this.__("nightReport_charts_legend_shortEvents_label"),
            await this.__("nightReport_charts_legend_longEvents_label"),
            await this.__("nightReport_charts_legend_disconnections_label"),
        ]

        const yAxisTitle = await this.__("nightReport_charts_Respiratory_event_chart_yAxis_title")
        const series: any = [
            {
                name: chartLegendLabels[2],
                data: chartData.events_disconnections,
                type: "area",
                color: "rgba(0,0,0,.15)",
                fillColor: "rgba(0,0,0,.15)",
                turboThreshold: 10000,
                lineWidth: 1,
                marker: { enabled: false },
                step: "left",
            },
            {
                name: chartLegendLabels[1],
                data: chartData.longEvents,
                type: "area",
                color: "#82d7ff",
                fillColor: "#82d7ff",
                turboThreshold: 10000,
                lineWidth: 1,
                marker: { enabled: false },
            },
            {
                name: chartLegendLabels[0],
                data: chartData.shortEvents,
                type: "area",
                color: "#323296",
                fillColor: "#323296",
                turboThreshold: 10000,
                lineWidth: 1,
                marker: { enabled: false },
            },
        ]

        const chartOptions: Highcharts.Options = _.cloneDeep(this.baseChartOptions)
        chartOptions.chart = { type: "column" }
        chartOptions.yAxis = {
            gridLineWidth: 0,
            title: {
                text: yAxisTitle,
                style: {
                    color: "gray",
                    fontSize: "11px",
                    "word-wrap": "break-word",
                },
            },
        }
        chartOptions.series = series

        return chartOptions
    }

    /* Head position */
    private async getHeadPositionChartOptionsAsync(chartData: ChartData): Promise<Highcharts.Options> {
        const chartYAxisLabels = [
            await this.__("nightReport_charts_Yaxis_prone_label"),
            await this.__("nightReport_charts_Yaxis_side_label"),
            await this.__("nightReport_charts_Yaxis_supine_label"),
            await this.__("nightReport_charts_Yaxis_standUp_label"),
        ]

        const chartLegendLabels = [
            await this.__("nightReport_charts_legend_disconnections_label"),
            await this.__("nightReport_charts_legend_headPosition_label"),
        ]

        const chartOptions: Highcharts.Options = _.cloneDeep(this.baseChartOptions)
        chartOptions.chart = { type: "spline" }
        chartOptions.yAxis = {
            gridLineWidth: 0,
            title: { text: "" },
            categories: chartYAxisLabels,
            min: 0,
            max: 3,
        }
        chartOptions.series = [
            {
                name: chartLegendLabels[0],
                type: "area",
                data: chartData.headPosition_disconnections as any,
                turboThreshold: 10000,
                color: "rgba(0,0,0,.15)",
                fillColor: "rgba(0,0,0,.15)",
                lineWidth: 1,
                marker: { enabled: false },
                step: "left",
            },
            {
                name: chartLegendLabels[1],
                type: "spline",
                data: chartData.headPosition as any,
                turboThreshold: 10000,
                color: "#323296",
                dashStyle: "Solid",
                lineWidth: 2,
                marker: { enabled: false },
            },
        ]

        return chartOptions
    }

    /* Utils */
    private async __(key: string): Promise<string> {
        return await TranslationUtilities.getTranslationAsync(this.translateService, key)
    }

    private getDateTimeLabelFormat(userUnitsAndFormatsPreferences: UnitsAndFormatsPreferences): string {
        if (doesUserPrefersH12HourFormat(userUnitsAndFormatsPreferences)) {
            return "%l:%M %P"
        } else {
            // Default or if hourFormat == H24
            return "%H:%M"
        }
    }
}
