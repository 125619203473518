import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"

@Directive({
    selector: "[dragDropFile]",
})
export class DragDropFileDirective {
    private readonly defaultBackgroundColor: string = "rgba(0, 0, 0, 0.03)"
    private readonly hoverBackgroundColor: string = "rgba(0, 0, 0, 0.10)"

    @Output() droppedFiles: EventEmitter<File[]> = new EventEmitter<File[]>()
    @HostBinding("style.background") public backgroundColor = this.defaultBackgroundColor

    constructor(protected readonly translate: TranslateService) {}

    @HostListener("dragover", ["$event"])
    public onDragOver(evt: DragEvent): void {
        evt.preventDefault()
        evt.stopPropagation()

        this.backgroundColor = this.hoverBackgroundColor
    }

    @HostListener("dragleave", ["$event"])
    public onDragLeave(evt: DragEvent): void {
        evt.preventDefault()
        evt.stopPropagation()

        this.backgroundColor = this.defaultBackgroundColor
    }

    @HostListener("drop", ["$event"])
    public async onDrop(evt: DragEvent): Promise<void> {
        evt.preventDefault()
        evt.stopPropagation()

        this.backgroundColor = this.defaultBackgroundColor

        const files: File[] = Array.from(evt.dataTransfer.files)

        this.droppedFiles.emit(files)
    }
}
