import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { UnitsAndFormats } from "../../../models/Formats/UnitsAndFormats"
import { PortalNotificationType } from "../../../models/PortalNotification"
import { UnitsAndFormatsPreferences } from "../../../models/UnitsAndFormatsPreferences"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-units-formats-preferences",
    templateUrl: "./units-formats-preferences.component.html",
    styleUrls: ["./units-formats-preferences.component.sass"],
})
export class UnitsAndFormatsPreferencesComponent extends BaseComponent implements OnInit {
    public isLoading = false

    public preference_length: string
    public preference_weight: string
    public preference_hour: string
    public preference_date: string

    private defaultLengthPreference: string = UnitsAndFormats.Centimeters
    private defaultWeigthPreference: string = UnitsAndFormats.Kilograms
    private defaultHourPreference: string = UnitsAndFormats.H24
    private defaultDatePreferences: string = UnitsAndFormats.DDMMYYYY

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        const savedPreferences: UnitsAndFormatsPreferences = this.authService.getPreferences()

        this.preference_length = savedPreferences["preferredLengthUnit"]
        this.preference_weight = savedPreferences["preferredWeightUnit"]
        this.preference_hour = savedPreferences["preferredHourFormat"]
        this.preference_date = savedPreferences["preferredDateFormat"]
    }

    public navigateBack() {
        this.router.navigate(["main/account"])
    }

    // -------------------------------------------------------------------------
    // Methods
    // -------------------------------------------------------------------------

    public async savePreferencesAsync(): Promise<void> {
        try {
            this.isLoading = true

            const updateUnitsAndFormatsPreferencesBody: UnitsAndFormatsPreferences = {
                preferredLengthUnit: this.preference_length,
                preferredWeightUnit: this.preference_weight,
                preferredHourFormat: this.preference_hour,
                preferredDateFormat: this.preference_date,
            }

            await this.sunriseApiService.updateUserUnitsAndFormatsPreferencesAsync(updateUnitsAndFormatsPreferencesBody)

            this.authService.setUnitsAndFormatsPreferences(updateUnitsAndFormatsPreferencesBody)

            this.isLoading = false

            this.showNotification("preferences_updated_success", PortalNotificationType.Success)
        } catch (error) {
            console.log(error)

            this.showErrorOccurred()

            this.isLoading = false
        }
    }
}
