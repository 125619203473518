<div *ngIf="object" class="field-container" [class.required]="required" [class.field-container--errored]="object.hasError">
    <label class="label">{{ object.label | translate }}</label>

    <div *ngIf="useDDMMYYYYFormat" datePickerDDMMYYYYFormat class="date-picker__input">
        <input matInput
               placeholder="{{ dateFormatLabel | uppercase }}"
               [matDatepicker]="datepicker"
               [max]="maxDate"
               [disabled]="disabled"
               [(ngModel)]="object.value"
               (dateChange)="dateChangeHandlerAsync()"
               (keyup)="keyupHandlerAsync($event)"
               (focusout)="unfocusHandlerAsync()">

        <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker startView="multi-year"></mat-datepicker>
    </div>

    <div *ngIf="!useDDMMYYYYFormat" datePickerMMDDYYYYFormat class="date-picker__input">
        <input matInput
               placeholder="{{ dateFormatLabel | uppercase }}"
               [matDatepicker]="datepicker"
               [max]="maxDate"
               [(ngModel)]="object.value"
               (dateChange)="dateChangeHandlerAsync()"
               (keyup)="keyupHandlerAsync($event)"
               (focusout)="unfocusHandlerAsync()">

        <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker startView="multi-year"></mat-datepicker>
    </div>

    <small *ngIf="object.showHint" class="hint-text">{{ object.hintString }}</small>
    <small *ngIf="object.hasError" class="error-text">{{ object.errorString }}</small>
</div>
