import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { OwlOptions } from "ngx-owl-carousel-o"
import { TranslatableComponent } from "../translatable.component"

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
})
export class HomeComponent extends TranslatableComponent implements AfterViewInit {
    // -------------------------------------------------------------------------
    // Variables
    // -------------------------------------------------------------------------

    public descriptions: any[] = [
        { id: 1, title: "home_title_1", description: "home_description_1" },
        { id: 2, title: "home_title_2", description: "home_description_2" },
        { id: 3, title: "home_title_3", description: "home_description_3" },
    ]

    public images: string[] = [
        "assets/images/home/home_1.jpeg",
        "assets/images/home/home_2.jpg",
        "assets/images/home/home_3.jpg",
    ]

    public customOptions: OwlOptions = {
        autoplay: true,
        autoplayHoverPause: false,
        autoplaySpeed: 1250,
        autoplayTimeout: 7500,
        center: true,
        dots: true,
        dotsSpeed: 1250,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
        },
        loop: true,
    }

    public currentDescription: any

    @ViewChild("descriptionWrapper") public descriptionWrapperElement: ElementRef

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(protected readonly translate: TranslateService) {
        super(translate)
    }

    public async ngAfterViewInit(): Promise<void> {
        await this.updateDescriptionAsync(0)
    }

    // -------------------------------------------------------------------------
    // UI utils
    // -------------------------------------------------------------------------

    public async updateDescriptionAsync(descriptionIndex: number): Promise<void> {
        if (!this.descriptionWrapperElement) return

        this.descriptionWrapperElement.nativeElement.classList.add("hidden")

        await this.delay(500)

        this.currentDescription = this.descriptions[descriptionIndex]

        this.descriptionWrapperElement.nativeElement.classList.remove("hidden")
    }

    public formatTitleNumber(titleNumber): string {
        return `${titleNumber.toString().padStart(2, "0")}.`
    }

    // -------------------------------------------------------------------------
    // Events
    // -------------------------------------------------------------------------

    public async carouselChangeEvent(event: any): Promise<void> {
        const newIndex: number = event.startPosition

        await this.updateDescriptionAsync(newIndex)
    }
}
