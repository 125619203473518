import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { TranslationUtilities } from "../../utilities/TranslationUtilities"

export abstract class ValidatableRule {
    private readonly errorStringTemplate: string

    protected constructor(
        private readonly translate: TranslateService,
        errorStringTemplate?: string,
        private readonly translateParams: object = null,
    ) {
        this.errorStringTemplate = errorStringTemplate || "i18n:common_validation_error"
    }

    public validate(value: any): boolean {
        return _.isEmpty(value) || this.validateRule(value)
    }

    /**
     * Apply the validation rule to the value, to validate the value.
     * Precondition: value is not empty.
     * @param value {string} Value of the field.
     * @return true if the value is valid, false otherwise.
     */
    protected abstract validateRule(value: any): boolean

    public async getErrorStringTemplateBaseAsync(): Promise<string> {
        if (this.errorStringTemplate.startsWith("i18n:")) {
            return await TranslationUtilities.getTranslationAsync(
                this.translate,
                this.errorStringTemplate.replace("i18n:", ""),
                this.translateParams,
            )
        } else {
            return this.errorStringTemplate
        }
    }
}
