import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { constants } from "../../config/constants"
import { PortalNotificationType } from "../../models/PortalNotification"
import { PrescriptionPrices } from "../../models/Prescriptions/PrescriptionPrices"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { RegExpRule } from "../../validation/rules/RegExpRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-presciption",
    templateUrl: "./presciption.component.html",
    styleUrls: ["./presciption.component.sass"],
})
export class PresciptionComponent extends BaseComponent implements OnInit {
    public emailAddress: ValidatableObject
    public isMailSending = false
    public numberOfSensors = 1

    public prescriptionUnitPrix: number
    public prescriptionReducedUnitPrix: number
    public doctorCurrencySymbol: string

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.translate.onLangChange.subscribe(() => this.updateLanguage())
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------
    public async ngOnInit(): Promise<void> {
        if (!this.sunriseApiService.accountHasRole(constants.roles.prescriber)) {
            this.navigateToMainPage()
        } else {
            await this.initValidatableObjectsAsync()

            await this.getPrescriptionPricesAsync()
        }
    }

    // -------------------------------------------------------------------------
    // Initialize validation
    // -------------------------------------------------------------------------

    private async initValidatableObjectsAsync(): Promise<void> {
        this.emailAddress = new ValidatableObject(this.translate, "common_email")
        this.emailAddress.addRule(new NotEmptyRule(this.translate))
        this.emailAddress.addRule(
            new RegExpRule(
                this.translate,
                constants.validation.patterns.email,
                "i18n:signUp_input_emailAddress_error_invalid",
            ),
        )
    }

    /**
     * Send the new prescription mail to the patient
     */
    public async sendPrescriptionMail(): Promise<void> {
        const isValid: boolean = await this.emailAddress?.validateAsync()

        if (isValid) {
            this.isMailSending = true

            try {
                await this.sunriseApiService.sendPrescriptionMailAsync(
                    this.emailAddress.value,
                    this.numberOfSensors,
                    this.translate.currentLang,
                )

                this.emailAddress.value = ""
                this.showNotification("prescriptions_notifications_mail_success", PortalNotificationType.Success)
            } catch (error) {
                this.showErrorOccurred()
            } finally {
                this.isMailSending = false
            }
        }
    }

    private async getPrescriptionPricesAsync() {
        try {
            const prescriptionPrices: PrescriptionPrices =
                await this.sunriseApiService.retrieveDoctorPrescriptionPricesAsync()

            this.setPrescriptionsPricesVariables(prescriptionPrices)
        } catch (error) {
            this.showErrorOccurred()
        }
    }

    private setPrescriptionsPricesVariables(prescriptionPrices: PrescriptionPrices) {
        this.prescriptionUnitPrix = prescriptionPrices.unitRealPrice

        if (prescriptionPrices.unitPriceWithReduction)
            this.prescriptionReducedUnitPrix = prescriptionPrices.unitPriceWithReduction

        if (this.prescriptionReducedUnitPrix) {
            const prescriptionRealPriceElement: HTMLElement = document.getElementById("prescriptionRealPrice")

            prescriptionRealPriceElement.classList.add("prices--barred")
        }

        this.doctorCurrencySymbol = prescriptionPrices.doctorCountry == "CountryUnitedKingdom" ? "£" : "€"
    }

    // -------------------------------------------------------------------------
    // Translation helpers
    // -------------------------------------------------------------------------

    protected updateLanguage(): void {
        super.updateBaseLanguage()
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async keyupHandler(event: any, inputObject: ValidatableObject): Promise<void> {
        // If 'Enter' is pressed
        if (event.keyCode == 13) {
            await this.sendPrescriptionMail()
        } else if (inputObject.hasError) {
            inputObject.validateAsync()
        }
    }
}
