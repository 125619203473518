import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as Highcharts from "highcharts"
import HighchartsBoost from "highcharts/modules/boost"
import * as _ from "lodash"
import { Subscription } from "rxjs"
import { NightReportTemplateV2Component } from "./night-report-templates/night-report-template-v2/night-report-template-v2.component"
import { constants } from "../../config/constants"
import { AnswersMapper } from "../../mappers/AnswersMapper"
import { QuestionnaireMapper } from "../../mappers/QuestionnaireMapper"
import { Night } from "../../models/Nights/Night"
import { NightReportTemplate } from "../../models/Nights/NightReportTemplate"
import { Patient } from "../../models/Patient"
import { PortalNotificationType } from "../../models/PortalNotification"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SocketIOService } from "../../services/socketio.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { UrlService } from "../../services/url.service"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import { BaseComponent } from "../base.component"

HighchartsBoost(Highcharts)

@Component({
    selector: "app-night-report",
    templateUrl: "./night-report.component.html",
    styleUrls: ["./night-report.component.sass"],
})
export class NightReportComponent extends BaseComponent implements OnDestroy {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    /* Main objects */
    public night: Night
    public patient: Patient
    public nightVersionId: string
    public nightVersions: any[]

    /* Checks */
    public isLoading = true

    /* Roles */
    public isResearcher: boolean
    public isAdmin: boolean
    public isSampleReport = false

    /* Report template */
    public templateReport: NightReportTemplate

    /* Enum */
    public eTemplateReport: typeof NightReportTemplate = NightReportTemplate

    private nightVersionAnalyzedSubscription: Subscription

    @ViewChild("nightReportTemplateV2Component") nightReportTemplateV2Component: NightReportTemplateV2Component

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly urlService: UrlService,
        protected readonly route: ActivatedRoute,
        protected readonly changeDetector: ChangeDetectorRef,
        protected readonly socketIOService: SocketIOService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.translate.onLangChange.subscribe(() => {
            if (this.night) {
                this.reloadPage()
            }
        })

        this.router.routeReuseStrategy.shouldReuseRoute = () => false
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.isLoading = true

        this.isAdmin = this.sunriseApiService.isAdmin()
        this.isResearcher = this.sunriseApiService.accountHasRole(constants.roles.researcher)

        await this.initializeDataNightsAsync()

        /*
         * Prevent errors when night is undefined
         * Caught above in initializeDataNightsAsync() but error can occur during the time of the redirect
         */
        if (!this.night) return

        await this.initializeTextsAsync()

        if (this.night.questionnaireAnswers) {
            this.formatQuestionnaire()
            this.formatQuestionnaireAnswers()
        }

        this.nightVersionAnalyzedSubscription = this.socketIOService.nightVersionAnalyzedEvent.subscribe(
            async (data) => {
                if (data.nightId == this.night?.nightId) {
                    await this.loadNightVersionsAsync(data.nightId, false)

                    const navigateToVersionCallback = () => {
                        this.router.navigate([`main/nights/${data.nightId}/version/${data.nightVersionId}`])
                    }

                    this.showNotification(
                        "nightReport_newVersionAvailable_description",
                        PortalNotificationType.Success,
                        "nightReport_newVersionAvailable_title",
                        "nightReport_newVersionAvailable_viewReport",
                        navigateToVersionCallback,
                        20,
                    )
                }
            },
        )

        this.isLoading = false

        if (!this.isSampleReport) {
            await this.sunriseApiService.seeNight(this.night.nightId)
        }
    }

    public ngOnDestroy() {
        this.nightVersionAnalyzedSubscription?.unsubscribe()
    }

    // -------------------------------------------------------------------------
    // Initialization
    // -------------------------------------------------------------------------

    public async initializeDataNightsAsync(): Promise<void> {
        /* Get ids */
        const nightId: string = RoutesUtilities.getRouteParam(this.route, "nightId")
        this.nightVersionId = RoutesUtilities.getRouteParam(this.route, "versionId")

        this.isSampleReport = nightId == "sample"

        /* Load night versions if night is not sample report */
        if (!this.isSampleReport) {
            await this.loadNightVersionsAsync(nightId)
        }

        const needToRedirect: boolean = this.redirectIfNeededAsync(nightId, this.nightVersionId)
        if (needToRedirect) {
            // prevent night from loading due to latency of router.navigate
            return
        }

        /* Get night, night sample or night version from drop-down menu selection*/
        if (this.isSampleReport) {
            this.night = await this.sunriseApiService.getSampleReportAsync()
        } else if (this.nightVersionId) {
            this.night = await this.sunriseApiService.getNightVersionAsync(this.nightVersionId)
            this.night["formattedCreationDate"] = this.formatDateTime(this.night["creationDate"])
        } else {
            this.night = await this.sunriseApiService.getNightAsync(nightId)
        }

        /* Get patient */
        this.patient = this.night?.userInfos

        /* Redirect to patient if not authorized or if an error occurred */
        if (!this.night) {
            this.router.navigate(["main/patients"])
            return
        } else if (this.night.reportHiddenOnPlatform && !this.isAdmin) {
            this.router.navigate([`main/patients/${this.patient.id}/nights`])
            return
        }

        /* Select the good template to display report */
        if ((this.night.isUnitedStatesNight && this.night.sensorVersion == "2") || this.night.sensorVersion == "Air") {
            this.templateReport = NightReportTemplate.V2

            this.changeDetector.detectChanges()

            await this.nightReportTemplateV2Component?.createDropDownItemsAsync()
        } else {
            this.templateReport = NightReportTemplate.V1

            this.changeDetector.detectChanges()
        }
    }

    private async loadNightVersionsAsync(nightId: string, showError: boolean = true): Promise<void> {
        try {
            const allNights: any = await this.sunriseApiService.getNightVersionsAsync(nightId)

            this.nightVersions = [allNights.originalNight]

            if (allNights.nightVersions && allNights.nightVersions.length > 0) {
                this.nightVersions.push(...allNights.nightVersions)

                if (this.nightReportTemplateV2Component) {
                    await this.nightReportTemplateV2Component.createDropDownItemsAsync()
                }
            }

            for (const version of this.nightVersions) {
                if (version.creationDate) {
                    version.formattedCreationDate = this.formatDateTime(version.creationDate, "/", false)
                }
            }

            this.changeDetector.detectChanges()

            if (this.nightReportTemplateV2Component) {
                await this.nightReportTemplateV2Component.createDropDownItemsAsync()
            }
        } catch {
            if (showError) {
                this.showNotification("nightReport_versions_loading_error", PortalNotificationType.Error)
            }
        }
    }

    private async initializeTextsAsync(): Promise<void> {
        if (this.night?.questionnaireAnswers) {
            /* ISI score */
            const isiScoreLabel: HTMLElement = document.getElementById("isiScoreLabel")
            if (isiScoreLabel) {
                isiScoreLabel.innerHTML = await this.getIsiScoreLabelAsync(this.night.questionnaireAnswers.scores.isi)
            }

            /* ESS score */
            const essScoreLabel: HTMLElement = document.getElementById("essScoreLabel")
            if (essScoreLabel) {
                essScoreLabel.innerHTML = await this.getEssScoreLabelAsync(this.night.questionnaireAnswers.scores.ess)
            }
        }
    }

    // -------------------------------------------------------------------------
    // Questionnaires
    // -------------------------------------------------------------------------

    private formatQuestionnaire(): void {
        this.night.questionnaire =
            this.night.questionnaireNumber == "Questionnaire001"
                ? QuestionnaireMapper.toAdultQuestionnaire(this.night.questionnaire, this.translate.currentLang)
                : QuestionnaireMapper.toChildQuestionnaire(this.night.questionnaire, this.translate.currentLang)
    }

    private formatQuestionnaireAnswers(): void {
        if (!this.night.questionnaireAnswers) {
            return
        }

        this.night.questionnaireAnswerDate = this.night.questionnaireAnswers.creationDate
        this.night.questionnaireAnswers = AnswersMapper.toAnswers(this.night.questionnaireAnswers.answerLines)
    }

    // -------------------------------------------------------------------------
    // Get labels
    // -------------------------------------------------------------------------

    private async getIsiScoreLabelAsync(isiScore = -1): Promise<string> {
        if (isiScore == -1) {
            return "-"
        }

        let isiScoreLabel: string = isiScore + " " + (await this.__("common_points")) + " - "

        if (isiScore < 8) {
            isiScoreLabel += await this.__("nightReport_isi_low_insomnia")
        } else if (isiScore < 15) {
            isiScoreLabel += await this.__("nightReport_isi_mild_insomnia")
        } else if (isiScore < 22) {
            isiScoreLabel += await this.__("nightReport_isi_moderate_insomnia")
        } else {
            isiScoreLabel += await this.__("nightReport_isi_severe_insomnia")
        }

        return isiScoreLabel
    }

    private async getEssScoreLabelAsync(essScore = -1): Promise<string> {
        if (essScore == -1) {
            return "-"
        }

        let essScoreLabel: string = essScore + " " + (await this.__("common_points")) + " - "

        if (essScore < 11) {
            essScoreLabel += await this.__("nightReport_ess_low_sleepiness")
        } else if (essScore < 15) {
            essScoreLabel += await this.__("nightReport_ess_mild_sleepiness")
        } else if (essScore < 18) {
            essScoreLabel += await this.__("nightReport_ess_moderate_sleepiness")
        } else {
            essScoreLabel += await this.__("nightReport_ess_severe_sleepiness")
        }

        return essScoreLabel
    }

    // -------------------------------------------------------------------------
    // Export pdf
    // -------------------------------------------------------------------------

    private getLastAnalyzedNightVersionId(): string {
        const nightVersions: any[] = this.nightVersions.filter((nightVersion) => nightVersion.doctorId != undefined)
        let nightVersionId: string = undefined

        if (!_.isEmpty(nightVersions)) {
            nightVersions.sort((nightVersionA, nightVersionB) => {
                const creationDateA: number = new Date(nightVersionA.creationDate).getTime()
                const creationDateB: number = new Date(nightVersionB.creationDate).getTime()

                return creationDateB - creationDateA
            })

            nightVersionId = nightVersions[0].nightVersionId
        }

        return nightVersionId
    }

    // -------------------------------------------------------------------------
    // Navigation delegates
    // -------------------------------------------------------------------------

    private comeFromNightList(): boolean {
        let previousUrl: string

        if (this.urlService.previousUrl$) {
            this.urlService.previousUrl$.subscribe((url: string) => (previousUrl = url))

            return constants.validation.patterns.nightsListUrl.test(previousUrl)
        } else {
            return false
        }
    }

    private redirectIfNeededAsync(nightId: string, nightVersionId: string): boolean {
        /* Join : /nights/sample */
        if (nightId == "sample") {
            if (nightVersionId) {
                this.router.navigate(["main/nights/sample"])

                return true
            }
        } else if (!nightVersionId) {
            /* Join : /nights/:id */
            /* Come from night list and user is admin or researcher */
            if (this.comeFromNightList() && this.nightVersions?.length > 1) {
                nightVersionId = this.getLastAnalyzedNightVersionId()

                /* Redirect to last night version if night version is available */
                if (nightVersionId) {
                    this.router.navigate([`main/nights/${nightId}/version/${nightVersionId}`])

                    return true
                }
            }
        } else {
            /* Join : /nights/:id/version/:versionId */
            /* Redirect if user is not allowed to view a version of a report */
            if (this.nightVersions.length <= 1) {
                this.router.navigate([`main/nights/${nightId}`])

                return true
            }
        }

        return false
    }
}
