import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { CountryISO } from "@justin-s/ngx-intl-tel-input"
import { TranslateService } from "@ngx-translate/core"
import { constants } from "../../config/constants"
import { AccountActionType } from "../../models/AccountActionType"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { PasswordRegExp } from "../../utilities/passwordRegExp"
import { StringsUtilities } from "../../utilities/StringsUtilities"
import { EqualityRule } from "../../validation/rules/EqualityRule"
import { MinLengthRule } from "../../validation/rules/MinLengthRule"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { PhoneNumberValidRule } from "../../validation/rules/PhoneNumberValidRule"
import { RegExpRule } from "../../validation/rules/RegExpRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { TranslatableComponent } from "../translatable.component"

@Component({
    selector: "app-account-fields",
    templateUrl: "./account-fields.component.html",
})
export class AccountFieldsComponent extends TranslatableComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isLoading = true
    public selectedCountry: CountryISO

    public firstName: ValidatableObject
    public lastName: ValidatableObject
    public emailAddress: ValidatableObject
    public birthdate: ValidatableObject
    public password: ValidatableObject
    public passwordConfirmation: ValidatableObject
    public country: ValidatableObject
    public phoneNumberWithPrefix: ValidatableObject
    public phoneNumber: ValidatableObject

    public countries: any

    private AccountActionTypeFieldsConfig = {
        [AccountActionType.DoctorAccountCreation]: [
            { name: "firstName", required: true },
            { name: "lastName", required: true },
            { name: "emailAddress", required: true },
            { name: "country", required: true },
            { name: "phoneNumberWithPrefix", required: true },
            { name: "password", required: true },
            { name: "passwordConfirmation", required: false },
        ],
        [AccountActionType.DoctorAccountEdition]: [
            { name: "firstName", required: true },
            { name: "lastName", required: true },
            { name: "emailAddress", required: true },
            { name: "country", required: true },
            { name: "phoneNumberWithPrefix", required: true },
        ],
        [AccountActionType.DoctorAccountFilled]: [
            { name: "firstName", required: true },
            { name: "lastName", required: true },
            { name: "emailAddress", required: true },
            { name: "country", required: true },
            { name: "phoneNumberWithPrefix", required: true },
        ],
        [AccountActionType.PatientAccountCreation]: [
            { name: "firstName", required: false },
            { name: "lastName", required: false },
            { name: "emailAddress", required: false },
            { name: "country", required: false },
            { name: "phoneNumber", required: false },
            { name: "birthdate", required: false },
        ],
        [AccountActionType.PatientAccountEdition]: [
            { name: "firstName", required: false },
            { name: "lastName", required: false },
            { name: "country", required: false },
            { name: "phoneNumber", required: false },
            { name: "birthdate", required: false },
        ],
    }

    private validatableObjectInitializers = {
        firstName: () => {
            this.firstName = new ValidatableObject(this.translate, "signUp_input_firstName_label")
            this.firstName.addRule(new MinLengthRule(this.translate, 2))
        },
        lastName: () => {
            this.lastName = new ValidatableObject(this.translate, "signUp_input_lastName_label")
            this.lastName.addRule(new MinLengthRule(this.translate, 2))
        },
        emailAddress: () => {
            this.emailAddress = new ValidatableObject(this.translate, "signUp_input_emailAddress_label")
            this.emailAddress.addRule(
                new RegExpRule(
                    this.translate,
                    constants.validation.patterns.email,
                    "i18n:signUp_input_emailAddress_error_invalid",
                ),
            )
        },
        birthdate: () => {
            this.birthdate = new ValidatableObject(this.translate, "signUp_input_birthdate_label")
        },
        country: () => {
            this.country = new ValidatableObject(this.translate, "signUp_input_country_label")
            this.country.value = undefined
        },
        phoneNumberWithPrefix: () => {
            this.phoneNumberWithPrefix = new ValidatableObject(this.translate, "signUp_input_phoneNumber_label")
            this.phoneNumberWithPrefix.addRule(new PhoneNumberValidRule(this.translate))
        },
        phoneNumber: () => {
            this.phoneNumber = new ValidatableObject(this.translate, "signUp_input_phoneNumber_label")
            this.phoneNumber.addRule(new MinLengthRule(this.translate, 6))
        },
        password: async () => {
            this.password = new ValidatableObject(this.translate, "signUp_input_password_label")
            await this.password.addHintAsync("signUp_input_password_error_invalid")
            this.password.addRule(
                new RegExpRule(this.translate, PasswordRegExp, "i18n:signUp_input_password_error_invalid"),
            )
        },
        passwordConfirmation: () => {
            this.passwordConfirmation = new ValidatableObject(this.translate, "signUp_input_passwordConfirmation_label")
            this.passwordConfirmation.addRule(
                new EqualityRule(
                    this.translate,
                    this.password,
                    "i18n:signUp_input_passwordConfirmation_error_mustMatch",
                ),
            )
        },
    }

    @Input("accountActionType") public accountActionType: AccountActionType = AccountActionType.DoctorAccountCreation
    @Output("enterPressed") public enterPressed: EventEmitter<void> = new EventEmitter<void>()
    @Output("componentInit") public componentInit: EventEmitter<void> = new EventEmitter<void>()

    /* Enum */
    public eAccountActionType: typeof AccountActionType = AccountActionType

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly sunriseApiService: SunriseApiService,
    ) {
        super(translate)

        this.translate.onLangChange.subscribe(async () => this.updateLanguage())
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        await this.fillCountriesAsync()
        await this.initValidatableObjectsAsync()

        this.isLoading = false

        this.componentInit.emit()
    }

    // -------------------------------------------------------------------------
    // Translation helpers
    // -------------------------------------------------------------------------

    protected updateLanguage(): void {
        this.updateCountriesNames()

        super.updateBaseLanguage()
    }

    // -------------------------------------------------------------------------
    // Objects validation
    // -------------------------------------------------------------------------

    private addNotEmptyRule(validatableObject: ValidatableObject): void {
        if (validatableObject) {
            validatableObject.addRule(new NotEmptyRule(this.translate))
        }
    }

    public async initValidatableObjectsAsync(): Promise<void> {
        const fieldsConfig = this.AccountActionTypeFieldsConfig[this.accountActionType]

        fieldsConfig.forEach((field) => {
            // eslint-disable-next-line no-prototype-builtins
            if (this.validatableObjectInitializers.hasOwnProperty(field.name)) {
                this.validatableObjectInitializers[field.name]()

                if (field.required) {
                    this.addNotEmptyRule(this[field.name])
                }
            }
        })
    }

    /**
     * Validate validatable objects.
     * Force validating all the fields and do not stop at first error.
     * @return true if all the fields are valid, false otherwise
     */
    public async validateObjectsAsync(): Promise<boolean> {
        let isValid = true
        const fieldsConfig = this.AccountActionTypeFieldsConfig[this.accountActionType]

        for (let i = 0; i < fieldsConfig.length; i++) {
            const fieldName = fieldsConfig[i].name

            if (this[fieldName]) {
                isValid = (await this[fieldName].validateAsync()) && isValid
            }
        }
        return isValid
    }

    // -------------------------------------------------------------------------
    // Event helpers
    // -------------------------------------------------------------------------

    public countryChangeEvent(country: any): void {
        if (country) {
            const countryPrefix: string = this.getCountryPrefixFromCountryNumber(country)

            if (countryPrefix) {
                this.selectedCountry = countryPrefix as CountryISO
            }
        }
    }

    public enterPressedEvent() {
        this.enterPressed.emit()
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    private async fillCountriesAsync(): Promise<void> {
        this.countries = await this.sunriseApiService.getCountriesAsync()

        this.updateCountriesNames()
    }

    private updateCountriesNames(): void {
        if (this.countries && this.countries.length) {
            for (let i = 0; i < this.countries.length; i++) {
                this.countries[i]["translatedName"] = this.countries[i]["name"][this.translate.currentLang]
            }
        }
    }

    private getCountryPrefixFromCountryNumber(countryNumber: string): string {
        const country: any = this.countries.find((country) => country.countryNumber == countryNumber)

        if (country) return country.prefix
    }

    public formatEmailAddress(emailAddress: string): string {
        emailAddress = StringsUtilities.formatAddressEmail(emailAddress)

        return emailAddress
    }
}
