import { Component, Input } from "@angular/core"
import { ValidatableObject } from "../../../../validation/ValidatableObject"

@Component({
    selector: "app-validatable-textarea",
    templateUrl: "./validatable-textarea.component.html",
})
export class ValidatableTextAreaComponent {
    // -------------------------------------------------------------------------
    // Instance variables
    // -------------------------------------------------------------------------

    @Input("placeHolder") placeHolder: string
    @Input("validatableObject") object: ValidatableObject

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async unfocusHandlerAsync(): Promise<void> {
        await this.object.validateAsync()
    }
}
