import { Component, EventEmitter, Input, Output } from "@angular/core"
import { ValidatableObject } from "../../validation/ValidatableObject"

@Component({
    selector: "app-password-field",
    templateUrl: "./password-field.component.html",
    styleUrls: ["./password-field.component.sass"],
})
export class PasswordFieldComponent {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isPasswordHidden = true

    @Input("validatableObject") password: ValidatableObject
    @Input("required") required = false
    @Input("useLabelAsPlaceholder") useLabelAsPlaceholder = false

    @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>()

    // -------------------------------------------------------------------------
    // Inputs helpers
    // -------------------------------------------------------------------------

    public onPasswordVisibilityButtonClicked() {
        this.isPasswordHidden = !this.isPasswordHidden
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async keyupHandlerAsync(event: KeyboardEvent) {
        if (event.key == "Enter") {
            this.enterPressed.emit()
        } else if (this.password.hasError) {
            await this.password.validateAsync()
        }
    }

    public async unfocusHandlerAsync(): Promise<void> {
        await this.password.validateAsync()
    }
}
