import { Injectable } from "@angular/core"
import * as _ from "lodash"
import { CookieService } from "ngx-cookie-service"
import { UnitsAndFormatsPreferences } from "../models/UnitsAndFormatsPreferences"
import { DefaultUnitsAndFormats } from "../utilities/UnitsAndFormatsUtilities"

/**
 * AuthService facilitates the management of the connection tokens.
 * AuthService uses localStorage to store tokens in the browser of the client.
 */
@Injectable({
    providedIn: "root",
})
export class AuthService {
    private readonly USE_SESSION_COOKIES: string = "useSessionCookies"
    private readonly ACCESS_TOKEN_KEY: string = "token"
    private readonly REFRESH_TOKEN_KEY: string = "refreshToken"

    private readonly IS_ADMIN_KEY: string = "isAdmin"
    private readonly IS_US_KEY: string = "isUnitedStates"
    private readonly ROLES_KEY: string = "roles"
    private readonly UNITS_FORMATS_PREFERENCES_KEY: string = "unitsAndFormatsPreferences"

    private readonly USER_EMAIL_ADDRESS_KEY: string = "userEmailAddress"
    private readonly ACCOUNT_CONFIRMED_KEY: string = "accountConfirmed"

    public isAuthenticated: boolean = this.checkUserIsAuthenticated()

    public constructor(private readonly cookiesService: CookieService) {}

    /**
     * Indicates if the current user is admin or not
     */
    public isAdmin(): boolean {
        return this.useSessionCookies()
            ? this.cookiesService.get(this.IS_ADMIN_KEY) === "true"
            : localStorage.getItem(this.IS_ADMIN_KEY) === "true"
    }

    /**
     * Indicates if the current user is US pracitioner or not
     */
    public isUnitedStates(): boolean {
        return this.useSessionCookies()
            ? this.cookiesService.get(this.IS_US_KEY) === "true"
            : localStorage.getItem(this.IS_US_KEY) === "true"
    }

    /* _________________________________________________ */
    /* ______________________TOKEN______________________ */
    /* _________________________________________________ */
    /**
     * Get token
     */
    public getToken(): string {
        return this.useSessionCookies()
            ? this.cookiesService.get(this.ACCESS_TOKEN_KEY)
            : localStorage.getItem(this.ACCESS_TOKEN_KEY)
    }

    /**
     * Get Refresh token
     */
    public getRefreshToken(): string {
        return this.useSessionCookies()
            ? this.cookiesService.get(this.REFRESH_TOKEN_KEY)
            : localStorage.getItem(this.REFRESH_TOKEN_KEY)
    }

    /**
     * Store tokens of the current client in localStorage.
     * @param {string} token Access token
     * @param {string} refreshToken Refresh token
     * @param {boolean} isAdmin Indicates if user is admin or not
     * @param {boolean} isUnitedStates Indicates if account is US or not
     * @param {boolean} stayConnected Indicates if user want to stay connected or not
     */
    public setTokens(
        token: string,
        refreshToken: string,
        isAdmin: boolean,
        isUnitedStates: boolean,
        stayConnected = false,
    ): void {
        if (stayConnected) {
            localStorage.setItem(this.ACCESS_TOKEN_KEY, token)
            localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken)

            if (isAdmin) localStorage.setItem(this.IS_ADMIN_KEY, "true")
            if (isUnitedStates) localStorage.setItem(this.IS_US_KEY, "true")

            this.isAuthenticated = true
        } else {
            this.cookiesService.set(this.USE_SESSION_COOKIES, "true", { path: "/" })
            this.cookiesService.set(this.ACCESS_TOKEN_KEY, token, { path: "/" })
            this.cookiesService.set(this.REFRESH_TOKEN_KEY, refreshToken, { path: "/" })

            if (isAdmin) this.cookiesService.set(this.IS_ADMIN_KEY, "true", { path: "/" })
            if (isUnitedStates) this.cookiesService.set(this.IS_US_KEY, "true", { path: "/" })
        }

        this.isAuthenticated = true
    }

    /**
     * Refresh tokens after an API call
     * @param {string} token Access token
     * @param {string} refreshToken Refresh token
     */
    public refreshTokens(token: string, refreshToken: string): void {
        if (this.useSessionCookies()) {
            this.cookiesService.set(this.ACCESS_TOKEN_KEY, token, { path: "/" })
            this.cookiesService.set(this.REFRESH_TOKEN_KEY, refreshToken, { path: "/" })
        } else {
            localStorage.setItem(this.ACCESS_TOKEN_KEY, token)
            localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken)
        }
    }

    /**
     * Transfer content of session cookie to localStorage
     */
    public transferSessionToLocalStorage(): void {
        const keys: string[] = Object.keys(this.cookiesService.getAll())

        for (const key of keys) {
            localStorage.setItem(key, this.cookiesService.get(key))
            this.cookiesService.delete(key, "/")
        }

        this.isAuthenticated = this.checkUserIsAuthenticated()
    }

    /* _________________________________________________ */
    /* ______________________ROLES______________________ */
    /* _________________________________________________ */

    /**
     * Store roles of the current client in localStorage.
     * @param {string[]} roles Roles of current client
     */
    public setRoles(roles: string[]): void {
        localStorage.setItem(this.ROLES_KEY, JSON.stringify(roles ? roles : []))
    }

    /**
     * Get roles of the current client from localStorage.
     * @return undefined if no role is found
     */
    public getRoles(): string[] {
        const roles: string[] = JSON.parse(localStorage.getItem(this.ROLES_KEY))
        return roles ? roles : undefined
    }

    private deleteRoles(): void {
        localStorage.removeItem(this.ROLES_KEY)
    }

    /* _________________________________________________ */
    /* ____________________PREFRENCES___________________ */
    /* _________________________________________________ */
    /**
     * Store roles of the current client in localStorage.
     * @param {UnitsAndFormatsPreferences} unitsAndFormatsPreferences Preferences of current client
     */
    public setUnitsAndFormatsPreferences(unitsAndFormatsPreferences: UnitsAndFormatsPreferences): void {
        if (unitsAndFormatsPreferences) {
            localStorage.setItem(this.UNITS_FORMATS_PREFERENCES_KEY, JSON.stringify(unitsAndFormatsPreferences))
        }
    }

    /**
     * Get unitsAndFormatsPreferences of the current client from localStorage.
     * @return undefined if no unitsAndFormatsPreferences is found
     */
    public getPreferences(): UnitsAndFormatsPreferences {
        try {
            const userUnitsAndFormatsPreferences: any = JSON.parse(
                localStorage.getItem(this.UNITS_FORMATS_PREFERENCES_KEY),
            )

            return userUnitsAndFormatsPreferences ? userUnitsAndFormatsPreferences : DefaultUnitsAndFormats
        } catch {
            return DefaultUnitsAndFormats
        }
    }

    private deletePreferences(): void {
        localStorage.removeItem(this.UNITS_FORMATS_PREFERENCES_KEY)
    }

    /**
     * Remove items in storages when user got disconnected
     */
    public disconnectUser(): void {
        this.deleteTokens()
        this.deleteRoles()
        this.deletePreferences()
        this.deleteUserEmailAddress()
        this.deleteAccountConfirmed()

        this.isAuthenticated = false
    }

    /**
     * Store emailAddress of the current user in localStorage.
     * @param {string} emailAddress emailAddress of current client
     */
    public setUserEmailAddress(emailAddress: string): void {
        localStorage.setItem(this.USER_EMAIL_ADDRESS_KEY, emailAddress)
    }

    /**
     * Get email address of the current client from localStorage.
     */
    public getUserEmailAddress(): string {
        return localStorage.getItem(this.USER_EMAIL_ADDRESS_KEY)
    }

    private deleteUserEmailAddress(): void {
        localStorage.removeItem(this.USER_EMAIL_ADDRESS_KEY)
    }

    /**
     * Store if current account is confirmed in localStorage.
     * @param {boolean} accountConfirmed accountConfirmed of current client
     */
    public setAccountConfirmed(accountConfirmed: boolean): void {
        localStorage.setItem(this.ACCOUNT_CONFIRMED_KEY, accountConfirmed.toString())
    }

    /**
     * Get is current account confirmed from localStorage.
     */
    public getAccountConfirmed(): boolean {
        const accountConfirmed: string = localStorage.getItem(this.ACCOUNT_CONFIRMED_KEY)

        return JSON.parse(accountConfirmed) === true
    }

    private deleteAccountConfirmed(): void {
        localStorage.removeItem(this.ACCOUNT_CONFIRMED_KEY)
    }

    /**
     * Delete tokens of the current client to disconnect him.
     */
    public deleteTokens(): void {
        localStorage.removeItem(this.ACCESS_TOKEN_KEY)
        localStorage.removeItem(this.REFRESH_TOKEN_KEY)
        localStorage.removeItem(this.IS_ADMIN_KEY)
        localStorage.removeItem(this.IS_US_KEY)

        this.cookiesService.delete(this.USE_SESSION_COOKIES, "/")
        this.cookiesService.delete(this.ACCESS_TOKEN_KEY, "/")
        this.cookiesService.delete(this.REFRESH_TOKEN_KEY, "/")
        this.cookiesService.delete(this.IS_ADMIN_KEY, "/")
        this.cookiesService.delete(this.IS_US_KEY, "/")
    }

    /**
     * Check if the current client has valid token (non-expired token).
     * @return true if the client has a valid token, false otherwise
     */
    public checkUserIsAuthenticated(): boolean {
        const refreshToken: string = this.getRefreshToken()
        const isExpired: boolean = _.isEmpty(refreshToken) || this.isTokenExpired(refreshToken)

        this.isAuthenticated = !isExpired

        return !isExpired
    }

    private isTokenExpired(token: string) {
        try {
            const expiry = JSON.parse(atob(token.split(".")[1])).exp

            return Math.floor(new Date().getTime() / 1000) >= expiry
        } catch {
            return true
        }
    }

    private useSessionCookies(): boolean {
        return this.cookiesService.get(this.USE_SESSION_COOKIES) === "true"
    }
}
