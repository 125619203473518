<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'templates' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateBack()'>{{ 'header_account_menu' | translate }}</a> / {{ 'templates_list' | translate }}
    </div>

    <div class="row row--guttered row--guttered-v row--smaller content" [style.paddingTop]="0" *ngIf="!isLoading">

        <div>
            <div class="templates-list-container" *ngIf="impressionTemplates.length > 0">
                <div class="list-row list-row--title list-row--no-border">{{ 'templates_type_impression' | translate }}</div>

                <div *ngFor="let template of impressionTemplates; let last = last">
                    <div class="template-row--no-content">
                        <!-- Template title -->
                        <div class="list-row list-row--no-border list-row--clickable template-header" (click)="toggleTemplate($event)">
                            <div class="col">{{template.title}}</div>
                        </div>
                        <!-- Template title -->

                        <!-- Template content -->
                        <div class="template-content">
                            <div class="template-text">{{template.text}}</div>
                            <app-button (clicked)="navigateToUpdateTemplate(template)" [customClasses]="'button--margin'">{{ 'common_update' | translate }}</app-button>
                            <app-button (clicked)="deleteTemplate(template)" [customClasses]="'button--margin'">{{ 'common_delete' | translate }}</app-button>
                        </div>

                        <div class="separator" *ngIf="!last"></div>
                        <!-- Template content -->
                    </div>
                </div>
            </div>

            <div *ngIf="impressionTemplates.length == 0">
                <p class="list-row list-row--title list-row--no-border">{{ 'template_noImpression' | translate }}</p>
            </div>
        </div>

        <div>
            <div *ngIf="recommendationTemplates.length > 0">
                <div class="list-row list-row--title list-row--no-border">{{ 'templates_type_recommendation' | translate }}</div>

                <div *ngFor="let template of recommendationTemplates; let last = last">
                    <div class="template-row--no-content">
                        <!-- Template title -->
                        <div class="list-row list-row--no-border list-row--clickable template-header" (click)="toggleTemplate($event)">
                            <div class="col">{{template.title}}</div>
                        </div>
                        <!-- Template title -->

                        <!-- Template content -->
                        <div class="template-content">
                            <div class="template-text">{{template.text}}</div>
                            <app-button (clicked)="navigateToUpdateTemplate(template)" [customClasses]="'button--margin'">{{ 'common_update' | translate }}</app-button>
                            <app-button (clicked)="deleteTemplate(template)" [customClasses]="'button--margin'">{{ 'common_delete' | translate }}</app-button>
                        </div>

                        <div class="separator" *ngIf="!last"></div>
                        <!-- Template content -->
                    </div>
                </div>
            </div>

            <div *ngIf="recommendationTemplates.length == 0">
                <p class="list-row list-row--title list-row--no-border">{{ 'template_noRecommendation' | translate }}</p>
            </div>
        </div>

        <div class="list-row list-row--no-border">
            <app-button (clicked)="navigateToCreateTemplate()">{{ 'template_create' | translate }}</app-button>
        </div>
    </div>

    <div *ngIf='isLoading' class="row row--guttered row--tiny content">
        <span>{{ 'templates_list_loading' | translate }}</span>
    </div>
</main>
