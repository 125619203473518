import { AfterViewInit, Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { SimpleModalService } from "@looorent/ngx-simple-modal"
import { TranslateService } from "@ngx-translate/core"
import * as FileSaver from "file-saver"
import * as _ from "lodash"
import { constants } from "../../config/constants"
import { PromptComponent } from "../../dialogs/prompt.component"
import { Night } from "../../models/Nights/Night"
import { NightSupportInfo } from "../../models/Nights/NightSupportInfo"
import { PortalNotificationType } from "../../models/PortalNotification"
import { SupportMessageTemplate } from "../../models/support/SupportMessageTemplate"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiMktService } from "../../services/sunrise-api-mkt.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-admin-support",
    templateUrl: "./admin-support.component.html",
    styleUrls: ["./admin-support.component.sass"],
})
export class AdminSupportComponent extends BaseComponent implements OnInit, AfterViewInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------
    private readonly ncfBaseUri: string = "https://neomalabs.atlassian.net/browse"
    private readonly zohoOrderBaseUri: string = "https://crm.zoho.eu/crm/org20066358236/tab/SalesOrders"
    private readonly intercomUserProfileBaseUri: string = "https://app.intercom.com/a/apps/u0c8b1b5/users"

    private readonly numberOfMillisecondsInOneDay: number = 86400000

    public isLoading = false
    public isExportingNights = false
    public hasAdminSupportRole: boolean

    private defaultStartDateFilter: string
    private defaultEndDateFilter: string

    public nights: any[] = []

    public supportMessageTemplates: SupportMessageTemplate[]

    private filtersHtmlElements: any[] = [
        { filter: "filterDate", values: ["filterDateValue"] },
        { filter: "filterBetween", values: ["filterStartDate", "filterEndDate"] },
        { filter: "filterPatientName", values: ["filterPatientNameValue"] },
        { filter: "filterPatientEmail", values: ["filterPatientEmailValue"] },
        { filter: "filterSerialNumber", values: ["filterSerialNumberValue"] },
        { filter: "filterTypeNcf", values: ["filterTypeNcfValue"] },
        { filter: "filterDoctorName", values: ["filterDoctorNameValue"] },
        { filter: "filterDoctorEmail", values: ["filterDoctorEmailValue"] },
    ]

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly simpleModalService: SimpleModalService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly sunriseApiMktService: SunriseApiMktService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.hideFooter()

        this.isAdmin = this.sunriseApiService.isAdmin()
        this.hasAdminSupportRole = this.sunriseApiService.accountHasRole(constants.roles.supportAdmin)

        if (!this.canConsultReports) {
            this.navigateToMainPage()
        }

        await this.fillInNightsListWithDefaultFilterAsync()
    }

    // -------------------------------------------------------------------------
    // Navigation delegates
    // -------------------------------------------------------------------------

    public navigateToNight(night: any): void {
        if (night.isAnalyzed && (!night.reportHiddenOnPlatform || this.isAdmin)) {
            this.sunriseApiService.resetPatient()

            // Allow control click
            window.open(`/main/nights/${night.nightId}`, "_blank")
        }
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async fillInNightsList(filters: any = undefined, exportNights = false): Promise<void> {
        if (exportNights) {
            await this.exportNightsListAsync(filters)
        } else {
            this.isLoading = true

            this.nights = []
            const receivedNights = await this.getNightsAsync(filters)

            // Check that the night list is still empty, in case a previous request has been received.
            if (this.nights.length == 0) this.nights = receivedNights

            this.isLoading = false
        }
    }

    private async getNightsAsync(filters: any = undefined): Promise<any[]> {
        try {
            const res = await this.sunriseApiService.getSupportNightsListAsync(filters)

            res.data.nights.forEach((night) => {
                //Show disconnections beyond 15 min for support
                night.reliabilityIndex.lostData = night.reliabilityIndex.lostDataDuration >= 15
            })

            return res.data.nights
        } catch (error) {
            this.handleError(error)

            if (error.status == 404) {
                return []
            } else {
                this.showNotification("Une erreur s'est produite.", PortalNotificationType.Error)
            }
        }
    }

    private async exportNightsListAsync(filters: any = undefined): Promise<void> {
        try {
            this.isExportingNights = true

            const res = await this.sunriseApiService.exportSupportNightsListAsync(filters)

            FileSaver.saveAs(res.blob, res.filename, false)

            this.isExportingNights = false
        } catch (error) {
            this.isExportingNights = false

            this.handleError(error)
            this.showNotification("Une erreur s'est produite.", PortalNotificationType.Error)
        }
    }

    // -------------------------------------------------------------------------
    // UI helpers
    // -------------------------------------------------------------------------

    public async toggleReportSection(targetId: string): Promise<void> {
        const targetElement: HTMLElement = document.getElementById(targetId)

        targetElement.classList.toggle("report-section--minified")

        if (targetElement.classList.contains("report-section--minified")) return

        const night = this.nights.find((n) => n.nightId == targetId)

        try {
            this.sunriseApiService.seeNight(night.nightId.toString())
        } catch {
            this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
        }

        await this.fillSectionElementIfNeededAsync(night)
    }

    public async toggleFilterSection(): Promise<void> {
        const targetElement: HTMLElement = document.getElementById("filterSection")

        targetElement.classList.toggle("report-section--minified")
    }

    public async createNightNCFAsync(nightId: string, serialNumber: string): Promise<void> {
        try {
            const createdNCFNumber: string = (document.getElementById("NCF_" + nightId) as HTMLInputElement).value
            const night: any = this.nights.find((night) => night.nightId == nightId)

            const linked_NCF: string = (
                await this.sunriseApiMktService.addZohoSensorLinkedNCF(serialNumber, createdNCFNumber)
            ).data.linked_NCF

            night.zohoOrderInformations.nightsInformation.sensor_ncf_number = linked_NCF
        } catch (error) {
            console.log(error)

            this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
        }
    }

    public async deleteNightNCFAsync(nightId: string, serialNumber: string): Promise<void> {
        try {
            const linked_NCF: string = (await this.sunriseApiMktService.deleteZohoSensorLinkedNCF(serialNumber)).data
                .linked_NCF

            const night: any = this.nights.find((night) => night.nightId == nightId)
            night.zohoOrderInformations.nightsInformation.sensor_ncf_number = linked_NCF
        } catch (error) {
            console.log(error)

            this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
        }
    }

    public async addNightTypeNCFAsync(nightId: string, serialNumber: string): Promise<void> {
        try {
            const newTypeNCF: string = (document.getElementById("CODEBUG_" + nightId) as HTMLInputElement).value

            await this.sunriseApiService.addTypeNCFForANight(nightId, newTypeNCF, serialNumber)

            const night: any = this.nights.find((night) => night.nightId == nightId)
            night.typeNCF = newTypeNCF
        } catch (error) {
            console.log(error)

            this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
        }
    }

    public async deleteNightTypeNCFAsync(nightId: string, serialNumber: string): Promise<void> {
        try {
            await this.sunriseApiService.deleteTypeNCFForANight(nightId, serialNumber)

            const night: any = this.nights.find((night) => night.nightId == nightId)
            night.typeNCF = undefined
        } catch (error) {
            console.log(error)

            this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
        }
    }

    public async sendNewSensorAsync(night: any, checkBoxClick = false) {
        const nightId: string = night.nightId
        const sendSensorCheckbox: HTMLInputElement = <HTMLInputElement>document.getElementById("newSensor_" + nightId)

        //Default behavior of check box changes checked status on click
        if (checkBoxClick) sendSensorCheckbox.checked = !sendSensorCheckbox.checked

        if (!sendSensorCheckbox.checked) {
            let orderSubject: any

            if (night?.zohoOrderInformations?.orderInformation) {
                orderSubject = night.zohoOrderInformations.orderInformation.order_subject
            }

            if (!orderSubject) {
                this.showNotification("Commande non trouvée", PortalNotificationType.Error)

                return
            }

            try {
                const sendNewSensor: boolean = confirm("Etes vous sur de vouloir renvoyer un capteur pour cette nuit ?")

                if (sendNewSensor) {
                    const sasOrderInfo: object = (
                        await this.sunriseApiMktService.sendNewSensorAsync(
                            orderSubject,
                            night.adminInfos.sensorSerialNumber,
                        )
                    ).data.sasOrderInfo

                    night.zohoOrderInformations.orderInformation.order_SAS_subject = sasOrderInfo["sasOrderSubject"]
                    night.zohoOrderInformations.orderInformation.order_SAS_id = sasOrderInfo["sasOrderId"]

                    this.showNotification("La commande a été correctement créée.", PortalNotificationType.Success)
                }
            } catch (error) {
                console.log(error)

                this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
            }
        } else {
            this.showNotification("Une commande SAV existe déjà pour ce capteur.", PortalNotificationType.Error)

            return
        }
    }

    public openUriInNewTab(uri: string): void {
        window.open(uri, "_blank")
    }

    public openNcfInNewTab(ncfNumber: string) {
        const uri: string = `${this.ncfBaseUri}/${ncfNumber}`

        this.openUriInNewTab(uri)
    }

    public openZohoOrderInNewTab(orderId: string) {
        if (!orderId) return

        const uri: string = `${this.zohoOrderBaseUri}/${orderId}`

        this.openUriInNewTab(uri)
    }

    public copyToClipBoard(text: string, lang: string) {
        lang = lang == "fr" ? "fr" : "en"
        text = text.replace("{{lang}}", lang)

        const selBox: HTMLTextAreaElement = document.createElement("textarea")
        selBox.style.position = "fixed"
        selBox.style.left = "0"
        selBox.style.top = "0"
        selBox.style.opacity = "0"
        selBox.value = text
        document.body.appendChild(selBox)
        selBox.focus()
        selBox.select()
        document.execCommand("copy")
        document.body.removeChild(selBox)
    }

    public async openIntercomProfileInNewTabAsync(night: any) {
        if (
            !night.zohoOrderInformations ||
            !night.zohoOrderInformations.intercomInformation ||
            !night.zohoOrderInformations.intercomInformation.userId
        ) {
            this.showNotification("L'utilisateur n'a pas été trouvé.", PortalNotificationType.Error)

            return
        }

        window.open(
            `${this.intercomUserProfileBaseUri}/${night.zohoOrderInformations.intercomInformation.userId}`,
            "_blank",
        )
    }

    public selectTypeNcf(event: any, night: any) {
        const selectedTemplate: SupportMessageTemplate = this.supportMessageTemplates.find(
            (_) => _.typeNcfWithLang == event.target.value,
        )

        night.selectedTemplateNcf = selectedTemplate
    }

    private async fillSectionElementIfNeededAsync(night: any): Promise<void> {
        // Only for admin support roles
        if (!this.hasAdminSupportRole) return

        const hideNightCheckBox: HTMLInputElement = <HTMLInputElement>(
            document.getElementById("hidding_" + night.nightId)
        )

        if (night.isHiddenInDoctorList || night.isHiddenInPatientList) {
            hideNightCheckBox.checked = true
        }

        if (!night.zohoOrderInformations || !night.nightInformationForSupport) {
            try {
                const retrieveTemplates: boolean = !this.supportMessageTemplates
                const endDateTime: string = this.getUtcEndDateTimeForNight(night)

                const nightSupportInfo: NightSupportInfo =
                    await this.sunriseApiService.getNightInformationForSupportAsync(
                        night.userInfos.id,
                        night.adminInfos.sensorSerialNumber,
                        night.userInfos.email,
                        new Date(endDateTime),
                        retrieveTemplates,
                    )

                night.zohoOrderInformations = nightSupportInfo.orderInformation
                night.nightInformationForSupport = {
                    linksInformation: nightSupportInfo.linksInformation,
                    codeBugsHistorical: nightSupportInfo.codeBugsHistorical,
                    numberOfUsedSensors: nightSupportInfo.numberOfUsedSensors,
                    doctorCreatorEmail: nightSupportInfo.doctorCreatorEmail,
                }

                if (!this.supportMessageTemplates) {
                    this.supportMessageTemplates = nightSupportInfo.supportMessageTemplates
                }

                this.removeTypeNcfFromHistorical(night)
            } catch (error) {
                // For support investigations
                console.log(error)

                this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
            }
        }
    }

    private getUtcEndDateTimeForNight(night: any): string {
        const date = new Date(night.endDateTime.date)

        // Remove offset to get UTC
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset() - night.utcTimeOffset)

        return date.toISOString()
    }

    public removeTypeNcfFromHistorical(night): void {
        if (!night.nightInformationForSupport.codeBugsHistorical) return

        const typeNcfArrayIndex = night.nightInformationForSupport.codeBugsHistorical.indexOf(night.typeNCF)

        if (typeNcfArrayIndex > -1) {
            night.nightInformationForSupport.codeBugsHistorical.splice(typeNcfArrayIndex, 1)
        }
    }

    public getTypeNcfWithLangListFromTemplates(): string[] {
        if (!this.supportMessageTemplates) return
        const displayedTemplates: SupportMessageTemplate[] = this.supportMessageTemplates.filter((_) => _.isDisplayed)

        return displayedTemplates.map((_) => _.typeNcfWithLang)
    }

    public async hideNightAsync(night: Night, checkBoxClick = false): Promise<any> {
        const checkBox: HTMLInputElement = <HTMLInputElement>document.getElementById("hidding_" + night.nightId)

        //Default behavior of check box changes checked status on click
        if (checkBoxClick) checkBox.checked = !checkBox.checked

        const nightIsHidden = !checkBox.checked

        try {
            await this.sunriseApiService.hideNightAsync(night.nightId)

            this.updateCheckboxChecked(checkBox, nightIsHidden)

            this.showNotification("Nuit correctement mise à jour.", PortalNotificationType.Success)
        } catch (error) {
            console.log(error)

            this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
        }
    }

    // -------------------------------------------------------------------------
    // Communication Patient/Doctor
    // -------------------------------------------------------------------------
    public async sendUserMailAsync(night) {
        if (
            !night.zohoOrderInformations ||
            !night.zohoOrderInformations.intercomInformation ||
            !night.zohoOrderInformations.intercomInformation.conversationId
        ) {
            this.showNotification("La conversation Intercom n'a pas pu être chargée.", PortalNotificationType.Error)

            return
        }

        const patientMailTemplate: string = this.replaceSavProviderTextInTemplateIfNeeded(
            night.selectedTemplateNcf as SupportMessageTemplate,
            night.adminInfos,
        )

        this.simpleModalService
            .addModal(PromptComponent, {
                title: "Envoyer un mail au patient",
                message: patientMailTemplate,
            })
            .subscribe(async (message) => {
                if (message) {
                    try {
                        await this.sunriseApiMktService.answerUserOnIntercomAsync(
                            night.zohoOrderInformations.intercomInformation.conversationId,
                            message,
                        )

                        this.showNotification(
                            "Le mail a été correctement envoyé au patient.",
                            PortalNotificationType.Success,
                        )
                    } catch (error) {
                        console.log(error)
                        this.showNotification("La réponse n'a pas pu être envoyée.", PortalNotificationType.Error)
                    }
                }
            })
    }

    public async sendSupportCommentAsync(night: any) {
        const supportCommentTemplate: string = (night.selectedTemplateNcf as SupportMessageTemplate)
            .supportCommentTemplate

        this.simpleModalService
            .addModal(PromptComponent, {
                title: "Ajouter un commentaire médecin",
                message: supportCommentTemplate,
            })
            .subscribe(async (message) => {
                if (message) await this.addSupportCommentForAGivenNightAsync(night, message)
            })
    }

    public async updateSupportCommentAsync(night: any) {
        this.simpleModalService
            .addModal(PromptComponent, {
                title: "Modifier le commentaire médecin",
                message: night.supportComment,
            })
            .subscribe(async (message) => {
                if (message) await this.addSupportCommentForAGivenNightAsync(night, message)
            })
    }

    private async addSupportCommentForAGivenNightAsync(night: any, message: string) {
        try {
            await this.sunriseApiService.addSupportCommentForANight(night.nightId, message)

            night.supportComment = message
            this.showNotification("Le commentaire a été correctement envoyé.", PortalNotificationType.Success)
        } catch (error) {
            console.log(error)

            this.showNotification("Une erreur est survenue.", PortalNotificationType.Error)
        }
    }

    private replaceSavProviderTextInTemplateIfNeeded(
        selectedTemplate: SupportMessageTemplate,
        adminInfos: any,
    ): string {
        const textTemplate: string = selectedTemplate.patientMailTemplate

        if (!adminInfos.savProviderForm || adminInfos.savProviderForm.fillDate != null) {
            return textTemplate.replace("{{SAV_Provider}}", "")
        } else {
            const templateLang: string = selectedTemplate.typeNcfWithLang.substring(
                selectedTemplate.typeNcfWithLang.length - 2,
            )
            const savTemplate: SupportMessageTemplate = this.supportMessageTemplates.find(
                (template) => template.typeNcfWithLang == `SAVProvider - ${templateLang}`,
            )
            const savLinkUri: string = adminInfos.savProviderForm.linkUrl.replace(
                "{{lang}}",
                templateLang.toLocaleLowerCase(),
            )

            savTemplate.patientMailTemplate = savTemplate.patientMailTemplate.replace("{{savForm_uri}}", savLinkUri)

            return textTemplate.replace("{{SAV_Provider}}", savTemplate.patientMailTemplate)
        }
    }

    // -------------------------------------------------------------------------
    // Filters helpers
    // -------------------------------------------------------------------------

    public selectFilter(updateFilterId: string): void {
        const checkBox: HTMLInputElement = <HTMLInputElement>document.getElementById(updateFilterId)
        const filterUpdated: any = this.filtersHtmlElements.find((filter) => filter.filter == updateFilterId)

        filterUpdated.values.forEach((value) => {
            const valueElement: HTMLInputElement = document.getElementById(value) as HTMLInputElement
            valueElement.disabled = !checkBox.checked
            valueElement.value = ""

            // Enter default value for filters
            if (checkBox.checked) {
                if (filterUpdated.filter == "filterDate") {
                    const nowDate = new Date()

                    valueElement.value = `${this.pad(nowDate.getDate())}/${this.pad(
                        nowDate.getMonth() + 1,
                    )}/${nowDate.getFullYear()}`
                }

                if (filterUpdated.filter == "filterBetween") {
                    this.generateDefaultFilter()
                }
            }
        })
    }

    private getSelectedFilters(): any[] {
        const selectedFilters: any[] = []

        this.filtersHtmlElements.forEach((filter) => {
            const checkBox: HTMLInputElement = <HTMLInputElement>document.getElementById(filter.filter)

            if (checkBox.checked) {
                selectedFilters.push(filter)
            }
        })

        return selectedFilters
    }

    public async startFilterNightsAsync(exportNights = false): Promise<void> {
        const selectedFilters: any = this.getSelectedFilters()

        const nightFilters: object[] = []

        if (!_.isEmpty(selectedFilters)) {
            selectedFilters.forEach((filter) => {
                if (filter.filter == "filterBetween" || filter.filter == "filterDate") {
                    const endDateIndex: number = filter.filter == "filterDate" ? 0 : 1

                    const startDateFormatted: string = this.convertDDMMYYYYToTimestamp(
                        (document.getElementById(filter.values[0]) as HTMLInputElement).value,
                    )
                    const endDateFormatted: string = this.convertDDMMYYYYToTimestamp(
                        (document.getElementById(filter.values[endDateIndex]) as HTMLInputElement).value,
                    )

                    const startDateStringFormatted: string = parseInt(startDateFormatted).toString()
                    const endaDateStringFormatted: string = (
                        parseInt(endDateFormatted) + this.numberOfMillisecondsInOneDay
                    ).toString()

                    nightFilters.push({
                        type: "filterStartDate",
                        value: startDateStringFormatted,
                    })

                    nightFilters.push({
                        type: "filterEndDate",
                        value: endaDateStringFormatted,
                    })
                } else {
                    nightFilters.push({
                        type: filter.filter,
                        value: (document.getElementById(filter.values[0]) as HTMLInputElement).value,
                    })
                }
            })
        }

        //Close filter section
        await this.toggleFilterSection()

        await this.fillInNightsList(nightFilters, exportNights)
    }

    public async removeFilterNightsAsync(): Promise<void> {
        this.filtersHtmlElements.forEach((filter) => {
            const filterElement: HTMLInputElement = document.getElementById(filter.filter) as HTMLInputElement
            filter.selected = false
            filterElement.checked = false

            filter.values.forEach((value) => {
                const valueElement: HTMLInputElement = document.getElementById(value) as HTMLInputElement
                valueElement.disabled = true
                valueElement.value = ""
            })
        })

        await this.toggleFilterSection()

        await this.fillInNightsList()
    }

    private async fillInNightsListWithDefaultFilterAsync() {
        this.generateDefaultFilter()
        await this.toggleFilterSection()

        await this.startFilterNightsAsync()
    }

    // Default filter is all the night from three days before until today
    private generateDefaultFilter() {
        const filter: any = this.filtersHtmlElements.find((element) => element.filter == "filterBetween")
        const filterElement: HTMLInputElement = <HTMLInputElement>document.getElementById(filter.filter)
        const startValueElement: HTMLInputElement = <HTMLInputElement>document.getElementById(filter.values[0])
        const endValueElement: HTMLInputElement = <HTMLInputElement>document.getElementById(filter.values[1])

        if (!this.defaultStartDateFilter || !this.defaultEndDateFilter) {
            const startDate = new Date()
            const endDate = new Date()

            startDate.setDate(startDate.getDate() - 3)
            filterElement.checked = true
            filter.selected = true

            startValueElement.disabled = false
            endValueElement.disabled = false

            this.defaultStartDateFilter = `${this.pad(startDate.getDate())}/${this.pad(
                startDate.getMonth() + 1,
            )}/${startDate.getFullYear()}`
            this.defaultEndDateFilter = `${this.pad(endDate.getDate())}/${this.pad(
                endDate.getMonth() + 1,
            )}/${endDate.getFullYear()}`
        }

        startValueElement.value = this.defaultStartDateFilter
        endValueElement.value = this.defaultEndDateFilter
    }

    // -------------------------------------------------------------------------
    // Keyups handlers
    // -------------------------------------------------------------------------
    public async keyupFiltersHandler(event: any): Promise<void> {
        // If 'Enter' is pressed
        if (event.keyCode == 13) {
            await this.startFilterNightsAsync()
        }
    }

    // -------------------------------------------------------------------------
    // Others helpers
    // -------------------------------------------------------------------------

    public formatHour(dateTime): string {
        if (dateTime == undefined || dateTime.unixTimeInMillis == undefined) return ""

        try {
            const date: Date = new Date(dateTime.date)
            return this.pad(date.getHours(), 2) + ":" + this.pad(date.getMinutes(), 2)
        } catch {}
    }

    public getLocalTime(dateTime: any) {
        if (!dateTime) return
        const date = new Date(dateTime.unixTimeInMillis + dateTime.utcTimeOffsetInMinutes * 60000)

        return this.formatDateNumber(date.getUTCHours()) + ":" + this.formatDateNumber(date.getUTCMinutes())
    }

    private pad(num: number, size = 2): string {
        let str: string = num + ""

        while (str.length < size) {
            str = "0" + str
        }

        return str
    }

    private updateCheckboxChecked(checkBox: HTMLInputElement, isChecked: boolean) {
        checkBox.checked = isChecked
    }
}
