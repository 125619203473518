import { Component, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { NotEmptyRule } from "../../../validation/rules/NotEmptyRule"
import { RegExpRule } from "../../../validation/rules/RegExpRule"
import { ValidatableObject } from "../../../validation/ValidatableObject"
import { TranslatableComponent } from "../../translatable.component"

@Component({
    selector: "app-sensors-to-patient-assignation",
    templateUrl: "./sensors-to-patient-assignation.component.html",
})
export class SensorsToPatientAssignationComponent extends TranslatableComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public userId: string

    public serialNumber: ValidatableObject
    public secretCode: ValidatableObject
    public isSecretCodeRequired: ValidatableObject

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(protected readonly translate: TranslateService) {
        super(translate)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        await this.initValidatableObjectsAsync()
    }

    // -------------------------------------------------------------------------
    // Objects validation
    // -------------------------------------------------------------------------

    public async initValidatableObjectsAsync(): Promise<void> {
        this.serialNumber = new ValidatableObject(this.translate, "sensorUserAssign_serialNumber_label")
        this.serialNumber.addRule(new NotEmptyRule(this.translate))

        this.secretCode = new ValidatableObject(this.translate, "sensorUserAssign_pinCode_label")
        this.secretCode.addRule(new NotEmptyRule(this.translate))
        this.secretCode.addRule(new RegExpRule(this.translate, /^\d{4}$/, "i18n:sensorUserAssign_pinCode_error_label"))

        this.isSecretCodeRequired = new ValidatableObject(this.translate, "sensorUserAssign_pinCodeRequired_label")
        this.isSecretCodeRequired.value = false
    }

    /**
     * Format and validate validatable objects.
     * Force validating all the fields and do not stop at first error.
     * @return true if all the fields are valid, false otherwise
     */
    public async formatAndValidateObjectsAsync(): Promise<boolean> {
        let isValid: boolean = await this.serialNumber.validateAsync()

        if (this.isSecretCodeRequired.value) {
            isValid = (await this.secretCode.validateAsync()) && isValid
        } else {
            //Set it to null if not required
            // To be checked in  SS-2472 PR
            this.secretCode.value = null
        }

        return isValid
    }
}
