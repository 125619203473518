import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core"
import { SortOrder } from "../../../models/SortOrder"

@Component({
    selector: "app-sortable-list-header",
    templateUrl: "./sortable-list-header.component.html",
})
export class SortableListHeaderComponent {
    @Input() public columnName: string
    @Input() public columnSubtitle: string
    @Input() public columnValue: string
    @Input() public sortOrder: SortOrder
    @Input() public currentSortingColumnValue: string

    @Output() columnClicked: EventEmitter<string> = new EventEmitter()

    public isSorting: boolean = false

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentSortingColumnValue) {
            this.isSorting = this.columnValue == this.currentSortingColumnValue
        }
    }

    public onHeaderClick(): void {
        this.columnClicked.emit(this.columnValue)
    }
}
