import { Component, HostListener, OnInit } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { filter } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
})
export class NavbarComponent implements OnInit {
    // -------------------------------------------------------------------------
    // HTML event
    // -------------------------------------------------------------------------

    @HostListener("window:scroll", []) onScroll() {
        const logoIcon = document.getElementById("logoIcon")

        if (!logoIcon) return

        logoIcon.style.transform = `rotate(${window.pageYOffset / 5}deg)`
    }

    // -------------------------------------------------------------------------
    // Variables
    // -------------------------------------------------------------------------

    public isConnected: boolean

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly authService: AuthService,
        protected readonly router: Router,
    ) {
        this.isConnected = this.authService.isAuthenticated
    }

    public ngOnInit(): void {
        this.initRouterEvent()
    }

    // -------------------------------------------------------------------------
    // Router event
    // -------------------------------------------------------------------------

    private initRouterEvent(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.isConnected = this.authService.isAuthenticated

            this.closeNavbarOnMobile()
        })
    }

    // -------------------------------------------------------------------------
    // Utils
    // -------------------------------------------------------------------------

    private closeNavbarOnMobile(): void {
        const burgerElements = document.querySelectorAll(".navbar__burger")
        const buttonsWrapperElements = document.querySelectorAll(".navbar__buttons-wrapper")

        burgerElements.forEach((burgerElement) => burgerElement.classList.remove("navbar__burger--active"))
        buttonsWrapperElements.forEach((buttonsWrapper) =>
            buttonsWrapper.classList.remove("navbar__buttons-wrapper--active"),
        )
    }
}
