/**
 * Base declaraction for data access through LocalForage.
 */
export abstract class LocalForageDataAccessBase {
    /**
     * LocalForage instance.
     */
    private readonly localForage: LocalForage

    /**
     * Constructor.
     * @param {LocalForage} localForage LocalForage instance
     */
    public constructor(localForage: LocalForage) {
        this.localForage = localForage
    }

    /**
     * Sets an item into cache local storage.
     * @param {string} key Identifier of the item to set
     * @param {T} value Item to set
     * @returns set item, or undefined if an error occured
     */
    protected async setItemAsync<T>(key: string, value: T): Promise<T> {
        const serializedValue: string = JSON.stringify(value)
        const storedSerializedValue: string = await this.setSerializedItemAsync(key, serializedValue)

        if (!storedSerializedValue) {
            return undefined
        }

        return JSON.parse(storedSerializedValue)
    }

    /**
     * Sets a serialized item into cache local storage.
     * @param {string} key Identifier of the item to set
     * @param {string} value Item to set
     * @returns set serialized item, or undefined if an error occured
     */
    protected async setSerializedItemAsync(key: string, value: string): Promise<string> {
        try {
            return await this.localForage.setItem(key, value)
        } catch (err) {
            console.log(err)

            return undefined
        }
    }

    /**
     * Gets an item from cache local storage.
     * @param {string} key Identifier of the item to get
     * @returns item
     */
    protected async getItemAsync<T>(key: string): Promise<T> {
        const serializedValue: string = await this.getSerializedItemAsync(key)

        if (!serializedValue) {
            return undefined
        }

        return JSON.parse(serializedValue)
    }

    /**
     * Gets a serialized item from cache local storage.
     * @param {string} key Identifier of the item to get
     * @returns serialized item
     */
    protected async getSerializedItemAsync(key: string): Promise<string> {
        try {
            return await this.localForage.getItem(key)
        } catch (err) {
            console.log(err)

            return undefined
        }
    }

    /**
     * Removes an item from cache local storage.
     * @param {string} key Identifier of the item to remove
     */
    protected async removeItemAsync(key: string): Promise<void> {
        try {
            await this.localForage.removeItem(key)
        } catch (err) {
            console.log(err)
        }
    }

    /**
     * Gets all keys actually used by LocalForage.
     * @returns all keys used, or undefined if an error occured
     */
    protected async getAllKeys(): Promise<string[]> {
        try {
            return await this.localForage.keys()
        } catch (err) {
            console.log(err)

            return undefined
        }
    }
}
