import { Pipe, PipeTransform } from "@angular/core"
import * as _ from "lodash"
import { NightAnalysisActionValues } from "../../models/Nights/NightAnalysisActionValues"
import { SleepStudy } from "../../models/Nights/SleepStudy"
import { StudyListFilter } from "../../models/StudyListFilter"

@Pipe({
    name: "studyListFilter",
})
export class StudyListFilterPipe implements PipeTransform {
    transform(
        studies: SleepStudy[],
        filterText: string,
        searchFilter: StudyListFilter,
        filterByOffice: string,
    ): SleepStudy[] {
        filterText = filterText.toLowerCase()

        return studies.filter(
            (study) =>
                this.filterOnStudiesListFilter(study, searchFilter) &&
                this.filterOnInputText(study, filterText) &&
                this.filterByOffice(study, filterByOffice),
        )
    }

    private filterOnStudiesListFilter(study: SleepStudy, searchFilter: StudyListFilter): boolean {
        return (
            // All studies: do not filter the study list.
            searchFilter === StudyListFilter.AllStudies ||
            // Filter on studies to be approved.
            (searchFilter === StudyListFilter.ToBeApproved &&
                study.nightIsAnalyzed &&
                !study.nightAnalysisStep &&
                study.nightIsInterpretable) ||
            // Filter on studies to be interpreted.
            (searchFilter === StudyListFilter.ToBeInterpreted &&
                study.nightAnalysisStep?.actionType == NightAnalysisActionValues.Validation) ||
            // Filter on studies to be signed.
            (searchFilter === StudyListFilter.ToBeSigned &&
                study.nightAnalysisStep?.actionType == NightAnalysisActionValues.Interpretation) ||
            // Filter on signed studies.
            (searchFilter === StudyListFilter.Signed &&
                study.nightAnalysisStep?.actionType == NightAnalysisActionValues.Signature)
        )
    }

    private filterOnInputText(study: SleepStudy, inputText: string): boolean {
        // Filter on first name, last name or customIdentifier.
        return (
            `${study.firstName} ${study.lastName}`.toLowerCase().includes(inputText) ||
            `${study.lastName} ${study.firstName}`.toLowerCase().includes(inputText) ||
            study.userIdentifier?.toLowerCase().includes(inputText)
        )
    }

    private filterByOffice(study: SleepStudy, filterByOffice: string): boolean {
        // Filter on patient office
        return (
            filterByOffice == undefined ||
            filterByOffice == "all" ||
            (filterByOffice == "none" && _.isEmpty(study.userOffice)) ||
            study.userOffice?.officeId == filterByOffice
        )
    }
}
