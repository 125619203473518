<div *ngIf="currentAnalysisStep != eAnalysisStep.CannotAccessAnalysis">

    <!-- Night report analysis steps -->
    <div class="row row--guttered-h">

        <span class="step-container">
            <img class="step-image" [src]="isValidated ? '../../../assets/images/icon_valid_light__green.svg' : '../../../assets/images/checkbox__unchecked_light.png'">
            <span class="step-header-label" [style.color]="isValidated ? '#00A059' : 'black'">{{ 'analysis_step_label_revision' | translate}}</span>

            <app-button
                *ngIf="currentAnalysisStep == eAnalysisStep.Validation"
                [loading]="isCreatingStep"
                (clicked)="approveReportAsync()"
                [customClasses]="'button-header'"
            >
                {{ 'analysis_button_label_approve' | translate }}
            </app-button>
        </span>

        <span class="step-container step-not-accessible" [class.step-not-accessible]="!isValidated">
            <img class="step-image" [src]="isInterpreted ? '../../../assets/images/icon_valid_light__green.svg' : '../../../assets/images/checkbox__unchecked_light.png'">
            <span id="interpretationStep" class="step-header-label" [style.color]="isInterpreted ? '#00A059' : 'black'" [class.step-accessible]="isInterpreted" (click)="isInterpreted && onAnalysisSectionClick($event)">{{ 'analysis_step_label_interpretation' | translate}}</span>
        </span>

        <span class="step-container step-not-accessible" [class.step-not-accessible]="!isValidated || !isInterpreted">
            <img class="step-image" [src]="isSigned ? '../../../assets/images/icon_valid_light__green.svg' : '../../../assets/images/checkbox__unchecked_light.png'">
            <span id="signatureStep" class="step-header-label" [style.color]="isSigned ? '#00A059' : 'black'" [class.step-accessible]="isInterpreted && !isSigned" (click)="isInterpreted && !isSigned && onAnalysisSectionClick($event)">{{ 'analysis_step_label_signature' | translate}}</span>
        </span>

        <div class="step-indication">
            <p>{{ analysisStepInfo }}</p>
        </div>
    </div>
    <!-- Night report analysis steps -->

    <!-- Night report analysis interpretation section -->
    <div class="row row--guttered-h" [style.display]="currentAnalysisStep == eAnalysisStep.Interpretation ? 'inline-block' : 'none'">
        <div class="separator"></div>

        <h2 class="title-h2 complete-analysis-title">{{ 'analysis_step_label_interpretation' | translate}}</h2>

        <div class="complete-analysis-container">

            <div [style.display]="isGettingInterpretationTemplates? 'block' : 'none'">
                <p>{{ 'analysis_loading_retrievingInterpretationTemplates_label' | translate}}</p>
                <img class="logo_loading step-image" src="assets/images/sunrise-logo.svg" style="margin-bottom: 2rem;">
            </div>

            <div [style.display]="!isGettingInterpretationTemplates? 'block' : 'none'">
                <div class="two-field-container-per-row">
                    <div class="validatable-container">

                        <p class="step-label">{{ 'analysis_impressions_template' | translate}}</p>
                        <select id="selectTemplateType" (change)="onTemplateOptionSelected($event)">
                            <option>{{ 'analysis_emptyTemplate' | translate}}</option>
                            <option *ngFor="let impressionTemplate of impressionTemplates"
                                    [value]="impressionTemplate.nightAnalysisTemplateId"
                                    [selected]="impressionTemplate.nightAnalysisTemplateId == 'LastImpressionSaved'">{{ impressionTemplate.title }}</option>
                        </select>

                        <p class="step-label">{{ 'analysis_impressions_label' | translate}}</p>
                        <app-validatable-textarea class="validatable-container"
                                                  *ngIf="impression"
                                                  [validatableObject]="impression"
                                                  placeHolder="{{ 'analysis_impressions_placeholder' | translate}}"></app-validatable-textarea>
                    </div>

                    <div class="validatable-container">
                        <p class="step-label">{{ 'analysis_recommendation_template' | translate}}</p>
                        <select id="selectTemplateRecommendation" (change)="onTemplateOptionSelected($event)">
                            <option>{{ 'analysis_emptyTemplate' | translate}}</option>
                            <option *ngFor="let recommendationTemplate of recommendationTemplates"
                                    [value]="recommendationTemplate.nightAnalysisTemplateId"
                                    [selected]="recommendationTemplate.nightAnalysisTemplateId == 'LastRecommendationSaved'">{{ recommendationTemplate.title }}</option>
                        </select>

                        <p class="step-label">{{ 'analysis_recommendation_label' | translate}}</p>
                        <app-validatable-textarea class="validatable-container"
                                                  *ngIf="recommendation"
                                                  [validatableObject]="recommendation"
                                                  placeHolder="{{ 'analysis_recommendation_placeholder' | translate}}"></app-validatable-textarea>
                    </div>
                </div>

                <app-button
                    [loading]="isCreatingStep"
                    (clicked)="interpretReportAsync()"
                    [customClasses]="'button-analysis button--margin-bottom'"
                >
                    {{ 'analysis_button_label_interpretation' | translate }}
                </app-button>
            </div>
        </div>
    </div>
    <!-- Night report analysis interpretation section -->

    <!-- Night report analysis signature section -->
    <div class="row row--guttered-h" [style.display]="currentAnalysisStep == eAnalysisStep.Signature ? 'inline-block' : 'none'">
        <div class="separator"></div>

        <h2 class="title-h2 complete-analysis-title">{{ 'analysis_step_label_signature' | translate}}</h2>

        <div class="complete-analysis-container">

            <div [style.display]="isGettingLastSignature? 'block' : 'none'">
                <p>{{ 'analysis_loading_retrievingLastSignature_label' | translate}}</p>
                <img class="logo_loading step-image" src="assets/images/sunrise-logo.svg" style="margin-bottom: 2rem;">
            </div>

            <div [style.display]="!isGettingLastSignature? 'block' : 'none'">
                <div *ngIf="lastSignatureSaved">
                    <p id="signatureLabel" class="step-label"></p>

                    <img class="signature" imgWithViewer alt="signature" [src]="lastSignatureSaved.url">

                    <app-button
                        [loading]="isCreatingStep"
                        (clicked)="signReportAsync()"
                        [customClasses]="'button-analysis'"
                    >
                        {{ 'analysis_button_label_signature' | translate }}
                    </app-button>
                </div>

                <div *ngIf="!lastSignatureSaved">
                    <p>{{ 'analysis_dont_have_a_signature' | translate}}</p>

                    <app-button (clicked)="navigateToDefineSignature()">
                        {{ 'analysis_button_define_signature' | translate}}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
    <!-- Night report analysis signature section -->
</div>
