<header class="navbar-wrapper">
    <nav class="navbar disconnected-navbar">
        <div #burgerButton (click)="switchBurgerMenu()" class="navbar__burger">
            <span class="burger__line"></span>
            <span class="burger__line"></span>
            <span class="burger__line"></span>
        </div>

        <div class="navbar__logo-wrapper">
            <div [routerLink]="['/']" class="navbar__logo">
                <div class="logo__sun"></div>
                <div id="logoIcon" class="logo__icon"></div>
                <div class="logo__rise"></div>
            </div>
        </div>

        <div #buttonsWrapper class="navbar__buttons-wrapper">
            <ul class="navbar__links">
                <li (click)="switchSubLinks($event)" class="link">
                    <span #langLink class="lang"></span>
                    <ul class="link__sub-links">
                        <li *ngFor="let lang of langSubLinksList" (click)="useLanguage(lang)" class="sub-link lang">{{lang}}</li>
                    </ul>
                </li>
            </ul>

            <ul class="navbar__links">
                <li class="link">
                    <app-button
                        [routerLink]="['/login']"
                        [customClasses]="'button--small  button--auto-height'"
                    >
                        {{ 'signIn_action_signIn' | translate }}
                    </app-button>
                </li>
            </ul>
        </div>
    </nav>
</header>
