import { cacheLocalForage } from "./cacheLocalForage"
import { LocalForageDataAccessBase } from "./LocalForageDataAccessBase"

/**
 * Base declaraction for local cache access through LocalForage.
 */
export abstract class CacheLocalForageDataAccessBase extends LocalForageDataAccessBase {
    /**
     * Constructor.
     */
    public constructor() {
        super(cacheLocalForage)
    }
}
