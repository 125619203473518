import * as _ from "lodash"

export class StringsUtilities {
    public static GetUpperCurrentLanguage(languageAbbr: string): string {
        return _.capitalize(languageAbbr.substr(0, 2))
    }

    public static formatAddressEmail(emailAddress: string): string {
        return emailAddress?.replace(/ /g, "").toLowerCase()
    }
}
