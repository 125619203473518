import { Inject, Injectable } from "@angular/core"
import * as _ from "lodash"
import { NightReportService } from "./night-report.service"
import { RawDataCacheDataAccess } from "../dataAccess/cache/RawDataCacheDataAccess"
import { NightSignal } from "../models/Nights/NightSignal"
import { NightSignalType } from "../models/Nights/NightSignalType"
import { UnitsAndFormatsPreferences } from "../models/UnitsAndFormatsPreferences"
import { doesUserPrefersH12HourFormat } from "../utilities/UnitsAndFormatsUtilities"

@Injectable({ providedIn: "root" })
export class RawDataService {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        @Inject("RawDataCacheDataAccess") private rawDataCacheDataAccess: RawDataCacheDataAccess,
        private nightReportService: NightReportService,
    ) {}

    // -------------------------------------------------------------------------
    // Preferences of signals order
    // -------------------------------------------------------------------------

    /**
     * Sets preferences of signals order into cache.
     * @param {number[]} signalsOrder Preferences of signals order
     * represented by an array of number, where index identifies the signal and
     * value gives its position order.
     */
    public async setSignalsOrderPreferencesAsync(signalsOrder: number[]): Promise<void> {
        await this.rawDataCacheDataAccess.setSignalsOrderPreferencesAsync(signalsOrder)
    }

    /**
     * Gets preferences of signals order from cache.
     * @returns preferences of signals order
     */
    public async getSignalsOrderPreferencesAsync(): Promise<number[]> {
        return await this.rawDataCacheDataAccess.getSignalsOrderPreferencesAsync()
    }

    /**
     * Deletes preferences of signals order from cache.
     */
    public async deleteSignalsOrderPreferencesAsync(): Promise<void> {
        await this.rawDataCacheDataAccess.deleteSignalsOrderPreferencesAsync()
    }

    // -------------------------------------------------------------------------
    // Preferences of signals visibility
    // -------------------------------------------------------------------------

    /**
     * Sets preferences of signals visibility into cache.
     * @param {boolean[]} signalsVisibility Preferences of signals visibility
     * represented by an array of boolean, where index identifies the signal and
     * value gives its visibilty (true = visible, false = hidden).
     */
    public async setSignalsVisibilityPreferencesAsync(signalsVisibilty: boolean[]): Promise<void> {
        this.rawDataCacheDataAccess.setSignalsVisibilityPreferencesAsync(signalsVisibilty)
    }

    /**
     * Gets preferences of signals visibility from cache.
     * @returns preferences of signals visibility
     */
    public async getSignalsVisibilityPreferencesAsync(): Promise<boolean[]> {
        return await this.rawDataCacheDataAccess.getSignalsVisibilityPreferencesAsync()
    }

    /**
     * Deletes preferences of signals visibility from cache.
     */
    public async deleteSignalsVisibilityPreferencesAsync(): Promise<void> {
        await this.rawDataCacheDataAccess.deleteSignalsVisibilityPreferencesAsync()
    }

    // -------------------------------------------------------------------------
    // Draft of predictions update
    // -------------------------------------------------------------------------

    /**
     * Sets the updated predictions for the given night, to save the draft.
     * @param {string} nightId Identifier of the night
     * @param {string} nightId Identifier of the night version
     * @param {any} predictions Updated predictions
     */
    public async setUpdatedPredictionsDraftAsync(
        nightId: string,
        nightVersionId: string,
        predictions: any,
    ): Promise<void> {
        await this.rawDataCacheDataAccess.setUpdatedPredictionsDraftAsync(nightId, nightVersionId, predictions)
    }

    /**
     * Gets draft of updated predictions for the given night.
     * @param {string} nightId Identifier of the night
     * @param {string} nightId Identifier of the night version
     * @returns draft of updated prdictions for the given night
     */
    public async getUpdatedPredictionsDraftAsync(nightId: string, nightVersionId: string): Promise<any> {
        return await this.rawDataCacheDataAccess.getUpdatedPredictionsDraftAsync(nightId, nightVersionId)
    }

    /**
     * Deletes draft of updated predictions for the given night.
     * @param {string} nightId Identifier of the night
     * @param {string} nightId Identifier of the night version
     */
    public async deleteUpdatedPredictionsDraftAsync(nightId: string, nightVersionId: string): Promise<void> {
        await this.rawDataCacheDataAccess.deleteUpdatedPredictionsDraftAsync(nightId, nightVersionId)
    }

    /**
     * Deletes all drafts of updated predictions.
     */
    public async deleteAllUpdatedPredictionsDraftsAsync(): Promise<void> {
        await this.rawDataCacheDataAccess.deleteAllUpdatedPredictionsDraftsAsync()
    }

    // -------------------------------------------------------------------------
    // Clear or Update storage of LocalSrorage and IndexedDB service
    // -------------------------------------------------------------------------

    /**
     * Deletes all preferences and drafts linked to raw data: preferences of
     * order, preferences of visibility and drafts of updated predictions.
     */
    public async deleteAllPreferencesLinkedToRawDataAsync(): Promise<void> {
        await this.deleteSignalsOrderPreferencesAsync()
        await this.deleteSignalsVisibilityPreferencesAsync()
        await this.deleteAllUpdatedPredictionsDraftsAsync()
    }

    // -------------------------------------------------------------------------
    // Computed and Transform data service
    // -------------------------------------------------------------------------

    /**
     * This method allows to transform the raw data and the prediction
     * into understandable data for the graphs to interpret them
     * @param {Array} rawSignalsData
     * @param {Array} chartsData
     * @param {Array} night
     */
    public computeEdfData(rawSignalsData: any, chartsData: any, night: any): any {
        // prepare data for predictions
        const timeOffsetInMillis: number = night["utcTimeOffset"] * 60000

        const signals: NightSignal[] = night.signals

        const predictions_aw: number[] = this.nightReportService.getNightSignalValues(signals, NightSignalType.Awake)
        const predictions_ar: number[] = this.nightReportService.getNightSignalValues(signals, NightSignalType.Arousals)
        const predictions_obs: number[] = this.nightReportService.getNightSignalValues(
            signals,
            NightSignalType.Obstructive,
        )
        const predictions_central: number[] = this.nightReportService.getNightSignalValues(
            signals,
            NightSignalType.Central,
        )
        const predictions_norm: number[] = this.nightReportService.getNightSignalValues(signals, NightSignalType.Normal)
        const predictions_bxm: number[] = this.nightReportService.getNightSignalValues(signals, NightSignalType.Bruxism)
        const predictions_disconnections: number[] = this.nightReportService.getNightSignalValues(
            signals,
            NightSignalType.Disconnections,
        )
        const timesInMillis: number[] = this.nightReportService.getNightSignalValues(signals, NightSignalType.Timestamp)

        const hasBruxismPredictions = !_.isEmpty(predictions_bxm)

        if (!_.isEmpty(predictions_central)) chartsData.prediction_central = []

        for (let i = 0; i < timesInMillis.length; i++) {
            chartsData.predictions_times.push(timesInMillis[i] + timeOffsetInMillis)
            chartsData.predictions_aw.push([chartsData.predictions_times[i], predictions_aw[i]])
            chartsData.predictions_ar.push([chartsData.predictions_times[i], predictions_ar[i]])
            chartsData.predictions_obs.push([chartsData.predictions_times[i], predictions_obs[i]])
            chartsData.predictions_normal.push([chartsData.predictions_times[i], predictions_norm[i]])
            chartsData.predictions_disconnections.push([chartsData.predictions_times[i], predictions_disconnections[i]])

            if (hasBruxismPredictions) {
                chartsData.predictions_bxm.push([chartsData.predictions_times[i], predictions_bxm[i] / 2])
            }

            if (!_.isEmpty(predictions_central)) {
                chartsData.predictions_central.push([chartsData.predictions_times[i], predictions_central[i]])
            }
        }

        // prepare data for raw signals
        for (let i = 0; i < rawSignalsData["timestamps"].length; i++) {
            chartsData.raw_times.push(parseInt(rawSignalsData["timestamps"][i]) + timeOffsetInMillis)
            chartsData.raw_acc_x.push([chartsData.raw_times[i], parseFloat(rawSignalsData["accX"][i])])
            chartsData.raw_acc_y.push([chartsData.raw_times[i], parseFloat(rawSignalsData["accY"][i])])
            chartsData.raw_acc_z.push([chartsData.raw_times[i], parseFloat(rawSignalsData["accZ"][i])])
            chartsData.raw_gyro_x.push([chartsData.raw_times[i], parseFloat(rawSignalsData["gyroX"][i])])
            chartsData.raw_gyro_y.push([chartsData.raw_times[i], parseFloat(rawSignalsData["gyroY"][i])])
            chartsData.raw_gyro_z.push([chartsData.raw_times[i], parseFloat(rawSignalsData["gyroZ"][i])])
        }

        // Optimize length of data for raw signals.
        this.optimizeAllSignals(chartsData)
    }

    /**
     * This method is the invese of computeEdfData.
     * She reconverted the raw data.
     * @param {Object} newPredictions: Object of new predictions that have been modified
     * @param {Object} night
     * @returns {Object} signals: new signals based one newPredictions
     */
    public computeNewEdfData(newPredictions: any, night: any): NightSignal[] {
        const signals: NightSignal[] = _.cloneDeep(night.signals)

        signals.forEach((signal: NightSignal) => {
            if (newPredictions[signal.signalType]) {
                signal.data = newPredictions[signal.signalType].map((prediction) => prediction[1])

                if (signal.signalType == NightSignalType.Bruxism) {
                    signal.data = signal.data.map((data) => data * 2)
                }
            }
        })

        return signals
    }

    // -------------------------------------------------------------------------
    // Signals optimizations.
    // -------------------------------------------------------------------------

    /**
     * Optimize all raw data signals and add it to the chartsData object.
     */
    private optimizeAllSignals(chartsData: any): void {
        chartsData.acc_x = this.optimizeSignal(chartsData.raw_acc_x)
        chartsData.acc_y = this.optimizeSignal(chartsData.raw_acc_y)
        chartsData.acc_z = this.optimizeSignal(chartsData.raw_acc_z)

        chartsData.gyro_x = this.optimizeSignal(chartsData.raw_gyro_x)
        chartsData.gyro_y = this.optimizeSignal(chartsData.raw_gyro_y)
        chartsData.gyro_z = this.optimizeSignal(chartsData.raw_gyro_z)
    }

    /**
     * Optimize raw data signal by removing useless identical points.
     * @param {number[][]} signal Signal to optimize.
     * @returns An optimized signal.
     */
    private optimizeSignal(signal: number[][]): number[][] {
        if (signal.length == 0) {
            return signal
        }

        const optimizedSignal: number[][] = []

        // Force first value.
        optimizedSignal.push(signal[0])

        // Keep only relevant points. A point is only relevant if its value is
        // different from the previous point.
        for (let i = 1; i < signal.length; i++) {
            if (
                signal[i][1] != signal[i - 1][1] &&
                signal[i - 1][0] != optimizedSignal[optimizedSignal.length - 1][0]
            ) {
                optimizedSignal.push(signal[i - 1])
                optimizedSignal.push(signal[i])
            }
        }

        // Force last value.
        if (optimizedSignal[optimizedSignal.length - 1][0] != signal[signal.length - 1][0]) {
            optimizedSignal.push(signal[signal.length - 1])
        }

        return optimizedSignal
    }

    // -------------------------------------------------------------------------
    // Other
    // -------------------------------------------------------------------------

    /** s the vertical movement of the page to use the up and down zoom keys
     * @param {any} e: event
     */
    public disableScroll(e: any) {
        if (e.keyCode) {
            ;/^(32|33|34|35|36|37|38|39|40)$/.test(e.keyCode) && e.preventDefault()
        } else {
            e.preventDefault()
        }
    }

    public getDateTimeLabelFormat(userUnitsAndFormatsPreferences: UnitsAndFormatsPreferences): string {
        if (doesUserPrefersH12HourFormat(userUnitsAndFormatsPreferences)) {
            return "%l:%M %P"
        } else {
            // Default or if hourFormat == H24
            return "%H:%M"
        }
    }
}
