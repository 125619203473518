<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'createPatientAccount_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div [style.display]="isLoading ? 'none' : 'block'" class="row row--guttered row--tiny content">
        <div class="list-row list-row--title list-row--no-border">
            <div class="col">{{ 'createPatientAccount_personalInfo_section' | translate }}</div>
        </div>

        <div class="two-field-container-per-row">
            <app-validatable-input class="validatable-container"
                                   *ngIf="patientIdentifier"
                                   [validatableObject]="patientIdentifier"
                                   (enterPressed)="createPatientAccountAsync()"></app-validatable-input>
        </div>

        <div class="two-field-container-per-row" *ngIf="!showPersonalInformationFields" style="margin-bottom: 1.5rem;">
            <a (click)='displayPersonalInformationFields()'>{{ 'createPatientAccount_add_personalInfo_button' | translate }}</a>
        </div>

        <div [style.display]="!showPersonalInformationFields ? 'none' : 'block'">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'createPatientAccount_personalInfo_section' | translate }}</div>
            </div>

            <app-account-fields #personalInformationFields
                                [accountActionType]="eAccountActionType.PatientAccountCreation"
                                (enterPressed)="createPatientAccountAsync()"
                                (componentInit)="afterSignUpFieldsInit()"></app-account-fields>

            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'createPatientAccount_physicalInfo_section' | translate }}</div>
            </div>

            <div class="two-field-container-per-row">
                <app-validatable-select class="validatable-container required"
                                        *ngIf="gender"
                                        [validatableObject]="gender"
                                        [options]="genders"
                                        [selectedVariable]="'name'"
                                        [displayedVariable]="'displayedName'"></app-validatable-select>

                <app-validatable-input class="validatable-container"
                                       *ngIf="collarSize"
                                       [validatableObject]="collarSize"
                                       [useLabelAsPlaceholder]="true"
                                       (enterPressed)="createPatientAccountAsync()"></app-validatable-input>
            </div>

            <div class="two-field-container-per-row">
                <app-validatable-input class="validatable-container required"
                                       *ngIf="height"
                                       [validatableObject]="height"
                                       [useLabelAsPlaceholder]="true"
                                       (enterPressed)="createPatientAccountAsync()"></app-validatable-input>

                <app-validatable-input class="validatable-container required"
                                       *ngIf="weight"
                                       [validatableObject]="weight"
                                       [useLabelAsPlaceholder]="true"
                                       (enterPressed)="createPatientAccountAsync()"></app-validatable-input>
            </div>
        </div>

        <app-validatable-checkbox class="validatable-container"
                                  *ngIf="shouldCompleteQuestionnaire"
                                  [validatableObject]="shouldCompleteQuestionnaire">
        </app-validatable-checkbox>

        <app-validatable-checkbox *ngIf="assignSensorToUser"
                                  [validatableObject]="assignSensorToUser">
        </app-validatable-checkbox>

        <div *ngIf="assignSensorToUser?.value">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'createPatientAccount_assignationSensor_section' | translate }}</div>
            </div>

            <app-sensors-to-patient-assignation #sensorAssignationFields></app-sensors-to-patient-assignation>
        </div>

        <div *ngIf="hasPatientSharingRole || officesList.length > 1">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'createPatientAccount_sharePatient_section' | translate }}</div>
            </div>

            <div *ngIf="officesList.length > 1">
                <p>{{ "createPatientAccount_shareWithOffice_label" | translate }}</p>

                <app-validatable-select *ngIf="shareWithOffice"
                                        class="validatable-container"
                                        [validatableObject]="shareWithOffice"
                                        [options]="officesList"
                                        [selectedVariable]="'officeId'"
                                        [displayedVariable]="'officeName'"></app-validatable-select>
            </div>

            <div *ngIf="hasPatientSharingRole">
                <p>{{ "createPatientAccount_shareWithDoctors_label" | translate }}</p>

                <div class="share-patient__container">
                    <div class="doctor__container doctor__container--disabled">
                        <span>{{ doctorEmail }}</span>
                    </div>

                    <div *ngFor="let email of sharedDoctors" class="doctor__container">
                        <span>{{ email }}</span>
                        <div class="doctor-delete" (click)="removeSharedDoctor(email)"></div>
                    </div>

                    <div class="doctor__input">
                        <div class="input__container">
                            <autocomplete-input #autocompleteInput
                                                placeholder="{{ 'common_email' | translate }}..."
                                                [proposals]="getSharingProposals()"
                                                [showAddButton]="true"
                                                (validateInput)="addSharedDoctorAsync($event, autocompleteInput.inputElement.nativeElement)"
                            ></autocomplete-input>
                        </div>

                        <span [style.display]="autocompleteInput.inputElement?.nativeElement.classList?.contains('input--invalid') ? 'block' : 'none'"
                              class="input-error">{{ "signUp_input_emailAddress_error_invalid" | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="list-row list-row--no-border">
            <app-button
                [loading]="isCreatingAccount"
                (clicked)="createPatientAccountAsync()"
            >
                {{ 'common_create' | translate }}
            </app-button>
        </div>
    </div>

    <div *ngIf='isLoading' class="row row--guttered row--tiny content">
        {{ 'common_loading' | translate }}
    </div>
</main>
