<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'prescriptions_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--tiny content">
        <p>{{ 'prescriptions_explainations_label' | translate }}</p>
        <div class="row row--guttered-v2">
            <div *ngIf="emailAddress" class="field-container" [class.field-container--errored]="emailAddress.hasError">
                <input class="field" type="email" placeholder="{{ 'prescriptions_patient_email_placeholder' | translate:{'label': emailAddress.label | translate | lowercase} }}" (keyup)="keyupHandler($event, emailAddress)" [(ngModel)]="emailAddress.value">
                <small class="error-text" [innerHTML]="emailAddress.errorString"></small>
            </div>

            <div class="number-of-sensors-containers">
                <span class="number-of-sensors-item">{{ 'prescriptions_number_of_nights' | translate }}</span>

                <div class="number-of-sensors-item">
                    <input type="radio" id="numberOfSensors_1night" name="numberOfSensors" [value]="1" checked [(ngModel)]="numberOfSensors">
                    <label for="numberOfSensors_1night">{{ 'prescriptions_number_of_nights_1_night' | translate }}</label>
                </div>

                <div class="number-of-sensors-item">
                    <input type="radio" id="numberOfSensors_2nights" name="numberOfSensors" [value]="2" [(ngModel)]="numberOfSensors">
                    <label for="numberOfSensors_2nights">{{ 'prescriptions_number_of_nights_2_nights' | translate }}</label>
                </div>

                <div class="number-of-sensors-item">
                    <input type="radio" id="numberOfSensors_3nights" name="numberOfSensors" [value]="3" [(ngModel)]="numberOfSensors">
                    <label for="numberOfSensors_3nights">{{ 'prescriptions_number_of_nights_3_nights' | translate }}</label>
                </div>
            </div>

            <div class="number-of-sensors-containers" style="padding-bottom: 0rem;">
                <span class="number-of-sensors-item">{{ 'prescriptions_patient_prices' | translate }}</span>
                <label id="prescriptionRealPrice" class="prices"> {{prescriptionUnitPrix * numberOfSensors}}{{doctorCurrencySymbol}}</label>
                <label class="prices prices--reduced" *ngIf="prescriptionReducedUnitPrix">{{prescriptionReducedUnitPrix * numberOfSensors}}{{doctorCurrencySymbol}}</label>
            </div>

            <div class="field-container" *ngIf="prescriptionReducedUnitPrix">
                <label class="prices">{{ 'prescriptions_promotion_text' | translate }}</label>
            </div>

            <div class="field-container buttons-container">
                <app-button
                    [loading]="isMailSending"
                    (clicked)="sendPrescriptionMail()"
                >
                    {{ 'prescriptions_actions_send_mail' | translate }}
                </app-button>
            </div>
        </div>
    </div>
</main>
