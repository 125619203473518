import { Component, EventEmitter, Input, Output } from "@angular/core"
import { ValidatableObject } from "../../../../validation/ValidatableObject"

@Component({
    selector: "app-validatable-select",
    templateUrl: "./validatable-select.component.html",
})
export class ValidatableSelectComponent {
    @Input("validatableObject") object: ValidatableObject

    @Input("options") options: object[]
    @Input("displayedVariable") displayedVariable: string
    @Input("selectedVariable") selectedVariable: string

    @Input("placeholder") placeholder: string
    @Input("disabled") disabled = false
    @Input("required") required = false

    @Output() objectChangeEvent: EventEmitter<any> = new EventEmitter<any>()

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async changeHandlerAsync(object: ValidatableObject): Promise<void> {
        await this.object.validateAsync()

        this.objectChangeEvent.emit(object.value)
    }
}
