<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'resetPassword_confirmation_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>
    
    <div class="row row--guttered row--smaller content">
        <p>{{ 'resetPassword_confirmation_title' | translate }}</p>
        <p>{{ 'resetPassword_confirmation_subtitle' | translate }}</p>
    </div>
</main>