import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { PortalNotificationType } from "../../../models/PortalNotification"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-demo-settings",
    templateUrl: "./demo-settings.component.html",
})
export class DemoSettingsComponent extends BaseComponent {
    public isResettingAccount = false

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    public navigateBack() {
        this.router.navigate(["main/account"])
    }

    public async resetAccountAsync(): Promise<void> {
        const buttonElement: HTMLElement = document.getElementById("resetAccountButton")

        try {
            this.isResettingAccount = true

            await this.sunriseApiService.resetAccountAsync()

            this.showNotification("Account reset", PortalNotificationType.Success)
        } catch (error) {
            this.showErrorOccurred()
        } finally {
            this.isResettingAccount = false
        }
    }
}
