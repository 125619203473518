<div *ngIf="object" class="field-container" [class.required]="object.required" [class.field-container--errored]="object.hasError">
    <label class="label" *ngIf="object.label">{{ object.label | translate }}</label>

    <select class="field"
            id="country"
            [(ngModel)]="object.value"
            (change)="changeHandlerAsync(object)"
            [disabled]="disabled"
            required>
            <option value="undefined" disabled selected>{{ placeholder ?? 'common_select_input_placeholder' | translate }}</option>
            <option *ngFor="let option of options" [value]="option[selectedVariable]">{{ option[displayedVariable] }}</option>
    </select>

    <small *ngIf="object.showHint" class="hint-text">{{ object.hintString }}</small>
    <small *ngIf="object.hasError" class="error-text">{{ object.errorString }}</small>
</div>
