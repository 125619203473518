import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { DomSanitizer, SafeUrl } from "@angular/platform-browser"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { Patient } from "../../../models/Patient"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-night-report-embed-pdf",
    templateUrl: "./night-report-embed-pdf.component.html",
    styleUrls: ["../night-report.component.sass"],
})
export class NightReportEmbedPDF extends BaseComponent implements OnInit {
    @Input() isExportingReport: boolean
    @Input("isSampleReport") isSampleReport: boolean
    @Input("nightId") nightId: string
    @Input("nightVersionId") nightVersionId: string
    @Input("patient") patient: Patient

    @Output() exportReportEvent = new EventEmitter<void>()

    public nightReportPdfSrc: SafeUrl | undefined
    public isGeneratingPdfSrc = true

    constructor(
        protected readonly sanitizer: DomSanitizer,
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    public async ngOnInit(): Promise<void> {
        if (this.canConsultReports) {
            await this.getSleepReportPDFAsync()
        }
    }

    public async getSleepReportPDFAsync(): Promise<void> {
        this.isGeneratingPdfSrc = true

        try {
            let nightReportPDF: { blob: Blob; filename: string }

            if (this.isSampleReport) {
                nightReportPDF = await this.sunriseApiService.getNightReportSampleAsBlobAsync()
            } else if (this.nightVersionId != null && this.nightVersionId != "0") {
                nightReportPDF = await this.sunriseApiService.getNightVersionReportAsBlobAsync(
                    this.nightId,
                    this.nightVersionId,
                    this.patient.id,
                )
            } else {
                nightReportPDF = await this.sunriseApiService.getNightReportAsBlobAsync(
                    this.isAdmin,
                    this.nightId,
                    this.patient.id,
                )
            }

            this.nightReportPdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
                window.URL.createObjectURL(nightReportPDF.blob),
            )
        } catch (error) {
            this.showErrorOccurred()
        } finally {
            this.isGeneratingPdfSrc = false
        }
    }

    public async refreshSleepReportPDF(): Promise<void> {
        if (this.canConsultReports) {
            await this.getSleepReportPDFAsync()
        }
    }

    public exportReport(): void {
        this.exportReportEvent.emit()
    }
}
