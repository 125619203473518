import * as localForage from "localforage"
import { Constants } from "../models/config/Constants"

/**
 * Base constansts declaration. This can be overrided in constants.[env].ts
 * files to specify specific constants for on environment.
 */
export const constants: Constants = {
    env: "development",
    sentry: {
        isEnabled: true,
    },
    api: {
        marketing: {
            baseUri: null, // specific to a given environment; see specific files
            securityKey: null, // specific to a given environment; see specific files
        },
        product: {
            baseUri: null, // specific to a given environment; see specific files
            securityKey: null, // specific to a given environment; see specific files
        },
    },
    platform: {
        firebaseUris: null, // specific to a given environment; see specific files
        baseUri: null, // specific to a given environment; see specific files
    },
    rawDataViewer: {
        baseUri: null,
        securityKey: null,
    },
    dataAccess: {
        cache: {
            localForage: {
                driver: localForage.INDEXEDDB,
                name: "Sunrise",
                storeName: "Sunrise Local Cache",
                version: 1.0,
            },
            rawData: {
                draftsPredictions: {
                    keyPrefix: "draftPrediction",
                },
                signalsOrderPreferences: {
                    key: "sortedChannels",
                },
                signalsVisibilityPreferences: {
                    key: "notDisplayedShannels",
                },
            },
        },
        staticFiles: {
            googleCloudStorage: {
                baseUri: null, // specific to a given environment; see specific files
            },
        },
    },
    roles: {
        researcher: "researcher",
        support: "support",
        supportAdmin: "support-admin",
        prescriber: "prescriber",
        accountHandler: "account-handler",
        demoAccount: "demo-account",
        patientSharing: "patient-sharing",
    },
    validation: {
        patterns: {
            email: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+))$/,
            nightsListUrl: /\/main\/patients\/[0-9]+\/nights/,
        },
    },
    cryptoJs: {
        key: "246ddc79c3937",
    },
}
