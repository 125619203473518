import { TranslateService } from "@ngx-translate/core"
import { ValidatableRule } from "./ValidatableRule"
import { PhoneNumber } from "../../models/PhoneNumber"

export class PhoneNumberValidRule extends ValidatableRule {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(translate: TranslateService, errorStringTemplate?: string) {
        super(translate, errorStringTemplate || "i18n:signUp_input_phoneNumber_error_format")
    }
    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    protected validateRule(value: PhoneNumber): boolean {
        return value.isFormatValid
    }
}
