import * as _ from "lodash"

export class InputObject {
    public label: string
    public value: any = null

    constructor(label?: string) {
        this.label = !_.isEmpty(label) ? label : null
    }
}
