<div *ngIf="object" class="field-container field-container--checkbox" [class.disabled]="disabled" [class.required]="required" [class.field-container--errored]="object.hasError">
    <input type="checkbox"
           [disabled]="disabled"
           [id]="inputId + '-checkbox'"
           (change)="changeHandlerAsync()"
           [(ngModel)]="object.value">

    <label class="label" [for]="inputId + '-checkbox'" [innerHTML]="object.label | translate: args"></label>
    <span class="checkmark"></span>

    <small *ngIf="object.showHint" class="hint-text">{{ object.hintString }}</small>
    <small *ngIf="object.hasError" class="error-text">{{ object.errorString }}</small>
</div>
