import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { SimpleModalService } from "@looorent/ngx-simple-modal"
import { TranslateService } from "@ngx-translate/core"
import { ConfirmComponent } from "../../dialogs/confirm.component"
import { Night } from "../../models/Nights/Night"
import { NightAnalysisActionValues } from "../../models/Nights/NightAnalysisActionValues"
import { PortalNotificationType } from "../../models/PortalNotification"
import { SensorToUser } from "../../models/SensorToUser"
import { SortOrder } from "../../models/SortOrder"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { getPatientFullNameOrId } from "../../utilities/getPatientFullName"
import { HttpUtilities } from "../../utilities/HttpUtilities"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import { getDateFormatLabel } from "../../utilities/UnitsAndFormatsUtilities"
import { SortedList } from "../sortedLists"
import { SensorsToPatientAssignationComponent } from "../tools/sensors-to-patient-assignation/sensors-to-patient-assignation.component"

@Component({
    selector: "app-nights-list",
    templateUrl: "./nights-list.component.html",
    styleUrls: ["./nights-list.component.sass"],
})
export class NightsListComponent extends SortedList implements OnInit, AfterViewInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    public isLoading = false
    public isAssigning = false
    public hasNights = false
    public hasCreatedPatientAccount = false
    public showNewNightsOnly = false
    public showV2ScoreColumns = false

    public nights: Night[] = []
    public assignedSensors: SensorToUser[] = []

    public patientId: string
    public patient: any
    public patientName: string

    public dateFormatLabel: string

    @ViewChild("sensorAssignationFields") public sensorAssignationFields: SensorsToPatientAssignationComponent

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly simpleModalService: SimpleModalService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly route: ActivatedRoute,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)
        this.translate.onLangChange.subscribe(() => this.updateLanguage())
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.isLoading = true

        this.patientId = RoutesUtilities.getRouteParam(this.route, "id")
        this.patient = this.sunriseApiService.getPatient()

        if (!this.patient) {
            this.patient = await this.sunriseApiService.getPatientAsync(this.patientId)
        }

        this.patientName = getPatientFullNameOrId(
            this.patient.firstName,
            this.patient.lastName,
            this.patient.customIdentifier,
        )
        this.hasCreatedPatientAccount = this.patient.hasCreatedPatientAccount
        this.isAdmin = this.sunriseApiService.isAdmin()

        if (this.isUSPractitioner || this.isAdmin) {
            const assignedSensors: SensorToUser[] = await this.sunriseApiService.getAssignedSensorsAsync(this.patientId)
            this.assignedSensors = assignedSensors.filter((assignedSensor) => assignedSensor.isActive)
        }

        await this.fillInNightsList()

        this.initSortingValues("endDateTimeValue", SortOrder.Descending)

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Translation helpers
    // -------------------------------------------------------------------------

    protected updateLanguage(): void {
        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)

        super.updateBaseLanguage()
    }

    // -------------------------------------------------------------------------
    // Commands delegates
    // -------------------------------------------------------------------------

    public async assignSensorToPatientAsync(): Promise<void> {
        const isValid: boolean = await this.sensorAssignationFields.formatAndValidateObjectsAsync()

        if (isValid) {
            this.isAssigning = true

            try {
                // If not, error is handle in catch
                await this.sunriseApiService.sensorIsAssignableAsync(this.sensorAssignationFields.serialNumber.value)

                await this.sunriseApiService.assignSensorToPatientAsync(
                    this.sensorAssignationFields.serialNumber.value,
                    this.patientId,
                    this.sensorAssignationFields.secretCode.value,
                )

                this.sensorAssignationFields.serialNumber.value = undefined
                this.sensorAssignationFields.secretCode.value = undefined
                this.sensorAssignationFields.isSecretCodeRequired.value = false

                const assignedSensors: SensorToUser[] = await this.sunriseApiService.getAssignedSensorsAsync(
                    this.patientId,
                )
                this.assignedSensors = assignedSensors.filter((assignedSensor) => assignedSensor.isActive)

                this.showNotification("nightsList_assignSensor_success", PortalNotificationType.Success)
            } catch (error) {
                const errorCode: string = HttpUtilities.getErrorCodeFromHttpError(error)

                if (errorCode === "sensor_already_assigned") {
                    await this.showSensorAlreadyAssignedErrorAsync()
                } else if (errorCode == "not_found") {
                    await this.showSensorNotExistErrorAsync()
                } else {
                    await this.showErrorOccurredAsync()
                }
            } finally {
                this.isAssigning = false
            }
        }
    }

    public async confirmAssignationDeletionAsync(sensorToUserId: string): Promise<void> {
        this.simpleModalService
            .addModal(ConfirmComponent, { message: "nightsList_assignedSensorsList_confirmAssignationDeletion" })
            .subscribe(async (message) => {
                if (message == "true") {
                    await this.deleteSensorToUserAsync(sensorToUserId)
                }
            })
    }

    public async deleteSensorToUserAsync(sensorToUserId: string): Promise<void> {
        const sensorToUser: SensorToUser = this.assignedSensors.find(
            (assignedSensor) => assignedSensor.sensorToUserId == sensorToUserId,
        )

        try {
            sensorToUser.isRemoving = true

            await this.sunriseApiService.removeAssignedSensorAsync(sensorToUserId)

            const assignedSensors: SensorToUser[] = await this.sunriseApiService.getAssignedSensorsAsync(this.patientId)
            this.assignedSensors = assignedSensors.filter((assignedSensor) => assignedSensor.isActive)

            this.showNotification("nightsList_assignedSensorsList_deleteSuccess", PortalNotificationType.Success)
        } catch (error) {
            let errorMessage = "common_error"

            if (error.status == 403) {
                errorMessage = "nightsList_assignedSensorsList_removeNotAllowed_error"
            } else if (error.status == 404) {
                errorMessage = "sensorUserAssign_serialNumber_notFound"
            }

            sensorToUser.isRemoving = false

            this.showNotification(errorMessage, PortalNotificationType.Error)
        }
    }

    public navigateToNight(night: any): void {
        if (!night.reportHiddenOnPlatform || this.isAdmin) {
            this.router.navigate([`main/nights/${night.nightId}`])
        }
    }

    public navigateToEditPatient(): void {
        this.router.navigate([`main/patients/${this.patientId}/edit`])
    }

    public navigateToShowPatientDetails(): void {
        this.router.navigate([`main/patients/${this.patientId}/details`])
    }

    // -------------------------------------------------------------------------
    // Errors helpers
    // -------------------------------------------------------------------------

    public async showSensorAlreadyAssignedErrorAsync(): Promise<void> {
        this.sensorAssignationFields.serialNumber.errorString = await this.__(
            "sensorUserAssign_serialNumber_alreadyAssigned",
        )
        this.sensorAssignationFields.serialNumber.hasError = true
    }

    public async showSensorNotExistErrorAsync(): Promise<void> {
        this.sensorAssignationFields.serialNumber.errorString = await this.__("sensorUserAssign_serialNumber_notFound")
        this.sensorAssignationFields.serialNumber.hasError = true
    }

    protected async showErrorOccurredAsync(): Promise<void> {
        this.sensorAssignationFields.serialNumber.errorString = await this.__("common_error")
        this.sensorAssignationFields.serialNumber.hasError = true
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async fillInNightsList(): Promise<void> {
        this.hasNights = false

        this.nights = (await this.getNightsAsync()) || []
        this.nights.forEach((night) => this.fillNightInformation(night))

        if (this.nights.length > 0) {
            this.hasNights = true
            this.showV2ScoreColumns = this.nights.find((night) => night.ahi != null) != null
        }
    }

    private fillNightInformation(night: Night): void {
        night.endDateTimeValue = night.endDateTime.date

        let status = "analysis_status_1_toBeApproved"

        if (night.lastAnalysisStep) {
            if (night.lastAnalysisStep.actionType == NightAnalysisActionValues.Validation) {
                status = "analysis_status_2_toBeInterpreted"
            }

            if (night.lastAnalysisStep.actionType == NightAnalysisActionValues.Interpretation) {
                status = "analysis_status_3_toBeSigned"
            }

            if (night.lastAnalysisStep.actionType == NightAnalysisActionValues.Signature) {
                status = "analysis_status_4_signed"
            }
        }

        night.analysisStatus = status
    }

    private async getNightsAsync(): Promise<any[]> {
        try {
            const res = await this.sunriseApiService.getNightsAsync(this.patientId)

            return res.data.nights
        } catch (error) {
            this.handleError(error, false)
        }
    }

    public toggleShowNewNightsOnly(): void {
        this.showNewNightsOnly = !this.showNewNightsOnly
    }
}
