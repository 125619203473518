<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'showPatientDetails_label' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div [style.display]="isLoading ? 'none' : 'block'" class="row row--guttered row--tiny content">
        <div class="list-row list-row--title list-row--no-border">
            <div class="col">{{ 'createPatientAccount_personalInfo_section' | translate }}</div>
        </div>

        <div class="two-field-container-per-row">  
            <app-readonly-field class="validatable-container"
                                label=signUp_input_patient_identifier_label
                                [value]="customIdentifier"></app-readonly-field>
        </div>

        <div class="two-field-container-per-row">  
            <app-readonly-field class="validatable-container"
                                label=signUp_input_firstName_label
                                [value]="firstName"></app-readonly-field>

            <app-readonly-field class="validatable-container"
                                label=signUp_input_lastName_label
                                [value]="lastName"></app-readonly-field>
        </div>

        <div class="two-field-container-per-row">  
            <app-readonly-field class="validatable-container"
                                label=signUp_input_birthdate_label
                                [value]="birthdate"></app-readonly-field>
        </div>

        <app-readonly-field class="validatable-container"
                            label=signUp_input_emailAddress_label
                            [value]="emailAddress"></app-readonly-field>

        <div class="two-field-container-per-row">  
            <app-readonly-field class="validatable-container"
                                label=signUp_input_country_label
                                [value]="country"></app-readonly-field>

            <app-readonly-field class="validatable-container"
                                label=signUp_input_phoneNumber_label
                                [value]="phoneNumber"></app-readonly-field>
        </div>

        <div class="list-row list-row--title list-row--no-border">
            <div class="col">{{ 'createPatientAccount_physicalInfo_section' | translate }}</div>
        </div>

        <div class="two-field-container-per-row">
            <app-readonly-field class="validatable-container"
                                label=signUp_input_gender_label
                                [value]="gender"></app-readonly-field>

            <app-readonly-field class="validatable-container"
                                [label]=collarSizeLabel
                                [value]="collarSize"></app-readonly-field>
        </div>

        <div class="two-field-container-per-row">
            <app-readonly-field class="validatable-container"
                                [label]=heightLabel
                                [value]="height"></app-readonly-field>

            <app-readonly-field class="validatable-container"
                                [label]=weightLabel
                                [value]="weight"></app-readonly-field>
        </div>
    </div>
</main>
