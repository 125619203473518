import { Component } from "@angular/core"
import { constants } from "../../../../config/constants"

@Component({
    selector: "app-ifu-eu-and-row",
    templateUrl: "./ifu-eu-and-row.component.html",
    styleUrls: ["./ifu-eu-and-row.component.sass"],
})
export class IfuEuAndRowComponent {
    public staticFilesBaseUri: string = constants.dataAccess.staticFiles.googleCloudStorage.baseUri

    public effectiveIfuFiles: { name: string; url: string }[] = [
        { name: "HCP-IFU-V2.5-EN.pdf", url: "/ifu/HCP-IFU-V2.5-EN.pdf" },
        { name: "HCP-IFU-V2.5-FR.pdf", url: "/ifu/HCP-IFU-V2.5-FR.pdf" },
        { name: "HCP-IFU-V2.5-DE.pdf", url: "/ifu/HCP-IFU-V2.5-DE.pdf" },
        { name: "HCP-IFU-V2.5-NL.pdf", url: "/ifu/HCP-IFU-V2.5-NL.pdf" },
    ]

    public obsoleteIfuFiles: { name: string; url: string }[] = [
        { name: "HCP-IFU-V2.4-EN-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.4-EN-Obsolete.pdf" },
        { name: "HCP-IFU-V2.4-FR-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.4-FR-Obsolete.pdf" },
        { name: "HCP-IFU-V2.4-DE-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.4-DE-Obsolete.pdf" },
        { name: "HCP-IFU-V2.4-NL-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.4-NL-Obsolete.pdf" },
        { name: "HCP-IFU-V2.3-EN-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.3-EN-Obsolete.pdf" },
        { name: "HCP-IFU-V2.3-FR-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.3-FR-Obsolete.pdf" },
        { name: "HCP-IFU-V2.3-DE-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.3-DE-Obsolete.pdf" },
        { name: "HCP-IFU-V2.3-NL-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.3-NL-Obsolete.pdf" },
        { name: "HCP-IFU-V2.2-EN-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.2-EN-Obsolete.pdf" },
        { name: "HCP-IFU-V2.2-FR-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.2-FR-Obsolete.pdf" },
        { name: "HCP-IFU-V2.2-DE-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.2-DE-Obsolete.pdf" },
        { name: "HCP-IFU-V2.2-NL-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.2-NL-Obsolete.pdf" },
        { name: "HCP-IFU-V2.0-EN-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.0-EN-Obsolete.pdf" },
        { name: "HCP-IFU-V2.0-FR-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.0-FR-Obsolete.pdf" },
        { name: "HCP-IFU-V2.0-DE-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.0-DE-Obsolete.pdf" },
        { name: "HCP-IFU-V2.0-NL-Obsolete.pdf", url: "/ifu/HCP-IFU-V2.0-NL-Obsolete.pdf" },
        { name: "HCP-IFU-V1.5-EN-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.5-EN-Obsolete.pdf" },
        { name: "HCP-IFU-V1.3-EN-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.3-EN-Obsolete.pdf" },
        { name: "HCP-IFU-V1.3-FR-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.3-FR-Obsolete.pdf" },
        { name: "HCP-IFU-V1.3-DE-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.3-DE-Obsolete.pdf" },
        { name: "HCP-IFU-V1.3-NL-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.3-NL-Obsolete.pdf" },
        { name: "HCP-IFU-V1.2-EN-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.2-EN-Obsolete.pdf" },
        { name: "HCP-IFU-V1.2-FR-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.2-FR-Obsolete.pdf" },
        { name: "HCP-IFU-V1.2-DE-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.2-DE-Obsolete.pdf" },
        { name: "HCP-IFU-V1.2-NL-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.2-NL-Obsolete.pdf" },
        { name: "HCP-IFU-V1.1-FR-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.1-FR-Obsolete.pdf" },
        { name: "HCP-IFU-V1.1-DE-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.1-DE-Obsolete.pdf" },
        { name: "HCP-IFU-V1.1-NL-Obsolete.pdf", url: "/ifu/HCP-IFU-V1.1-NL-Obsolete.pdf" },
    ]
}
