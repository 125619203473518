import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { Doctor } from "../../models/Doctor"
import { NotificationType } from "../../models/NotificationType"
import { PortalNotificationType } from "../../models/PortalNotification"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { CheckedRule } from "../../validation/rules/CheckedRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { AccountFieldsComponent } from "../account-fields/account-fields.component"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-sign-up",
    templateUrl: "./sign-up.component.html",
    styleUrls: ["./sign-up.component.sass"],
})
export class SignUpComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isSigningUp = false

    public isAccountCreated = false
    public stayConnected: boolean

    public isLoading = true

    private token: string
    private preFilledEmailAddress: string

    public termsAndConditions: ValidatableObject
    public herebyCertify: ValidatableObject
    public subscribeNewsletter = false

    @ViewChild("signUpFields") public signUpFields: AccountFieldsComponent

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        protected readonly changeDetector: ChangeDetectorRef,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public ngOnInit(): void {
        this.token = this.getQueryParam("token")
        this.preFilledEmailAddress = this.getQueryParam("email")

        this.initValidatableObjects()
    }

    public async afterSignUpFieldsInit(): Promise<void> {
        await this.setDefaultCountryFromIpAsync()

        if (!_.isEmpty(this.token) && !_.isEmpty(this.preFilledEmailAddress)) {
            await this.linkToPatientAndRedirectIfNeededAsync()
        }

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Objects validation
    // -------------------------------------------------------------------------

    public initValidatableObjects(): void {
        this.termsAndConditions = new ValidatableObject(this.translate, "signUp_action_acceptTerms")
        this.termsAndConditions.addRule(
            new CheckedRule(this.translate, "i18n:signUp_input_termsAndCondition_error_mustBeAccepted"),
        )

        this.herebyCertify = new ValidatableObject(this.translate, "signUp_input_herebyCertify_label")
        this.herebyCertify.addRule(new CheckedRule(this.translate, "i18n:signUp_input_herebyCertify_error"))
    }

    private async validateObjectsAsync(): Promise<boolean> {
        let isValid: boolean

        isValid = await this.signUpFields.validateObjectsAsync()

        isValid = (await this.termsAndConditions.validateAsync()) && isValid
        isValid = (await this.herebyCertify.validateAsync()) && isValid

        return isValid
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public async signUp(): Promise<void> {
        const isValid: boolean = await this.validateObjectsAsync()

        if (isValid) {
            const notifications: NotificationType[] = [
                NotificationType.NewNightComment,
                NotificationType.NewPatientNight,
            ]

            if (this.subscribeNewsletter) notifications.push(NotificationType.Marketing)

            const doctor: Doctor = {
                firstName: this.signUpFields.firstName.value,
                lastName: this.signUpFields.lastName.value,
                email: this.signUpFields.emailAddress.value,
                password: this.signUpFields.password.value,
                phoneNumber: this.signUpFields.phoneNumberWithPrefix.value,
                notifications: notifications,
                countryNumber: this.signUpFields.country.value,
            }

            doctor.unitsAndFormatsPreferences = this.getDefaultPreferencesFromCountry(doctor.countryNumber)

            this.isSigningUp = true

            try {
                let apiResponse: any

                if (!_.isEmpty(this.preFilledEmailAddress)) {
                    apiResponse = await this.sunriseApiService.fillDoctorAsync(doctor, this.translate.currentLang)
                } else {
                    apiResponse = await this.sunriseApiService.createDoctorAsync(doctor, this.translate.currentLang)
                }

                const isUnitedStates: boolean = doctor.countryNumber == "CountryUnitedStates"

                await this.connectUserAsync(
                    doctor.email,
                    apiResponse["data"]["tokens"],
                    apiResponse["data"]["roles"],
                    doctor.unitsAndFormatsPreferences,
                    false,
                    isUnitedStates,
                    false,
                )

                this.isAccountCreated = true
                this.changeDetector.detectChanges()
            } catch (error) {
                if (error.status === 409) {
                    await this.showEmailAlreadyUsedErrorAsync()
                } else {
                    this.showErrorOccurred()
                }
            } finally {
                this.isSigningUp = false
            }
        }
    }

    // -------------------------------------------------------------------------
    // Error helpers
    // -------------------------------------------------------------------------

    private async showEmailAlreadyUsedErrorAsync(): Promise<void> {
        this.signUpFields.emailAddress.errorString = await this.__("signUp_input_emailAddress_error_conflict")
        this.signUpFields.emailAddress.hasError = true
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async linkToPatientAndRedirectIfNeededAsync(): Promise<void> {
        this.signUpFields.emailAddress.value = this.preFilledEmailAddress

        try {
            const res = await this.sunriseApiService.linkToPatientAsync(this.token, this.preFilledEmailAddress)

            this.showNotification("notifications_link_patient_success", PortalNotificationType.Success)

            if (!res.data.newDoctorAccount) {
                if (this.authService.checkUserIsAuthenticated()) {
                    this.navigateToMainPage()
                } else {
                    this.router.navigate(["/login"])
                }
            }
        } catch (error) {
            this.showNotification("notifications_link_patient_error", PortalNotificationType.Error)
        }
    }

    private async setDefaultCountryFromIpAsync(): Promise<void> {
        const ipCountry: any = await this.sunriseApiService.getCountryFromIpAsync()

        if (ipCountry) {
            this.signUpFields.country.value = ipCountry.countryNumber
            this.signUpFields.selectedCountry = ipCountry.prefix
        }
    }

    // -------------------------------------------------------------------------
    // Navigation utils
    // -------------------------------------------------------------------------

    public updateStayConnectedAndRedirectToPatientsList(): void {
        if (this.stayConnected) {
            this.authService.transferSessionToLocalStorage()
        }

        this.router.navigate(["/main/patients"])
    }
}
