import { Component, Input, OnInit } from "@angular/core"
import { NightData } from "../../../models/Nights/NightData"

@Component({
    selector: "app-night-report-section",
    templateUrl: "./night-report-section.component.html",
    styleUrls: ["./night-report-section.component.sass"],
})
export class NightReportSectionComponent implements OnInit {
    @Input("sectionName") sectionName: string
    @Input("nightDataList") nightDataList: NightData[] | NightData[][]

    public displayedNightDataList: NightData[]
    public displayedNightDataSubList: NightData[][]

    public ngOnInit(): void {
        if (this.nightDataList) {
            // If first element is an array, it's a double list
            // If first element is a NightData, it's a simple list
            if (this.nightDataList[0] instanceof Array) {
                this.displayedNightDataSubList = this.nightDataList as NightData[][]
            } else {
                this.displayedNightDataList = this.nightDataList as NightData[]
            }
        }
    }

    public toggleReportSection(event: any): void {
        const section: HTMLElement = event["target"]["parentElement"]["parentElement"]

        section.classList.toggle("report-section--minified")
    }
}
