<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'sharingManagement_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered" [class.display-none]="isMobile">
        <div class="filters-container">
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.AllPatients" (click)="updateMainFilterAsync(eFilterType.AllPatients)">
                <a>{{ 'patientsList_filter_allPatients' | translate }}</a>
                <span>{{ patients.length }}</span>
            </div>
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.Unshared" (click)="updateMainFilterAsync(eFilterType.Unshared)">
                <a>{{ 'patientsList_filter_unsharedPatients' | translate }}</a>
                <span>{{ numberUnsharedPatients }}</span>
            </div>
        </div>
    </div>

    <div class="row row--guttered row--smaller content" [style.padding-top]="'2rem'">
        <div id="filters-container">
            <div class="field-container" [style.padding-bottom]="'1rem'" [class.display-none]="!isMobile">
                <select class="field" #selectedFilter (change)="updateMainFilterAsync(selectedFilter.value)">
                    <option [selected]="currentFilter == eFilterType.AllPatients" [value]="eFilterType.AllPatients">{{ 'patientsList_filter_allPatients' | translate }} ({{ patients.length }})</option>
                    <option [selected]="currentFilter == eFilterType.Unshared" [value]="eFilterType.Unshared">{{ 'patientsList_filter_unsharedPatients' | translate }} ({{ patients.length }})</option>
                </select>
            </div>
        </div>

        <div [ngClass]="{'two-field-container-per-row' : offices?.length > 0}">
            <div class="field-container">
                <input class="field"
                       [(ngModel)]="searchFilter"
                       placeholder="{{ 'patientsList_filter_last_name' | translate }}"
                />
            </div>

            <div *ngIf="offices?.length > 0" class="field-container">
                <select class="field"
                        [(ngModel)]="filterByOffice">
                    <option value="all" selected>{{ "patientsList_all_offices" | translate }}</option>
                    <option *ngFor="let office of offices" [value]="office.officeId">{{ office.officeName }}</option>
                    <option value="none">{{ "signUp_input_sharedOffice_none_label" | translate }}</option>
                </select>
            </div>
        </div>

        <div id="patients-container">
            <div class="list-row list-row--header">
                <app-sortable-list-header
                    class="col"
                    [columnName]="'common_last_name'"
                    [columnValue]="'lastName'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    [columnName]="'common_first_name'"
                    [columnValue]="'firstName'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    *ngIf="isUSPractitioner"
                    [columnName]="'patientsList_columnHeader_patientId'"
                    [columnValue]="'customIdentifier'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    [columnName]="'common_birth_date'"
                    [columnSubtitle]="dateFormatLabel.toUpperCase()"
                    [columnValue]="'birthDate'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <div class="col">{{ 'sharingManagement_sharedWith_label' | translate }}</div>
            </div>

            <div *ngIf="!isLoading">
                <div class="list-row" *ngForEmpty="let patient of patients | sharingManagementFilter: searchFilter : currentFilter : filterByOffice | listSort: sortColumnValue : sortOrder; let last = last; else: noPatients" [ngClass]="{ 'list-row--no-border': last }">
                    <div class="col">{{ (patient.lastName) ? capitalizeFirstLetter(patient.lastName) : '-' }}</div>
                    <div class="col">{{ (patient.firstName) ? capitalizeFirstLetter(patient.firstName) : '-' }}</div>
                    <div *ngIf="isUSPractitioner" class="col">{{ (patient.customIdentifier) ? patient.customIdentifier : '-' }}</div>
                    <div class="col">{{ (patient.birthDate) ? formatDate(patient.birthDate) : '-' }}</div>
                    <div class="col" [style.max-width]="isUSPractitioner ? '20%' : '25%'">
                        <div class="share__container">
                            <div *ngIf="offices?.length > 0">
                                <div class="share__subtitle">{{ 'sharingManagement_office_label' | translate }}:</div>

                                <div class="shared_office__container">
                                    <select *ngIf="patient.office && patient.office.canEditOffice == false" disabled>
                                        <option>{{ patient.office.officeName }}</option>
                                    </select>

                                    <select *ngIf="patient.office && patient.office.canEditOffice == true" (ngModelChange)="updatePatientOfficeAsync(patient, $event)" [ngModel]="patient.office.officeId">
                                        <option *ngFor="let office of offices"
                                                [value]="office.officeId"
                                        >{{ office.officeName }}</option>

                                        <option value="none">{{ "signUp_input_sharedOffice_none_label" | translate }}</option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <div class="share__subtitle">{{ 'sharingManagement_users_label' | translate }}:</div>

                                <div class="shared-doctors__container">
                                    <div *ngFor="let link of sortedSharedLink(patient.sharedLinks)" class="doctor__container" [ngClass]="{ 'doctor__container--disabled': !link.canBeUnlinked }">
                                        <span>{{ link.email }}</span>
                                        <div *ngIf="link.canBeUnlinked" class="doctor-delete" (click)="removeSharedDoctorAsync(link.email, patient)"></div>
                                    </div>

                                    <div class="doctor__input">
                                        <div class="input__container">
                                            <autocomplete-input #autocompleteInput
                                                                placeholder="{{ 'common_email' | translate }}..."
                                                                [proposals]="getProposalsForPatient(patient)"
                                                                [showAddButton]="true"
                                                                (validateInput)="addSharedDoctorAsync($event, patient, autocompleteInput.inputElement.nativeElement)"
                                            ></autocomplete-input>
                                        </div>

                                        <span [style.display]="autocompleteInput.inputElement?.nativeElement.classList?.contains('input--invalid') ? 'block' : 'none'" class="input-error">{{ "signUp_input_emailAddress_error_invalid" | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template #noPatients>
                    <div class="list-row list-row--no-border">{{ 'patientsList_empty' | translate }}</div>
                </ng-template>
            </div>

            <div *ngIf="isLoading" class="list-row list-row--no-border">{{ 'patientsList_loading' | translate }}</div>
        </div>
    </div>
</main>
