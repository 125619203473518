<input #inputElement
       placeholder="{{ placeholder }}"
       (input)="inputChangeEvent()"
       (keyup.enter)="inputEnterPressed(inputElement.value)"
       (keyup.arrowDown)="preselectNextProposal()"
       (keyup.arrowUp)="preselectPreviousProposal()"
       autocomplete="not-displayed"
       required>

<button class="input-validate" *ngIf="showAddButton" (click)="emitInput(inputElement.value)">+</button>

<ng-container *ngIf="proposals">
    <div #proposalsContainer class="autocomplete--container">
        <div class="container__item"
             *ngForEmpty="let proposal of proposals | autocompleteInputFilter: inputElement.value; else: emptyProposal"
             (click)="emitInput(proposal)"
             (mouseenter)="preselectProposal($event)"
             (mouseleave)="clearPreselectedProposal()"
        >{{ proposal }}</div>

        <ng-template #emptyProposal>
            <div class="container__item container__item--default"
                 (click)="emitInput(inputElement.value)"
                 (mouseenter)="preselectProposal($event)"
                 (mouseleave)="clearPreselectedProposal()"
            >{{ "autocomplete-input_add" | translate }} {{ inputElement.value }}</div>
        </ng-template>
    </div>
</ng-container>
