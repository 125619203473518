<div class="report-section report-section--minified">
    <!-- Separator -->
    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <!-- Section title -->
    <div class="row row--guttered-h section-title-container">
        <h2 class="title-h2 section-title" (click)="toggleReportSection($event)">{{ 'nightReport_section_MCTQ' | translate}}</h2>
    </div>
    <!-- Section title -->

    <div class="section-content">
        <div *ngIf="answerDate"
             class="row row--guttered-h">
            <p class='question-answer'>
                {{ 'nightReport_answers_date' | translate: {'date': answerDate} }}
            </p>
        </div>

        <!-- Variables -->
        <div class="row row--guttered row--smaller content">
            <div class="list-row">
                <div class="col">{{ displayHourFormatLabel('Questionnaire_sleep_time_on_workdays_label' | translate) }}</div>
                <div class="col" id='sleepTimeOnWorkdaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_sleep_latency_on_workdays_label' | translate}}</div>
                <div class="col" id='sleepLatencyOnWorkdaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ displayHourFormatLabel('Questionnaire_sleep_end_on_workdays_label' | translate) }}</div>
                <div class="col" id='sleepEndOnWorkdaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_alarm_used_on_workdays_label' | translate}}</div>
                <div class="col">
                    {{ scores['alarmClockUseOnWorkdays'] === undefined ? ('common_unknown' | translate) : ((scores['alarmClockUseOnWorkdays'] === true) ? ('common_yes' | translate) : ('common_no' | translate)) }}
                </div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_sleep_inertia_on_workdays_label' | translate}}</div>
                <div class="col" id='sleepInertialOnWorkdaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_sleep_onset_on_workdays_label' | translate}}</div>
                <div class="col" id='sleepOnsetOnWorkdaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_sleep_duration_on_workdays_label' | translate}}</div>
                <div class="col" id='sleepDurationOnWorkdaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ displayHourFormatLabel('Questionnaire_mid_sleep_on_workdays_label' | translate) }}</div>
                <div class="col" id='midSleepOnWorkdaysVariable'></div>
            </div>

            <div class="list-row">
                <div class="col">{{ displayHourFormatLabel('Questionnaire_sleep_time_on_freedays_label' | translate) }}</div>
                <div class="col" id='sleepTimeOnFreedaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_sleep_latency_on_freedays_label' | translate}}</div>
                <div class="col" id='sleepLatencyOnFreedaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ displayHourFormatLabel('Questionnaire_sleep_end_on_freedays_label' | translate) }}</div>
                <div class="col" id='sleepEndOnFreedaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_alarm_used_on_freedays_label' | translate}}</div>
                <div class="col">
                    {{ scores['alarmClockUseOnFreedays'] === undefined ? ('common_unknown' | translate) : ((scores['alarmClockUseOnFreedays'] === true) ? ('common_yes' | translate) : ('common_no' | translate)) }}
                </div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_sleep_inertia_on_freedays_label' | translate}}</div>
                <div class="col" id='sleepInertialOnFreedaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_sleep_onset_on_freedays_label' | translate}}</div>
                <div class="col" id='sleepOnsetOnFreedaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ 'Questionnaire_sleep_duration_on_freedays_label' | translate}}</div>
                <div class="col" id='sleepDurationOnFreedaysVariable'></div>
            </div>
            <div class="list-row">
                <div class="col">{{ displayHourFormatLabel('Questionnaire_mid_sleep_on_freedays_label' | translate) }}</div>
                <div class="col" id='midSleepOnFreedaysVariable'></div>
            </div>

            <div class="list-row">
                <div class="col">{{ 'Questionnaire_average_weelky_sleep_duration_label' | translate}}</div>
                <div class="col" id='averageWeeklySleepDurationVariable'></div>
            </div>
            <div class="list-row list-row--no-border">
                <div class="col">{{ displayHourFormatLabel('Questionnaire_chronotype_label' | translate) }}</div>
                <div class="col" id='chronotypeVariable'></div>
            </div>
        </div>
        <!-- Variables -->

        <!-- Data -->
        <div class="row row--guttered row--smaller content" *ngIf="night?.questionnaire?.sections && night?.questionnaire?.sections[2]?.questions">
            <div class="question" *ngFor="let question of night.questionnaire.sections[2].questions">
                <h3 class="title-h3 question-title">{{ question.displayedTitle }}</h3>
                <ng-container
                    *ngIf="isRangeQuestion(question.questionType); then rangeQuestion; else radioQuestion">
                </ng-container>
                <ng-template #rangeQuestion>
                    <div class="range-container">
                        <div class="range-wrapper"
                             *ngFor="let range of getAdaptedRanges(question); let index = index">
                            <img src="{{ (isAnswer(question, night.questionnaireAnswers, index)) ? '../assets/images/checkbox__checked_light.png' : '../assets/images/checkbox__unchecked_light.png' }}"/>

                            <p>{{ range }}</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template #radioQuestion>
                    <div *ngIf="isRadioQuestion(question); else textQuestion">
                        <p class='question-answer'>
                            {{ this.getRadioAnswer(question, night.questionnaireAnswers) }}
                        </p>
                    </div>
                </ng-template>
                <ng-template #textQuestion>
                    <p class='question-answer'>
                        {{ (this.getAnswer(question, night.questionnaireAnswers) == 'true' ? ('common_yes' | translate) : (this.getAnswer(question, night.questionnaireAnswers) == 'false' ? ('common_no' | translate) : this.formatMctqAnswer(question))) }}
                    </p>

                    <!-- Separator -->

                    <div class="separator separator--subsection" [style.display]="question.lastOfSubsection ? 'block' : 'none'"></div>
                </ng-template>
            </div>
        </div>
        <!-- Data -->
    </div>
</div>
