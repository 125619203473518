export enum UnitsAndFormats {
    // Length units
    Centimeters = "cm",
    Inches = "in",

    // Weight units
    Kilograms = "kg",
    Pounds = "lb",

    // Hour format
    H24 = "24h",
    H12 = "12h",

    //Date format
    DDMMYYYY = "DDMMYYYY",
    MMDDYYYY = "MMDDYYYY",
}
