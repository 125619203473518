import { QuestionnaireAnswerLine } from "../models/Questionnaires/QuestionnaireAnswerLine"

export class AnswersMapper {
    public static toAnswers(dataAccessAnswers: any[]): QuestionnaireAnswerLine[] {
        const answers: QuestionnaireAnswerLine[] = []
        for (const answer of dataAccessAnswers) {
            const newAnswer = new QuestionnaireAnswerLine()
            newAnswer.questionNumber = answer.questionNumber
            newAnswer.answerValue = answer.answerValue
            answers.push(newAnswer)
        }
        return answers
    }
}
