<main class="main">
    <div class="row row--guttered row--guttered-v row--smaller content" [style.padding-top]="0">
        <p class="list-row list-row--title list-row--no-border">{{ 'template_data' | translate }}</p>

        <div class="validatable-container field-container">
            <label class="label">{{ 'template_type' | translate }}</label>
            <select id="selectTemplateType" (change)="onTemplateTypeSelected($event)">
                <option value="Impression"
                        [selected]="templateToUpdate?.type == 'Impression'">{{ 'impression' | translate }}</option>
                <option value="Recommendation"
                        [selected]="templateToUpdate?.type == 'Recommendation'">{{ 'recommendation' | translate }}</option>
            </select>

            <label class="label">{{ 'template_title' | translate }}</label>
            <app-validatable-input class="validatable-container"
                                   *ngIf="templateTitle"
                                   [validatableObject]="templateTitle"
                                   placeHolder="{{ 'template_placeholder_title' | translate }}"></app-validatable-input>

            <label class="label">{{ 'template_text' | translate }}</label>
            <app-validatable-textarea class="validatable-container"
                                      *ngIf="templateText"
                                      [validatableObject]="templateText"
                                      placeHolder="{{ 'template_placeholder_text' | translate }}"></app-validatable-textarea>

            <app-button
                [loading]="isLoading"
                (clicked)="templateToUpdate ? updateTemplateAsync() : createTemplateAsync()"
            >
                {{ (templateToUpdate ? 'common_update' : 'template_button_register') | translate }}
            </app-button>
        </div>
    </div>
</main>
