import { Component, EventEmitter, Input, Output } from "@angular/core"
import { ValidatableObject } from "../../../../validation/ValidatableObject"

@Component({
    selector: "app-validatable-input",
    templateUrl: "./validatable-input.component.html",
})
export class ValidatableInputComponent {
    // -------------------------------------------------------------------------
    // Instance variables
    // -------------------------------------------------------------------------

    @Input("placeHolder") placeHolder: string
    @Input("validatableObject") object: ValidatableObject
    @Input("disabled") disabled = false
    @Input("formatInput") formatInput: (value: string) => object
    @Input("useLabelAsPlaceholder") useLabelAsPlaceholder = false
    @Input("transformToUpperCase") transformToUpperCase = false

    @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>()

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async keyupHandlerAsync(event: KeyboardEvent): Promise<void> {
        if (event.key == "Enter") {
            this.enterPressed.emit()
        } else if (this.object.hasError) {
            await this.object.validateAsync()
        }
    }

    public async unfocusHandlerAsync(): Promise<void> {
        if (this.formatInput) {
            this.object.value = this.formatInput(this.object.value)
        }

        await this.object.validateAsync()
    }
}
