import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { PortalNotificationType } from "../../../models/PortalNotification"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { PasswordRegExp } from "../../../utilities/passwordRegExp"
import { EqualityRule } from "../../../validation/rules/EqualityRule"
import { NotEmptyRule } from "../../../validation/rules/NotEmptyRule"
import { NotEqualityRule } from "../../../validation/rules/NotEqualityRule"
import { RegExpRule } from "../../../validation/rules/RegExpRule"
import { ValidatableObject } from "../../../validation/ValidatableObject"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-update-password",
    templateUrl: "./update-password.component.html",
})
export class UpdatePasswordComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isLoading = true
    public isUpdatingPassword = false

    public oldPassword: ValidatableObject
    public newPassword: ValidatableObject
    public newPasswordConfirmation: ValidatableObject

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        await this.initValidatableObjectsAsync()

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Objects validation
    // -------------------------------------------------------------------------

    public async initValidatableObjectsAsync(): Promise<void> {
        this.oldPassword = new ValidatableObject(this.translate, "updatePassword_oldPassword_label")
        this.oldPassword.addRule(new NotEmptyRule(this.translate))
        await this.oldPassword.addHintAsync("updatePassword_oldPassword_hint")

        this.newPassword = new ValidatableObject(this.translate, "updatePassword_newPassword_label")
        this.newPassword.addRule(new NotEmptyRule(this.translate))
        this.newPassword.addRule(
            new RegExpRule(this.translate, PasswordRegExp, "i18n:signUp_input_password_error_invalid"),
        )
        this.newPassword.addRule(
            new NotEqualityRule(this.translate, this.oldPassword, "i18n:updatePassword_newPassword_mustBeDifferent"),
        )
        await this.newPassword.addHintAsync("signUp_input_password_error_invalid")

        this.newPasswordConfirmation = new ValidatableObject(
            this.translate,
            "updatePassword_newPasswordConfirmation_label",
        )
        this.newPasswordConfirmation.addRule(
            new EqualityRule(
                this.translate,
                this.newPassword,
                "i18n:signUp_input_passwordConfirmation_error_mustMatch",
            ),
        )
    }

    /**
     * Validate validatable objects.
     * Force validating all the fields and do not stop at first error.
     * @return true if all the fields are valid, false otherwise
     */
    public async validateObjectsAsync(): Promise<boolean> {
        let isValid: boolean

        isValid = await this.oldPassword.validateAsync()
        isValid = (await this.newPassword.validateAsync()) && isValid
        isValid = (await this.newPasswordConfirmation.validateAsync()) && isValid

        return isValid
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public navigateBack(): void {
        this.router.navigate(["main/account"])
    }

    public async updatePasswordAsync(): Promise<void> {
        const isValid: boolean = await this.validateObjectsAsync()

        if (isValid) {
            try {
                this.isUpdatingPassword = true
                const tokens: any = await this.sunriseApiService.updatePasswordAsync(
                    this.authService.getUserEmailAddress(),
                    this.oldPassword.value,
                    this.newPassword.value,
                    this.isAdmin,
                )

                this.authService.refreshTokens(tokens["token"], tokens["refreshToken"])

                this.showNotification("account_update_password_success_notification", PortalNotificationType.Success)

                this.isUpdatingPassword = false

                this.navigateBack()
            } catch (error) {
                if (error.status == 400) {
                    this.oldPassword.errorString = await this.__("updatePassword_oldPassword_error")
                    this.oldPassword.hasError = true
                } else {
                    this.showErrorOccurred()
                }

                this.isUpdatingPassword = false
            }
        }
    }
}
