import { Component, HostListener, OnInit, Renderer2 } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as introJs from "intro.js/intro.js"
import * as _ from "lodash"
import { constants } from "../../config/constants"
import { Office } from "../../models/Office"
import { Patient } from "../../models/Patient"
import { PatientListFilter } from "../../models/PatientListFilter"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { getDateFormatLabel } from "../../utilities/UnitsAndFormatsUtilities"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { SortedList } from "../sortedLists"

@Component({
    selector: "app-patients-list",
    templateUrl: "./patients-list.component.html",
    styleUrls: ["./patients-list.component.sass"],
})
export class PatientsListComponent extends SortedList implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    private readonly ONBOARDING_KEY: string = "hasSeenOnboarding"
    public introJS = introJs()

    public patientsList: Patient[] = []
    public doctorOffices: Office[]

    public currentFilter: PatientListFilter = PatientListFilter.AllPatients
    public searchFilter = ""
    public filterByDates: ValidatableObject
    public filterByNewNights: ValidatableObject
    public filterStartDate: ValidatableObject
    public filterEndDate: ValidatableObject
    public filterByOffice: string

    public nbPatients = 0
    public nbPatientsWithNight = 0
    public nbPatientsActivated = 0
    public nbPatientsNonActivated = 0

    public isLoading = true
    public isMobile: boolean
    public hasPrescriberRole: boolean

    public dateFormatLabel: string

    /* Enum */
    public eFilterType: typeof PatientListFilter = PatientListFilter

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        protected readonly renderer: Renderer2,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)
        this.translate.onLangChange.subscribe(() => this.updateLanguage())

        this.hasPrescriberRole = this.sunriseApiService.accountHasRole(constants.roles.prescriber)

        this.isMobile = window.innerWidth < 1330
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        await this.sunriseApiService.setRolesAsync(this.authService.isAdmin())

        if (!this.canConsultReports) {
            this.navigateToMainPage()
        } else {
            this.isLoading = true

            await this.initValidatableObjectsAsync()

            await this.fillDoctorOfficesAsync()
            await this.fillInPatientsListAsync()

            this.refreshNumberOfFilteredPatients()
            this.initSortingValues("lastName")

            this.isLoading = false

            const hasSeenOnBoarding = !_.isEmpty(localStorage.getItem(this.ONBOARDING_KEY))

            // 1330px is the minimal width to have a desktop display and not a mobile one
            if (!hasSeenOnBoarding && !this.authService.isAdmin() && !this.isMobile) {
                await this.showOnBoardingAsync()
            }
        }
    }

    // -------------------------------------------------------------------------
    // Event listener
    // -------------------------------------------------------------------------

    @HostListener("window:resize", [])
    public onResize() {
        this.isMobile = window.innerWidth < 1330

        // Below a 1330px width, mobile template is displayed
        if (this.isMobile) {
            this.introJS.exit()
        }
    }

    // -------------------------------------------------------------------------
    // Translation helpers
    // -------------------------------------------------------------------------

    protected updateLanguage(): void {
        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)
    }

    // -------------------------------------------------------------------------
    // Navigation delegates
    // -------------------------------------------------------------------------

    public navigateToPatient(patient: Patient): void {
        this.sunriseApiService.setPatient(patient)

        this.router.navigate([`main/patients/${patient.id}/nights`])
    }

    public navigateToSampleNight(): void {
        this.router.navigate(["main/nights/sample"])
    }

    // -------------------------------------------------------------------------
    // Initialize validation
    // -------------------------------------------------------------------------

    private async initValidatableObjectsAsync(): Promise<void> {
        this.filterByDates = new ValidatableObject(this.translate, "patientsList_show_filter_date")
        this.filterByNewNights = new ValidatableObject(this.translate, "patientsList_only_new_nights_label")

        this.filterStartDate = new ValidatableObject(this.translate, "patientsList_filter_date_startDate")
        this.filterStartDate.addRule(new NotEmptyRule(this.translate, "i18n:signUp_input_date_wrongFormat"))

        this.filterEndDate = new ValidatableObject(this.translate, "patientsList_filter_date_endDate")
        this.filterEndDate.addRule(new NotEmptyRule(this.translate, "i18n:signUp_input_date_wrongFormat"))
    }

    // -------------------------------------------------------------------------
    // Filter patients
    // -------------------------------------------------------------------------

    public async updateMainFilterAsync(filterType: string): Promise<void> {
        if (this.isLoading || filterType == this.currentFilter) return

        if (filterType === PatientListFilter.Activated || filterType === PatientListFilter.NonActivated) {
            this.filterByDates.value = false
            this.filterByNewNights.value = false
        }

        this.currentFilter = filterType as PatientListFilter
    }

    public refreshNumberOfFilteredPatients() {
        this.nbPatients = this.patientsList.length
        this.nbPatientsWithNight = this.patientsList.filter((patient) => patient.hasPerformedNight).length
        this.nbPatientsActivated = this.patientsList.filter(
            (patient) => patient.lastLoginDate && !patient.hasPerformedNight,
        ).length
        this.nbPatientsNonActivated = this.patientsList.filter((patient) => !patient.lastLoginDate).length
    }

    // -------------------------------------------------------------------------
    // Fill patients list
    // -------------------------------------------------------------------------

    public async fillDoctorOfficesAsync(): Promise<void> {
        try {
            const doctorOffices: Office[] = await this.sunriseApiService.getDoctorOfficesAsync()

            if (doctorOffices.length > 0) {
                this.doctorOffices = doctorOffices.sort((officeA, officeB) => {
                    return officeA.officeName >= officeB.officeName ? 1 : -1
                })

                this.filterByOffice = "all"
            }
        } catch {
            // If an error occurred, do nothing
            this.doctorOffices = []
        }
    }

    public async fillInPatientsListAsync(): Promise<void> {
        try {
            const retrieveOffices = this.doctorOffices?.length > 0
            const res = await this.sunriseApiService.getPatientsAsync(false, retrieveOffices)

            this.patientsList = res["data"]["patients"]
        } catch (error) {
            this.handleError(error)

            this.showErrorOccurred()
        }
    }

    // -------------------------------------------------------------------------
    // OnBoarding
    // -------------------------------------------------------------------------

    private async showOnBoardingAsync() {
        const spaceBetweenLines = "<div class='space'></div>"

        this.introJS.setOptions({
            tooltipClass: "customTooltip",
            overlayOpacity: 0.4,
            disableInteraction: true,
            dontShowAgain: true,
            exitOnEsc: false,
            exitOnOverlayClick: false,
            hidePrev: true,
            showBullets: false,
            doneLabel: await this.__("onboarding_button_finish"),
            nextLabel: await this.__("onboarding_button_next"),
            prevLabel: await this.__("onboarding_button_previous"),
            steps: [
                {
                    title: await this.__("onboarding_title_welcome"),
                    intro: await this.__("onboarding_tip_welcome_navigate"),
                },
                {
                    element: "#patients-container",
                    title: await this.__("onboarding_title_my_patients"),
                    intro:
                        (await this.__("onboarding_tip_patients_see_more")) +
                        spaceBetweenLines +
                        (await this.__("onboarding_tip_patients_sample")),
                },
                {
                    element: "#filters-container",
                    title: await this.__("onboarding_title_my_patients"),
                    intro: await this.__("onboarding_tip_patients_filters"),
                },
                {
                    element: "#account",
                    title: await this.__("onboarding_title_my_account"),
                    intro:
                        (await this.__("onboarding_tip_my_account_double_authentication")) +
                        spaceBetweenLines +
                        (await this.__("onboarding_tip_my_account_notifications")),
                },
            ],
        })

        if (this.hasPrescriberRole) {
            this.introJS.addStep({
                element: "#prescription",
                title: await this.__("onboarding_title_invitation"),
                intro: await this.__("onboarding_tip_invite_patients"),
            })
        }

        this.introJS.start()

        // Can't add this in JSON options above
        // We can only add it dynamically
        // By default, don't show again element is hidden in style.sass
        const dontWantToSeeDemoHTMLElement = await this.getDontWantToSeeDemoHTMLElement()
        const introJSDontShowAgainElement = document.getElementsByClassName("introjs-dontShowAgain")[0]

        if (introJSDontShowAgainElement) {
            this.renderer.appendChild(introJSDontShowAgainElement, dontWantToSeeDemoHTMLElement)
        }
    }

    private async getDontWantToSeeDemoHTMLElement(): Promise<HTMLParagraphElement> {
        const dontWantToSeeDemoHTMLElement: HTMLParagraphElement = this.renderer.createElement("a")

        dontWantToSeeDemoHTMLElement.innerText = await this.__("onboarding_dont_want_to_see_demo")
        dontWantToSeeDemoHTMLElement.className = "forgot-password-button"

        dontWantToSeeDemoHTMLElement.onclick = () => {
            localStorage.setItem(this.ONBOARDING_KEY, "true")
            this.introJS.exit()
        }

        return dontWantToSeeDemoHTMLElement
    }
}
