import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { Moment } from "moment"
import { constants } from "../../config/constants"
import { AccountActionType } from "../../models/AccountActionType"
import { UnitsAndFormats } from "../../models/Formats/UnitsAndFormats"
import { Office } from "../../models/Office"
import { PortalNotificationType } from "../../models/PortalNotification"
import { UnitsAndFormatsPreferences } from "../../models/UnitsAndFormatsPreferences"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { HttpUtilities } from "../../utilities/HttpUtilities"
import { convertInchesToCentimeters, convertPoundsToKilograms } from "../../utilities/UnitsAndFormatsUtilities"
import { MaxValueRule } from "../../validation/rules/MaxValueRule"
import { MinValueRule } from "../../validation/rules/MinValueRule"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { NumberRule } from "../../validation/rules/NumberRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { AccountFieldsComponent } from "../account-fields/account-fields.component"
import { BaseComponent } from "../base.component"
import { SensorsToPatientAssignationComponent } from "../tools/sensors-to-patient-assignation/sensors-to-patient-assignation.component"

@Component({
    selector: "app-create-patient-account",
    templateUrl: "./create-patient-account.component.html",
    styleUrls: ["./create-patient-account.component.sass"],
    encapsulation: ViewEncapsulation.None,
})
export class CreatePatientAccountComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Constants
    // -------------------------------------------------------------------------

    private readonly LAST_USED_OFFICE: string = "lastUsedOffice"

    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isLoading = true
    public isCreatingAccount = false
    public showPersonalInformationFields = false

    public genders: object[] = []

    public patientIdentifier: ValidatableObject
    public assignSensorToUser: ValidatableObject
    public gender: ValidatableObject
    public collarSize: ValidatableObject
    public height: ValidatableObject
    public weight: ValidatableObject
    public shouldCompleteQuestionnaire: ValidatableObject
    public shareWithOffice: ValidatableObject

    public autocompleteValues: string[] = []
    public doctorEmail: string = localStorage.getItem("userEmailAddress").toLowerCase()
    public sharedDoctors: string[] = []
    public officesList: Office[] = [{ officeId: "none", officeName: "" }]

    @ViewChild("personalInformationFields") public personalInformationFields: AccountFieldsComponent
    @ViewChild("sensorAssignationFields") public sensorAssignationFields: SensorsToPatientAssignationComponent

    /* Enum */
    public eAccountActionType: typeof AccountActionType = AccountActionType

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.translate.onLangChange.subscribe(async () => await this.fillDisplayedTextAsync())
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        if (!this.isUSPractitioner) {
            this.navigateToMainPage()
        } else {
            await this.fillDisplayedTextAsync()
            await this.initValidatableObjectsAsync()
        }
    }

    public async afterSignUpFieldsInit(): Promise<void> {
        const doctorInformation = await this.getDoctorInformationAsync()
        const { countryNumber, sharedEmails, officesList } = doctorInformation

        if (countryNumber) {
            this.personalInformationFields.country.value = countryNumber
            this.personalInformationFields.countryChangeEvent(countryNumber)
        }

        if (sharedEmails) {
            this.autocompleteValues = sharedEmails
        }

        if (this.officesList) {
            const sortedOffices = officesList.sort((officeA, officeB) =>
                officeA.officeName >= officeB.officeName ? 1 : -1,
            )

            this.officesList.unshift(...sortedOffices)

            this.shareWithOffice.value = sessionStorage.getItem(this.LAST_USED_OFFICE) || "none"
        }

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Initialization
    // -------------------------------------------------------------------------

    public async fillDisplayedTextAsync() {
        this.genders = [
            { name: "male", displayedName: await this.__("signUp_input_gender_male_label") },
            { name: "female", displayedName: await this.__("signUp_input_gender_female_label") },
        ]

        const noneOffice = this.officesList.find((office) => office.officeId == "none")
        if (noneOffice) {
            noneOffice.officeName = await this.__("signUp_input_sharedOffice_none_label")
        }
    }

    public displayPersonalInformationFields(): void {
        this.showPersonalInformationFields = true
    }

    public async initValidatableObjectsAsync(): Promise<void> {
        const preferences: UnitsAndFormatsPreferences = this.authService.getPreferences()

        let collarSizeLabel = "signUp_input_collarSize_cm_label"
        let heightLabel = "signUp_input_height_cm_label"
        let weightLabel = "signUp_input_weight_kg_label"

        let maxCollarSize = 100
        let maxHeight = 250
        let maxWeight = 250

        if (preferences.preferredLengthUnit != UnitsAndFormats.Centimeters) {
            collarSizeLabel = "signUp_input_collarSize_in_label"
            heightLabel = "signUp_input_height_in_label"

            maxCollarSize = 39
            maxHeight = 98
        }

        if (preferences.preferredWeightUnit != UnitsAndFormats.Kilograms) {
            weightLabel = "signUp_input_weight_lb_label"
            maxWeight = 550
        }

        this.patientIdentifier = new ValidatableObject(this.translate, "signUp_input_patient_identifier_label")
        this.patientIdentifier.addRule(new NotEmptyRule(this.translate))

        this.gender = new ValidatableObject(this.translate, "signUp_input_gender_label")
        this.gender.value = undefined

        this.collarSize = new ValidatableObject(this.translate, collarSizeLabel)
        this.collarSize.addRule(new NumberRule(this.translate))
        this.collarSize.addRule(new MinValueRule(this.translate, 0))
        this.collarSize.addRule(new MaxValueRule(this.translate, maxCollarSize))

        this.height = new ValidatableObject(this.translate, heightLabel)
        this.height.addRule(new NumberRule(this.translate))
        this.height.addRule(new MinValueRule(this.translate, 0))
        this.height.addRule(new MaxValueRule(this.translate, maxHeight))

        this.weight = new ValidatableObject(this.translate, weightLabel)
        this.weight.addRule(new NumberRule(this.translate))
        this.weight.addRule(new MinValueRule(this.translate, 0))
        this.weight.addRule(new MaxValueRule(this.translate, maxWeight))

        this.shouldCompleteQuestionnaire = new ValidatableObject(
            this.translate,
            "createPatientAccount_shouldCompleteQuestionnaire",
        )

        this.assignSensorToUser = new ValidatableObject(this.translate, "signUp_assign_sensor_label")
        this.assignSensorToUser.value = false

        this.shareWithOffice = new ValidatableObject(this.translate)
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public getSharingProposals(): string[] {
        return this.autocompleteValues.filter((value) => !this.sharedDoctors.includes(value))
    }

    public async addSharedDoctorAsync(email: string, input: HTMLInputElement): Promise<void> {
        if (!email) return

        email = email.toLowerCase()

        if (!constants.validation.patterns.email.test(email)) {
            input.classList.add("input--invalid")
            return
        }

        if (this.sharedDoctors.includes(email) || email == this.doctorEmail) {
            this.showNotification("createPatientAccount_sharePatient_doctorAlreadyAdded", PortalNotificationType.Error)
        } else {
            this.sharedDoctors.push(email)
            this.sharedDoctors = this.sharedDoctors.sort()

            input.value = ""
        }
    }

    public removeSharedDoctor(emailToRemove: string): void {
        this.sharedDoctors = this.sharedDoctors.filter((email) => email != emailToRemove)
    }

    public async createPatientAccountAsync(): Promise<void> {
        const isValid: boolean = await this.validateObjectsAsync()

        if (isValid) {
            const patient: any = this.createPatientObject()

            this.isCreatingAccount = true

            try {
                const canCreateAccount: boolean =
                    !this.assignSensorToUser.value ||
                    (await this.sunriseApiService.sensorIsAssignableAsync(
                        this.sensorAssignationFields.serialNumber.value,
                    ))

                if (canCreateAccount) {
                    const createdUserId: string = await this.sunriseApiService.createPatientAccount(patient)

                    if (this.assignSensorToUser.value) {
                        await this.sunriseApiService.assignSensorToPatientAsync(
                            this.sensorAssignationFields.serialNumber.value,
                            createdUserId,
                            this.sensorAssignationFields.secretCode.value,
                        )
                    }

                    // Save last used office to retrieve on next consecutive patient creation
                    if (patient["sharedOffice"]) {
                        sessionStorage.setItem(this.LAST_USED_OFFICE, patient["sharedOffice"])
                    } else {
                        sessionStorage.removeItem(this.LAST_USED_OFFICE)
                    }

                    this.isCreatingAccount = false

                    this.showNotification("createPatientAccount_confirmation", PortalNotificationType.Success)
                    this.navigateToMainPage()
                }
            } catch (error) {
                this.isCreatingAccount = false

                if (error.status === 409) {
                    await this.showEmailAlreadyUsedErrorAsync()
                } else {
                    const errorCode: string = HttpUtilities.getErrorCodeFromHttpError(error)

                    if (errorCode === "sensor_already_assigned") {
                        await this.showSensorAlreadyAssignedErrorAsync()
                    } else if (errorCode == "not_found") {
                        await this.showSensorNotExistErrorAsync()
                    } else {
                        this.showErrorOccurred()
                    }
                }
            }
        }
    }

    private async validateObjectsAsync(): Promise<boolean> {
        let isValid: boolean = await this.patientIdentifier.validateAsync()

        if (this.showPersonalInformationFields) {
            isValid = (await this.personalInformationFields.validateObjectsAsync()) && isValid
            isValid = (await this.gender.validateAsync()) && isValid
            isValid = (await this.collarSize.validateAsync()) && isValid
            isValid = (await this.height.validateAsync()) && isValid
            isValid = (await this.weight.validateAsync()) && isValid
        }

        if (this.assignSensorToUser.value == true) {
            isValid = (await this.sensorAssignationFields.formatAndValidateObjectsAsync()) && isValid
        }

        return isValid
    }

    private createPatientObject(): any {
        const preferences: UnitsAndFormatsPreferences = this.authService.getPreferences()
        const firstName: string = this.personalInformationFields.firstName.value
        const lastName: string = this.personalInformationFields.lastName.value
        const birthdate: Moment = this.personalInformationFields.birthdate.value
        const phoneNumber: string = this.personalInformationFields.phoneNumber.value

        let collarSize: number = this.collarSize.value != "" ? this.collarSize.value : null
        let height: number = this.height.value != "" ? this.height.value : null
        let weight: number = this.weight.value != "" ? this.weight.value : null

        if (collarSize && preferences.preferredLengthUnit == UnitsAndFormats.Inches) {
            collarSize = convertInchesToCentimeters(this.collarSize.value)
        }

        if (height && preferences.preferredLengthUnit == UnitsAndFormats.Inches) {
            height = convertInchesToCentimeters(this.height.value)
        }

        if (weight && preferences.preferredWeightUnit == UnitsAndFormats.Pounds) {
            weight = convertPoundsToKilograms(this.weight.value)
        }

        const patient: object = {
            customIdentifier: this.patientIdentifier.value,
            shouldCompleteQuestionnaire: !_.isNil(this.shouldCompleteQuestionnaire.value)
                ? this.shouldCompleteQuestionnaire.value
                : false,
            sharedPractitioners: this.sharedDoctors,
            sharedOffice: [undefined, "none"].includes(this.shareWithOffice?.value)
                ? undefined
                : this.shareWithOffice.value,
        }

        if (this.showPersonalInformationFields) {
            patient["firstName"] = firstName != "" ? firstName : null
            patient["lastName"] = lastName != "" ? lastName : null
            patient["email"] = this.personalInformationFields.emailAddress.value

            patient["patientInfo"] = {
                birthDate: birthdate
                    ? {
                          year: birthdate.year(),
                          month: birthdate.month() + 1,
                          day: birthdate.date(),
                      }
                    : null,
                countryNumber: this.personalInformationFields.country.value,
                phoneNumber: phoneNumber,
                gender: this.gender.value,
                height,
                weight,
                collarSize,
            }
        }

        return patient
    }

    // -------------------------------------------------------------------------
    // Other utils
    // -------------------------------------------------------------------------

    private async getDoctorInformationAsync(): Promise<{
        countryNumber: string
        sharedEmails: string[]
        officesList: Office[]
    }> {
        try {
            const res: any = (await this.sunriseApiService.getMeAsync(true, true))["data"]
            const account: any = res["account"]
            const sharedEmails: string[] = res["sharedEmails"]
            const officesList: Office[] = res["officesList"]

            return { countryNumber: account?.countryNumber, sharedEmails, officesList }
        } catch (error) {
            return undefined
        }
    }

    // -------------------------------------------------------------------------
    // Error helpers
    // -------------------------------------------------------------------------

    private async showEmailAlreadyUsedErrorAsync(): Promise<void> {
        this.personalInformationFields.emailAddress.errorString = await this.__(
            "signUp_input_emailAddress_error_conflict",
        )
        this.personalInformationFields.emailAddress.hasError = true
    }

    public async showSensorAlreadyAssignedErrorAsync(): Promise<void> {
        this.sensorAssignationFields.serialNumber.errorString = await this.__(
            "sensorUserAssign_serialNumber_alreadyAssigned",
        )
        this.sensorAssignationFields.serialNumber.hasError = true
    }

    public async showSensorNotExistErrorAsync(): Promise<void> {
        this.sensorAssignationFields.serialNumber.errorString = await this.__("sensorUserAssign_serialNumber_notFound")
        this.sensorAssignationFields.serialNumber.hasError = true
    }
}
