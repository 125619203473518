import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnChanges,
    Output,
    SimpleChange,
    SimpleChanges,
} from "@angular/core"
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { CountryISO } from "@justin-s/ngx-intl-tel-input"
import { PhoneNumber } from "../../../../models/PhoneNumber"
import { NotEmptyRule } from "../../../../validation/rules/NotEmptyRule"
import { ValidatableObject } from "../../../../validation/ValidatableObject"

@Component({
    selector: "app-phone-number-input",
    templateUrl: "./phone-number-input.component.html",
})
export class PhoneNumberInputComponent implements DoCheck, OnChanges {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public displayedCountry: CountryISO = CountryISO.Belgium

    public phoneNumberFormGroup = new UntypedFormGroup({
        phoneNumberControl: new UntypedFormControl(undefined, [Validators.required]),
    })

    @Input("validatableObject") object: ValidatableObject
    @Input("selectedCountry") public selectedCountry: CountryISO

    @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>()

    private differ: KeyValueDiffer<any, any>

    constructor(protected readonly differs: KeyValueDiffers) {}

    // -------------------------------------------------------------------------
    // Angular cycles
    // -------------------------------------------------------------------------

    public ngOnChanges(changes: SimpleChanges): void {
        const selectedCountryChange: SimpleChange = changes["selectedCountry"]
        const objectChange: SimpleChange = changes["object"]

        if (selectedCountryChange && selectedCountryChange.currentValue) {
            const phoneNumber: PhoneNumber = this.object.value

            if (!phoneNumber || !phoneNumber.phoneNumber) {
                this.displayedCountry = selectedCountryChange.currentValue
            }
        }

        // Create differ when object is set for the first time to catch changes
        if (objectChange && objectChange.firstChange) {
            this.differ = this.differs.find(this.object).create()
        }
    }

    public ngDoCheck(): void {
        if (this.differ) {
            // Get all object changes
            const objectChanges = this.differ.diff(this.object)

            if (objectChanges) {
                objectChanges.forEachChangedItem(async (objectChange) => {
                    if (objectChange.key == "value") {
                        await this.updatePhoneNumberInputAsync(objectChange.currentValue)
                    }
                })
            }
        }
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    public updatePhoneNumberValue(): void {
        const phoneNumberValid: boolean = this.phoneNumberFormGroup.valid
        const formValues: any = this.phoneNumberFormGroup.value["phoneNumberControl"]

        let newPhoneNumberValue: PhoneNumber = undefined

        if (formValues) {
            newPhoneNumberValue = {
                isFormatValid: phoneNumberValid,
                needToUpdateInput: false,
                countryCode: formValues.countryCode,
                dialCode: formValues.dialCode,
                phoneNumber: formValues.number,
            }
        } else if (this.object.containsRule(NotEmptyRule)) {
            newPhoneNumberValue = {
                isFormatValid: phoneNumberValid,
                needToUpdateInput: false,
            }
        }

        this.object.value = newPhoneNumberValue
    }

    // -------------------------------------------------------------------------
    // Event helpers
    // -------------------------------------------------------------------------

    public async keyupHandlerAsync(event: any): Promise<void> {
        // If 'Enter' is pressed
        if (event.keyCode == 13) {
            this.enterPressed.emit()
        } else if (this.object.hasError) {
            this.updatePhoneNumberValue()
            await this.object.validateAsync()
        }
    }

    public async unfocusHandlerAsync(): Promise<void> {
        this.updatePhoneNumberValue()
        await this.object.validateAsync()
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    private async updatePhoneNumberInputAsync(phoneNumber: PhoneNumber) {
        if (phoneNumber?.needToUpdateInput) {
            this.phoneNumberFormGroup.controls.phoneNumberControl.setValue(phoneNumber.phoneNumber)

            if (phoneNumber.countryCode) {
                this.displayedCountry = phoneNumber.countryCode.toLowerCase() as CountryISO
            }

            // Need to await some milliseconds after "setValue" of phoneNumberControl
            // If we don't await, the lib doesn't have the time to check if phoneNumber
            // is valid so isFormatValid is set to false and not to true
            await new Promise((res) => setTimeout(res, 500))

            this.updatePhoneNumberValue()
        }
    }
}
