import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { NotificationType } from "../../../models/NotificationType"
import { PortalNotificationType } from "../../../models/PortalNotification"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
})
export class NotificationsComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isLoading = true
    public enabledNotifications: string[] = []

    /* Enum */
    public eNotificationType: typeof NotificationType = NotificationType

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        await this.loadAccountNotificationsAsync()
    }

    // -------------------------------------------------------------------------
    // Initialization
    // -------------------------------------------------------------------------

    private async loadAccountNotificationsAsync(): Promise<void> {
        this.isLoading = true

        const account = await this.getAccountDataAsync()
        this.enabledNotifications = account.notifications

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public navigateBack(): void {
        this.router.navigate(["main/account"])
    }

    public async switchNotificationsAsync(notificationType: NotificationType) {
        try {
            const enableNotification = !this.enabledNotifications.includes(notificationType)
            const successMessage: string = enableNotification
                ? "notifications_enable_success"
                : "notifications_disable_success"

            await this.sunriseApiService.updateNotificationStatus(notificationType, enableNotification)

            if (enableNotification) {
                this.enabledNotifications.push(notificationType)
            } else {
                this.enabledNotifications = this.enabledNotifications.filter(
                    (notification) => notification != notificationType,
                )
            }

            this.showNotification(successMessage, PortalNotificationType.Success)
        } catch (error) {
            this.handleError(error)

            this.showErrorOccurred()
        }
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    private async getAccountDataAsync(): Promise<any> {
        try {
            const res: any = await this.sunriseApiService.getMeAsync()

            return res["data"]["account"]
        } catch (error) {
            this.handleError(error)

            this.showErrorOccurred()
        }
    }
}
