import { TranslateService } from "@ngx-translate/core"
import { ValidatableRule } from "./ValidatableRule"
import { InputObject } from "../InputObject"

export class NotEqualityRule extends ValidatableRule {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        translate: TranslateService,
        private readonly referenceObject: InputObject,
        errorStringTemplate?: string,
    ) {
        super(translate, errorStringTemplate || "i18n:common_validation_error_mustBeDifferent")
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    protected validateRule(value: any): boolean {
        return value !== this.referenceObject.value
    }
}
