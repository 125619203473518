<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ "legal_privacyPolicies" | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--smaller content">
        <div class="policies-container">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ "legal_cookiesAndPrivacyPolicy_cookiesPolicy" | translate }}</div>
            </div>
            <ul>
                <li *ngFor="let link of cookiesPolicyFiles">
                    {{ link.lang }} - <a [href]="cookiesPolicyBaseUrl + link.url" target="_blank">{{ link.name }} ({{ link.url }})</a>
                </li>
            </ul>
        </div>

        <div>
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ "legal_cookiesAndPrivacyPolicy_privacyPolicy" | translate }}</div>
            </div>
            <ul>
                <li *ngFor="let link of privacyPolicyFiles">
                    {{ link.lang }} - <a [href]="privacyPolicyBaseUrl + link.url" target="_blank">{{ link.name }} ({{ link.url }})</a>
                </li>
            </ul>
        </div>
    </div>
</main>
