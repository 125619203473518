<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'signUp_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <ng-container *ngIf="!isAccountCreated">
        <div class="row row--guttered row--tiny content" [style.display]="isLoading ? 'none' : ''">
            <app-account-fields #signUpFields
                                (enterPressed)="signUp()"
                                (componentInit)="afterSignUpFieldsInit()"></app-account-fields>

            <app-validatable-checkbox *ngIf="herebyCertify"
                                      [validatableObject]="herebyCertify"
                                      [required]="true"></app-validatable-checkbox>

            <app-validatable-checkbox *ngIf="termsAndConditions"
                                      [validatableObject]="termsAndConditions"
                                      [required]="true"
                                      [objectLabelArgs]="{
                                          'terms_url': staticFilesBaseUri + '/sunrise-platform-terms_of_use.pdf',
                                          'cookies_url': staticFilesBaseUri + '/sunrise-platform-cookies.pdf'
                                      }"></app-validatable-checkbox>

            <div class="field-container field-container--checkbox">
                <input type="checkbox"
                       id="newsletter-checkbox"
                       [(ngModel)]="subscribeNewsletter">
                <label class="label" for="newsletter-checkbox"> {{ "signUp_action_receiveNewsletter" | translate }}</label>
                <span class="checkmark"></span>
            </div>

            <div class="field-container buttons-container">
                <app-button
                    [loading]="isSigningUp"
                    (clicked)="signUp()"
                >
                    {{ 'signUp_action_signUp' | translate }}
                </app-button>

                <div class="create-account-button-container">
                    <a [routerLink]="['/login']"  class="button-like create-account-button">{{ 'signUp_signIn_button' | translate }}</a>
                </div>
            </div>
        </div>

        <div *ngIf='isLoading' class="row row--guttered row--tiny content">
            {{ 'common_loading' | translate }}
        </div>
    </ng-container>

    <ng-container *ngIf="isAccountCreated">
        <div class="row row--guttered row--smaller content">
            <p>{{ 'signUp_accountCreate_title' | translate }}</p>
            <p>{{ 'signUp_accountCreate_subtitle' | translate }}</p>

            <div class="field-container field-container--checkbox stay-connected-checkbox">
                <input id="stayConnected" type="checkbox" [(ngModel)]="stayConnected">
                <label class="label" for="stayConnected" [innerHTML]="'signIn_action_stayConnected' | translate"></label>
                <span class="checkmark"></span>
            </div>

            <div class="buttons-container">
                <app-button
                    (clicked)="updateStayConnectedAndRedirectToPatientsList()"
                >
                    {{ 'common_done' | translate }}
                </app-button>
            </div>
        </div>
    </ng-container>
</main>
