import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { TranslatableComponent } from "../../translatable.component"

@Component({
    selector: "app-delay-button",
    templateUrl: "./delay-button.component.html",
})
export class DelayButtonComponent extends TranslatableComponent {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    @ViewChild("delayButton") delayButton: ElementRef

    @Input() public defaultText: string
    @Input() public delayText: string

    @Input() public delayInSeconds: number

    @Output() buttonClickedEvent: EventEmitter<void> = new EventEmitter<void>()

    public isButtonActive = true
    private timerId: any

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(protected readonly translate: TranslateService) {
        super(translate)
    }

    // -------------------------------------------------------------------------
    // Functions
    // -------------------------------------------------------------------------

    public async buttonClick(): Promise<void> {
        if (this.isButtonActive) {
            this.buttonClickedEvent.emit()
        }
    }

    public startDelay(): void {
        let currentDelay: number = this.delayInSeconds

        this.isButtonActive = false

        this.timerId = this.setIntervalWithoutDelay(async () => {
            this.delayButton.nativeElement.innerText = await this.__(this.delayText, { delay: currentDelay })

            currentDelay--
        })

        setTimeout(async () => {
            clearInterval(this.timerId)

            this.delayButton.nativeElement.innerText = await this.__(this.defaultText)
            this.isButtonActive = true
        }, this.delayInSeconds * 1000)
    }

    public async resetDelayAsync(): Promise<void> {
        clearInterval(this.timerId)

        this.delayButton.nativeElement.innerText = await this.__(this.defaultText)
        this.isButtonActive = true
    }

    /**
     * Function to start an interval without the first delay
     * @param func Function to execute
     * @return The id of the timer
     */
    public setIntervalWithoutDelay(func: () => void) {
        func()
        return setInterval(func, 1000)
    }
}
