import { Injectable } from "@angular/core"
import { CacheLocalForageDataAccessBase } from "./CacheLocalForageDataAccessBase"
import { constants } from "../../../config/constants"
import { RawDataCacheDataAccess } from "../RawDataCacheDataAccess"

/**
 * Access to raw data from local cache through LocalForage.
 */
@Injectable()
export class RawDataCacheLocalForageDataAccess
    extends CacheLocalForageDataAccessBase
    implements RawDataCacheDataAccess
{
    /**
     * Key identifying signals order preferences in storage.
     */
    private static readonly SignalsOrderPreferencesKey: string =
        constants.dataAccess.cache.rawData.signalsOrderPreferences.key

    /**
     * Key identifying signals visibility preferences in storage.
     */
    private static readonly SignalsVisibilityPreferencesKey: string =
        constants.dataAccess.cache.rawData.signalsVisibilityPreferences.key

    /**
     * Prefix of the key identifying drafts of updated predictions in storage.
     */
    private static readonly DraftsPredictionsKeyPrefix: string =
        constants.dataAccess.cache.rawData.draftsPredictions.keyPrefix

    // -------------------------------------------------------------------------
    // Preferences of signals order
    // -------------------------------------------------------------------------

    // from RawDataCacheDataAccess
    public async setSignalsOrderPreferencesAsync(signalsOrder: number[]): Promise<void> {
        await this.setItemAsync(RawDataCacheLocalForageDataAccess.SignalsOrderPreferencesKey, signalsOrder)
    }

    // from RawDataCacheDataAccess
    public async getSignalsOrderPreferencesAsync(): Promise<number[]> {
        return await this.getItemAsync<number[]>(RawDataCacheLocalForageDataAccess.SignalsOrderPreferencesKey)
    }

    // from RawDataCacheDataAccess
    public async deleteSignalsOrderPreferencesAsync(): Promise<void> {
        await this.removeItemAsync(RawDataCacheLocalForageDataAccess.SignalsOrderPreferencesKey)
    }

    // -------------------------------------------------------------------------
    // Preferences of signals visibility
    // -------------------------------------------------------------------------

    // from RawDataCacheDataAccess
    public async setSignalsVisibilityPreferencesAsync(signalsVisibilty: boolean[]): Promise<void> {
        await this.setItemAsync(RawDataCacheLocalForageDataAccess.SignalsVisibilityPreferencesKey, signalsVisibilty)
    }

    // from RawDataCacheDataAccess
    public async getSignalsVisibilityPreferencesAsync(): Promise<boolean[]> {
        return await this.getItemAsync<boolean[]>(RawDataCacheLocalForageDataAccess.SignalsVisibilityPreferencesKey)
    }

    // from RawDataCacheDataAccess
    public async deleteSignalsVisibilityPreferencesAsync(): Promise<void> {
        await this.removeItemAsync(RawDataCacheLocalForageDataAccess.SignalsVisibilityPreferencesKey)
    }

    // -------------------------------------------------------------------------
    // Draft of raw data updates
    // -------------------------------------------------------------------------

    // from RawDataCacheDataAccess
    public async setUpdatedPredictionsDraftAsync(
        nightId: string,
        nightVersionId: string,
        predictions: any,
    ): Promise<void> {
        const key: string = this.getKeyForUpdatedPredictionsDraftItem(nightId, nightVersionId)

        await this.setItemAsync(key, predictions)
    }

    // from RawDataCacheDataAccess
    public async getUpdatedPredictionsDraftAsync(nightId: string, nightVersionId: string): Promise<any> {
        const key: string = this.getKeyForUpdatedPredictionsDraftItem(nightId, nightVersionId)

        return await this.getItemAsync<any>(key)
    }

    // from RawDataCacheDataAccess
    public async deleteUpdatedPredictionsDraftAsync(nightId: string, nightVersionId: string): Promise<void> {
        const key: string = this.getKeyForUpdatedPredictionsDraftItem(nightId, nightVersionId)

        await this.removeItemAsync(key)
    }

    // from RawDataCacheDataAccess
    public async deleteAllUpdatedPredictionsDraftsAsync(): Promise<void> {
        const keys: string[] = await this.getAllKeys()

        if (keys && keys.length != 0) {
            const keysOfUpdatedPredictionsDrafts: string[] = keys.filter((key) =>
                key.startsWith(RawDataCacheLocalForageDataAccess.DraftsPredictionsKeyPrefix),
            )

            keysOfUpdatedPredictionsDrafts.forEach(async (key) => await this.removeItemAsync(key))
        }
    }

    /**
     * Gets the key that identifies the draft of updated predictions for the
     * given night.
     * @param {string} nightId Identifier of the night
     * @param {string} nightVersionId Identifier of the night version
     * @returns the key that identifies the draft of updated predictions for the
     *      given night
     */
    private getKeyForUpdatedPredictionsDraftItem(nightId: string, nightVersionId: string): string {
        return `${RawDataCacheLocalForageDataAccess.DraftsPredictionsKeyPrefix}${nightId}${nightVersionId}`
    }
}
