import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AnalysisStep } from "../../../models/Nights/AnalysisStep"
import { NightAnalysisActionValues } from "../../../models/Nights/NightAnalysisActionValues"
import { NightAnalysisStep } from "../../../models/Nights/NightAnalysisStep"
import { NightAnalysisTemplate } from "../../../models/Nights/NightAnalysisTemplate"
import { NightAnalysisTemplateTypeValues } from "../../../models/Nights/NightAnalysisTemplateTypeValues"
import { Signature } from "../../../models/Signature"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { NotEmptyRule } from "../../../validation/rules/NotEmptyRule"
import { ValidatableObject } from "../../../validation/ValidatableObject"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-night-report-analysis",
    templateUrl: "./night-report-analysis.component.html",
    styleUrls: ["./night-report-analysis.component.sass"],
})
export class NightReportAnalysisComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    @Input() public nightId: string
    @Input() public nightVersionId: string

    @Output() currentAnalysisStepWillChangeEvent: EventEmitter<AnalysisStep> = new EventEmitter<AnalysisStep>()

    public analysisStepInfo: string
    public utcTimeOffsetInMinutes: number
    public impression: ValidatableObject
    public impressionTemplates: NightAnalysisTemplate[] = []
    public recommendation: ValidatableObject
    public recommendationTemplates: NightAnalysisTemplate[] = []
    public lastSignatureSaved: Signature
    public lastNightAnalysisStep: NightAnalysisStep

    // Adapt state of different steps
    public isValidated = false
    public isInterpreted = false
    public isSigned = false

    // Display specific section
    public eAnalysisStep: typeof AnalysisStep = AnalysisStep
    public currentAnalysisStep: AnalysisStep

    // Loading
    public isCreatingStep = false
    public isGettingInterpretationTemplates = false
    public isGettingLastSignature = false

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    public constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.initValidatableObject()
        this.initUtcTimeOffsetInMinutes()
        await this.initStateAsync()
    }

    // -------------------------------------------------------------------------
    // Initialization
    // -------------------------------------------------------------------------

    private initValidatableObject() {
        this.impression = new ValidatableObject(this.translate)
        this.impression.addRule(new NotEmptyRule(this.translate))

        this.recommendation = new ValidatableObject(this.translate)
        this.recommendation.addRule(new NotEmptyRule(this.translate))
    }

    private initUtcTimeOffsetInMinutes() {
        const date = new Date()
        // If time zone = GMT+1 : getTimezoneOffset return -60 => * -1 necessary to correspond to API
        this.utcTimeOffsetInMinutes = date.getTimezoneOffset() * -1
    }

    private async initStateAsync(): Promise<void> {
        const lastAnalysisResponse = this.nightVersionId
            ? await this.sunriseApiService.getLastNightVersionAnalysisStep(this.nightVersionId, null)
            : await this.sunriseApiService.getLastNightAnalysisStep(this.nightId, null)

        // If response is not undefined, it means that doctor can access analysis
        if (lastAnalysisResponse) {
            this.lastNightAnalysisStep = lastAnalysisResponse.data.nightAnalysisStep
            await this.setCurrentAnalysisStep()
        } else {
            this.currentAnalysisStep = AnalysisStep.CannotAccessAnalysis
        }
    }

    // -------------------------------------------------------------------------
    // Set current analysis by doctor step
    // -------------------------------------------------------------------------

    public async setCurrentAnalysisStep(): Promise<void> {
        if (this.lastNightAnalysisStep) {
            this.isSigned = this.lastNightAnalysisStep.actionType == NightAnalysisActionValues.Signature
            this.isInterpreted =
                this.isSigned || this.lastNightAnalysisStep.actionType == NightAnalysisActionValues.Interpretation
            this.isValidated =
                this.isInterpreted ||
                (this.lastNightAnalysisStep.actionType == NightAnalysisActionValues.Validation &&
                    this.lastNightAnalysisStep.isValidated)
        }

        this.currentAnalysisStepWillChangeEvent.emit(this.currentAnalysisStep)

        if (!this.isValidated) {
            this.currentAnalysisStep = AnalysisStep.Validation
        } else if (!this.isInterpreted) {
            this.currentAnalysisStep = AnalysisStep.Interpretation
        } else if (!this.isSigned) {
            this.currentAnalysisStep = AnalysisStep.Signature
        } else {
            this.currentAnalysisStep = AnalysisStep.None
        }

        await this.adaptViewToAnalysisStep()
    }

    private async adaptViewToAnalysisStep(): Promise<void> {
        switch (this.currentAnalysisStep) {
            case AnalysisStep.Validation:
                this.analysisStepInfo = await this.__("analysis_step_info_approve")
                break
            case AnalysisStep.Interpretation:
                this.analysisStepInfo = await this.__("analysis_step_info_interpret")
                await this.getAllNightAnalysisInterpretationTemplate()
                break
            case AnalysisStep.Signature:
                this.analysisStepInfo = await this.__("analysis_step_info_sign")
                await this.getDoctorLastSignatureAsync()
                break
            default:
                this.analysisStepInfo = ""
        }
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public async approveReportAsync(): Promise<void> {
        const approveReportPromise: Promise<NightAnalysisStep> = this.sunriseApiService.createNigthAnalysisStep(
            this.createNightAnalysisStepRequestBody(AnalysisStep.Validation),
        )

        await this.createAnalysisStepAsync(approveReportPromise)
    }

    public async interpretReportAsync(): Promise<void> {
        let isValid: boolean = await this.impression.validateAsync()
        isValid = (await this.recommendation.validateAsync()) && isValid

        if (isValid) {
            const interpretReportPromise: Promise<NightAnalysisStep> = this.sunriseApiService.createNigthAnalysisStep(
                this.createNightAnalysisStepRequestBody(AnalysisStep.Interpretation),
            )

            await this.createAnalysisStepAsync(interpretReportPromise)
        }
    }

    public async signReportAsync(): Promise<void> {
        if (!this.isCreatingStep) {
            const signReportPromise: Promise<NightAnalysisStep> = this.sunriseApiService.createNigthAnalysisStep(
                this.createNightAnalysisStepRequestBody(AnalysisStep.Signature),
            )

            await this.createAnalysisStepAsync(signReportPromise)
        }
    }

    private createNightAnalysisStepRequestBody(analysisStep: AnalysisStep): object {
        const body: object = this.nightVersionId ? { nightVersionId: this.nightVersionId } : { nightId: this.nightId }

        body["utcTimeOffsetInMinutes"] = this.utcTimeOffsetInMinutes

        if (analysisStep == AnalysisStep.Validation) {
            body["validateReport"] = true
        } else if (analysisStep == AnalysisStep.Interpretation) {
            body["interpretation"] = {
                impression: this.impression.value,
                recommendation: this.recommendation.value,
            }
        } else if (analysisStep == AnalysisStep.Signature) {
            body["signatureId"] = this.lastSignatureSaved.signatureId
        }

        return body
    }

    private async createAnalysisStepAsync(promise: Promise<NightAnalysisStep>) {
        this.isCreatingStep = true

        try {
            this.lastNightAnalysisStep = await promise
        } catch (error) {
            this.showErrorOccurred()
        } finally {
            this.isCreatingStep = false
            this.setCurrentAnalysisStep()
        }
    }

    public onTemplateOptionSelected(event: any) {
        if (event.target.id == "selectTemplateType") {
            const selectedTemplate: NightAnalysisTemplate = this.impressionTemplates.find(
                (template) => template.nightAnalysisTemplateId == event.target.value,
            )
            this.setInterpretationValue(selectedTemplate, this.impression)
        } else if (event.target.id == "selectTemplateRecommendation") {
            const selectedTemplate: NightAnalysisTemplate = this.recommendationTemplates.find(
                (template) => template.nightAnalysisTemplateId == event.target.value,
            )
            this.setInterpretationValue(selectedTemplate, this.recommendation)
        }
    }

    private setInterpretationValue(template: NightAnalysisTemplate, validatableObject: ValidatableObject) {
        if (template) {
            validatableObject.value = template.text
        } else {
            validatableObject.value = ""
        }
    }

    public async onAnalysisSectionClick(event): Promise<void> {
        if (event.target.id == "interpretationStep") {
            if (this.currentAnalysisStep == AnalysisStep.Interpretation) {
                this.currentAnalysisStep = AnalysisStep.None
                this.analysisStepInfo = ""
            } else {
                this.currentAnalysisStep = AnalysisStep.Interpretation
                this.analysisStepInfo = await this.__("analysis_step_info_interpretationAlreadyDone")
                await this.getAllNightAnalysisInterpretationTemplate()
            }
        } else if (event.target.id == "signatureStep") {
            if (this.currentAnalysisStep == AnalysisStep.Signature) {
                this.currentAnalysisStep = AnalysisStep.None
                this.analysisStepInfo = ""
            } else {
                this.currentAnalysisStep = AnalysisStep.Signature
                if (!this.isSigned) {
                    this.analysisStepInfo = await this.__("analysis_step_info_sign")
                } else {
                    this.analysisStepInfo = await this.__("analysis_step_info_signatureAlreadyDone")
                }
                await this.getDoctorLastSignatureAsync()
            }
        }
    }

    public async getAllNightAnalysisInterpretationTemplate(): Promise<void> {
        this.isGettingInterpretationTemplates = true

        const templates: NightAnalysisTemplate[] = await this.sunriseApiService.getAllNightAnalysisTemplate()
        this.impressionTemplates = templates.filter((t) => t.type == NightAnalysisTemplateTypeValues.Impression)
        this.recommendationTemplates = templates.filter((t) => t.type == NightAnalysisTemplateTypeValues.Recommendation)

        const nightAnalysisLatestInterpretation: NightAnalysisStep = this.nightVersionId
            ? (
                  await this.sunriseApiService.getLastNightVersionAnalysisStep(
                      this.nightVersionId,
                      NightAnalysisActionValues.Interpretation,
                  )
              ).data.nightAnalysisStep
            : (
                  await this.sunriseApiService.getLastNightAnalysisStep(
                      this.nightId,
                      NightAnalysisActionValues.Interpretation,
                  )
              ).data.nightAnalysisStep

        if (nightAnalysisLatestInterpretation) {
            const latestImpression: NightAnalysisTemplate = {
                nightAnalysisTemplateId: "LastImpressionSaved",
                title: await this.__("analysis_step_info_lastImpressionsSavedLabel"),
                text: nightAnalysisLatestInterpretation.impression,
            }
            this.impressionTemplates.splice(0, 0, latestImpression)
            this.setInterpretationValue(latestImpression, this.impression)

            const latestRecommendation: NightAnalysisTemplate = {
                nightAnalysisTemplateId: "LastRecommendationSaved",
                title: await this.__("analysis_step_info_lastRecommendationsSavedLabel"),
                text: nightAnalysisLatestInterpretation.recommendation,
            }
            this.recommendationTemplates.splice(0, 0, latestRecommendation)
            this.setInterpretationValue(latestRecommendation, this.recommendation)
        }

        this.isGettingInterpretationTemplates = false
    }

    private async getDoctorLastSignatureAsync(): Promise<void> {
        try {
            this.isGettingLastSignature = true

            this.lastSignatureSaved = await this.sunriseApiService.getLastSignatureSavedAsync()

            const res = await this.sunriseApiService.getMeAsync()
            const account = res["data"]["account"]
            const signatureLabel: HTMLElement = document.getElementById("signatureLabel")
            signatureLabel.innerHTML = await this.__("analysis_signed_as", {
                firstName: account["firstName"],
                lastName: account["lastName"],
            })

            this.isGettingLastSignature = false
        } catch {
            this.lastSignatureSaved = null

            this.isGettingLastSignature = false
        }
    }

    public navigateToDefineSignature(): void {
        this.router.navigate(["main/account/signature"])
    }
}
