import { Component, Input, OnInit } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { Night } from "../../../../models/Nights/Night"
import { UnitsAndFormatsPreferences } from "../../../../models/UnitsAndFormatsPreferences"
import { AuthService } from "../../../../services/auth.service"
import { TranslationUtilities } from "../../../../utilities/TranslationUtilities"
import {
    doesUserPrefersH12HourFormat,
    formatAMPM,
    getHourFormatLabel,
} from "../../../../utilities/UnitsAndFormatsUtilities"
import { QuestionnairesComponent } from "../questionnaires.component"

@Component({
    selector: "app-mctq-questionnaire",
    templateUrl: "./mctq-questionnaire.component.html",
    styleUrls: ["../../night-report.component.sass"],
})
export class MctqQuestionnaireComponent extends QuestionnairesComponent implements OnInit {
    @Input() public night: Night
    @Input() public answerDate: string

    public scores: any
    public hourFormatLabel: string

    constructor(
        protected readonly translate: TranslateService,
        protected readonly authService: AuthService,
    ) {
        super(translate)
    }

    public async ngOnInit(): Promise<void> {
        this.scores = this.night.questionnaireAnswers.scores

        this.hourFormatLabel = getHourFormatLabel(this.authService.getPreferences(), this.translate.currentLang)

        await this.initializeMCTQVariablesAsync()
    }

    /**
     * Initialize the scores computed in the MCTQ questionnaire.
     */
    private async initializeMCTQVariablesAsync(): Promise<void> {
        const elementsToUpdate = [
            document.getElementById("sleepLatencyOnWorkdaysVariable"),
            document.getElementById("sleepInertialOnWorkdaysVariable"),
            document.getElementById("sleepOnsetOnWorkdaysVariable"),
            document.getElementById("sleepDurationOnWorkdaysVariable"),

            document.getElementById("sleepLatencyOnFreedaysVariable"),
            document.getElementById("sleepInertialOnFreedaysVariable"),
            document.getElementById("sleepOnsetOnFreedaysVariable"),
            document.getElementById("sleepDurationOnFreedaysVariable"),

            document.getElementById("averageWeeklySleepDurationVariable"),
        ]

        const valuesToUpdate = [
            this.scores["sleepLatencyOnWorkdaysInMinutes"],
            this.scores["sleepInertiaOnWorkdaysInMinutes"],
            this.scores["sleepOnsetOnWorkdaysInMinutes"],
            this.scores["sleepDurationOnWorkdaysInMinutes"],

            this.scores["sleepLatencyOnFreedaysInMinutes"],
            this.scores["sleepInertiaOnFreedaysInMinutes"],
            this.scores["sleepOnsetOnFreedaysInMinutes"],
            this.scores["sleepDurationOnFreedaysInMinutes"],

            this.scores["averageWeeklySleepDurationInMinutes"],
        ]

        for (let i = 0; i < elementsToUpdate.length; i++) {
            elementsToUpdate[i].innerHTML =
                valuesToUpdate[i] === undefined
                    ? await this.__("common_unknown")
                    : this.formatTimeMctq(valuesToUpdate[i])
        }

        const elementsToUpdateWithHourFormat = [
            document.getElementById("sleepTimeOnWorkdaysVariable"),
            document.getElementById("sleepEndOnWorkdaysVariable"),
            document.getElementById("midSleepOnWorkdaysVariable"),
            document.getElementById("sleepTimeOnFreedaysVariable"),
            document.getElementById("sleepEndOnFreedaysVariable"),
            document.getElementById("midSleepOnFreedaysVariable"),
            document.getElementById("chronotypeVariable"),
        ]

        const valuesToUpdateWithHourFormat = [
            this.scores["sleepTimeOnWorkdaysInMinutes"],
            this.scores["sleepEndOnWorkdaysInMinutes"],
            this.scores["midSleepOnWorkdaysInMinutes"],
            this.scores["sleepTimeOnFreedaysInMinutes"],
            this.scores["sleepEndOnFreedaysInMinutes"],
            this.scores["midSleepOnFreedaysInMinutes"],
            this.scores["chronotypeInMinutes"],
        ]

        for (let i = 0; i < elementsToUpdateWithHourFormat.length; i++) {
            elementsToUpdateWithHourFormat[i].innerHTML =
                valuesToUpdateWithHourFormat[i] === undefined
                    ? await this.__("common_unknown")
                    : this.formatHour(this.authService.getPreferences(), valuesToUpdateWithHourFormat[i])
        }
    }

    private formatHour(userUnitsAndFormatsPreferences: UnitsAndFormatsPreferences, strDate: string): string {
        if (doesUserPrefersH12HourFormat(userUnitsAndFormatsPreferences)) {
            return formatAMPM(strDate)
        } else {
            // Default or hourFormat == H24
            return this.formatTimeMctq(strDate)
        }
    }

    /**
     * Format a string representing a date to a string of format: hh:mm
     * @param {string} strDate Date to format
     * @return the formatted date
     */
    private formatTimeMctq(strDate: string): string {
        const totalMinutes: number = parseInt(strDate)
        const totalHours: number = Math.floor(totalMinutes / 60)
        const remainingMinutes: number = Math.round(totalMinutes - totalHours * 60)

        return `${totalHours < 10 ? "0" + totalHours : totalHours}:${
            remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes
        }`
    }

    public formatMctqAnswer(question: any): string {
        const answer: any = this.getAnswer(question, this.night.questionnaireAnswers)

        let formattedAnswer: string

        const questionNumberToFormat: string[] = [
            "QuestionS004Q002a",
            "Question_MCTQ_Workdays_ReadyToSleep",
            "QuestionS004Q002d",
            "QuestionS004Q003a",
            "Question_MCTQ_Freedays_ReadyToSleep",
            "QuestionS004Q003d",
            "QuestionS005Q001",
            "QuestionS005Q002",
        ]

        if (
            questionNumberToFormat.find((questionNumber) => questionNumber == question.questionNumber) &&
            answer != "-"
        ) {
            formattedAnswer = this.formatHour(this.authService.getPreferences(), answer)
        } else if (question.textFormat == "minutes" && answer != "-") {
            formattedAnswer = this.formatTimeMctq(answer)
        } else {
            formattedAnswer = answer
        }

        return formattedAnswer
    }

    public displayHourFormatLabel(text: string): string {
        return text.replace(/{{format}}/g, this.hourFormatLabel)
    }

    /* Utils */
    private async __(key: string): Promise<string> {
        return await TranslationUtilities.getTranslationAsync(this.translate, key)
    }
}
