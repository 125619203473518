import { TranslateService } from "@ngx-translate/core"
import { QuestionnaireAnswerLine } from "../../../models/Questionnaires/QuestionnaireAnswerLine"
import { QuestionnaireQuestion } from "../../../models/Questionnaires/QuestionnaireQuestion"
import { QuestionType } from "../../../models/Questionnaires/QuestionType"
import { StringsUtilities } from "../../../utilities/StringsUtilities"

export class QuestionnairesComponent {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    public constructor(protected readonly translate: TranslateService) {}

    // -------------------------------------------------------------------------
    // Questionnaire utils
    // -------------------------------------------------------------------------

    public isRangeQuestion(questionType: QuestionType): boolean {
        return questionType == QuestionType.Range
    }

    public isRadioQuestion(question: QuestionnaireQuestion): boolean {
        return question.questionType == QuestionType.Radio && question.radioLabelsEn.length > 2
    }

    public getRadioAnswer(question: QuestionnaireQuestion, questionnaireAnswers: QuestionnaireAnswerLine[]): string {
        const answerFound: QuestionnaireAnswerLine = this.getAnswerFromQuestionNumber(
            question.questionNumber,
            questionnaireAnswers,
        )

        if (!answerFound || !answerFound.answerValue) return "-"

        return this.getAdaptedRadioLabel(question, parseInt(answerFound.answerValue))
    }

    private getAdaptedRadioLabel(question: QuestionnaireQuestion, radioValue: number): string {
        const indexArrayLabels: number = question["radioValues"].indexOf(radioValue)
        const radioLabels: [] =
            question["radioLabels" + StringsUtilities.GetUpperCurrentLanguage(this.translate.currentLang)]

        return radioLabels[indexArrayLabels]
    }

    public getAdaptedRanges(question: QuestionnaireQuestion): string[] {
        return question["rangeLabels" + StringsUtilities.GetUpperCurrentLanguage(this.translate.currentLang)]
    }

    public isAnswer(
        question: QuestionnaireQuestion,
        questionnaireAnswers: QuestionnaireAnswerLine[],
        index: number,
    ): boolean {
        const answerFound: QuestionnaireAnswerLine = this.getAnswerFromQuestionNumber(
            question.questionNumber,
            questionnaireAnswers,
        )

        return answerFound && answerFound.answerValue == index.toString()
    }

    public getAnswer(question: QuestionnaireQuestion, questionnaireAnswers: QuestionnaireAnswerLine[]): string {
        const answerFound: QuestionnaireAnswerLine = this.getAnswerFromQuestionNumber(
            question.questionNumber,
            questionnaireAnswers,
        )

        if (!answerFound || !answerFound.answerValue) return "-"

        return answerFound.answerValue
    }

    private getAnswerFromQuestionNumber(
        questionNumber: string,
        questionnaireAnswers: QuestionnaireAnswerLine[],
    ): QuestionnaireAnswerLine {
        return questionnaireAnswers.find((answer) => answer.questionNumber == questionNumber)
    }

    // -------------------------------------------------------------------------
    // UI utils
    // -------------------------------------------------------------------------

    public toggleReportSection(event: any): void {
        const section: HTMLElement = event["target"]["parentElement"]["parentElement"]

        section.classList.toggle("report-section--minified")
    }
}
