<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'setPassword_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--tiny content">
        <p>{{ 'setPassword_recommendation_label' | translate }}</p>
        <app-password-field *ngIf="password"
                            [validatableObject]="password"
                            (enterPressed)="savePasswordAsync()"></app-password-field>

        <app-password-field *ngIf="passwordConfirmation"
                            [validatableObject]="passwordConfirmation"
                            (enterPressed)="savePasswordAsync()"></app-password-field>

        <div class="field-container two-buttons-container">
            <app-button
                [loading]="isSavingPassword"
                (clicked)="savePasswordAsync()"
            >
                {{ 'common_save' | translate }}
            </app-button>

            <div>
                <a (click)="navigateToMainPage()" class="button-like">{{ 'common_skip' | translate }}</a>
            </div>
        </div>
    </div>
</main>
