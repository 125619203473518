<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'demoSettings_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateBack()'>{{ 'account_pageTitle' | translate }}</a> / {{'myAccount_demoSettings' | translate }}
    </div>

    <div class="row row--guttered row--tiny content" [style.padding-top]="0">
        <div class="row">
            <div class="list-row list-row--title">
                <div class="col">{{ 'demoSettings_reset' | translate }}</div>
            </div>

            <div class="list-row list-row--no-border">
                <p [innerHTML]="'demoSettings_reset_explanation' | translate | replaceLineBreaks"></p>
            </div>

            <div class="list-row list-row--no-border">
                <div class="col">
                    <app-button
                        [loading]="isResettingAccount"
                        (clicked)="resetAccountAsync()"
                    >
                        {{ 'demoSettings_reset_button' | translate }}
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</main>
