import { Component, HostListener, ViewEncapsulation } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { constants } from "../../config/constants"
import { Office } from "../../models/Office"
import { Patient } from "../../models/Patient"
import { PatientListFilter } from "../../models/PatientListFilter"
import { PortalNotificationType } from "../../models/PortalNotification"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { getDateFormatLabel } from "../../utilities/UnitsAndFormatsUtilities"
import { SortedList } from "../sortedLists"

@Component({
    selector: "app-sharing-management",
    templateUrl: "./sharing-management.component.html",
    styleUrls: ["./sharing-management.component.sass"],
    encapsulation: ViewEncapsulation.None,
})
export class SharingManagementComponent extends SortedList {
    // -------------------------------------------------------------------------
    // Instance variables
    // -------------------------------------------------------------------------

    public patients: Patient[] = []

    public numberUnsharedPatients = 0

    public currentFilter: PatientListFilter = PatientListFilter.AllPatients
    public searchFilter = ""
    public filterByOffice: string
    public dateFormatLabel: string

    public autocompleteValues: string[] = []
    public offices: Office[]

    public isLoading = true
    public isMobile: boolean

    /* Enum */
    public eFilterType: typeof PatientListFilter = PatientListFilter

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)
        this.translate.onLangChange.subscribe(() => this.updateLanguage())

        this.isMobile = window.innerWidth < 1330
    }

    public async ngOnInit(): Promise<void> {
        if (!this.hasPatientSharingRole) {
            this.navigateToMainPage()

            return
        }

        this.patients = await this.getPatientsAsync()
        await this.fillOfficesAsync()

        this.refreshNumberOfUnsharedPatients()
        this.fillAutocompleteValues()

        this.initSortingValues("lastName")

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Fill patients list
    // -------------------------------------------------------------------------

    private async getPatientsAsync(): Promise<Patient[]> {
        try {
            const res = await this.sunriseApiService.getPatientsAsync(true, true)

            return res["data"]["patients"]
        } catch (error) {
            this.handleError(error)

            this.showErrorOccurred()
        }
    }

    private async fillOfficesAsync(): Promise<void> {
        try {
            const officesList: Office[] = await this.sunriseApiService.getDoctorOfficesAsync()

            if (officesList) {
                const sortedOffices = officesList.sort((officeA, officeB) =>
                    officeA.officeName >= officeB.officeName ? 1 : -1,
                )

                this.offices = sortedOffices
                this.filterByOffice = "all"

                this.patients.forEach((patient: Patient) => {
                    const office: Office = patient.office

                    if (office) {
                        office.canEditOffice = officesList.map((office) => office.officeId).includes(office.officeId)
                    } else {
                        patient.office = { officeId: "none", canEditOffice: true }
                    }
                })
            }
        } catch (error) {
            this.showErrorOccurred()
        }
    }

    // -------------------------------------------------------------------------
    // Utils
    // -------------------------------------------------------------------------

    public refreshNumberOfUnsharedPatients(): void {
        this.numberUnsharedPatients = this.patients.filter(
            (patient) =>
                patient.sharedLinks.length === 0 && (patient.office == undefined || patient.office.officeId === "none"),
        ).length
    }

    private fillAutocompleteValues(): void {
        this.patients.forEach((patient) => {
            patient.sharedLinks.forEach((link) => {
                if (link.canBeUnlinked && !this.autocompleteValues.includes(link.email)) {
                    this.autocompleteValues.push(link.email)
                }
            })
        })
    }

    public getProposalsForPatient(patient: Patient): string[] {
        return this.autocompleteValues.filter((value) => !patient.sharedLinks.map((link) => link.email).includes(value))
    }

    public sortedSharedLink(
        sharedLinks: { email: string; canBeUnlinked: boolean }[],
    ): { email: string; canBeUnlinked: boolean }[] {
        return sharedLinks.sort((linkA, linkB) => linkA.email.localeCompare(linkB.email))
    }

    // -------------------------------------------------------------------------
    // Edit shared doctors
    // -------------------------------------------------------------------------

    public async addSharedDoctorAsync(email: string, patient: Patient, input: HTMLInputElement): Promise<void> {
        if (!email) return

        email = email.toLowerCase()

        if (!constants.validation.patterns.email.test(email)) {
            input.classList.add("input--invalid")
            return
        }

        try {
            const newLinks = await this.sunriseApiService.sharePatientAsync(patient.accountId, email)

            patient.sharedLinks = newLinks
            input.value = null

            this.refreshNumberOfUnsharedPatients()
            this.fillAutocompleteValues()
        } catch (error) {
            if (error.status === 400) {
                this.showNotification("sharingManagement_alreadyLinked_error", PortalNotificationType.Error)
            } else {
                this.showErrorOccurred()
            }
        }
    }

    public async removeSharedDoctorAsync(email: string, patient: Patient): Promise<void> {
        try {
            const newLinks = await this.sunriseApiService.deleteSharedPatientAsync(patient.accountId, email)

            patient.sharedLinks = newLinks

            this.refreshNumberOfUnsharedPatients()
        } catch (e) {
            this.showErrorOccurred()
        }
    }

    public async updatePatientOfficeAsync(patient: Patient, officeId: string): Promise<void> {
        try {
            const patientId: string = patient.accountId

            if (officeId !== "none") {
                await this.sunriseApiService.addPatientToOfficeAsync(officeId, patientId)
            } else {
                await this.sunriseApiService.removePatientFromOfficeAsync(patientId)
            }

            const newOffice = this.offices.find((office) => office.officeId === officeId) || { officeId: "none" }
            newOffice.canEditOffice = true

            patient.office = newOffice

            this.refreshNumberOfUnsharedPatients()
        } catch {
            this.showErrorOccurred()
        }
    }

    // -------------------------------------------------------------------------
    // Filter patients
    // -------------------------------------------------------------------------

    public async updateMainFilterAsync(filterType: string): Promise<void> {
        if (this.isLoading || filterType == this.currentFilter) return

        this.currentFilter = filterType as PatientListFilter
    }

    // -------------------------------------------------------------------------
    // Event listener
    // -------------------------------------------------------------------------

    @HostListener("window:resize", [])
    public onResize() {
        this.isMobile = window.innerWidth < 1330
    }

    // -------------------------------------------------------------------------
    // Translation helpers
    // -------------------------------------------------------------------------

    protected updateLanguage(): void {
        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)
    }
}
