<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'accountDeletionRequestConfirmed_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--smaller content" *ngIf="!tokenExpired">
        <p>{{ 'accountDeletionRequestConfirmed_title' | translate }}</p>
        <p>{{ 'accountDeletionRequestConfirmed_subtitle' | translate }}</p>
    </div>

    <div class="row row--guttered row--smaller content" *ngIf="tokenExpired">
        <p>{{ 'accountDeletionRequestConfirmed_error_title' | translate }}</p>
        <p>{{ 'accountDeletionRequestConfirmed_error_subtitle' | translate }}</p>
    </div>
</main>
