<!-- Page content -->
<main>
    <!-- Page title -->
    <div class="row row--guttered-h title-row">
        <div class="title-row__left-content">
            <h1 class="title-h1">{{ "nightReport_page_title" | translate }} ({{ formatDate(night.endDateTime.date) }})</h1>

            <drop-down-menu *ngIf="!isSampleReport && dropDownVersionItems.length > 0"
                            [selectedItem]="dropDownVersionSelectedItem"
                            [items]="dropDownVersionItems"
                            (selectedItemChange)="navigateToNightVersion($event.nightVersionId)">
            </drop-down-menu>
        </div>

        <div class="title-row__left-content">
            <app-button
                *ngIf="!isSampleReport && (night.scoringRule == '1A' || !night.scoringRule)"
                (clicked)="openRawDataViewer('1A')"
            >
                {{ 'sleep_study_openInViewer1A_button' | translate }}
            </app-button>

            <app-button
                *ngIf="!isSampleReport && (night.scoringRule == '1B' || !night.scoringRule)"
                (clicked)="openRawDataViewer('1B')"
            >
                {{ 'sleep_study_openInViewer1B_button' | translate }}
            </app-button>
        </div>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <!-- Back buttons -->
    <div *ngIf="patient" class="row row--guttered">
        <a [routerLink]="['/main/patients']">{{ 'patientsList_page_title' | translate }}</a> /
        <span *ngIf="!isSampleReport"><a (click)="navigateBack()">{{ patientName }}</a> /</span>
        {{ "nightReport_page_title" | translate }} ({{ formatDate(night.endDateTime.date) }})
    </div>
    <!-- Back buttons -->

    <app-night-report-analysis *ngIf="!isSampleReport"
                               [nightId]="night.nightId"
                               [nightVersionId]="nightVersionId"
                               (currentAnalysisStepWillChangeEvent)="onNightAnalysisChanged($event)"></app-night-report-analysis>
    <!-- Page title -->

    <!-- Sleep Study Report PDF -->
    <app-night-report-embed-pdf *ngIf="night"
                                #nightReportEmbedPDF
                                [isSampleReport]="isSampleReport"
                                [nightId]="night.nightId"
                                [nightVersionId]="nightVersionId"
                                [patient]="patient"
                                [isExportingReport]="isExportingReport"
                                (exportReportEvent)="exportReport()"></app-night-report-embed-pdf>
    <!-- Sleep Study Report PDF -->

    <!-- Admin informations -->
    <app-night-report-section *ngIf="night?.nightDataTemplate?.adminInformation && isAdmin"
                              [nightDataList]="night.nightDataTemplate.adminInformation"
                              [sectionName]="'nightReport_adminInformation_section'"></app-night-report-section>
    <!-- Admin informations -->

    <!-- Child questionnaire -->
    <app-child-questionnaire [night]="night"
                             [answerDate]="formatDate(night?.questionnaireAnswerDate?.toString())"
                             *ngIf="night?.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire002'"></app-child-questionnaire>
    <!-- Child questionnaire -->

    <!-- ESS questionnaire -->
    <app-ess-questionnaire [night]="night"
                           [answerDate]="formatDate(night?.questionnaireAnswerDate?.toString())"
                           *ngIf="night?.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire001'"></app-ess-questionnaire>
    <!-- ESS questionnaire -->

    <!-- ISI questionnaire -->
    <app-isi-questionnaire [night]="night"
                           [answerDate]="formatDate(night?.questionnaireAnswerDate?.toString())"
                           *ngIf="night?.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire001'"></app-isi-questionnaire>
    <!-- ISI questionnaire -->

    <!-- MCTQ questionnaire -->
    <app-mctq-questionnaire [night]="night"
                            [answerDate]="formatDate(night?.questionnaireAnswerDate?.toString())"
                            *ngIf="night?.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire001'"></app-mctq-questionnaire>
    <!-- MCTQ questionnaire -->

    <div class="components-versions">
        {{componentsVersionsContent}}
    </div>
</main>
