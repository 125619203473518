import { ChangeDetectorRef, Component, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { AccountActionType } from "../../../models/AccountActionType"
import { Doctor } from "../../../models/Doctor"
import { PortalNotificationType } from "../../../models/PortalNotification"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { AccountFieldsComponent } from "../../account-fields/account-fields.component"
import { BaseComponent } from "../../base.component"

@Component({
    selector: "app-personal-info",
    templateUrl: "./personal-info.component.html",
})
export class PersonalInfoComponent extends BaseComponent {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isLoading = true
    public isUpdatingProfile = false

    @ViewChild("updateProfileFields") public updateProfileFields: AccountFieldsComponent

    /* Enum */
    public eAccountActionType: typeof AccountActionType = AccountActionType

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        protected readonly changesDetector: ChangeDetectorRef,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public navigateBack(): void {
        this.router.navigate(["main/account"])
    }

    public async updateProfile(): Promise<void> {
        const isValid: boolean = await this.updateProfileFields.validateObjectsAsync()

        if (isValid) {
            const doctor: Doctor = {
                firstName: this.updateProfileFields.firstName.value,
                lastName: this.updateProfileFields.lastName.value,
                email: this.updateProfileFields.emailAddress.value,
                phoneNumber: this.updateProfileFields.phoneNumberWithPrefix.value,
            }

            try {
                this.isUpdatingProfile = true
                await this.sunriseApiService.updateDoctorAsync(doctor)

                this.showNotification("account_update_profile_success_notification", PortalNotificationType.Success)
            } catch (error) {
                this.showErrorOccurred()
            } finally {
                this.isUpdatingProfile = false
            }
        }
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    private async fillInAccountDataAsync(): Promise<void> {
        const account = await this.getAccountDataAsync()

        if (!_.isEmpty(account)) {
            // Used to update phone number input
            const phoneNumber: any = account["phoneNumber"]
            phoneNumber["needToUpdateInput"] = true

            this.updateProfileFields.firstName.value = account["firstName"]
            this.updateProfileFields.lastName.value = account["lastName"]
            this.updateProfileFields.emailAddress.value = account["email"]
            this.updateProfileFields.phoneNumberWithPrefix.value = phoneNumber
            this.updateProfileFields.country.value = account["countryNumber"]
        }

        this.changesDetector.detectChanges()
        this.isLoading = false
    }

    public async afterSignUpFieldsInit(): Promise<void> {
        await this.fillInAccountDataAsync()
    }

    private async getAccountDataAsync(): Promise<any> {
        try {
            const res: any = await this.sunriseApiService.getMeAsync()

            return res["data"]["account"]
        } catch (error) {
            this.handleError(error)
        }
    }
}
