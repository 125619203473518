import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { NightAnalysisTemplate } from "../../../../models/Nights/NightAnalysisTemplate"
import { AuthService } from "../../../../services/auth.service"
import { NotificationsService } from "../../../../services/notifications.service"
import { SunriseApiService } from "../../../../services/sunrise-api.service"
import { RoutesUtilities } from "../../../../utilities/RoutesUtilities"
import { BaseComponent } from "../../../base.component"

@Component({
    selector: "app-update-template",
    templateUrl: "./update-template.component.html",
})
export class UpdateTemplateComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    public templateToUpdate: NightAnalysisTemplate
    public isLoading = true

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        protected readonly route: ActivatedRoute,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    public async ngOnInit(): Promise<void> {
        await this.initializeTemplateDataAsync()
    }

    // -------------------------------------------------------------------------
    // Initialization
    // -------------------------------------------------------------------------

    private async initializeTemplateDataAsync(): Promise<void> {
        const templateId: string = RoutesUtilities.getRouteParam(this.route, "templateId")

        try {
            this.templateToUpdate = await this.sunriseApiService.getTemplateAsync(templateId)
        } catch (error) {
            this.showErrorOccurred()
        }
        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public navigateToMyAccount(): void {
        this.router.navigate(["main/account"])
    }

    public navigateToTemplatesList(): void {
        this.router.navigate(["main/account/templates-list"])
    }
}
