<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'myAccount_security' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateBack()'>{{ 'account_pageTitle' | translate }}</a> / {{'myAccount_security' | translate }}
    </div>

    <div class="row row--guttered row--tiny content" *ngIf="!isLoading">
        <app-switch-button [checked]="useEmailTwoFA"
                           [label]="'security_email2FA_switch'"
                           (checkboxChecked)="switchEmailTwoFAAsync($event)"></app-switch-button>

        <div class="email-verification-container" [style.display]="emailTwoFANeedVerification ? 'block' : 'none'">
            <p class="small">{{ 'security_email2FA_emailSent' | translate }}</p>

            <div class="field-container" [class.field-container--errored]="verificationCode.hasError">
                <input class="field" type="text" placeholder="{{ 'security_verificationCode' | translate }}" [(ngModel)]="verificationCode.value">
                <small class="error-text" [innerHTML]="verificationCode.errorString"></small>

                <app-delay-button #resendVerificationCodeButton
                                  [defaultText]="'signIn_action_resendVerificationCode'"
                                  [delayText]="'signIn_action_resendEmail'"
                                  [delayInSeconds]="disableButtonTimeInSeconds"
                                  (buttonClickedEvent)="resendVerificationCode()"
                ></app-delay-button>
            </div>

            <div class="field-container">
                <app-button
                    [loading]="isUpdatingSecurity"
                    (clicked)="updateSecurityAsync()"
                >
                    {{ 'security_verificationCode_verify' | translate }}
                </app-button>
            </div>
        </div>
    </div>

    <div *ngIf='isLoading' class="row row--guttered row--tiny content">
        {{ 'account_loading' | translate }}
    </div>
</main>
