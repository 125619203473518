import { Pipe, PipeTransform } from "@angular/core"
import { Patient } from "../../models/Patient"
import { PatientListFilter } from "../../models/PatientListFilter"

@Pipe({
    name: "sharingManagementFilter",
})
export class SharingManagementFilterPipe implements PipeTransform {
    transform(
        patients: Patient[],
        filterText: string,
        searchFilter: PatientListFilter,
        filterByOffice: string,
    ): Patient[] {
        filterText = filterText.toLowerCase()

        return patients.filter(
            (patient) =>
                this.filterBySharingManagementFilter(patient, searchFilter) &&
                this.filterByInputText(patient, filterText) &&
                this.filterByOffice(patient, filterByOffice),
        )
    }

    private filterBySharingManagementFilter(patient: Patient, searchFilter: PatientListFilter): boolean {
        return (
            // All patients: do not filter the patient list.
            searchFilter === PatientListFilter.AllPatients ||
            // Filter on patients that have no shared links or no office.
            (searchFilter === PatientListFilter.Unshared &&
                patient.sharedLinks.length === 0 &&
                (patient.office == undefined || patient.office.officeId === "none"))
        )
    }

    private filterByInputText(patient: Patient, inputText: string): boolean {
        // Filter on first name, last name or customIdentifier.
        return (
            `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(inputText) ||
            `${patient.lastName} ${patient.firstName}`.toLowerCase().includes(inputText) ||
            patient.customIdentifier?.toLowerCase().includes(inputText)
        )
    }

    private filterByOffice(patient: Patient, filterByOffice: string): boolean {
        // Filter on office.
        return filterByOffice == undefined || filterByOffice == "all" || patient.office?.officeId == filterByOffice
    }
}
