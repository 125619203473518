import { Directive, ElementRef, ViewChild } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { enumContains } from "../utilities/enumContains"
import { Lang } from "../utilities/Lang"
import { TranslationUtilities } from "../utilities/TranslationUtilities"

@Directive()
export abstract class TranslatableComponent {
    // -------------------------------------------------------------------------
    // Variables
    // -------------------------------------------------------------------------

    public languages: string[] = ["fr", "en", "nl"]
    public langSubLinksList: string[]

    @ViewChild("langLink") langLinkElement: ElementRef

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    protected constructor(protected readonly translate: TranslateService) {
        this.getPreferredLanguage()

        this.translate.onLangChange.subscribe(() => this.updateBaseLanguage())
    }

    // -------------------------------------------------------------------------
    // Translation helpers
    // -------------------------------------------------------------------------

    /**
     * Define the language to use
     * @param lang Language code (two-character code)
     */
    public useLanguage(lang?: string): void {
        let currentLang: string = !_.isEmpty(lang) ? lang.toLowerCase() : this.getBrowserLanguage()

        if (!enumContains(Lang, currentLang)) currentLang = Lang.En

        localStorage.setItem("currentLanguage", currentLang)
        this.translate.use(currentLang)
        this.translate.currentLang = currentLang
    }

    public getPreferredLanguage(): string {
        let lang: string = localStorage.getItem("currentLanguage")

        if (_.isEmpty(lang)) {
            lang = this.getBrowserLanguage()
        }

        if (!enumContains(Lang, lang)) lang = Lang.En

        localStorage.setItem("currentLanguage", lang)
        this.translate.use(lang)
        this.translate.currentLang = lang

        return lang
    }

    private getBrowserLanguage(): string {
        const lang: string = (navigator.languages && navigator.languages[0]) || navigator.language

        return lang ? lang.substring(0, 2) : ""
    }

    /**
     * This method is called everytime the language is updated.
     * Override it in child classes to perform soma action when the language is updated.
     */
    protected updateBaseLanguage(): void {
        this.updateLangLink()
    }

    /**
     * Function used to update the lang link in the top bar
     */
    private updateLangLink(): void {
        const currentLang: string = this.translate.currentLang

        if (this.langLinkElement) {
            this.langLinkElement.nativeElement.innerText = currentLang

            this.langSubLinksList = this.languages.filter((lang) => lang != currentLang)
        }
    }

    /**
     * Shortcut to get the text corresponding to the given key.
     * @param {string} key Key identifying the text
     * @param params Params to update in text
     * @return the text
     */
    protected async __(key: string, params: object = null): Promise<string> {
        try {
            this.getPreferredLanguage()

            return await TranslationUtilities.getTranslationAsync(this.translate, key, params)
        } catch (error) {
            console.log(error)
        }
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    /**
     * Delay
     * @param {number} ms Deil in milliseconds
     */
    protected async delay(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(() => resolve(), ms))
    }
}
