<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'myAccount_updatePassword' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateBack()'>{{ 'account_pageTitle' | translate }}</a> / {{ 'myAccount_updatePassword' | translate }}
    </div>

    <div class="row row--guttered row--tiny content" [style.paddingTop]="0" *ngIf="!isLoading">
        <app-password-field class="validatable-container"
                            *ngIf="oldPassword"
                            [validatableObject]="oldPassword"
                            (enterPressed)="updatePasswordAsync()"></app-password-field>

        <div class="two-field-container-per-row" [style.paddingTop]="'1.5rem'">
            <app-password-field class="validatable-container"
                                *ngIf="newPassword"
                                [validatableObject]="newPassword"
                                (enterPressed)="updatePasswordAsync()"></app-password-field>

            <app-password-field class="validatable-container"
                                *ngIf="newPasswordConfirmation"
                                [validatableObject]="newPasswordConfirmation"
                                (enterPressed)="updatePasswordAsync()"></app-password-field>
        </div>

        <div class="field-container" [style.paddingTop]="'2rem'">
            <app-button
                [loading]="isUpdatingPassword"
                (clicked)="updatePasswordAsync()"
            >
                {{ 'updatePassword_update_button' | translate }}
            </app-button>
        </div>
    </div>

    <div *ngIf="isLoading" class="row row--guttered row--tiny content">
        {{ 'account_loading' | translate }}
    </div>
</main>
