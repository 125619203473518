<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'template_update' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateToMyAccount()'>{{ 'header_account_menu' | translate }}</a> / <a (click)='navigateToTemplatesList()'>{{ 'templates_list' | translate }}</a> / {{ 'template_update' | translate }}
    </div>

    <app-template-form *ngIf="!isLoading && templateToUpdate"
                       [templateToUpdate]="templateToUpdate"></app-template-form>

    <div *ngIf='isLoading' class="row row--guttered row--tiny content">
        <span>{{ 'template_loading' | translate }}</span>
    </div>
</main>
