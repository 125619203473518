<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'myAccount_notifications' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateBack()'>{{ 'account_pageTitle' | translate }}</a> / {{'myAccount_notifications' | translate }}
    </div>

    <div class="row row--guttered row--tiny content" [style.paddingTop]="0" *ngIf="!isLoading">
        <div class="row">
            <div class="list-row list-row--title">
                <div class="col">{{ 'notifications_products' | translate }}</div>
            </div>

            <div class="list-row list-row--no-border">
                <div class="col">
                    <app-switch-button [checked]="enabledNotifications.includes(eNotificationType.NewPatientShared)"
                                       [label]="'notifications_newPatientShared_switch'"
                                       (checkboxChecked)="switchNotificationsAsync(eNotificationType.NewPatientShared)"
                    ></app-switch-button>
                </div>
            </div>

            <div class="list-row list-row--no-top-padding list-row--no-border">
                <div class="col">
                    <app-switch-button [checked]="enabledNotifications.includes(eNotificationType.NewPatientNight)"
                                       [label]="'notifications_newPatientNight_switch'"
                                       (checkboxChecked)="switchNotificationsAsync(eNotificationType.NewPatientNight)"
                    ></app-switch-button>
                </div>
            </div>

            <div class="list-row list-row--no-top-padding list-row--no-border">
                <div class="col">
                    <app-switch-button [checked]="enabledNotifications.includes(eNotificationType.NewNightVersion)"
                                       [label]="'notifications_newVersionCreated_switch'"
                                       (checkboxChecked)="switchNotificationsAsync(eNotificationType.NewNightVersion)"
                    ></app-switch-button>
                </div>
            </div>

            <div class="list-row list-row--no-top-padding list-row--no-border">
                <div class="col">
                    <app-switch-button [checked]="enabledNotifications.includes(eNotificationType.NewNightComment)"
                                       [label]="'notifications_newNightComment_switch'"
                                       (checkboxChecked)="switchNotificationsAsync(eNotificationType.NewNightComment)"
                    ></app-switch-button>
                </div>
            </div>

            <div class="list-row list-row--no-top-padding list-row--no-border">
                <div class="col">
                    <app-switch-button [checked]="enabledNotifications.includes(eNotificationType.NewNightSigned)"
                                       [label]="'notifications_reportSigned_switch'"
                                       (checkboxChecked)="switchNotificationsAsync(eNotificationType.NewNightSigned)"
                    ></app-switch-button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="list-row list-row--title">
                <div class="col">{{ 'notifications_marketing' | translate }}</div>
            </div>

            <div class="list-row list-row--no-border">
                <div class="col">
                    <app-switch-button [checked]="enabledNotifications.includes(eNotificationType.Marketing)"
                                       [label]="'notifications_marketing_switch'"
                                       (checkboxChecked)="switchNotificationsAsync(eNotificationType.Marketing)">
                    </app-switch-button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isLoading" class="row row--guttered row--tiny content">
        {{ 'account_loading' | translate }}
    </div>
</main>
