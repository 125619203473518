import * as _ from "lodash"
import { Questionnaire } from "../models/Questionnaires/Questionnaire"
import { QuestionnaireQuestion } from "../models/Questionnaires/QuestionnaireQuestion"
import { QuestionnaireSection } from "../models/Questionnaires/QuestionnaireSection"
import { QuestionType } from "../models/Questionnaires/QuestionType"
import { StringsUtilities } from "../utilities/StringsUtilities"

export class QuestionnaireMapper {
    private static readonly GeneralQuestionsList = [
        "Question_Symptoms_Smoke",
        "Question_Symptoms_HighBloodPressure",
        "QuestionS002Q002a",
        "QuestionS002Q002b",
        "QuestionS002Q002f",
        "QuestionS002Q002c",
        "QuestionS002Q002e",
        "QuestionS002Q002d",
        "QuestionS002Q002g",
    ]

    private static readonly ISISectionQuestionsList = [
        "QuestionS002Q001",
        "QuestionS002Q003a",
        "QuestionS002Q003b",
        "QuestionS002Q003c",
        "QuestionS002Q004",
        "Question_Symptoms_OtherPeople",
        "Question_Symptoms_Worried",
    ]

    private static readonly MCTQSectionQuestionsList = [
        "Question_Habits_WorkDayCount",
        "QuestionS004Q002a",
        "Question_MCTQ_Workdays_ReadyToSleep",
        "QuestionS004Q002b",
        "QuestionS004Q002c",
        "QuestionS004Q002d",
        "QuestionS004Q002e",
        "QuestionS004Q002f",
        "QuestionS004Q003a",
        "Question_MCTQ_Freedays_ReadyToSleep",
        "QuestionS004Q003b",
        "QuestionS004Q003c",
        "QuestionS004Q003d",
        "QuestionS004Q003e",
        "QuestionS004Q003f",
        "QuestionS005Q001",
        "QuestionS005Q002",
    ]

    private static readonly MCTQLastSubsectionQuestionsList = ["Question_Habits_WorkDayCount", "QuestionS004Q002f"]

    private static readonly ESSSectionQuestionsList = [
        "QuestionS003Q001a",
        "QuestionS003Q001b",
        "QuestionS003Q001c",
        "QuestionS003Q001d",
        "QuestionS003Q001e",
        "QuestionS003Q001f",
        "QuestionS003Q001g",
        "QuestionS003Q001h",
    ]

    /**
     * Get formatted adult questionnaire
     * @param {any} dataAccessQuestionnaire the questionnaire from the database
     * @param {string} lang the current language
     * @returns {Questionnaire} the formatted questionnaire
     */
    public static toAdultQuestionnaire(dataAccessQuestionnaire: any, lang: string): Questionnaire {
        const questionnaire: Questionnaire = new Questionnaire()
        questionnaire.sections = []

        questionnaire.sections.push(
            QuestionnaireMapper.toQuestionnaireSection(
                dataAccessQuestionnaire,
                QuestionnaireMapper.GeneralQuestionsList,
                lang,
            ),
        )
        questionnaire.sections.push(
            QuestionnaireMapper.toQuestionnaireSection(
                dataAccessQuestionnaire,
                QuestionnaireMapper.ISISectionQuestionsList,
                lang,
            ),
        )
        questionnaire.sections.push(
            QuestionnaireMapper.toQuestionnaireSection(
                dataAccessQuestionnaire,
                QuestionnaireMapper.MCTQSectionQuestionsList,
                lang,
            ),
        )
        questionnaire.sections.push(
            QuestionnaireMapper.toQuestionnaireSection(
                dataAccessQuestionnaire,
                QuestionnaireMapper.ESSSectionQuestionsList,
                lang,
            ),
        )

        return questionnaire
    }

    /**
     * Get formatted children questionnaire
     * @param {any} dataAccessQuestionnaire the questionnaire from the database
     * @param {string} lang the current language
     * @returns {Questionnaire} the formatted questionnaire
     */
    public static toChildQuestionnaire(dataAccessQuestionnaire: any, lang: string): Questionnaire {
        const questions: any[] = dataAccessQuestionnaire.questionnaireSections[0].questions

        const questionNumbers: string[] = []
        const formattedQuestionnaire: Questionnaire = new Questionnaire()
        formattedQuestionnaire.sections = []

        for (const question of questions) {
            questionNumbers.push(question.questionNumber)
        }

        formattedQuestionnaire.sections.push(
            QuestionnaireMapper.toQuestionnaireSection(dataAccessQuestionnaire, questionNumbers, lang),
        )

        return formattedQuestionnaire
    }

    private static toQuestionnaireSection(
        dataAccessQuestionnaire: any,
        questionNumbers: string[],
        lang: string,
    ): QuestionnaireSection {
        if (!dataAccessQuestionnaire) {
            return
        }

        const section: QuestionnaireSection = new QuestionnaireSection()
        section.questions = []
        for (const questionNumber of questionNumbers) {
            let found = false
            for (const questionnaireSection of dataAccessQuestionnaire.questionnaireSections) {
                if (!found) {
                    const questionFound = this.getQuestion(questionnaireSection, questionNumber, lang)

                    if (questionFound.displayedTitle) {
                        found = true
                        section.questions.push(questionFound)
                    }
                }
            }
        }
        section.questions = section.questions.filter((question) => question.displayedTitle !== undefined)

        return section
    }

    private static getQuestion(dataAccessSection: any, questionNumber: string, lang: string): QuestionnaireQuestion {
        const questionnaireQuestion: QuestionnaireQuestion = new QuestionnaireQuestion()
        let questionFound: any
        for (const question of dataAccessSection.questions) {
            if (!questionFound && _.isEmpty(question.questionGroupNumber)) {
                if (question.questionNumber == questionNumber) {
                    questionFound = question
                }
            } else {
                if (!questionFound) {
                    questionFound = question.questions.find((question) => question.questionNumber == questionNumber)
                }
            }

            if (questionFound) {
                questionnaireQuestion.titleEn = questionFound.titleEn
                questionnaireQuestion.titleFr = questionFound.titleFr
                questionnaireQuestion.titleNl = questionFound.titleNl
                questionnaireQuestion.questionType = this.getQuestionType(questionFound.questionType)
                questionnaireQuestion.questionNumber = questionNumber

                QuestionnaireMapper.MCTQLastSubsectionQuestionsList.forEach((lastSubsectionQuestion) => {
                    if (questionNumber == lastSubsectionQuestion) {
                        questionnaireQuestion.lastOfSubsection = true
                    }
                })

                switch (questionnaireQuestion.questionType) {
                    case QuestionType.Range:
                        questionnaireQuestion.rangeLabelsEn = questionFound.rangeLabelsEn
                        questionnaireQuestion.rangeLabelsFr = questionFound.rangeLabelsFr
                        questionnaireQuestion.rangeLabelsNl = questionFound.rangeLabelsNl
                        questionnaireQuestion.rangeLabelsDe = questionFound.rangeLabelsDe
                        questionnaireQuestion.displayedRangeLabels =
                            questionFound["rangeLabels" + StringsUtilities.GetUpperCurrentLanguage(lang)]
                        questionnaireQuestion.displayedTitle =
                            questionFound["title" + StringsUtilities.GetUpperCurrentLanguage(lang)]
                        break
                    case QuestionType.Radio:
                        questionnaireQuestion.radioLabelsEn = questionFound.radioLabelsEn
                        questionnaireQuestion.radioLabelsFr = questionFound.radioLabelsFr
                        questionnaireQuestion.radioLabelsNl = questionFound.radioLabelsNl
                        questionnaireQuestion.radioLabelsDe = questionFound.radioLabelsDe
                        questionnaireQuestion.displayedRadioLabels =
                            questionFound["radioLabels" + StringsUtilities.GetUpperCurrentLanguage(lang)]
                        questionnaireQuestion.radioValues = questionFound.radioValues
                        questionnaireQuestion.displayedTitle =
                            questionFound["title" + StringsUtilities.GetUpperCurrentLanguage(lang)]
                        break
                    case QuestionType.Checkbox:
                        questionnaireQuestion.checkboxLabelEn = questionFound.checkboxLabelEn
                        questionnaireQuestion.checkboxLabelNl = questionFound.checkboxLabelNl
                        questionnaireQuestion.checkboxLabelFr = questionFound.checkboxLabelFr
                        questionnaireQuestion.checkboxLabelDe = questionFound.checkboxLabelDe
                        questionnaireQuestion.displayedTitle =
                            questionFound["checkboxLabel" + StringsUtilities.GetUpperCurrentLanguage(lang)]
                        break
                    default:
                        questionnaireQuestion.textFormat = questionFound.textFormat
                        questionnaireQuestion.displayedTitle =
                            questionFound["title" + StringsUtilities.GetUpperCurrentLanguage(lang)]
                        break
                }
            }
        }

        return questionnaireQuestion
    }

    private static getQuestionType(stringQuestionType: string): QuestionType {
        if (stringQuestionType == "range") {
            return QuestionType.Range
        }
        if (stringQuestionType == "radio") {
            return QuestionType.Radio
        }
        if (stringQuestionType == "checkbox") {
            return QuestionType.Checkbox
        }
        return QuestionType.Text
    }

    public static updateQuestionnaireLanguage(questionnaire: Questionnaire, lang: string): Questionnaire {
        for (const section of questionnaire.sections) {
            for (const question of section.questions) {
                if (question.questionType == QuestionType.Checkbox) {
                    question.displayedTitle = question["checkboxLabel" + StringsUtilities.GetUpperCurrentLanguage(lang)]
                } else {
                    question.displayedTitle = question["title" + StringsUtilities.GetUpperCurrentLanguage(lang)]
                }
            }
        }
        return questionnaire
    }
}
