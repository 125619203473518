import { TranslateService } from "@ngx-translate/core"
import { ValidatableRule } from "./ValidatableRule"

export class MinValueRule extends ValidatableRule {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        translate: TranslateService,
        private readonly minValue: number,
        errorStringTemplate?: string,
    ) {
        super(translate, errorStringTemplate || "i18n:common_validation_error_minValue", { minValue: minValue })
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    protected validateRule(value: number): boolean {
        return value >= this.minValue
    }
}
