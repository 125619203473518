import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "../../../../services/auth.service"
import { NotificationsService } from "../../../../services/notifications.service"
import { SunriseApiService } from "../../../../services/sunrise-api.service"
import { NightReportTemplateComponent } from "../night-report-template.component"

@Component({
    selector: "app-night-report-template-v1",
    templateUrl: "./night-report-template-v1.component.html",
    styleUrls: ["../../night-report.component.sass"],
})
export class NightReportTemplateV1Component extends NightReportTemplateComponent {
    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }
}
