import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-password-confirmed",
    templateUrl: "./password-confirmed.component.html",
    styleUrls: ["./password-confirmed.component.sass"],
})
export class PasswordConfirmedComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public ngOnInit(): void {
        this.hideNavBar()
        this.useLanguage(RoutesUtilities.getRouteParam(this.activatedRoute, "lang"))
    }
}
