<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'createPrefilledAccount_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <ng-container>
        <div class="row row--guttered row--tiny content">
            <app-account-fields #createPrefilledAccountFields
                                [accountActionType]="eAccountActionType.DoctorAccountFilled"
                                (enterPressed)="createPrefilledAccountAsync()"></app-account-fields>

            <div class="field-container field-container--checkbox">
                <input type="checkbox"
                    id="newsletter-checkbox"
                    [(ngModel)]="subscribeNewsletter">
                <label class="label" for="newsletter-checkbox"> {{ "signUp_action_receiveNewsletter" | translate }}</label>
                <span class="checkmark"></span>
            </div>

            <div class="field-container buttons-container" [class.field-container--errored]="error != ''">
                <app-button
                    [loading]="isCreatingAccount"
                    (clicked)="createPrefilledAccountAsync()"
                >
                    {{ 'common_create' | translate }}
                </app-button>
                <small class="error-text">{{error}}</small>
            </div>
        </div>
    </ng-container>
</main>
