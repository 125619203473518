import * as _ from "lodash"

export function enumContains(type: any, value: any): boolean {
    if (!(type && value)) {
        return false
    }

    if (!_.isObject(type)) {
        return false
    }

    const keys = Object.keys(type)

    if (!(keys && !_.isEmpty(keys))) {
        return false
    }

    const iMax = keys.length
    let i = 0

    while (i < iMax && type[keys[i]] !== value) {
        i++
    }

    return i < iMax
}
