<div class="report-section">
    <!-- Separator -->
    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>
    <!-- Separator -->

    <!-- Section title -->
    <div class="row row--guttered-h section-title-container tile-row">
        <h2 class="title-h2 section-title" (click)="toggleReportSection($event)">Sleep Study Report</h2>

        <app-button
            [loading]="isExportingReport"
            (clicked)="exportReport()"
            style="position: absolute; right: 2rem; margin-top: 0.5rem;"
        >
            {{ 'nightReport_action_print' | translate }}
        </app-button>
    </div>
    <!-- Section title -->

    <!-- Section content -->
    <div class="row row--guttered-h section-content">
        <div [style.display]="isGeneratingPdfSrc ? 'block' : 'none'">
            {{ 'common_loading' | translate }}
        </div>

        <embed *ngIf="nightReportPdfSrc"
               [src]="nightReportPdfSrc"
               type="application/pdf"
               [style.height]="isGeneratingPdfSrc ? '0vh' : '100vh'"
               [style.border-top-width]="isGeneratingPdfSrc ? '0px': ' 1px'"
               style="width: 100%; border-top: solid #000000;">
    </div>
    <!-- Section content -->
</div>
