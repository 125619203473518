import { Component, Input } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { Night } from "../../../../models/Nights/Night"
import { QuestionnairesComponent } from "../questionnaires.component"

@Component({
    selector: "app-isi-questionnaire",
    templateUrl: "./isi-questionnaire.component.html",
    styleUrls: ["../../night-report.component.sass"],
})
export class IsiQuestionnaireComponent extends QuestionnairesComponent {
    @Input() public night: Night
    @Input() public answerDate: string

    constructor(protected readonly translate: TranslateService) {
        super(translate)
    }
}
