import { TranslateService } from "@ngx-translate/core"
import { ValidatableRule } from "./ValidatableRule"

export class NumberRule extends ValidatableRule {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(translate: TranslateService, errorStringTemplate?: string) {
        super(translate, errorStringTemplate || "i18n:common_validation_error_mustBeNumber")
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    protected validateRule(value: any): boolean {
        return !isNaN(parseInt(value))
    }
}
