import { Component, Input, OnInit } from "@angular/core"
import * as Highcharts from "highcharts"
import { ChartType } from "../../../models/Nights/ChartType"
import { Night } from "../../../models/Nights/Night"
import { NightReportChartsService } from "../../../services/night-report-charts.service"

@Component({
    selector: "app-night-report-chart",
    templateUrl: "./night-report-chart.component.html",
    styleUrls: ["../night-report.component.sass"],
})
export class NightReportChartComponent implements OnInit {
    @Input() public night: Night
    @Input() public chartType: ChartType

    public options: Highcharts.Options
    public Highcharts: typeof Highcharts = Highcharts

    public constructor(protected readonly nightReportChartsService: NightReportChartsService) {}

    public async ngOnInit(): Promise<void> {
        this.options = await this.nightReportChartsService.getChartOptionsFromChartTypeAsync(this.chartType, this.night)
    }

    /**
     * Function called by chart and used to reflow the chart when it's loaded
     */
    public chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
        setTimeout(() => {
            chart.reflow()
        }, 0)
    }
}
