import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { PortalNotificationType } from "../../models/PortalNotification"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-sav-provider",
    templateUrl: "./sav-provider.component.html",
})
export class SavProviderComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public isSendingForm = false

    public firstName: ValidatableObject
    public lastName: ValidatableObject
    public address: ValidatableObject
    public country: ValidatableObject
    public city: ValidatableObject
    public zipCode: ValidatableObject

    private savToken: string
    public formFilled = false

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.hideNavBar()
        this.hideFooter()

        this.savToken = this.getQueryParam("token")

        this.useLanguage(RoutesUtilities.getRouteParam(this.activatedRoute, "lang"))

        await this.initValidatableObjectsAsync()
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public async sendSavFormAsync(): Promise<void> {
        const isValid: boolean = await this.validateObjectsAsync()

        if (isValid) {
            this.isSendingForm = true

            const savFormBody: object = {
                savToken: this.savToken,
                fullName: `${this.firstName.value} ${this.lastName.value}`,
                country: this.country.value,
                address: this.address.value,
                city: this.city.value,
                zipCode: this.zipCode.value,
            }

            try {
                await this.sunriseApiService.sendFilledSavFormAsync(savFormBody)

                this.formFilled = true
            } catch (error) {
                this.formFilled = false

                if (error.status === 400) {
                    this.showNotification("sav_provider_invalidToken_error", PortalNotificationType.Error)
                } else {
                    this.showErrorOccurred()
                }
            }

            this.isSendingForm = false
        }
    }

    // -------------------------------------------------------------------------
    // Objects validation
    // -------------------------------------------------------------------------

    public async initValidatableObjectsAsync(): Promise<void> {
        this.firstName = new ValidatableObject(this.translate, "savForm_input_firstName_label")
        this.firstName.addRule(new NotEmptyRule(this.translate))

        this.lastName = new ValidatableObject(this.translate, "savForm_input_lastName_label")
        this.lastName.addRule(new NotEmptyRule(this.translate))

        this.address = new ValidatableObject(this.translate, "savForm_input_address_label")
        this.address.addRule(new NotEmptyRule(this.translate))

        this.country = new ValidatableObject(this.translate, "savForm_input_country_label")
        this.country.addRule(new NotEmptyRule(this.translate))

        this.city = new ValidatableObject(this.translate, "savForm_input_city_label")
        this.city.addRule(new NotEmptyRule(this.translate))

        this.zipCode = new ValidatableObject(this.translate, "savForm_input_zipCode_label")
        this.zipCode.addRule(new NotEmptyRule(this.translate))
    }

    /**
     * Validate validatable objects.
     * Force validating all the fields and do not stop at first error.
     * @return true if all the fields are valid, false otherwise
     */
    public async validateObjectsAsync(): Promise<boolean> {
        let isValid: boolean

        isValid = await this.address.validateAsync()
        isValid = (await this.country.validateAsync()) && isValid
        isValid = (await this.city.validateAsync()) && isValid
        isValid = (await this.zipCode.validateAsync()) && isValid
        isValid = (await this.firstName.validateAsync()) && isValid
        isValid = (await this.lastName.validateAsync()) && isValid

        return isValid
    }
}
