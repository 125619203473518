<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'myAccount_signatures' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateBack()'>{{ 'account_pageTitle' | translate }}</a> / {{'myAccount_signatures' | translate }}
    </div>

    <div class="row row--guttered row--tiny content" [style.paddingTop]="0" *ngIf="!isLoading">
        <div class="row row--guttered-v" [style.paddingTop]="0">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'signatures_current' | translate }}</div>
            </div>

            <div class="list-row list-row--no-border">
                <div class="col">
                    <img *ngIf="currentSignature" imgWithViewer alt="signature" class="signature" [src]="currentSignature.url">
                    <div *ngIf="!currentSignature">{{ 'signatures_noSignature' | translate }}</div>
                </div>
            </div>
        </div>

        <div class="row row--guttered-v">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'signatures_upload' | translate }}</div>
            </div>

            <div *ngIf="!newSignature" class="list-row list-row--no-border">
                <div class="dropper-container" dragDropFile (droppedFiles)="filesDroppedEvent($event)">
                    <img class="dropper-container__upload-img" alt="upload" src="assets/images/upload_light.png">
                    <div class="bold">{{ 'signatures_dragDrop' | translate }}</div>
                    <div class="small">{{ 'common_or' | translate }}</div>
                    <app-button
                        (clicked)="uploadSignature.click()"
                        [customClasses]="'button--small'"
                    >
                        {{ 'signatures_browseFile' | translate }}
                    </app-button>
                    <div class="small-note" [ngClass]="{'small-note--errored': fileError}">{{ 'signatures_fileInfo' | translate }}</div>
                    <div *ngIf="fileError" class="small-note small-note--errored">{{ fileError | translate }}</div>
                    <input #uploadSignature type="file" class="display-none" [accept]="allowedFileTypes.join(',')" [multiple]="false" (change)="fileUploadedEvent($event)">
                </div>
            </div>

            <div *ngIf="newSignature" class="list-row list-row--no-border">
                <div class="col">
                    <div class="new_signature-container">
                        <span (click)="resetNewSignature()" class="delete-button">&times;</span>
                        <img imgWithViewer alt="signature" class="signature" [src]="newSignature.url">
                    </div>
                </div>
                <div class="col col--see-more">
                    <app-button
                        [loading]="isUploading"
                        (clicked)="uploadSignatureAsync()"
                        [customClasses]="'button--small'"
                    >
                        {{ 'signatures_uploadNew' | translate }}
                    </app-button>
                </div>
            </div>
        </div>

        <div class="row row--guttered-v" *ngIf="signaturesHistory && signaturesHistory.length > 0">
            <div class="list-row list-row--title list-row--no-border">
                <div class="col">{{ 'signatures_history' | translate }}</div>
            </div>

            <div *ngFor="let signature of signaturesHistory; let last = last" class="list-row" [ngClass]="{'list-row--no-border': last}">
                <div class="col">
                    <img imgWithViewer alt="signature" class="signature signature--small" [src]="signature.url">
                </div>
                <div class="col">{{ formatDateTime(signature.creationDate) }}</div>
            </div>
        </div>
    </div>

    <div *ngIf='isLoading' class="row row--guttered row--tiny content">
        {{ 'account_loading' | translate }}
    </div>
</main>
