<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'preferences_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateBack()'>{{ 'account_pageTitle' | translate }}</a> / {{'myAccount_preferences' | translate }}
    </div>

    <div class="row row--guttered row--tiny content">
        <div class="row row--guttered-v2">
            <div class="units-formats-preferences-container">
                <span class="units-formats-preferences-item">{{ 'preferences_length_units_label' | translate }}</span>

                <div class="units-formats-preferences-item">
                    <input type="radio" id="preferences_length_cm" name="preference_length" value="cm" [(ngModel)]="preference_length">
                    <label for="preferences_length_cm">{{ 'preferences_cm_checkbox_label' | translate }}</label>
                </div>

                <div class="units-formats-preferences-item">
                    <input type="radio" id="preferences_length_in" name="preference_length" value="in" [(ngModel)]="preference_length">
                    <label for="preferences_length_in">{{ 'preferences_in_checkbox_label' | translate }}</label>
                </div>
            </div>

            <div class="units-formats-preferences-container">
                <span class="units-formats-preferences-item">{{ 'preferences_weight_units_label' | translate }}</span>

                <div class="units-formats-preferences-item">
                    <input type="radio" id="preferences_weight_kg" name="preference_weight" value="kg" [(ngModel)]="preference_weight">
                    <label for="preferences_weight_kg">{{ 'preferences_kg_checkbox_label' | translate }}</label>
                </div>

                <div class="units-formats-preferences-item">
                    <input type="radio" id="preferences_weight_lb" name="preference_weight" value="lb" [(ngModel)]="preference_weight">
                    <label for="preferences_weight_lb">{{ 'preferences_lb_checkbox_label' | translate }}</label>
                </div>
            </div>

            <div class="units-formats-preferences-container">
                <span class="units-formats-preferences-item">{{ 'preferences_hour_format_label' | translate }}</span>

                <div class="units-formats-preferences-item">
                    <input type="radio" id="preferences_hour_24" name="preference_hour" value="24h" [(ngModel)]="preference_hour">
                    <label for="preferences_hour_24">{{ 'preferences_24h_checkbox_label' | translate }}</label>
                </div>

                <div class="units-formats-preferences-item">
                    <input type="radio" id="preferences_hour_12" name="preference_hour" value="12h" [(ngModel)]="preference_hour">
                    <label for="preferences_hour_12">{{ 'preferences_12h_checkbox_label' | translate }}</label>
                </div>
            </div>

            <div class="units-formats-preferences-container">
                <span class="units-formats-preferences-item">{{ 'preferences_date_format_label' | translate }}</span>

                <div class="units-formats-preferences-item">
                    <input type="radio" id="preferences_date_ddmmyyyy" name="preference_date" value="DDMMYYYY" [(ngModel)]="preference_date">
                    <label for="preferences_date_ddmmyyyy">{{ 'preferences_ddmmyyyy_checkbox_label' | translate }}</label>
                </div>

                <div class="units-formats-preferences-item">
                    <input type="radio" id="preferences_date_mmddyyyy" name="preference_date" value="MMDDYYYY" [(ngModel)]="preference_date">
                    <label for="preferences_date_mmddyyyy">{{ 'preferences_mmddyyyy_checkbox_label' | translate }}</label>
                </div>
            </div>

            <div class="field-container buttons-container">
                <app-button
                    [loading]="isLoading"
                    (clicked)="savePreferencesAsync()"
                >
                    {{ 'preferences_save_button_label' | translate }}
                </app-button>
            </div>
        </div>
    </div>
</main>
