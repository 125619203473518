import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { NightAnalysisTemplate } from "../../../../models/Nights/NightAnalysisTemplate"
import { NightAnalysisTemplateTypeValues } from "../../../../models/Nights/NightAnalysisTemplateTypeValues"
import { PortalNotificationType } from "../../../../models/PortalNotification"
import { AuthService } from "../../../../services/auth.service"
import { NotificationsService } from "../../../../services/notifications.service"
import { SunriseApiService } from "../../../../services/sunrise-api.service"
import { NotEmptyRule } from "../../../../validation/rules/NotEmptyRule"
import { ValidatableObject } from "../../../../validation/ValidatableObject"
import { BaseComponent } from "../../../base.component"

@Component({
    selector: "app-template-form",
    templateUrl: "./template-form.component.html",
    styleUrls: ["./template-form.component.sass"],
})
export class TemplateFormComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    @Input() public templateToUpdate: NightAnalysisTemplate

    public isLoading = false
    public templateText: ValidatableObject
    public templateTitle: ValidatableObject
    private templateType: NightAnalysisTemplateTypeValues = NightAnalysisTemplateTypeValues.Impression

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.initValidatableObject()
    }

    private initValidatableObject() {
        this.templateText = new ValidatableObject(this.translate)
        this.templateText.addRule(new NotEmptyRule(this.translate))

        this.templateTitle = new ValidatableObject(this.translate)
        this.templateTitle.addRule(new NotEmptyRule(this.translate))

        if (this.templateToUpdate) {
            this.templateText.value = this.templateToUpdate.text
            this.templateTitle.value = this.templateToUpdate.title
            this.templateType = this.templateToUpdate.type as NightAnalysisTemplateTypeValues
        }
    }

    // -------------------------------------------------------------------------
    // Events listener
    // -------------------------------------------------------------------------

    public onTemplateTypeSelected(event: any): void {
        this.templateType = event.target.value
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public async updateTemplateAsync(): Promise<void> {
        let isValid: boolean = await this.templateTitle.validateAsync()
        isValid = (await this.templateText.validateAsync()) && isValid

        if (isValid) {
            this.isLoading = true

            try {
                this.templateToUpdate.title = this.templateTitle.value
                this.templateToUpdate.text = this.templateText.value
                this.templateToUpdate.type = this.templateType

                await this.sunriseApiService.updateTemplateAsync(this.templateToUpdate)
                this.showNotification("template_updated", PortalNotificationType.Success)
            } catch (error) {
                this.showErrorOccurred()
            } finally {
                this.isLoading = false
            }
        }
    }

    public async createTemplateAsync(): Promise<void> {
        let isValid: boolean = await this.templateTitle.validateAsync()
        isValid = (await this.templateText.validateAsync()) && isValid

        if (isValid) {
            this.isLoading = true

            try {
                const template: NightAnalysisTemplate = {
                    title: this.templateTitle.value,
                    text: this.templateText.value,
                    type: this.templateType,
                }
                await this.sunriseApiService.createTemplateAsync(template)
                this.showNotification("template_created", PortalNotificationType.Success)
            } catch (error) {
                this.showErrorOccurred()
            } finally {
                this.isLoading = false
            }
        }
    }
}
