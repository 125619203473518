<main>
    <!-- Page title -->
    <div class="row row--guttered row--flex">
        <h1 class="title-h1">{{ "nightReport_page_title" | translate }} ({{ formatDate(night.endDateTime.date) }})</h1>

        <app-button [loading]="isExportingReport"
                    (clicked)="exportReport()"
        >{{ 'nightReport_action_print' | translate }}</app-button>
    </div>
    <!-- Page title -->

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <!-- Back buttons -->
    <div *ngIf="patient" class="row row--guttered">
        <a [routerLink]="['/main/patients']">{{ 'patientsList_page_title' | translate }}</a> /
        <span *ngIf="!isSampleReport"><a (click)="navigateBack()">{{ patientName }}</a> /</span>
        {{ "nightReport_page_title" | translate }} ({{ formatDate(night.endDateTime.date) }})
    </div>
    <!-- Back buttons -->

    <!-- Page content -->
    <div class="row">
        <!-- Admin information -->
        <app-night-report-section *ngIf="night?.nightDataTemplate?.adminInformation && isAdmin"
                                  [nightDataList]="night.nightDataTemplate.adminInformation"
                                  [sectionName]="'nightReport_adminInformation_section'"></app-night-report-section>
        <!-- Admin information -->

        <!-- Summary -->
        <div class="report-section">
            <!-- Separator -->
            <div class="row row--guttered-h">
                <div class="separator"></div>
            </div>
            <!-- Separator -->

            <!-- Section title -->
            <div class="row row--guttered-h section-title-container">
                <h2 class="title-h2 section-title" (click)="toggleReportSection($event)">{{ 'nightReport_summary_section_title' | translate }}</h2>
            </div>
            <!-- Section title -->

            <div class="section-content">
                <!-- Questionnaire answers -->
                <div class="row row--guttered row--smaller content" [style.display]="night.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire001' ? 'block' : 'none'">
                    <div class="question">
                        <h3 class="title-h3 question-title">{{ 'nightReport_answers_date_label' | translate }}</h3>
                        <p class='question-answer'>
                            {{ 'nightReport_answers_date_description' | translate: {'date': formatDate(night?.questionnaireAnswerDate?.toString())} }}
                        </p>
                    </div>

                    <div class="question">
                        <h3 class="title-h3 question-title">{{ 'nightReport_patient_complaints' | translate  }}</h3>
                        <p class='question-answer'>
                            {{ night?.patientComplaints }}
                        </p>
                    </div>

                    <div class="question">
                        <h3 class="title-h3 question-title">{{ 'nightReport_section_ESS_score' | translate }}</h3>
                        <p id="essScoreLabel" class='question-answer'></p>
                    </div>

                    <div class="question">
                        <h3 class="title-h3 question-title">{{ 'nightReport_section_ISI_score' | translate }}</h3>
                        <p id="isiScoreLabel" class='question-answer'></p>
                    </div>
                </div>
                <!-- Questionnaire answers -->

                <!-- Night informations -->
                <div class="row row--guttered row--smaller content" *ngIf="night?.nightDataTemplate">
                    <app-night-report-tab-line [nightData]="getNightData('NSD03')"></app-night-report-tab-line>
                    <app-night-report-tab-line [nightData]="getNightData('NSD25')"></app-night-report-tab-line>
                    <app-night-report-tab-line [nightData]="getNightData('NSD31')"></app-night-report-tab-line>
                    <app-night-report-tab-line [nightData]="getNightData('NSD42')"></app-night-report-tab-line>
                    <app-night-report-tab-line [nightData]="getNightData('NSD43')"></app-night-report-tab-line>
                    <app-night-report-tab-line [nightData]="getNightData('NSD72') || getNightData('NSD44')"></app-night-report-tab-line>
                    <app-night-report-tab-line [nightData]="getNightData('NSD45')"></app-night-report-tab-line>
                </div>
                <!-- Night informations -->

                <!-- Patient feedback -->
                <div class="row row--guttered row--smaller content">
                    <div class="question">
                        <h3 class="title-h3 question-title">{{ 'nightReport_question_alcohol_label' | translate }}</h3>
                        <p class="question-answer">{{ (night?.nightQuestions[1].answer !== undefined ? (night?.nightQuestions[1].answer ? ('common_yes' | translate) : ('common_no' | translate) ) : '-') + (night?.nightQuestions[1].answerText !== undefined ? (' - ' + night?.nightQuestions[1].answerText) : '') }}</p>
                    </div>
                    <div class="question">
                        <h3 class="title-h3 question-title">{{ 'nightReport_sedatives_label' | translate }}</h3>
                        <p class="question-answer">{{ (night?.nightQuestions[2].answer !== undefined ? (night?.nightQuestions[2].answer ? ('common_yes' | translate) : ('common_no' | translate) ) : '-') + (night?.nightQuestions[2].answerText !== undefined ? (' - ' + night?.nightQuestions[2].answerText) : '') }}</p>
                    </div>
                    <div class="question" [style.display]="night?.questionnaireNumber == 'Questionnaire002' ? 'default': 'none'">
                        <h3 class="title-h3 question-title">{{ 'nightReport_baby_pacifier_label' | translate }}</h3>
                        <p class="question-answer">{{ (night?.childNightQuestions[0].answer !== undefined ? (night?.childNightQuestions[0].answer ? ('common_yes' | translate) : ('common_no' | translate) ) : '-') + (night?.childNightQuestions[0].answerText !== undefined ? (' - ' + night?.childNightQuestions[0].answerText) : '') }}</p>
                    </div>
                    <div class="question">
                        <h3 class="title-h3 question-title">{{ 'nightReport_question_medical_treatment_label' | translate }}</h3>
                        <p class="question-answer">{{ (night?.nightQuestions[3].answer !== undefined ? (night?.nightQuestions[3].answer ? ('common_yes' | translate) : ('common_no' | translate) ) : '-') + (night?.nightQuestions[3].answerText !== undefined ? (' - ' + night?.nightQuestions[3].answerText) : '') }}</p>
                    </div>
                    <div class="question">
                        <h3 class="title-h3 question-title">{{ 'nightReport_patient_comment_label' | translate }}</h3>
                        <p class="question-answer">{{ night?.feedback.comment ? night?.feedback.comment : '-' }}</p>
                    </div>
                    <h3 class="title-h3 question-title">{{ 'nightReport_reliabilityIndex' | translate }}</h3>
                    <div class="content reliability-index-text-container">
                        <p class="question-answer reliability-index-text" [hidden]="!night?.testOnly">
                            {{ 'nightReport_reliabilityIndex_testOnly' | translate }}
                        </p>

                        <p class="question-answer reliability-index-text reliability-index-text"
                           [hidden]="!night.reliabilityIndex.nightIssues.significantDisconnectionPeriod">
                            {{ 'nightReport_reliabilityIndex_error_lostData' | translate: { 'lostDataDuration': night?.reliabilityIndex.disconnectionDurationInMins } }}
                        </p>
                        <p class="question-answer reliability-index-text reliability-index-text"
                           [hidden]="!night.reliabilityIndex.nightIssues.sensorFallenDetected">
                            {{ 'nightReport_reliabilityIndex_error_fallenDetected' | translate: { 'fallenTime': night?.reliabilityIndex.fallenTimeString } }}
                        </p>
                        <p class="question-answer reliability-index-text reliability-index-text"
                           [hidden]="!night.reliabilityIndex.nightIssues.sensorFallenReported"
                           [innerHTML]="'nightReport_reliabilityIndex_error_fallenReported' | translate"></p>
                        <p class="question-answer reliability-index-text reliability-index-text"
                           [hidden]="!night.reliabilityIndex.nightIssues.recordingTimeTooLow">
                            {{ 'nightReport_reliabilityIndex_error_sleepTimeLow' | translate }}</p>
                        <p class="reliability-index-text reliability-index-text"
                           [hidden]="!night.reliabilityIndex.nightIssues.usedBabyPacifier">
                            {{ 'nightReport_reliabilityIndex_error_usedBabyPacifier' | translate }}</p>
                        <p class="reliability-index-text reliability-index-text"
                           [hidden]="!night.reliabilityIndex.nightIssues.MaxRecordingDurationReached">
                            {{ 'nightReport_reliabilityIndex_error_maxDuration' | translate }}</p>


                        <p class="question-answer reliability-index-text reliability-index-text--success"
                           [hidden]="night.reliabilityIndex.nightIssues.numberOfIssues > 0">
                            {{ 'nightReport_reliabilityIndex_success' | translate }}</p>
                        <p class="question-answer reliability-index-text reliability-index-text--success"
                           *ngIf="(night.reliabilityIndex.nightIssues.numberOfIssues > 0 && night.reliabilityIndex.isNightValid == 'valid')">
                            {{ 'nightReport_reliabilityIndex_valid' | translate }}</p>
                        <p class="question-answer reliability-index-text reliability-index-text--error"
                           *ngIf="(night.reliabilityIndex.nightIssues.numberOfIssues > 0 && night.reliabilityIndex.isNightValid == 'invalid')">
                            {{ 'nightReport_reliabilityIndex_invalid' | translate }}</p>
                        <p class="question-answer reliability-index-text reliability-index-text--error"
                           *ngIf="(night.reliabilityIndex.nightIssues.numberOfIssues > 0 && night.reliabilityIndex.isNightValid == 'impacted')">
                            {{ 'nightReport_reliabilityIndex_impacted' | translate }}</p>

                    </div>

                    <h3 class="title-h3 question-title" *ngIf="night?.supportComment">{{ 'nightReport_Sunrise_comment' | translate }}</h3>
                    <div class="content reliability-index-text-container">
                        <p class="question-answer reliability-index-text">
                            {{ night?.supportComment }}
                        </p>
                    </div>
                </div>
                <!-- Patient feedback -->

                <!-- Sleep stages Chart -->
                <app-night-report-chart *ngIf="night && night.chartData.useSleepStagesPredictions"
                                        [night]="night"
                                        [chartType]="eChartType.SleepStages"></app-night-report-chart>
                <!-- Sleep stages Chart -->

                <!-- Sleep stages Proba Chart -->
                <!--
                Chart of sleep stages probabilities currently hidden following
                Pierre Martinot's request.
                -->
                <!--<p class="row row--guttered content" style="padding-bottom: 0.5rem" *ngIf="night.chartData.hasSleepStagesProbasPredictions">{{ 'nightReport_charts_proba_informative_label' | translate }}</p>



                <app-night-report-chart *ngIf="night && night.chartData.hasSleepStagesProbasPredictions"
                                        [night]="night"
                                        [chartType]="eChartType.SleepStagesProba"></app-night-report-chart>-->
                <!-- Sleep stages Proba Chart -->

                <!-- Arousal Chart -->
                <app-night-report-chart *ngIf="night"
                                        [night]="night"
                                        [chartType]="eChartType.Arousals"></app-night-report-chart>
                <!-- Arousal Chart -->

                <!-- Respiratory effort Chart -->
                <app-night-report-chart *ngIf="night"
                                        [night]="night"
                                        [chartType]="eChartType.RespiratoryEffort"></app-night-report-chart>
                <!-- Respiratory effort Chart -->

                <!-- Head position Chart -->
                <app-night-report-chart *ngIf="night"
                                        [night]="night"
                                        [chartType]="eChartType.HeadPosition"></app-night-report-chart>
                <!-- Head position Chart -->
            </div>
        </div>
        <!-- Summary -->

        <!-- Patient informations -->
        <app-night-report-section *ngIf="night?.nightDataTemplate?.patientInformation"
                                  [nightDataList]="night.nightDataTemplate.patientInformation"
                                  [sectionName]="'nightReport_patientInformation_section'"></app-night-report-section>
        <!-- Patient informations -->

        <!-- Child questionnaire -->
        <app-child-questionnaire [night]="night"
                                 *ngIf="night?.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire002'"></app-child-questionnaire>
        <!-- Child questionnaire -->

        <!-- ESS questionnaire -->
        <app-ess-questionnaire [night]="night"
                               *ngIf="night?.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire001'"></app-ess-questionnaire>
        <!-- ESS questionnaire -->

        <!-- ISI questionnaire -->
        <app-isi-questionnaire [night]="night"
                               *ngIf="night?.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire001'"></app-isi-questionnaire>
        <!-- ISI questionnaire -->

        <!-- MCTQ questionnaire -->
        <app-mctq-questionnaire [night]="night"
                                *ngIf="night?.questionnaireAnswers && night?.questionnaireNumber == 'Questionnaire001'"></app-mctq-questionnaire>
        <!-- MCTQ questionnaire -->

        <!-- Recording information -->
        <app-night-report-section *ngIf="night?.nightDataTemplate?.recordingInformation"
                                  [nightDataList]="night.nightDataTemplate.recordingInformation"
                                  [sectionName]="'nightReport_recordingInformation_section'"></app-night-report-section>
        <!-- Recording information -->

        <!-- Sleep scoring data -->
        <app-night-report-section *ngIf="night?.nightDataTemplate?.sleepScoring"
                                  [nightDataList]="night.nightDataTemplate.sleepScoring"
                                  [sectionName]="'nightReport_sleepScoring_section'"></app-night-report-section>
        <!-- Sleep scoring data -->

        <!-- Arousal, respiratory and motor events -->
        <app-night-report-section *ngIf="night?.nightDataTemplate?.respiratoryEvents"
                                  [nightDataList]="night.nightDataTemplate.respiratoryEvents"
                                  [sectionName]="'nightReport_respiratoryEvents_section'"></app-night-report-section>
        <!-- Arousal, respiratory and motor events -->

        <!-- Position -->
        <app-night-report-section *ngIf="night?.nightDataTemplate?.positions"
                                  [nightDataList]="night.nightDataTemplate.positions"
                                  [sectionName]="'nightReport_positions_section'"></app-night-report-section>
        <!-- Position -->

        <!-- Raw Data -->
        <app-night-report-raw-data
            [nightId]="night.nightId"
            [nightVersionId]="nightVersionId"
            [night]="night"
            [patient]="patient"
            [sampleReport]="isSampleReport"
            *ngIf="night && isResearcher"></app-night-report-raw-data>
        <!-- Raw Data -->

        <div class="components-versions">
            {{componentsVersionsContent}}
        </div>
    </div>
    <!-- Page content -->
</main>
