import { TranslateService } from "@ngx-translate/core"

export class TranslationUtilities {
    public static async getTranslationAsync(
        translate: TranslateService,
        key: string,
        params: object = null,
    ): Promise<string> {
        return new Promise((resolve) => {
            if (params) {
                translate.get(key, params).subscribe((res) => resolve(res))
            } else {
                translate.get(key).subscribe((res) => resolve(res))
            }
        })
    }
}
