import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { PortalNotificationType } from "../../models/PortalNotification"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { PasswordRegExp } from "../../utilities/passwordRegExp"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import { EqualityRule } from "../../validation/rules/EqualityRule"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { RegExpRule } from "../../validation/rules/RegExpRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.sass"],
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public error = ""
    public isResetingPassword = false
    public password: ValidatableObject
    public passwordConfirmation: ValidatableObject

    private currentLang: string

    private accountId: string
    private accountType: string
    private resetToken: string

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        private readonly activatedRoute: ActivatedRoute,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.currentLang = RoutesUtilities.getRouteParam(this.activatedRoute, "lang")

        this.useLanguage(this.currentLang)

        this.accountId = this.getQueryParam("id")
        this.accountType = this.getQueryParam("type")
        this.resetToken = this.getQueryParam("token")

        await this.initValidatableObjectsAsync()
    }

    // -------------------------------------------------------------------------
    // Initialize validation
    // -------------------------------------------------------------------------

    private async initValidatableObjectsAsync(): Promise<void> {
        this.password = new ValidatableObject(this.translate, "resetPassword_newPassword_label")
        this.password.addRule(new NotEmptyRule(this.translate))
        this.password.addRule(
            new RegExpRule(this.translate, PasswordRegExp, "i18n:signUp_input_password_error_invalid"),
        )
        await this.password.addHintAsync("signUp_input_password_error_invalid")

        this.passwordConfirmation = new ValidatableObject(this.translate, "signUp_input_passwordConfirmation_label")
        this.passwordConfirmation.addRule(
            new EqualityRule(this.translate, this.password, "i18n:signUp_input_passwordConfirmation_error_mustMatch"),
        )
    }

    /**
     * Validate validatable objects.
     * Force validating all the fields and do not stop at first error.
     * @return true if all the fields are valid, false otherwise
     */
    private async validateObjectsAsync(): Promise<boolean> {
        let isValid: boolean
        isValid = await this.password.validateAsync()
        isValid = (await this.passwordConfirmation.validateAsync()) && isValid

        return isValid
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public async resetPassword(): Promise<void> {
        const isValid: boolean = await this.validateObjectsAsync()

        if (isValid) {
            this.isResetingPassword = true
            this.hideErrorOccurred()

            try {
                await this.sunriseApiService.resetPasswordAsync(
                    this.accountId,
                    this.password.value,
                    this.accountType,
                    this.resetToken,
                )

                if (this.accountType === "patient") {
                    this.router.navigate([`reset-confirmed/${this.currentLang}`])
                } else {
                    this.showNotification("resetPassword_confirmation_title", PortalNotificationType.Success)

                    this.router.navigate(["login"])
                }
            } catch (error) {
                await this.showErrorOccurredAsync()
            } finally {
                this.isResetingPassword = false
            }
        }
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async showErrorOccurredAsync(): Promise<void> {
        this.error = await this.__("common_error")
    }

    private hideErrorOccurred(): void {
        this.error = ""
    }
}
