<div class="notifications__container">
    <div *ngFor="let notification of notifications"
         class="notification"
         [ngClass]="{
            'notification--success': notification.notificationType == ePortalNotificationType.Success,
            'notification--warning': notification.notificationType == ePortalNotificationType.Warning,
            'notification--information': notification.notificationType == ePortalNotificationType.Information,
            'notification--error': notification.notificationType == ePortalNotificationType.Error,
         }"
         @slideInOut
    >
        <div class="notification__title-container">
            <div class="notification__title">{{ notification.title | translate }}</div>

            <div class="notification__close" (click)="removeNotification(notification)">+</div>
        </div>

        <div class="notification__separator"></div>

        <div class="notification__description">{{ notification.description | translate }}</div>

        <div *ngIf="notification.linkTitle" (click)="linkClicked(notification)" class="notification__link">{{ notification.linkTitle | translate }}</div>
    </div>
</div>
