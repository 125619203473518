import { BaseComponent } from "./base.component"
import { SortOrder } from "../models/SortOrder"

export class SortedList extends BaseComponent {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    public sortColumnValue: string
    public sortOrder: SortOrder

    public initSortingValues(value: string, order: SortOrder = SortOrder.Ascending) {
        this.sortColumnValue = value
        this.sortOrder = order
    }

    public sortListByColumn(columnValue: string) {
        if (this.sortColumnValue == columnValue) {
            this.sortOrder = this.sortOrder == SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending
        } else {
            this.sortColumnValue = columnValue
            this.sortOrder = SortOrder.Ascending
        }
    }
}
