import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { ValidatableRule } from "./ValidatableRule"

export class NotEmptyRule extends ValidatableRule {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(translate: TranslateService, errorStringTemplate?: string) {
        super(translate, errorStringTemplate || "i18n:common_validation_error_notEmpty")
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    /**
     * @override
     */
    public validate(value: any): boolean {
        return !_.isEmpty(value)
    }

    /*
     * Since we override the validate() method directly, validateRule() method is useless in this case and is not implemented.
     */
    protected validateRule(value: any): boolean {
        throw new Error("Not implemented")
    }
}
