import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core"

@Component({
    selector: "app-button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.sass"],
})
export class ButtonComponent {
    @Input() disabled = false
    @Input() loading = false
    @Input() customClasses = ""

    @Output() clicked: EventEmitter<void> = new EventEmitter<void>()

    @ViewChild("buttonElement") private buttonElement: ElementRef

    public buttonWidth: number

    private updateButtonWidth(): void {
        this.buttonWidth = this.buttonElement.nativeElement.offsetWidth
    }

    public onClick(): void {
        this.updateButtonWidth()

        this.clicked.emit()
    }
}
