import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AuthService } from "./auth.service"
import { constants } from "../config/constants"
import { Doctor } from "../models/Doctor"
import { NightAnalysisActionValues } from "../models/Nights/NightAnalysisActionValues"
import { NightAnalysisTemplate } from "../models/Nights/NightAnalysisTemplate"
import { NightSupportInfo } from "../models/Nights/NightSupportInfo"
import { SleepStudy } from "../models/Nights/SleepStudy"
import { NotificationType } from "../models/NotificationType"
import { Office } from "../models/Office"
import { Patient } from "../models/Patient"
import { PrescriptionPrices } from "../models/Prescriptions/PrescriptionPrices"
import { SensorToUser } from "../models/SensorToUser"
import { Signature } from "../models/Signature"
import { UserLogin } from "../models/UserLogin"

/**
 * SunriseApiService facilitates the communication with the Sunrise API.
 */
@Injectable({
    providedIn: "root",
})
export class SunriseApiService {
    private static readonly API_URI: string = `${constants.api.product.baseUri}/api/v1`

    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) {}

    // -------------------------------------------------------------------------
    // Maintenance
    // -------------------------------------------------------------------------

    public async getMaintenanceAsync(): Promise<any> {
        return await this.getAsync("maintenance/sunPlatform")
    }

    // -------------------------------------------------------------------------
    // Sign in
    // -------------------------------------------------------------------------

    /**
     * Make a login request.
     * @param {!UserLogin} userLogin Login (combination of email address, password and optional resend mail option)
     * @param {boolean} isAdmin A boolean indicating whether to try a admin login operation or a standard login operation.
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async loginAsync(userLogin: UserLogin, isAdmin = false): Promise<any> {
        const path = `accounts/${isAdmin ? "admin" : "doctor"}/login/request`

        return await this.postAsync(path, userLogin)
    }

    /**
     * Make a login request.
     * @param {string} email Email address of the user
     * @param {string} verificationCode verification code
     * @param {boolean} isAdmin A boolean indicating whether to try a admin login operation or a standard login operation.
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async loginVerificationAsync(email: string, verificationCode: string, isAdmin = false): Promise<any> {
        const path = `accounts/${isAdmin ? "admin" : "doctor"}/login/verification`

        return await this.postAsync(path, { email: email, verificationCode: verificationCode })
    }

    // -------------------------------------------------------------------------
    // Sign up
    // -------------------------------------------------------------------------

    /**
     * Make a request to store a new doctor.
     * @param {!Doctor} doctor Doctor to store
     * @param {string} language Language of the doctor
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async createDoctorAsync(doctor: Doctor, language: string): Promise<any> {
        return await this.postAsync(`accounts/doctor?lang=${language}`, doctor)
    }

    public async createPrefilledAccountAsync(doctor: Doctor): Promise<any> {
        return await this.postAsync("accounts/admin/createPrefilledDoctor", doctor)
    }

    /**
     * Make a request to create a new patient from doctor account
     * @param {any} patient Patient account to create
     * @return {string} The identifier of the created account
     */
    public async createPatientAccount(patient: any): Promise<string> {
        const res: any = await this.postAsync("accounts/doctor/createPatient", patient)

        return res.data.createdUserId
    }

    /**
     * Make a request to update a new patient from doctor account
     * @param {any} patient Patient account to update
     * @return {string} The identifier of the created account
     */
    public async updatePatientAccount(patient: any): Promise<void> {
        await this.postAsync("accounts/doctor/updatePatient", patient)
    }

    /**
     * Make a request to store data in an existing doctor.
     * @param {!Doctor} doctor Doctor data to fill to the existing doctor entity
     * @param {string} language Language of the doctor
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async fillDoctorAsync(doctor: Doctor, language: string): Promise<any> {
        return await this.putAsync(`accounts/doctor/fill?lang=${language}`, doctor)
    }

    /**
     * Make a request to get available countries.
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getCountriesAsync(): Promise<any> {
        return (await this.getAsync("countries")).data.countries
    }

    /**
     * Make request to get user country from ip address
     * @return the API response
     */
    public async getCountryFromIpAsync(): Promise<any> {
        const res: any = await this.getAsync("countries/getFromIp")

        if (res && res["data"]) return res["data"]["country"]
    }

    // -------------------------------------------------------------------------
    // Password management
    // -------------------------------------------------------------------------

    public async updatePasswordAsync(
        emailAddress: string,
        oldPassword: string,
        newPassword: string,
        isAdmin = false,
    ): Promise<any> {
        const path: string = isAdmin ? "accounts/admin/updatePassword" : "accounts/doctor/updatePassword"

        const res: any = await this.postAsync(path, {
            email: emailAddress,
            oldPassword: oldPassword,
            newPassword: newPassword,
        })

        return res["tokens"]
    }

    // -------------------------------------------------------------------------
    // 2FA
    // -------------------------------------------------------------------------

    public async updateTwoFAAsync(enabled: boolean, resend = false): Promise<void> {
        await this.postAsync("accounts/doctor/2fa", { enabled: enabled, resendCode: resend })
    }

    public async verifyTwoFACodeAsync(verificationCode: string): Promise<void> {
        await this.postAsync("accounts/doctor/2fa/verify", { code: verificationCode })
    }

    // -------------------------------------------------------------------------
    // Account confirmation
    // -------------------------------------------------------------------------

    public async setIsAccountConfirmedAsync(): Promise<void> {
        if (this.authService.getAccountConfirmed()) {
            return
        }
        if (this.authService.isAdmin()) {
            this.authService.setAccountConfirmed(true)
            return
        } else {
            try {
                const path = "accounts/doctor/confirmed"
                const body: object = { doctorEmail: this.authService.getUserEmailAddress() }
                const res: any = await this.postAsync(path, body)

                this.authService.setAccountConfirmed(res["data"]["isConfirmed"])
            } catch {}
        }
    }

    /**
     * Check if the account is confirmed or not.
     * @param accountEmail Email identifying the account
     * @param isAdmin Boolean whether the account is an admin account or not
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async resendConfirmationEmailAsync(accountEmail: string, isAdmin = false): Promise<boolean> {
        const path: string = isAdmin ? "accounts/admin/confirm/request" : "accounts/doctor/confirm/request"
        const body: object = isAdmin
            ? { adminEmail: accountEmail, resend: true }
            : { doctorEmail: accountEmail, resend: true }

        return await this.postAsync(path, body)
    }

    // -------------------------------------------------------------------------
    // Account utilities
    // -------------------------------------------------------------------------

    /**
     * Set the role of an account by email in localStorage
     * @param isAdmin Boolean whether the account is an admin account or not
     * @throw API errors to be handled by the caller
     */
    public async setRolesAsync(isAdmin = false): Promise<void> {
        const path: string = isAdmin ? "accounts/admin/roles" : "accounts/doctor/roles"
        const res: any = await this.postAsync(path, {})

        let roles: string[] = res["data"]["roles"]

        // Check if a doctor is allowed to prescribe
        if (!isAdmin && res["data"]["isAllowedToPrescribe"]) {
            if (!roles) roles = []

            roles.push(constants.roles.prescriber)
        }

        this.authService.setRoles(roles)
    }

    // -------------------------------------------------------------------------
    // Reset password
    // -------------------------------------------------------------------------

    /**
     * Make a request the get an email to reset the password.
     * @param {string} email Email address of the account of which reset the password
     * @param {boolean} resend Boolean indicates if the request need to resend a mail for the second time or not
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async requestResetPasswordAsync(email: string, resend = false): Promise<any> {
        return this.postAsync("accounts/doctor/reset/request", { email: email, resend: resend })
    }

    /**
     * Make a request the get an email to reset the password.
     * @param {string} accountId Identifier of the account
     * @param {string} password New password
     * @param {string} accountType The type of the account
     * @param {string} resetToken The reset token
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async resetPasswordAsync(
        accountId: string,
        password: string,
        accountType: string,
        resetToken: string,
    ): Promise<any> {
        const path: string = accountType === "patient" ? "accounts/patient/reset" : "accounts/doctor/reset"

        return this.postAsync(path, { accountId: accountId, password: password, resetToken: resetToken })
    }

    /**
     * Set the password of the current account
     * @param {string} password the password to set
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async setPasswordAsync(password: string): Promise<any> {
        const path = "accounts/doctor/setPassword"

        return await this.postAsync(path, { password: password })
    }

    // -------------------------------------------------------------------------
    // Account
    // -------------------------------------------------------------------------

    /**
     * Get the information of the connected doctor.
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getMeAsync(retrieveSharedEmails: boolean = false, retrieveOffices: boolean = false): Promise<any> {
        return await this.getAsync("accounts/doctor/me", { retrieveSharedEmails, retrieveOffices })
    }

    public async updateDoctorAsync(doctor: Doctor): Promise<any> {
        return await this.putAsync("accounts/doctor", doctor)
    }

    public async updateNotificationStatus(notificationType: NotificationType, enable: boolean): Promise<void> {
        await this.postAsync("accounts/doctor/notifications", { notificationType, enable })
    }

    public async updateUserUnitsAndFormatsPreferencesAsync(userUnitsAndFormatsPreferences: any): Promise<void> {
        await this.putAsync("accounts/doctor/updateUnitsAndFormatsPreferences", userUnitsAndFormatsPreferences)
    }

    // -------------------------------------------------------------------------
    // Signatures
    // -------------------------------------------------------------------------

    public async getSignaturesAsync(): Promise<Signature[]> {
        const res: any = await this.getAsync("accounts/doctor/signatures")

        if (res && res["data"]) return res["data"]["signatures"]
    }

    public async getLastSignatureSavedAsync(): Promise<Signature> {
        const res: any = await this.getAsync("accounts/doctor/signatures/lastSaved")

        if (res && res["data"]) return res["data"]["lastSignatureSaved"]
    }

    public async uploadSignatureAsync(signature: Signature): Promise<void> {
        const formData: FormData = new FormData()

        formData.append("signature_file", signature.file)

        await this.postAsync("accounts/doctor/signatures/upload", formData)
    }

    // -------------------------------------------------------------------------
    // Night analysis templates
    // -------------------------------------------------------------------------

    public async getDoctorTemplatesAsync(): Promise<NightAnalysisTemplate[]> {
        const res: any = await this.getAsync("accounts/doctor/templates/my")
        let templates: NightAnalysisTemplate[]

        if (res && res["data"] && res["data"]["templates"] && res["data"]["templates"].length != 0) {
            templates = res["data"]["templates"]
        }

        return templates
    }

    public async getTemplateAsync(templateId: string): Promise<NightAnalysisTemplate> {
        const res: any = await this.getAsync(`accounts/doctor/template/${templateId}`)

        return res["data"]["template"]
    }

    public async createTemplateAsync(template: NightAnalysisTemplate): Promise<string> {
        const res: any = await this.postAsync("accounts/doctor/createTemplate", template)

        if (res && res["data"]) return res["data"]["templateId"]
    }

    public async updateTemplateAsync(template: NightAnalysisTemplate): Promise<void> {
        await this.putAsync("accounts/doctor/updateTemplate", template)
    }

    public async deleteTemplateAsync(nightAnalysisTemplateId: string): Promise<any> {
        return await this.postAsync("accounts/doctor/deleteTemplate", { nightAnalysisTemplateId })
    }

    // -------------------------------------------------------------------------
    // Patients
    // -------------------------------------------------------------------------

    /**
     * Link a doctor with a patient
     * @param {string} token Token identifying the link
     * @param {string} doctorEmail Email address of the doctor
     * @return the parsed API response
     * @throw API errors to be handled by the caller
     */
    public async linkToPatientAsync(token: string, doctorEmail: string): Promise<any> {
        return await this.getAsync("accounts/patient/doctors/link", { token, email: doctorEmail })
    }

    /**
     * Get the list of the patients linked with the doctor.
     * @param {any} patientId id of the patient
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getPatientAsync(patientId: any): Promise<any> {
        return this.isAdmin()
            ? (await this.postAsync("accounts/admin/patient", { userId: patientId })).data.patient
            : (await this.postAsync("accounts/doctor/patient", { userId: patientId })).data.patient
    }

    /**
     * Get the list of the patients linked with the doctor.
     * @param {boolean} retrieveSharedLinks Boolean indicates if shared links need to be retrieved
     * @param {boolean} retrieveOffices Boolean indicates if offices need to be retrieved
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getPatientsAsync(retrieveSharedLinks = false, retrieveOffices = false): Promise<any> {
        if (this.isAdmin()) {
            return await this.postAsync("accounts/admin/patients", null)
        }

        return await this.postAsync("accounts/doctor/patients/my", { retrieveSharedLinks, retrieveOffices })
    }

    public isAdmin(): boolean {
        return this.authService.isAdmin()
    }

    /**
     * Check if the logged in account has a given role
     * @param {string} role the role to check
     * @return true if the account has the role
     */
    public accountHasRole(role: string): boolean {
        const roles: string[] = this.authService.getRoles()

        return roles && roles.includes(role)
    }

    public setPatient(patient) {
        localStorage.setItem("patient", JSON.stringify(patient))
    }

    public getPatient() {
        const patientInStorage = localStorage.getItem("patient")
        let patient: Patient | undefined = undefined

        if (patientInStorage) {
            patient = JSON.parse(patientInStorage)

            this.resetPatient()
        }

        return patient
    }

    public resetPatient() {
        localStorage.removeItem("patient")
    }

    // -------------------------------------------------------------------------
    // Nights
    // -------------------------------------------------------------------------

    public async getSleepStudiesAsync(): Promise<SleepStudy[]> {
        return (await this.getAsync("accounts/doctor/patients/my/sleepStudies"))["data"]["sleepStudiesList"]
    }

    /**
     * Add the current doctor to the "seen" list of the night
     * @param {string} nightId Identifier of the night
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async seeNight(nightId: string): Promise<void> {
        return await this.postAsync("nights/seen", {}, { nightId })
    }

    /**
     * Get the night by a given Id
     * @param {string} nightId Id of the night
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getNightAsync(nightId = ""): Promise<any> {
        try {
            const response: any = await this.postAsync("nights/id", null, { nightId })

            return response.data.night
        } catch {
            // return undefined if an error occurred or if user is not authorized
            return undefined
        }
    }

    /**
     * Get list of versions of night by a given Id
     * @param {string} nightId Id of the night
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getNightVersionsAsync(nightId: string): Promise<any> {
        const nightVersionsResponse: any = await this.getAsync("nights/versions/" + nightId)

        return {
            originalNight: nightVersionsResponse.data.night,
            nightVersions: nightVersionsResponse.data.nightVersions,
        }
    }

    /**
     * Get version of night by a given Id
     * @param {string} nightVersionId Id of the night version
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getNightVersionAsync(nightVersionId: string): Promise<any> {
        try {
            const nightVersionResponse: any = await this.postAsync("nights/nightVersion/" + nightVersionId, null)

            return nightVersionResponse.data.night
        } catch {
            // return undefined if an error occurred or if user is not authorized
            return undefined
        }
    }

    /**
     * Get sample night
     * @return the API reponse
     * @throw API errors to be handled bu the caller
     */
    public async getSampleReportAsync(): Promise<any> {
        const path = "nights/sample"
        return (await this.getAsync(path)).data.sampleNight
    }

    /**
     * Get the list of the night of a given patient.
     * @param {string} patientId Id of the patient
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getNightsAsync(patientId: string): Promise<any> {
        return await this.postAsync("nights/patient", { patientId })
    }

    /**
     * Get the entire list of nights by end date time descending.
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getSupportNightsListAsync(filters: any = undefined): Promise<any> {
        return await this.postAsync("accounts/admin/support/nights", { filters })
    }

    /**
     * Export the entire list of nights by end date time ascending.
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async exportSupportNightsListAsync(filters: any = undefined): Promise<any> {
        return await this.postBlobAsync("accounts/admin/support/nights/export", { filters })
    }

    /**
     * Get the entire list of nights by end date time descending.
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async addSupportCommentForANight(nightId: string, comment: string): Promise<any> {
        return await this.postAsync("accounts/admin/support/commentNight", { nightId, comment })
    }

    /**
     * Add the code bug to a given night for support
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async addTypeNCFForANight(nightId: string, typeNCF: string, serialNumber: string): Promise<any> {
        return await this.postAsync("accounts/admin/support/addTypeNCF", { nightId, typeNCF, serialNumber })
    }

    /**
     * Delete the code bug to a given night for support
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async deleteTypeNCFForANight(nightId: string, serialNumber): Promise<any> {
        return await this.postAsync("accounts/admin/support/deleteTypeNCF", { nightId, serialNumber })
    }

    public async hideNightAsync(nightId: string): Promise<any> {
        return await this.postAsync("accounts/admin/support/hideNight", { nightId: nightId })
    }

    /**
     * Get the night information requested for support
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async getNightInformationForSupportAsync(
        userId: string,
        serialNumber: string,
        emailAddress: string,
        nightEndDate: Date,
        retrieveTemplates = false,
    ): Promise<NightSupportInfo> {
        return (
            await this.postAsync("accounts/admin/nightInformationForSupport", {
                userId,
                serialNumber,
                emailAddress,
                retrieveTemplates,
                nightEndDate,
            })
        ).data.nightInformationForSupport
    }

    /**
     * Get night report as Blob (Binary Large Object).
     * @param {boolean} isAdmin A boolean indicating whether to try a admin login operation or a standard login operation.
     * @param {string} nightId Id of the night
     * @param {string} userId Id of the user
     * @param {string} userId Current language
     * @return the API response as Blob
     * @throw API errors to be handled by the caller
     */
    public async getNightReportAsBlobAsync(
        isAdmin: boolean,
        nightId: string,
        userId: string,
    ): Promise<{ blob: Blob; filename: string }> {
        const path = `${isAdmin ? "nights/admin/" : "nights/doctor/"}${nightId}/export`

        return await this.postBlobAsync(path, { userId: userId })
    }

    /**
     * Get night version report as Blob (Binary Large Object).
     * @param {string} nightId Id of the night
     * @param {string} nightVersionId Id of the night version
     * @param {string} userId Id of the user
     * @return the API response as Blob
     * @throw API errors to be handled by the caller
     */
    public async getNightVersionReportAsBlobAsync(
        nightId: string,
        nightVersionId: string,
        userId: string,
    ): Promise<{ blob: Blob; filename: string }> {
        const path = "nights/versions/export"

        return await this.postBlobAsync(path, { userId: userId, nightId: nightId, nightVersionId: nightVersionId })
    }

    public async getNightReportSampleAsBlobAsync(): Promise<{ blob: Blob; filename: string }> {
        const path = "nights/sample/export"

        return await this.getBlobAsync(path)
    }

    public async getEdfDataFileUriAsync(isAdmin: boolean, nightId: string, userId: string): Promise<string> {
        const path = `nights/${isAdmin ? "admin/" : "doctor/"}${nightId}/edf`
        const uri: string = (await this.postAsync(path, { userId: userId })).data.signalsUriFile

        return uri
    }

    public async getEdfDataSampleReport() {
        const path = "nights/sample/edf"

        return (await this.getAsync(path)).data.signalsUriFile
    }

    public async getEdfFileUrlAsync(isAdmin: boolean, nightId: string, userId: string): Promise<string> {
        const path = `${isAdmin ? "nights/admin/" : "nights/doctor/"}${nightId}/edf/export`

        return (await this.postAsync(path, { userId: userId })).data.downloadUrl
    }

    public async getEdfFileUrlSampleReportAsync(): Promise<string> {
        const path = "nights/sample/edf/export"

        return (await this.getAsync(path)).data.downloadUrl
    }

    public async getLastNightAnalysisStep(
        nightId: string,
        nightAnalysisActionValue: NightAnalysisActionValues,
    ): Promise<any> {
        try {
            return await this.postAsync(
                "nights/lastAnalysisStep",
                { nightAnalysisActionType: nightAnalysisActionValue },
                { nightId },
            )
        } catch {
            return undefined
        }
    }

    public async getLastNightVersionAnalysisStep(
        nightVersionId: string,
        nightAnalysisActionValue: NightAnalysisActionValues,
    ): Promise<any> {
        try {
            return await this.postAsync(
                "nights/versions/lastAnalysisStep",
                { nightAnalysisActionType: nightAnalysisActionValue },
                { nightVersionId },
            )
        } catch {
            return undefined
        }
    }

    public async createNigthAnalysisStep(nightAnalysisStep: any): Promise<any> {
        const createdNightAnalysisStepResponse: any = await this.putAsync("nights/analysisStep", nightAnalysisStep)

        return createdNightAnalysisStepResponse.data.analysisStep
    }

    public async getAllNightAnalysisTemplate(): Promise<NightAnalysisTemplate[]> {
        const response: any = await this.getAsync("nights/analysisTemplatesForDoctor")

        return response.data.analysisTemplates
    }

    // -------------------------------------------------------------------------
    // Sensors Assignation
    // -------------------------------------------------------------------------

    public async assignSensorToPatientAsync(serialNumber: string, userId: string, codePin: string = null) {
        await this.postAsync("sensorsToUsers", { serialNumber, userId, codePin })
    }

    public async sensorIsAssignableAsync(serialNumber: string): Promise<boolean> {
        const res: any = await this.postAsync("sensorsToUsers/isSensorAssignable", { serialNumber })

        return res.data.isSensorAssignable
    }

    public async getAssignedSensorsAsync(userId: string): Promise<SensorToUser[]> {
        const res: any = await this.getAsync("sensorsToUsers/getListForUser", { userId })

        return res.data.sensorToUserDTOs
    }

    public async removeAssignedSensorAsync(sensorToUserId: string): Promise<void> {
        await this.postAsync("sensorsToUsers/setInactive", { sensorToUserId })
    }

    // -------------------------------------------------------------------------
    // SAV
    // -------------------------------------------------------------------------

    public async createSavFormAsync(email: string, serialNumber: string): Promise<any> {
        const res: any = await this.postAsync("providers/sav-form/create", { email, serialNumber })

        return res.data.savUri
    }

    public async sendFilledSavFormAsync(savForm: object): Promise<void> {
        await this.postAsync("providers/sav-form/send", savForm)
    }

    // -------------------------------------------------------------------------
    // Prescriptions
    // -------------------------------------------------------------------------

    /**
     * Send the prescription mail to the patient
     * @param {string} patientEmailAddress Email address of the patient
     * @param {number} numberOfSensors Number of sensors
     * @param {string} lang Language of the mail to send
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async sendPrescriptionMailAsync(
        patientEmailAddress: string,
        numberOfSensors: number,
        lang: string,
    ): Promise<any> {
        return await this.postAsync("prescriptions", {
            patientEmailAddress: patientEmailAddress,
            numberOfSensors: numberOfSensors,
            lang: lang,
        })
    }

    /**
     * Get doctor prescription prices
     * @return the API response
     * @throw API errors to be handled by the caller
     */
    public async retrieveDoctorPrescriptionPricesAsync(): Promise<PrescriptionPrices> {
        return (await this.getAsync("prescriptions/prices"))["data"]["prescriptionPrices"]
    }

    // -------------------------------------------------------------------------
    // Demo requests
    // -------------------------------------------------------------------------

    public async resetAccountAsync(): Promise<void> {
        await this.getAsync("demo/resetAccount")
    }

    // -------------------------------------------------------------------------
    // Sharing management
    // -------------------------------------------------------------------------

    public async sharePatientAsync(
        patientId: string,
        doctorEmail: string,
    ): Promise<{ email: string; canBeUnlinked: boolean }[]> {
        const res = (await this.postAsync("accounts/doctor/sharePatient/createLink", { patientId, doctorEmail }))[
            "data"
        ]["newLinks"]

        return res
    }

    public async deleteSharedPatientAsync(
        patientId: string,
        doctorEmail: string,
    ): Promise<{ email: string; canBeUnlinked: boolean }[]> {
        const res = (await this.postAsync("accounts/doctor/sharePatient/removeLink", { patientId, doctorEmail }))[
            "data"
        ]["newLinks"]

        return res
    }

    public async getDoctorOfficesAsync(): Promise<Office[]> {
        return (await this.getAsync("offices"))["data"]["offices"]
    }

    public async addPatientToOfficeAsync(officeId: string, patientId: string): Promise<void> {
        await this.postAsync("offices/addPatient", { accountPatientId: patientId, officeId })
    }

    public async removePatientFromOfficeAsync(patientId: string): Promise<void> {
        await this.postAsync("offices/removePatient", { accountPatientId: patientId })
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    private getApiUri(path: string, params: object = {}): string {
        if (!params["lang"]) {
            params["lang"] = localStorage.getItem("currentLanguage")
        }

        const paramsString: string = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&")

        return `${SunriseApiService.API_URI}/${path}?${paramsString}`
    }

    /**
     * Make a GET request
     * @param {string} path API path to call
     * @param {object} params Params of the query
     * @return the parsed API response
     * @throw API errors to be handled by the caller
     */
    private async getAsync(path: string, params: object = {}): Promise<any> {
        const headers: HttpHeaders = this.getCompletedHeaders()
        const uri: string = this.getApiUri(path, params)

        const response: HttpResponse<any> = await new Promise((resolve, reject) => {
            this.http.get(uri, { headers: headers, observe: "response" }).subscribe({
                next: (res) => resolve(res),
                error: (err) => reject(err),
            })
        })

        this.handleRefreshTokenChanges(response.body)

        return response.body
    }

    /**
     * Make a GET request to retrieve a Blob object (such as a PDF file)
     * @param {string} path API path to call
     * @param {object} params Params of the query
     * @return the parsed API response as Blob
     * @throw API errors to be handled by the caller
     */
    private async getBlobAsync(path: string, params: object = {}): Promise<{ blob: Blob; filename: string }> {
        const headers: HttpHeaders = this.getCompletedHeaders()
        const uri: string = this.getApiUri(path, params)

        const response: HttpResponse<any> = await new Promise((resolve, reject) => {
            this.http.get(uri, { headers: headers, responseType: "blob", observe: "response" }).subscribe({
                next: (res) => resolve(res),
                error: (err) => reject(err),
            })
        })

        const filename: string = response.headers.get("Content-Disposition").replace(/^.*filename=(.*)$/g, "$1")

        this.handleRefreshTokenChanges(response)

        return { blob: response.body, filename: filename }
    }

    /**
     * Make a POST request
     * @param {string} path API path to call
     * @param {any} body Body of the request
     * @param {object} params Params of the query
     * @return the parsed API response
     * @throw API errors to be handled by the caller
     */
    private async postAsync(path: string, body: any, params: object = {}): Promise<any> {
        const headers: HttpHeaders = this.getCompletedHeaders()
        const uri: string = this.getApiUri(path, params)

        const response: HttpResponse<any> = await new Promise((resolve, reject) => {
            this.http.post(uri, body, { headers: headers, observe: "response" }).subscribe({
                next: (res) => resolve(res),
                error: (err) => reject(err),
            })
        })

        this.handleRefreshTokenChanges(response.body)

        return response.body
    }

    /**
     * Make a POST request
     * @param {string} path API path to call
     * @param {any} body Body of the request
     * @param {object} params Params of the query
     * @return the parsed API response as Blob
     * @throw API errors to be handled by the caller
     */
    private async postBlobAsync(
        path: string,
        body: any,
        params: object = {},
    ): Promise<{ blob: Blob; filename: string }> {
        const headers: HttpHeaders = this.getCompletedHeaders()
        const uri: string = this.getApiUri(path, params)

        const response: HttpResponse<any> = await new Promise((resolve, reject) => {
            this.http.post(uri, body, { headers: headers, responseType: "blob", observe: "response" }).subscribe({
                next: (res) => resolve(res),
                error: (err) => reject(err),
            })
        })

        const filename: string = response.headers.get("Content-Disposition").replace(/^.*filename=(.*)$/g, "$1")

        this.handleRefreshTokenChanges(response)

        return { blob: response.body, filename: filename }
    }

    /**
     * Make a PUT request
     * @param {string} path API path to call
     * @param {any} body Body of the request
     * @param {object} params Params of the query
     * @return the parsed API response
     * @throw API errors to be handled by the caller
     */
    private async putAsync(path: string, body: any, params: object = {}): Promise<any> {
        const headers: HttpHeaders = this.getCompletedHeaders()
        const uri: string = this.getApiUri(path, params)

        const response: HttpResponse<any> = await new Promise((resolve, reject) => {
            this.http.put(uri, body, { headers: headers, observe: "response" }).subscribe({
                next: (res) => resolve(res),
                error: (err) => reject(err),
            })
        })

        this.handleRefreshTokenChanges(response.body)

        return response.body
    }

    /**
     * Get Headers with access token and refresh token
     * @return Headers with access token and refresh token
     */
    private getCompletedHeaders(): HttpHeaders {
        const accessToken: string = this.authService.getToken()
        const refreshToken: string = this.authService.getRefreshToken()

        let headers: HttpHeaders = new HttpHeaders()

        if (accessToken) {
            headers = headers.append("Authorization", `Bearer ${accessToken}`)
            headers = headers.append("refresh-token", refreshToken)
        }

        return headers
    }

    private handleRefreshTokenChanges(res: object) {
        if (res && res["tokens"]) {
            const accessToken: string = res["tokens"]["accessToken"]
            const refreshToken: string = res["tokens"]["refreshToken"]

            this.authService.refreshTokens(accessToken, refreshToken)
        }
    }
}
