import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { ValidatableRule } from "./ValidatableRule"

export class RegExpRule extends ValidatableRule {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        translate: TranslateService,
        private readonly regExp: RegExp,
        errorStringTemplate: string,
    ) {
        super(translate, errorStringTemplate)
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    protected validateRule(value: string): boolean {
        return !_.isEmpty(value.match(this.regExp))
    }
}
