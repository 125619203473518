import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { InputObject } from "./InputObject"
import { NotEmptyRule } from "./rules/NotEmptyRule"
import { ValidatableRule } from "./rules/ValidatableRule"
import { TranslationUtilities } from "../utilities/TranslationUtilities"

export class ValidatableObject extends InputObject {
    public required = false
    public showHint = false
    public hasError = false
    public hintString = ""
    public errorString = ""

    private rules: ValidatableRule[] = []
    private failedRules: ValidatableRule[] = []
    private displayAllErrors = false

    constructor(
        protected readonly translate: TranslateService,
        label?: string,
    ) {
        super(label)
        translate.onLangChange.subscribe(async () => {
            await this.fillErrorStringAsync(this.failedRules)
        })
    }

    public async validateAsync(displayAllErrors = false): Promise<boolean> {
        this.hasError = false
        this.showHint = false
        this.errorString = ""
        this.failedRules = []
        this.displayAllErrors = displayAllErrors

        if (this.value == "") this.value = null

        for (const rule of this.rules) {
            if (!rule.validate(this.value)) {
                this.failedRules.push(rule)
            }
        }

        if (this.failedRules.length == 0) {
            return true
        }

        await this.fillErrorStringAsync(this.failedRules)

        this.hasError = true

        return false
    }

    public addRule(rule: ValidatableRule): void {
        if (rule instanceof NotEmptyRule) {
            this.required = true
        }

        this.rules.push(rule)
    }

    public async addHintAsync(hint: string): Promise<void> {
        this.hintString = await TranslationUtilities.getTranslationAsync(this.translate, hint)
        this.showHint = true
    }

    public containsRule(searchedRule: typeof ValidatableRule): boolean {
        return this.rules.find((rule) => (rule as any).constructor.name == searchedRule.name) != undefined
    }

    public async fillErrorStringAsync(rules: ValidatableRule[]): Promise<void> {
        if (_.isEmpty(rules)) {
            this.errorString = ""
        } else {
            const label: string = this.label
                ? (await TranslationUtilities.getTranslationAsync(this.translate, this.label)).toLowerCase()
                : ""
            if (!this.displayAllErrors) {
                const errorString: string = await rules[0].getErrorStringTemplateBaseAsync()
                this.errorString = errorString.replace("{{label}}", label)
                this.errorString = this.errorString.replace("{{lowerLabel}}", label)
                this.errorString = this.getCapitalizedError(this.errorString)
            } else {
                let errorString: string

                this.errorString = ""

                for (const rule of rules) {
                    errorString = await rule.getErrorStringTemplateBaseAsync()
                    errorString = errorString.replace("{{label}}", label)
                    errorString = errorString.replace("{{lowerLabel}}", label)

                    this.errorString += this.getCapitalizedError(errorString) + "\n"
                }
            }
        }
    }

    getCapitalizedError(errorString: string): string {
        return errorString.charAt(0).toUpperCase() + errorString.substr(1).toLowerCase()
    }
}
