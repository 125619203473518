import { AfterViewInit, Directive } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as _ from "lodash"
import { TranslatableComponent } from "./translatable.component"
import { constants } from "../config/constants"
import { UnitsAndFormats } from "../models/Formats/UnitsAndFormats"
import { PortalNotificationType } from "../models/PortalNotification"
import { UnitsAndFormatsPreferences } from "../models/UnitsAndFormatsPreferences"
import { AuthService } from "../services/auth.service"
import { NotificationsService } from "../services/notifications.service"
import { SunriseApiService } from "../services/sunrise-api.service"
import { Lang } from "../utilities/Lang"
import { doesUserPrefersH12HourFormat, doesUserPrefersMmddyyyyDateFormat } from "../utilities/UnitsAndFormatsUtilities"

@Directive()
export abstract class BaseComponent extends TranslatableComponent implements AfterViewInit {
    public readonly staticFilesBaseUri: string = constants.dataAccess.staticFiles.googleCloudStorage.baseUri

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------
    public isAdmin: boolean
    public isUSPractitioner: boolean
    public hasDemoRole: boolean
    public hasSupportRole: boolean
    public hasSupportAdminRole: boolean
    public hasPatientSharingRole: boolean
    public canConsultReports: boolean

    protected constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate)

        this.isAdmin = authService.isAdmin()
        this.isUSPractitioner = authService.isUnitedStates()
        this.hasSupportRole = this.sunriseApiService.accountHasRole(constants.roles.support)
        this.hasSupportAdminRole = this.sunriseApiService.accountHasRole(constants.roles.supportAdmin)
        this.hasDemoRole = this.sunriseApiService.accountHasRole(constants.roles.demoAccount)
        this.hasPatientSharingRole = this.sunriseApiService.accountHasRole(constants.roles.patientSharing)
        this.canConsultReports = !this.isAdmin || this.hasSupportRole

        this.removeFirebaseUriIfNeeded()

        this.sunriseApiService
            .getMaintenanceAsync()
            .then(async (res) => {
                if (res?.data?.isMaintenance) {
                    this.router.navigate(["/maintenance"])
                }
            })
            .catch(() => {})
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public ngAfterViewInit(): void {
        this.translate.setDefaultLang(Lang.En)
        this.translate.use(localStorage.getItem("currentLanguage"))
    }

    private removeFirebaseUriIfNeeded(): void {
        const currentUri = window.location.href
        const firebaseUri = constants.platform.firebaseUris.find((uri) => currentUri.startsWith(uri))

        if (firebaseUri) {
            location.href = window.location.href.replace(firebaseUri, constants.platform.baseUri)
        }
    }

    // -------------------------------------------------------------------------
    // Account helpers
    // -------------------------------------------------------------------------

    public async connectUserAsync(
        emailAddress: string,
        tokens: { token; refreshToken },
        roles: string[],
        rawUnitsAndFormatsPreferences: object,
        isAdmin: boolean,
        isUnitedStates: boolean,
        stayConnected: boolean,
    ): Promise<void> {
        this.authService.setTokens(tokens.token, tokens.refreshToken, isAdmin, isUnitedStates, stayConnected)
        this.authService.setRoles(roles)
        this.authService.setUserEmailAddress(emailAddress)

        if (rawUnitsAndFormatsPreferences) {
            const userUnitsAndFormatsPreferences: UnitsAndFormatsPreferences = {
                preferredLengthUnit: rawUnitsAndFormatsPreferences["preferredLengthUnit"],
                preferredWeightUnit: rawUnitsAndFormatsPreferences["preferredWeightUnit"],
                preferredHourFormat: rawUnitsAndFormatsPreferences["preferredHourFormat"],
                preferredDateFormat: rawUnitsAndFormatsPreferences["preferredDateFormat"],
            }

            this.authService.setUnitsAndFormatsPreferences(userUnitsAndFormatsPreferences)
        }

        this.sunriseApiService.resetPatient()

        await this.sunriseApiService.setIsAccountConfirmedAsync()
        await this.sunriseApiService.setRolesAsync(isAdmin)
    }

    public async connectUserAndRedirectToMainPageAsync(
        emailAddress: string,
        tokens: { token; refreshToken },
        roles: string[],
        rawUnitsAndFormatsPreferences: object,
        isAdmin: boolean,
        isUnitedStates: boolean,
        stayConnected: boolean,
    ): Promise<void> {
        await this.connectUserAsync(
            emailAddress,
            tokens,
            roles,
            rawUnitsAndFormatsPreferences,
            isAdmin,
            isUnitedStates,
            stayConnected,
        )
        this.navigateToMainPage()
    }

    public navigateToMainPage(): void {
        if (this.authService.checkUserIsAuthenticated()) {
            if (this.isAdmin && !this.canConsultReports) {
                this.router.navigate(["/main/admin/create-prefilled-account"])
            } else {
                this.router.navigate(["/main/patients"])
            }
        } else {
            this.router.navigate(["/"])
        }
    }

    protected getDefaultPreferencesFromCountry(countryNumber: string): UnitsAndFormatsPreferences {
        const defaultUnitsAndFormatsPreferences: UnitsAndFormatsPreferences = {
            preferredLengthUnit: UnitsAndFormats.Centimeters,
            preferredWeightUnit: UnitsAndFormats.Kilograms,
            preferredHourFormat: UnitsAndFormats.H24,
            preferredDateFormat: UnitsAndFormats.DDMMYYYY,
        }

        if (countryNumber == "CountryUnitedKingdom") {
            defaultUnitsAndFormatsPreferences.preferredLengthUnit = UnitsAndFormats.Inches
            defaultUnitsAndFormatsPreferences.preferredWeightUnit = UnitsAndFormats.Pounds
        } else if (countryNumber == "CountryUnitedStates") {
            defaultUnitsAndFormatsPreferences.preferredLengthUnit = UnitsAndFormats.Inches
            defaultUnitsAndFormatsPreferences.preferredWeightUnit = UnitsAndFormats.Pounds
            defaultUnitsAndFormatsPreferences.preferredHourFormat = UnitsAndFormats.H12
            defaultUnitsAndFormatsPreferences.preferredDateFormat = UnitsAndFormats.MMDDYYYY
        }

        return defaultUnitsAndFormatsPreferences
    }

    // -------------------------------------------------------------------------
    // UI helpers
    // -------------------------------------------------------------------------

    /**
     * Toggle a report-section element
     * @param {any} event Event
     */
    public toggleReportSection(event: any): void {
        const section: HTMLElement = event["target"]["parentElement"]["parentElement"]

        section.classList.toggle("report-section--minified")
    }

    /**
     * Return the string with the first letter capitalized
     * @param {string} str String
     * @return the string with the first letter capitalized
     */
    public capitalizeFirstLetter(str: string): string {
        return _.isEmpty(str) ? "" : str.charAt(0).toUpperCase() + str.slice(1)
    }

    /**
     * Format a number to display a given number of decimals
     * @param {number} number Number to format
     * @param {string} scoringRule The rule use for scoring
     * @param {number} nbDecimals Number of decimals to display
     * @return the number with a given number of decimals
     */
    public formatScore(number: number, scoringRule: string = undefined, nbDecimals = 1): string {
        return number != null ? `${number.toFixed(nbDecimals)} ${scoringRule ? `(${scoringRule})` : ""}` : "-"
    }

    /**
     * Format a string representing a date to a string of format: mm.dd.yyyy
     * @param {string | Date} dateToFormat Date to format
     * @param {string} separator Separator to use (default : '/')
     * @param {boolean} useUTC Use UTC date (default : true)
     * @return the formatted date
     */
    public formatDate(dateToFormat: string | Date, separator = "/", useUTC: boolean = true): string {
        if (!dateToFormat) {
            return
        }

        const date: Date = dateToFormat instanceof Date ? dateToFormat : new Date(dateToFormat.replace(/\s/, "T"))

        const dayOfMonth: string = this.formatDateNumber(useUTC ? date.getUTCDate() : date.getDate())
        const month: string = this.formatDateNumber((useUTC ? date.getUTCMonth() : date.getMonth()) + 1)
        const year: string = (useUTC ? date.getUTCFullYear() : date.getFullYear()).toString()

        if (doesUserPrefersMmddyyyyDateFormat(this.authService.getPreferences())) {
            return `${month}${separator}${dayOfMonth}${separator}${year}`
        } else {
            // Default or if dateFormat == DDMMYYYY
            return `${dayOfMonth}${separator}${month}${separator}${year}`
        }
    }

    /**
     * Format a string representing an hour to a string of format: HH:MM
     * @param {string | Date} dateToFormat Date to format
     * @param {boolean} useUTC Use UTC date (default : true)
     */
    public formatHour(dateToFormat: string | Date, useUTC: boolean = true) {
        if (!dateToFormat) {
            return
        }

        const date: Date = dateToFormat instanceof Date ? dateToFormat : new Date(dateToFormat.replace(/\s/, "T"))
        const formattedHour: string = date
            .toLocaleTimeString("en-US", {
                timeZone: useUTC ? "UTC" : undefined,
                hour12: doesUserPrefersH12HourFormat(this.authService.getPreferences()),
                hour: "numeric",
                minute: "numeric",
            })
            .toLowerCase()

        return formattedHour
    }

    /**
     * Format a string representing a date to a string of format: mm.dd.yyyy
     * @param {string} strDate Date to format
     * @param {string} separator Separator to use (default : '/')
     * @param {boolean} useUTC Use UTC date (default : true)
     * @return the formatted date
     */
    public formatDateTime(strDate: string, separator = "/", useUTC: boolean = true): string {
        const date: Date = new Date(strDate.replace(/\s/, "T"))
        const dateString: string = this.formatDate(date, separator, useUTC)
        const hourString: string = this.formatHour(date, useUTC)

        return `${dateString} ${hourString}`
    }

    /**
     * Format the number used in a date to add a pre-0 if needed (when n < 10)
     * @param {number} n Number to format
     * @return the number with a pre-0 if needed
     */
    public formatDateNumber(n: number): string {
        return n < 10 ? `0${n}` : `${n}`
    }

    /**
     * Format the duraction
     * @param {number} hours Hours to format
     * @return the formatted duration, or -1 if hours is undefined
     */
    public formatDuration(hours: number): string {
        if (hours == null || isNaN(hours)) {
            return "-1"
        }

        const hoursStr: string = this.formatDateNumber(Math.floor(hours))
        const minutes: number = Math.round((hours % 1) * 60)
        const minutesStr: string = this.formatDateNumber(minutes)

        return `${hoursStr}:${minutesStr}`
    }

    public convertDDMMYYYYToTimestamp(time: string): string {
        const [day, month, year] = this.extractPartsOfDate(time)
        return new Date(year, month - 1, day).getTime().toString()
    }

    public convertMMDDYYYYToTimestamp(time: string): string {
        const [month, day, year] = this.extractPartsOfDate(time)
        return new Date(year, month - 1, day).getTime().toString()
    }

    public extractPartsOfDate(time: string): number[] {
        return [parseInt(time.slice(0, 2)), parseInt(time.slice(3, 5)), parseInt(time.slice(6, 10))]
    }

    public getRoundNumber(number: number): number {
        return Math.round(number)
    }

    // -------------------------------------------------------------------------
    // Notifications handlers
    // -------------------------------------------------------------------------

    /**
     * Show the notification banner
     * @param {string} description The message to display
     * @param {PortalNotificationType} notificationType The type of the notification
     * @param {string | undefined} title The title to display (otherwise, default title)
     * @param {string | undefined} linkTitle The text for a link button in the notification
     * @param {() => void | undefined} linkCallback The function to call when clicking on the link text
     * @param {number} displayTime Time to display the notification (default : 5sec)
     */
    protected showNotification(
        description: string,
        notificationType: PortalNotificationType = PortalNotificationType.Success,
        title: string | undefined = undefined,
        linkTitle: string | undefined = undefined,
        linkCallback: () => void | undefined = undefined,
        displayTime = 5,
    ): void {
        this.notificationsService.showNotification(
            description,
            notificationType,
            title,
            linkTitle,
            linkCallback,
            displayTime,
        )
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    /**
     * Handle errors through the app
     * @param {any} error Error to handle
     * @param {boolean} disconnectUser Disconnect user after 403 error
     */
    protected handleError(error: any, disconnectUser: boolean = true): void {
        console.log(error)

        if (error["status"] === 403) {
            if (disconnectUser) {
                this.disconnectUser()
            } else {
                // No need to wait for the message, message can be displayed during redirection
                this.showNotification("common_unauthorized", PortalNotificationType.Error)

                this.navigateToMainPage()
            }
        }
    }

    protected showErrorOccurred(): void {
        this.showNotification("common_error", PortalNotificationType.Error)
    }

    /**
     * Disconnect the user and redirect to login page.
     */
    protected disconnectUser(): void {
        this.authService.disconnectUser()

        this.navigateToMainPage()
    }

    /**
     * Get the value of the specified Query parameter.
     * @param {string} param Name of the parameter
     * @return the value of the specified Query parameter
     */
    protected getQueryParam(param: string): string {
        const currentUri: string = window.location.search.substring(1)
        const urParts: string[] = currentUri.split("&")

        for (let i = 0; i < urParts.length; i++) {
            const parameters: string[] = urParts[i].split("=")

            if (parameters[0] == param) {
                return parameters[1]
            }
        }
    }

    public hideFooter() {
        const footer: HTMLElement = document.getElementById("footer")
        footer.style.display = "none"
    }

    public hideNavBar() {
        const navBar: HTMLElement = document.getElementById("navBar")
        navBar.style.display = "none"
    }

    /**
     * Reload the page
     */
    public reloadPage(): void {
        window.location.reload()
    }

    public async isAccountConfirmedAsync(): Promise<boolean> {
        await this.sunriseApiService.setIsAccountConfirmedAsync()

        return this.authService.getAccountConfirmed()
    }
}
