import { Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { PortalNotificationType } from "../../../models/PortalNotification"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { NotEmptyRule } from "../../../validation/rules/NotEmptyRule"
import { ValidatableObject } from "../../../validation/ValidatableObject"
import { BaseComponent } from "../../base.component"
import { DelayButtonComponent } from "../../tools/delay-button/delay-button.component"

@Component({
    selector: "app-security",
    templateUrl: "./security.component.html",
    styleUrls: ["./security.component.sass"],
})
export class SecurityComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public readonly disableButtonTimeInSeconds: number = 60

    public isLoading = true
    public isUpdatingSecurity = false

    public emailTwoFANeedVerification = false
    public activationAlreadyRequested = false
    public useEmailTwoFA: boolean

    public verificationCode: ValidatableObject

    @ViewChild("resendVerificationCodeButton") resendVerificationCodeButton: DelayButtonComponent
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        await this.loadAccountSecurityAsync()

        this.initValidatableObjets()
    }

    // -------------------------------------------------------------------------
    // Initialization
    // -------------------------------------------------------------------------

    private async loadAccountSecurityAsync(): Promise<void> {
        this.isLoading = true

        const account = await this.getAccountDataAsync()

        this.useEmailTwoFA = account.twoFATypes.includes("email")

        this.isLoading = false
    }

    private initValidatableObjets(): void {
        this.verificationCode = new ValidatableObject(this.translate, "security_verificationCode")
        this.verificationCode.addRule(new NotEmptyRule(this.translate))
    }

    // -------------------------------------------------------------------------
    // Validation
    // -------------------------------------------------------------------------

    private async validateVerificationCodeAsync(): Promise<boolean> {
        return await this.verificationCode.validateAsync()
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public navigateBack(): void {
        this.router.navigate(["main/account"])
    }

    public async updateSecurityAsync(): Promise<void> {
        await this.verifyTwoFACodeAsync()
    }

    public async switchEmailTwoFAAsync(enableTwoFA: boolean): Promise<void> {
        this.emailTwoFANeedVerification = enableTwoFA

        if (enableTwoFA) {
            if (!this.activationAlreadyRequested) {
                this.activationAlreadyRequested = true

                await this.requestTwoFAAsync()
            }
        } else {
            if (this.useEmailTwoFA) {
                await this.disableTwoFAAsync()
            }
        }
    }

    // -------------------------------------------------------------------------
    // 2FA helpers
    // -------------------------------------------------------------------------

    private async disableTwoFAAsync(): Promise<void> {
        try {
            await this.sunriseApiService.updateTwoFAAsync(false)

            this.useEmailTwoFA = false
        } catch (error) {
            await this.showErrorOccurredAsync()
        }
    }

    private async requestTwoFAAsync(resend = false): Promise<void> {
        this.resendVerificationCodeButton.startDelay()

        try {
            await this.sunriseApiService.updateTwoFAAsync(true, resend)
        } catch (error) {
            await this.showErrorOccurredAsync()

            await this.resendVerificationCodeButton.resetDelayAsync()
        }
    }

    private async verifyTwoFACodeAsync(): Promise<void> {
        const isValid: boolean = await this.validateVerificationCodeAsync()

        if (!isValid) {
            return
        }

        try {
            this.isUpdatingSecurity = true

            await this.sunriseApiService.verifyTwoFACodeAsync(this.verificationCode.value)

            this.useEmailTwoFA = true

            this.showNotification("security_2fa_enabled", PortalNotificationType.Success)

            this.activationAlreadyRequested = false
            this.emailTwoFANeedVerification = false
        } catch (error) {
            if (error.status === 401) {
                await this.showWrongVerificationCodeErrorAsync()
            } else {
                await this.showErrorOccurredAsync()
            }
        } finally {
            this.isUpdatingSecurity = false
        }
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async resendVerificationCode(): Promise<void> {
        await this.requestTwoFAAsync(true)
    }

    private async showWrongVerificationCodeErrorAsync(): Promise<void> {
        this.verificationCode.errorString = await this.__("security_verificationCode_error")
        this.verificationCode.hasError = true
    }

    protected async showErrorOccurredAsync(): Promise<void> {
        this.verificationCode.errorString = await this.__("common_error")
        this.verificationCode.hasError = true
    }

    private async getAccountDataAsync(): Promise<any> {
        try {
            const res: any = await this.sunriseApiService.getMeAsync()

            return res["data"]["account"]
        } catch (error) {
            this.handleError(error)
        }
    }
}
