  <!-- Test synchronized charts -->
  <div class="report-section" [style.display]="isResearcher ? 'block' : 'none'">
    <!-- Separator -->
    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>
    <!-- Separator -->

    <!-- Section title -->
    <div class="row row--guttered-h section-title-container">
        <h2 class="title-h2 section-title" (click)="toggleReportSection($event)">{{ 'nightReport_edf_values' | translate }}</h2>
    </div>
    <!-- Section title -->
    <div class="section-content">
        <div [style.display]="needEdfChart ? 'none' : 'block'">
            <p class="row row--guttered-h">{{'nightReport_edf_downloading_information_text' | translate}}</p>

            <div class="download-edf-buttons">
                <app-button
                    [loading]="isEdfLoading"
                    (clicked)="initAndDisplaySynchronizedChartComponentsAsync()"
                    [customClasses]="'button--margin'"
                >
                    {{ 'nightReport_edf_view_button' | translate }}
                </app-button>

                <app-button
                    [loading]="isEdfExporting"
                    (clicked)="exportEdf()"
                    [customClasses]="'button--margin'"
                >
                    {{ 'nightReport_edf_download_button' | translate }}
                </app-button>
            </div>
        </div>

        <div id="synchronized" [style.display]="needEdfChart ? 'block' : 'none'">
            <app-button
                (clicked)="resetAllZoom()"
                [disabled]="!canResetAllZoom"
                [customClasses]="'reset-zoom-button'"
            >
                {{ 'nightReport_edf_reset_zoom' | translate }}
            </app-button>

            <!-- Chart 0 -->
            <div class="row row--guttered-h">
                <div class="list-row list-row--no-border list-row--no-padding">
                    <div class="col--tiny setter-container setter-container--first">
                        <div class="row">
                            <p class="vertical-container">
                                {{ 'nightReport_charts_raw_data_yAxis_title' | translate}}
                            </p>
                        </div>
                    </div>

                    <div class="col--tiny setter-container setter-container--first blockSize"></div>

                    <div class="col">
                        <div class="synchronizedGraph" id="RawPredictionsChart"></div>
                    </div>
                </div>
            </div>
            <!-- Chart 0 -->

            <!-- List of chart draggable (1 to 6 chart) -->
            <div id="list-chart-draggable">
                <!-- Chart 1 -->
                <div class="row row--guttered-h chart-spacing">
                  <div class="chart-opaque" id="chart1">
                    <div class="list-row list-row--no-border list-row--no-padding">
                        <div class="col--tiny setter-container handle">
                            <div class="row">
                                <p class="vertical-container">
                                    {{ 'nightReport_charts_acc_x_yAxis_title' | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="col--tiny setter-container">
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('AccXChart', true)"
                                >
                                     +
                                </app-button>
                            </div>
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('AccXChart', false)"
                                >
                                     -
                                </app-button>
                            </div>
                        </div>
                        <div class="col">
                            <div class="synchronizedGraph" id="AccXChart"></div>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- Chart 1 -->
                <!-- Chart 2 -->
                <div class="row row--guttered-h chart-spacing">
                  <div class="chart-opaque"  id="chart2">
                    <div class="list-row list-row--no-border list-row--no-padding">
                        <div class="col--tiny setter-container handle">
                            <div class="row">
                                <p class="vertical-container">
                                    {{ 'nightReport_charts_acc_y_yAxis_title' | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="col--tiny setter-container">
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('AccYChart', true)"
                                >
                                     +
                                </app-button>
                            </div>
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('AccYChart', false)"
                                >
                                     -
                                </app-button>
                            </div>
                        </div>
                        <div class="col">
                            <div class="synchronizedGraph" id="AccYChart"></div>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- Chart 2 -->
                <!-- Chart 3 -->
                <div class="row row--guttered-h chart-spacing">
                  <div class="chart-opaque" id="chart3">
                    <div class="list-row list-row--no-border list-row--no-padding">
                        <div class="col--tiny setter-container handle">
                            <div class="row">
                                <p class="vertical-container">
                                    {{ 'nightReport_charts_acc_z_yAxis_title' | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="col--tiny setter-container">
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('AccZChart', true)"
                                >
                                     +
                                </app-button>
                            </div>
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('AccZChart', false)"
                                >
                                     -
                                </app-button>
                            </div>
                        </div>
                        <div class="col">
                            <div class="synchronizedGraph" id="AccZChart"></div>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- Chart 3 -->
                <!-- Chart 4 -->
                <div class="row row--guttered-h chart-spacing">
                  <div class="chart-opaque" id="chart4">
                    <div class="list-row list-row--no-border list-row--no-padding">
                        <div class="col--tiny setter-container handle">
                            <div class="row">
                                <p class="vertical-container">
                                    {{ 'nightReport_charts_gyro_x_yAxis_title' | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="col--tiny setter-container">
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('GyroXChart', true)"
                                >
                                     +
                                </app-button>
                            </div>
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('GyroXChart', false)"
                                >
                                     -
                                </app-button>
                            </div>
                        </div>
                        <div class="col">
                            <div class="synchronizedGraph" id="GyroXChart"></div>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- Chart 4 -->
                <!-- Chart 5 -->
                <div class="row row--guttered-h chart-spacing">
                  <div class="chart-opaque" id="chart5">
                    <div class="list-row list-row--no-border list-row--no-padding">
                        <div class="col--tiny setter-container handle">
                            <div class="row">
                                <p class="vertical-container">
                                    {{ 'nightReport_charts_gyro_y_yAxis_title' | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="col--tiny setter-container">
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('GyroYChart', true)"
                                >
                                     +
                                </app-button>
                            </div>
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('GyroYChart', false)"
                                >
                                     -
                                </app-button>
                            </div>
                        </div>
                        <div class="col">
                            <div class="synchronizedGraph" id="GyroYChart"></div>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- Chart 5 -->
                <!-- Chart 6 -->
                <div class="row row--guttered-h chart-spacing">
                  <div class="chart-opaque" id="chart6">
                    <div class="list-row list-row--no-border list-row--no-padding">
                        <div class="col--tiny setter-container handle">
                            <div class="row">
                                <p class="vertical-container">
                                    {{ 'nightReport_charts_gyro_z_yAxis_title' | translate}}
                                </p>
                            </div>
                        </div>
                        <div class="col--tiny setter-container">
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('GyroZChart', true)"
                                >
                                     +
                                </app-button>
                            </div>
                            <div class="row">
                                <app-button
                                    [customClasses]="'setter-button'"
                                    (clicked)="setExtremes('GyroZChart', false)"
                                >
                                     -
                                </app-button>
                            </div>
                        </div>
                        <div class="col">
                            <div class="synchronizedGraph" id="GyroZChart"></div>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- Chart 6 -->
            </div>

            <!-- Channel visibility control -->
            <div class="container-channel-control">
                <!-- visibility of channels-->
                <div class="wrapper-channels-control">
                    <!-- Accelerometer 1 -->
                    <div class="channel-control">
                        <div class="channel-control-circle"></div>
                        <p class="channel-control-name" id="channel1">{{ 'nightReport_charts_acc_x_yAxis_title' | translate }}</p>
                    </div>
                    <!-- Accelerometer 1 -->

                    <!-- Accelerometer 2 -->
                    <div class="channel-control">
                        <div class="channel-control-circle"></div>
                        <p class="channel-control-name" id="channel2">{{ 'nightReport_charts_acc_y_yAxis_title' | translate }}</p>
                    </div>
                    <!-- Accelerometer 2 -->

                    <!-- Accelerometer 3 -->
                    <div class="channel-control">
                        <div class="channel-control-circle"></div>
                        <p class="channel-control-name" id="channel3">{{ 'nightReport_charts_acc_z_yAxis_title' | translate }}</p>
                    </div>
                    <!-- Accelerometer 3 -->

                    <!-- Gyroscope 1 -->
                    <div class="channel-control">
                        <div class="channel-control-circle"></div>
                        <p class="channel-control-name" id="channel4">{{ 'nightReport_charts_gyro_x_yAxis_title' | translate }}</p>
                    </div>
                    <!-- Gyroscope 1 -->

                    <!-- Gyroscope 2 -->
                    <div class="channel-control">
                        <div class="channel-control-circle"></div>
                        <p class="channel-control-name" id="channel5">{{ 'nightReport_charts_gyro_y_yAxis_title' | translate }}</p>
                    </div>
                    <!-- Gyroscope 2 -->

                    <!-- Gyroscope 3 -->
                    <div class="channel-control">
                        <div class="channel-control-circle"></div>
                        <p class="channel-control-name" id="channel6">{{ 'nightReport_charts_gyro_z_yAxis_title' | translate }}</p>
                    </div>
                    <!-- Gyroscope 3 -->
                </div>
            </div>
        </div>

        <div class="download-edf-buttons" [style.display]="needEdfChart ? 'block' : 'none'">
            <app-button
                [loading]="isEdfExporting"
                (clicked)="exportEdf()"
                [customClasses]="'button--margin'"
            >
                {{ 'nightReport_edf_download_button' | translate }}
            </app-button>
        </div>
    </div>
</div>
<!-- Test synchronized charts -->


