export enum NightSignalType {
    Awake = "aw",
    Arousals = "ar",
    Obstructive = "obs",
    Normal = "normal",
    SleepStages = "sleepStages",
    Bruxism = "bxm",
    Head = "head",
    HeadSideAngle = "headSideAngle",
    SleepStagesProba = "sleepStagesProba",

    Central = "central",

    Events = "events",
    ShortEvents = "shortEvents",
    LongEvents = "longEvents",

    Timestamp = "timestamps",
    Disconnections = "disconnections",
}
