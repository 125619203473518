export enum Lang {
    En = "en",
    Fr = "fr",
    Nl = "nl",
    De = "de",
}

export function getLocalCountryFromLang(lang: string): string {
    let countryCode = "en_US"

    switch (lang) {
        case Lang.Fr:
            countryCode = "fr_FR"
            break
        case Lang.Nl:
            countryCode = "nl_BE"
            break
        case Lang.De:
            countryCode = "de_DE"
            break
    }

    return countryCode
}
