import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "../../../services/auth.service"
import { TranslatableComponent } from "../../translatable.component"

@Component({
    selector: "drop-down-menu",
    templateUrl: "./drop-down-menu.component.html",
    styleUrls: ["./drop-down-menu.component.sass"],
})
export class DropDownMenuComponent extends TranslatableComponent {
    @Input() selectedItem: any
    @Input() items: any[]

    @Output() selectedItemChange = new EventEmitter<any>()

    public showSubLinks = false

    @HostListener("document:click", ["$event"])
    private onClick(event: MouseEvent) {
        if (this.showSubLinks && !this.elementRef.nativeElement.contains(event.target)) {
            this.showSubLinks = false
        }
    }

    constructor(
        protected readonly authService: AuthService,
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly elementRef: ElementRef,
    ) {
        super(translate)
    }

    public switchSubLinks(): void {
        this.showSubLinks = !this.showSubLinks
    }

    public setSelectedItem(item: any): void {
        this.selectedItem = item
        this.selectedItemChange.emit(item)
    }
}
