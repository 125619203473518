import { HttpClient } from "@angular/common/http"
import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import * as Highcharts from "highcharts"
import highchartsBoost from "highcharts/modules/boost"
import * as _ from "lodash"
import { constants } from "../../../config/constants"
import { SignalChart } from "../../../models/nightReport/rawData/SignalChart"
import { ChartType } from "../../../models/Nights/ChartType"
import { PortalNotificationType } from "../../../models/PortalNotification"
import { AuthService } from "../../../services/auth.service"
import { NotificationsService } from "../../../services/notifications.service"
import { RawDataService } from "../../../services/raw-data.service"
import { SunriseApiService } from "../../../services/sunrise-api.service"
import { BaseComponent } from "../../base.component"

highchartsBoost(Highcharts)

// -----------------------------------------------------------------------------
// Global declarations
// -----------------------------------------------------------------------------

// Get Sortable (sortable.js)
declare const Sortable: any

// Declare highlight function on Point of highcharts.
// Used for synchornized tooltips on hover on signal charts
declare module "highcharts" {
    interface Point {
        highlight(event: Highcharts.PointerEventObject): void
    }
}

/**
 * Override the reset function because we do not need to hide the tooltips and
 * crosshairs. Used for synchornized tooltips on hover on signal charts.
 */
Highcharts.Pointer.prototype.reset = function () {
    return undefined
}

/**
 * Highlight a point by showing tooltip, setting hover state and draw crosshair.
 * Used for synchornized tooltips on hover on signal charts.
 */
Highcharts.Point.prototype.highlight = function () {
    this.onMouseOver() // Show the hover marker
    this.series.chart.tooltip.refresh(this) // Show the tooltip
}

// -----------------------------------------------------------------------------
// NightReportRawDataComponent
// -----------------------------------------------------------------------------

@Component({
    selector: "app-night-report-raw-data",
    templateUrl: "./night-report-raw-data.component.html",
    styleUrls: ["./night-report-raw-data.component.sass"],
})
export class NightReportRawDataComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables
    // -------------------------------------------------------------------------

    @Input("nightId") nightId: string
    @Input("nightVersionId") nightVersionId: string
    @Input("night") night: any
    @Input("patient") patient: any
    @Input("sampleReport") isSampleReport: boolean

    private chartsData = {
        predictions_times: [],
        predictions_aw: [],
        predictions_ar: [],
        predictions_obs: [],
        predictions_central: [],
        predictions_normal: [],
        predictions_bxm: [],
        predictions_disconnections: [],

        raw_times: [],
        raw_acc_x: [],
        raw_acc_y: [],
        raw_acc_z: [],
        raw_gyro_x: [],
        raw_gyro_y: [],
        raw_gyro_z: [],

        acc_x: [],
        acc_y: [],
        acc_z: [],
        gyro_x: [],
        gyro_y: [],
        gyro_z: [],
    }

    private isSynchronizingChartsZoom = false

    public canGenerateReport = false
    public canResetPredictions = false
    public canResetAllZoom = false
    public isEditing = false
    public isEdfExporting = false
    public isEdfLoading = false
    public isGeneratingReport = false
    public isResearcher: boolean
    public needEdfChart = false
    public showBxm: boolean
    public series: any = {
        Awake: "aw",
        Microarousal: "ar",
        Obstructive: "obs",
        Normal: "normal",
        Bxm: "bxm",
        Disconnections: "disconnections",
    }

    public Highcharts: typeof Highcharts
    public eChartType: typeof ChartType = ChartType

    private indexOfRawDataChartInChartsList: number

    // -------------------------------------------------------------------------
    // Constructors
    // -------------------------------------------------------------------------

    constructor(
        protected readonly authService: AuthService,
        protected readonly router: Router,
        private http: HttpClient,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly translate: TranslateService,
        protected readonly notificationsService: NotificationsService,
        private readonly rawDataService: RawDataService,
        protected readonly route: ActivatedRoute,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.Highcharts = Highcharts
    }

    // -------------------------------------------------------------------------
    // Angular component initialization
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        this.isResearcher = this.sunriseApiService.accountHasRole(constants.roles.researcher)
    }

    // -------------------------------------------------------------------------
    // Raw data charts
    // -------------------------------------------------------------------------

    /**
     * Initialize and display the raw data charts (predictions chart and signals
     * charts) with preferred disposition (order and visibility) and last saved
     * state (draft of updated predictions). This method is called from
     * night-report.component.ts.
     */
    public async initAndDisplaySynchronizedChartComponentsAsync() {
        try {
            this.isEdfLoading = true

            let uri: string

            if (this.isSampleReport) {
                uri = await this.sunriseApiService.getEdfDataSampleReport()
            } else {
                uri = await this.sunriseApiService.getEdfDataFileUriAsync(this.isAdmin, this.nightId, this.patient.id)
            }

            const fileContent = await new Promise<string>((resolve, reject) => {
                this.http.get(uri, { responseType: "text" }).subscribe({
                    next: (data) => {
                        resolve(data.toString())
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            })

            const edfData: any = JSON.parse(fileContent)

            this.rawDataService.computeEdfData(edfData, this.chartsData, this.night)

            this.showBxm = !_.isEmpty(this.chartsData.predictions_bxm)
            this.indexOfRawDataChartInChartsList = Highcharts.charts.length

            await this.drawSynchronizedCharts()
            this.initVisibilityControlsOfSignalsCharts()
            this.initStortableForSignalsCharts()

            this.needEdfChart = true
        } catch (error) {
            console.log(error)

            if (error.status === 404) {
                this.showNotification("common_not_found", PortalNotificationType.Error)
            } else {
                this.showErrorOccurred()
            }
        } finally {
            this.isEdfLoading = false
        }
    }

    // -------------------------------------------------------------------------
    // Display charts
    // -------------------------------------------------------------------------

    /**
     * Display all synchronized charts, including predictions chart and signals
     * charts.
     */
    private async drawSynchronizedCharts(): Promise<void> {
        await this.displayRawDataChartAsync()
        await this.displaySignalsChartsAsync()
    }

    // -------------------------------------------------------------------------
    // Display charts: Predictions chart
    // -------------------------------------------------------------------------

    private async displayRawDataChartAsync(): Promise<void> {
        const chartData: any[] = [
            _.cloneDeep(this.chartsData.predictions_aw),
            _.cloneDeep(this.chartsData.predictions_ar),
            _.cloneDeep(this.chartsData.predictions_obs),
            _.cloneDeep(this.chartsData.predictions_normal),
            _.cloneDeep(this.chartsData.predictions_disconnections),
        ]

        const chartLegendLabels: string[] = [
            await this.__("nightReport_charts_legend_wakefulness_label"),
            await this.__("nightReport_charts_legend_arousal_label"),
            await this.__("nightReport_charts_legend_obstructive_label"),
            await this.__("nightReport_charts_legend_normal_label"),
            await this.__("nightReport_charts_legend_disconnections_label"),
        ]

        let hasBxmPrediction = false
        let hasCentralPrediction = false

        if (!_.isEmpty(this.chartsData.predictions_central)) {
            chartData.push(_.cloneDeep(this.chartsData.predictions_central))
            chartLegendLabels.push("central")

            hasCentralPrediction = true
        }

        if (!_.isEmpty(this.chartsData.predictions_bxm)) {
            chartData.push(_.cloneDeep(this.chartsData.predictions_bxm))
            chartLegendLabels.push(await this.__("nightReport_charts_legend_bruxism_label"))

            hasBxmPrediction = true
        }

        const chartOptions: any = {
            boost: { enabled: false },
            chart: {
                animation: false,
                height: 190,
                marginBottom: 50,
                marginLeft: 100,
                marginRight: 0,
                marginTop: 8,
                panKey: "shift",
                panning: {
                    enabled: true,
                    type: "x",
                },
                selectionMarkerFill: "rgba(237, 237, 237, 0.5)",
                shadow: false,
                spacing: [0, 0, 0, 0],
                type: "area",
                zoomType: "x",
            },
            credits: { enabled: false },
            legend: {
                align: "right",
                enabled: true,
            },
            plotOptions: {
                area: { stacking: "percent" },
                series: {
                    enableMouseTracking: true,
                    marker: { enabled: false },
                    states: {
                        hover: { enabled: false },
                        inactive: { opacity: 1 },
                    },
                    step: "right",
                    stickyTracking: false,
                },
            },
            series: [
                {
                    id: "aw",
                    color: "#ff8c8c",
                    data: chartData[0],
                    fillColor: "#ff8c8c",
                    name: chartLegendLabels[0],
                },
                {
                    id: "ar",
                    color: "#ffcfa0",
                    data: chartData[1],
                    fillColor: "#ffcfa0",
                    name: chartLegendLabels[1],
                },
                {
                    id: "obs",
                    color: "#80bfff",
                    data: chartData[2],
                    fillColor: "#80bfff",
                    name: chartLegendLabels[2],
                },
                {
                    id: "normal",
                    color: "#67ffb3",
                    data: chartData[3],
                    fillColor: "#67ffb3",
                    name: chartLegendLabels[3],
                },
                {
                    id: "disconnections",
                    color: "#d9d9d9",
                    data: chartData[4],
                    fillColor: "#d9d9d9",
                    name: chartLegendLabels[4],
                },
            ],
            subtitle: { text: null },
            title: { text: null },
            tooltip: {
                animation: false,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
                borderRadius: 0,
                borderWidth: 0,
                enabled: true,
                followPointer: true,
                formatter: function (tooltip: Highcharts.Tooltip) {
                    let tooltipLabel: string = tooltip.defaultFormatter.call(this, tooltip)

                    if (!this.point.isNull) {
                        const indexOfX: number = this.series.xData.indexOf(this.x)

                        const formattedTime: string = tooltipLabel[0].slice(30, -14)

                        const awakePrediction: number = chartData[0][indexOfX][1]
                        const arousalPrediction: number = chartData[1][indexOfX][1]
                        const obstructivePrediction: number = chartData[2][indexOfX][1]
                        const normalPrediction: number = chartData[3][indexOfX][1]
                        const disconnectionPrediction: number = chartData[4][indexOfX][1]

                        const tooltipLabelParts: string[] = []
                        tooltipLabelParts.push('<p style="font-size: 10px">', `<span>${formattedTime}</span><br>`)

                        if (awakePrediction == 1) {
                            tooltipLabelParts.push(`<span style="color: #ff8c8c">●</span> ${chartLegendLabels[0]}`)
                        } else if (arousalPrediction == 1) {
                            tooltipLabelParts.push(`<span style="color: #ffcfa0">●</span> ${chartLegendLabels[1]}`)
                        } else if (obstructivePrediction == 1) {
                            tooltipLabelParts.push(`<span style="color: #80bfff">●</span> ${chartLegendLabels[2]}`)
                        } else if (normalPrediction == 1) {
                            tooltipLabelParts.push(`<span style="color: #67ffb3">●</span> ${chartLegendLabels[3]}`)
                        } else if (disconnectionPrediction == 1) {
                            tooltipLabelParts.push(`<span style="color: #d9d9d9">●</span> ${chartLegendLabels[4]}`)
                        }

                        if (hasCentralPrediction) {
                            const centralPrediction: number = chartData[5][indexOfX][1]

                            if (centralPrediction > 0) {
                                tooltipLabelParts.push(
                                    `<br><span style="color: rgba(243, 248, 98, 0.5)">●</span> ${chartLegendLabels[5]}`,
                                )
                            }
                        }

                        if (hasBxmPrediction) {
                            const predictionIndex: number = hasCentralPrediction ? 6 : 5
                            const rmmaPrediction: number = chartData[predictionIndex][indexOfX][1]

                            if (rmmaPrediction > 0) {
                                tooltipLabelParts.push(
                                    `<br><span style="color: rgba(0, 0, 0, 0.5)">●</span> ${chartLegendLabels[predictionIndex]}`,
                                )
                            }
                        }

                        tooltipLabelParts.push("</p>")

                        tooltipLabel = tooltipLabelParts.join("")
                    }

                    return tooltipLabel
                },
                padding: 5,
                positioner: function (labelWidth, labelHeight, point) {
                    return { x: point.plotX + 100 - labelWidth, y: 0 }
                },
                shadow: false,
                xDateFormat: "%H:%M:%S.%L",
            },
            xAxis: {
                crosshair: {
                    color: "#CCCCCC",
                    snap: false,
                    width: 2,
                    zIndex: 10,
                },
                endOnTick: false,
                events: {
                    afterSetExtremes: (e: any) => {
                        this.synchronizeRawDataChartsOnXAxisZoom(e)
                        this.addPlotlineZoom(e)
                    },
                },
                gridLineWidth: 0,
                labels: {
                    enabled: true,
                    distance: 10,
                    formatter: function () {
                        // Display label each 10s interval.
                        const shouldDisplayLabel: boolean = this.value % 10000 == 0
                        const label: string = shouldDisplayLabel ? this.axis.defaultLabelFormatter.call(this) : ""

                        return label
                    },
                },
                lineColor: "#CCCCCC",
                lineWidth: 2,
                // Set max to null to let Highcharts compute the value
                // automatically. See:
                // https://api.highcharts.com/highcharts/xAxis.max.
                max: null,
                maxPadding: 0,
                // Set min to null to let Highcharts compute the value
                // automatically. See:
                // https://api.highcharts.com/highcharts/xAxis.min.
                min: null,
                minPadding: 0,
                minRange: 60000, // Display at least 1 minute (at 10 Hz).
                minorGridLineWidth: 0,
                minorTickInterval: 1000,
                startOnTick: false,
                states: {
                    hover: { enabled: false },
                    inactive: { opacity: 0 },
                },
                tickColor: "#CCCCCC",
                tickLength: 5,
                tickPixelInterval: 100,
                tickPosition: "outside",
                tickWidth: 1,
                title: { text: null },
                type: "datetime",
                dateTimeLabelFormats: {
                    minute: this.rawDataService.getDateTimeLabelFormat(this.authService.getPreferences()),
                    hour: this.rawDataService.getDateTimeLabelFormat(this.authService.getPreferences()),
                    day: this.rawDataService.getDateTimeLabelFormat(this.authService.getPreferences()),
                },
                visible: true,
                zoomEnabled: true,
            },
            yAxis: {
                endOnTick: true,
                gridLineColor: "#CCCCCC",
                gridLineWidth: 1,
                labels: {
                    enabled: true,
                    formatter: function () {
                        // Display only first and last labels.
                        const shouldDisplayLabel: boolean = this.isFirst || this.isLast
                        const label: number | string = shouldDisplayLabel ? Math.round(this.value * 100) / 100 : ""

                        return label
                    },
                },
                lineColor: "#CCCCCC",
                lineWidth: 2,
                max: 100,
                min: 0,
                minPadding: 0,
                minorGridLineWidth: 0,
                minorTickWidth: 0,
                maxPadding: 0,
                startOnTick: true,
                tickColor: "#CCCCCC",
                tickLength: 10,
                tickPosition: "outside",
                tickWidth: 1,
                title: { text: "" },
                visible: true,
                zoomEnabled: false,
            },
        }

        if (hasCentralPrediction) {
            chartOptions.series.push({
                id: "central",
                color: "rgba(243, 248, 98, 1)",
                data: chartData[5],
                fillColor: "rgba(243, 248, 98, 0.5)",
                name: chartLegendLabels[5],
                zIndex: 5,
            })
        }

        if (hasBxmPrediction) {
            const predictionIndex: number = hasCentralPrediction ? 6 : 5

            chartOptions.series.push({
                id: "bxm",
                color: "rgba(0, 0, 0, 0.5)",
                data: chartData[predictionIndex],
                fillColor: "rgba(0, 0, 0, 0.25)",
                name: chartLegendLabels[predictionIndex],
                zIndex: predictionIndex,
            })
        }

        Highcharts.chart("RawPredictionsChart", chartOptions)

        // Disable scroll with keyboard
        window.addEventListener("keydown", this.rawDataService.disableScroll, false)
        // Add event binded by control zooming and moving left and right on chart
        window.addEventListener("keydown", this.eventKey.bind(this), false)
    }

    // -------------------------------------------------------------------------
    // Display charts: Signals charts
    // -------------------------------------------------------------------------

    /**
     * Display signals charts in the raw data section.
     */
    private async displaySignalsChartsAsync(): Promise<void> {
        // List charts to display.
        const accelerometerUnit: string = await this.__("nightReport_charts_acc_unit")
        const gyroscopeUnit: string = await this.__("nightReport_charts_gyro_unit")
        const charts: SignalChart[] = [
            {
                identifier: "AccXChart",
                unitText: accelerometerUnit,
                data: this.chartsData.acc_x,
                highchartsOptions: {
                    yAxis: { tickInterval: 0.0001 },
                },
            },
            {
                identifier: "AccYChart",
                unitText: accelerometerUnit,
                data: this.chartsData.acc_y,
                highchartsOptions: {
                    yAxis: { tickInterval: 0.0001 },
                },
            },
            {
                identifier: "AccZChart",
                unitText: accelerometerUnit,
                data: this.chartsData.acc_z,
                highchartsOptions: {
                    yAxis: { tickInterval: 0.0001 },
                },
            },
            {
                identifier: "GyroXChart",
                unitText: gyroscopeUnit,
                data: this.chartsData.gyro_x,
                highchartsOptions: {
                    yAxis: { tickInterval: 0.0001 },
                },
            },
            {
                identifier: "GyroYChart",
                unitText: gyroscopeUnit,
                data: this.chartsData.gyro_y,
                highchartsOptions: {
                    yAxis: { tickInterval: 0.0001 },
                },
            },
            {
                identifier: "GyroZChart",
                unitText: gyroscopeUnit,
                data: this.chartsData.gyro_z,
                highchartsOptions: {
                    yAxis: { tickInterval: 0.0001 },
                },
            },
        ]

        // display charts
        const chartsCount: number = charts.length

        for (let i = 0; i < chartsCount; i++) {
            const chart: SignalChart = charts[i]

            // display X axis only on the last signal chart
            const displayXAxis: boolean = i == chartsCount - 1

            this.displaySignalChart(chart, displayXAxis)
        }

        // set order and visibility of charts based on stored preferences
        await this.reOrderSignalsChartsBasedOnPreferencesAsync()
        await this.updateSignalsChartsVisibilityBasedOnPreferencesAsync()
        // attach event listener for synchronized tooltips on mouse over
        const events: string[] = ["mousemove", "touchmove", "touchstart"]

        for (const event of events) {
            document.getElementById("synchronized").addEventListener(event, this.onMouseOverRawDataChart.bind(this))
        }
    }

    /**
     * Display signal chart.
     * @param chart Signal chart
     * @param isVisibleXAxis Boolean indicating if the X axis is visible or not
     */
    private displaySignalChart(chart: SignalChart, isVisibleXAxis = false): void {
        // Define default options for series.
        let chartSeriesOption: Highcharts.SeriesLineOptions = {
            animation: false,
            boostThreshold: 1,
            color: "#E26756",
            data: chart.data,
            enableMouseTracking: true,
            lineWidth: 1,
            marker: {
                enabled: false,
                states: {
                    hover: { enabled: false },
                },
            },
            shadow: false,
            showInLegend: false,
            stickyTracking: false,
            turboThreshold: 1,
            type: "line",
        }

        // Merge default options for series with specific options for this
        // signal chart.
        chartSeriesOption = _.merge({}, chartSeriesOption, chart.highchartsSeriesOptions)

        // Define default options for series.
        let chartOptions: any = {
            boost: {
                allowForce: true,
                enabled: true,
                pixelRatio: 0,
                seriesThreshold: 1,
                // Disable GPU translations, because it causes glitch artefacts
                // with our dataset, see:
                // https://api.highcharts.com/highcharts/boost.useGPUTranslations
                useGPUTranslations: false,
                usePreallocated: false,
            },
            chart: {
                animation: false,
                height: 110 + (isVisibleXAxis ? 12 : 0),
                marginBottom: isVisibleXAxis ? 20 : 8,
                marginLeft: 100,
                marginRight: 0,
                marginTop: 8,
                panKey: "shift",
                panning: {
                    enabled: true,
                    type: "x",
                },
                selectionMarkerFill: "rgba(237, 237, 237, 0.5)",
                shadow: false,
                spacing: [0, 0, 0, 0],
                type: "line",
                zoomType: "x",
            },
            credits: { enabled: false },
            legend: { enabled: false },
            series: [chartSeriesOption],
            subtitle: { text: null },
            title: { text: null },
            tooltip: {
                animation: false,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
                borderRadius: 0,
                borderWidth: 0,
                enabled: true,
                followPointer: true,
                formatter: function (tooltip: Highcharts.Tooltip) {
                    let tooltipFormat: string | string[] = tooltip.defaultFormatter.call(this, tooltip)

                    if (Array.isArray(tooltipFormat) && tooltipFormat.length >= 2) {
                        const formattedTime = `${tooltipFormat[0].slice(30, -14)}`

                        tooltipFormat = `<p style="font-size: 10px">
                            <span>${formattedTime}</span><br>
                            ${tooltipFormat[1]}
                        </p>`
                    }

                    return tooltipFormat
                },
                padding: 5,
                pointFormat: "{point.y:.2f}",
                positioner: function (labelWidth, labelHeight, point) {
                    return { x: point.plotX + 100 - labelWidth, y: 8 }
                },
                shadow: false,
                xDateFormat: "%H:%M:%S.%L",
            },
            xAxis: {
                crosshair: {
                    color: "#CCCCCC",
                    snap: false,
                    width: 2,
                    zIndex: 10,
                },
                endOnTick: false,
                events: {
                    afterSetExtremes: this.synchronizeRawDataChartsOnXAxisZoom.bind(this),
                },
                gridLineWidth: 0,
                labels: {
                    enabled: true,
                    distance: 10,
                    formatter: function () {
                        // Display label each 10s interval.
                        const shouldDisplayLabel: boolean = this.value % 10000 == 0
                        const label: string = shouldDisplayLabel ? this.axis.defaultLabelFormatter.call(this) : ""

                        return label
                    },
                },
                lineColor: "#CCCCCC",
                lineWidth: 2,
                // Set max to null to let Highcharts compute the value
                // automatically. See:
                // https://api.highcharts.com/highcharts/xAxis.max.
                max: null,
                maxPadding: 0,
                // Set min to null to let Highcharts compute the value
                // automatically. See:
                // https://api.highcharts.com/highcharts/xAxis.min.
                min: null,
                minPadding: 0,
                minRange: 60000, // Display at least 1 minute (at 10 Hz).
                minorGridLineWidth: 0,
                minorTickInterval: 1000,
                startOnTick: false,
                states: {
                    hover: { enabled: false },
                    inactive: { opacity: 0 },
                },
                tickColor: "#CCCCCC",
                tickLength: 5,
                tickPixelInterval: 100,
                tickPosition: "outside",
                tickWidth: 1,
                title: { text: null },
                type: "datetime",
                dateTimeLabelFormats: {
                    minute: this.rawDataService.getDateTimeLabelFormat(this.authService.getPreferences()),
                    hour: this.rawDataService.getDateTimeLabelFormat(this.authService.getPreferences()),
                    day: this.rawDataService.getDateTimeLabelFormat(this.authService.getPreferences()),
                },
                visible: isVisibleXAxis,
                zoomEnabled: true,
            },
            yAxis: {
                endOnTick: true,
                gridLineColor: "#CCCCCC",
                gridLineWidth: 1,
                labels: {
                    enabled: true,
                    formatter: function () {
                        // Display only first and last labels.
                        const shouldDisplayLabel: boolean = this.isFirst || this.isLast
                        const label: number | string = shouldDisplayLabel ? Math.round(this.value * 100) / 100 : ""

                        return label
                    },
                },
                lineColor: "#CCCCCC",
                lineWidth: 2,
                // Set max to null to let Highcharts compute the value
                // automatically. See:
                // https://api.highcharts.com/highcharts/yAxis.max.
                max: null,
                // Set min to null to let Highcharts compute the value
                // automatically. See:
                // https://api.highcharts.com/highcharts/yAxis.min.
                min: null,
                minPadding: 0,
                minorGridLineWidth: 0,
                minorTickWidth: 0,
                maxPadding: 0,
                startOnTick: true,
                tickColor: "#CCCCCC",
                tickLength: 10,
                tickPosition: "outside",
                tickWidth: 1,
                title: {
                    style: { fontSize: "13px" },
                    text: chart.unitText,
                    textAlign: "center",
                },
                visible: true,
                zoomEnabled: false,
            },
        }

        // Merge default options for series with specific options for this
        // signal chart.
        chartOptions = _.merge({}, chartOptions, chart.highchartsOptions)

        Highcharts.chart(chart.identifier, chartOptions)
    }

    /**
     * Callback called when a signals chart is highlight by the mouse, to
     * synchronize tooltips.
     * @param {MouseEvent | TouchEvent} mouseOverEvent Mouse over event
     */
    private onMouseOverRawDataChart(mouseOverEvent: MouseEvent | TouchEvent): void {
        const signalsCharts: Highcharts.Chart[] = this.getAllRawDataCharts()

        let point: Highcharts.Point
        let pointerEvent: Highcharts.PointerEventObject

        for (const chart of signalsCharts) {
            // find coordinates within the chart
            pointerEvent = chart.pointer.normalize(mouseOverEvent)

            // get the hovered point
            point = chart.series[0].searchPoint(pointerEvent, true)

            if (point) {
                point.highlight(pointerEvent)
            }
        }
    }

    // -------------------------------------------------------------------------
    // Order signals charts based on preferences
    // -------------------------------------------------------------------------

    /**
     * Re-order signal charts based on signals order preferences.
     */
    private async reOrderSignalsChartsBasedOnPreferencesAsync(): Promise<void> {
        const chartsOrder: number[] = await this.rawDataService.getSignalsOrderPreferencesAsync()

        if (!_.isEmpty(chartsOrder)) {
            this.reOrderSignalsCharts(chartsOrder)
        }
    }

    /**
     * Re-order signal charts based on the given order.
     * @param {!SignalChart[]} charts Charts data
     * @param {number[]} chartsOrder Charts order as an array of number, where index
     * identifies the signal and value gives its position order.
     */
    private reOrderSignalsCharts(chartsOrder: number[]): void {
        const container: HTMLElement = document.querySelector("#list-chart-draggable")
        const chartsContainers: NodeListOf<HTMLElement> = container.querySelectorAll(":scope > *")

        // remove all current signals charts from view
        container.innerHTML = ""

        // append signals charts in the preferred order
        for (const chartIndex of chartsOrder) {
            container.appendChild(chartsContainers[chartIndex])
        }

        // replace the X axis on the last visible signals chart
        this.replaceXAxisOnCurrentLastVisibleSignalChart()
    }

    // -------------------------------------------------------------------------
    // Visibility signals charts based on preferences
    // -------------------------------------------------------------------------

    /**
     * Adapt visibility of signal charts based on signals visibility
     * preferences.
     */
    private async updateSignalsChartsVisibilityBasedOnPreferencesAsync(): Promise<void> {
        const signalsVisibility: boolean[] = await this.rawDataService.getSignalsVisibilityPreferencesAsync()

        if (!_.isEmpty(signalsVisibility)) {
            await this.updateSignalsChartsVisibilityAsync(signalsVisibility)
        }
    }

    /**
     * This method is called to set the channels with their save disposition
     * state.
     * @param {boolean[]} signalsVisibility Visibility options of signals charts
     */
    private async updateSignalsChartsVisibilityAsync(signalsVisibility: boolean[]): Promise<void> {
        for (let i = 0; i < signalsVisibility.length; i++) {
            const signalChartContainerId = `chart${i + 1}`
            const signalChartLegendId = `channel${i + 1}`

            this.showOrHideSignalsChart(signalChartContainerId, signalsVisibility[i])
            this.showOrHideSignalsChartLegend(signalChartLegendId, signalsVisibility[i])
        }

        // replace the X axis on the last visible signals chart
        this.replaceXAxisOnCurrentLastVisibleSignalChart()
    }

    // -------------------------------------------------------------------------
    // Dynamically re-order signals charts
    // -------------------------------------------------------------------------

    /**
     * Initializes new Sortable instance for signals charts. This uses the
     * sortable.js library.
     */
    private initStortableForSignalsCharts(): void {
        new Sortable(document.getElementById("list-chart-draggable"), {
            handle: ".handle",
            animation: 150,
            ghostClass: "blue-background-class",
            onChange: this.onSignalsOrderChangedAsync.bind(this),
        })
    }

    /**
     * Callback called when order of signals changed.
     */
    private async onSignalsOrderChangedAsync(): Promise<void> {
        // replace X axis on current last chart
        this.replaceXAxisOnCurrentLastVisibleSignalChart()

        // save new order in preferences
        await this.saveCurrentSignalsOrderAsync()
    }

    /**
     * Saves current signals order in preferences.
     */
    private async saveCurrentSignalsOrderAsync(): Promise<void> {
        const signalsOrder: number[] = this.getCurrentSignalsOrder()

        await this.rawDataService.setSignalsOrderPreferencesAsync(signalsOrder)

        // force resave signals visibility preferences since order changed
        await this.saveCurrentSignalsVisibilityAsync()
    }

    /**
     * Gets the current signals order.
     * @returns the current signals order
     */
    private getCurrentSignalsOrder(): number[] {
        // save new order in preferences
        const signalsElements: Element[] = Array.from(document.getElementsByClassName("handle"))
        const signalsOrder: number[] = []

        for (const element of signalsElements) {
            const id: number = parseInt(element.parentElement.parentElement.id.substr(5)) - 1

            signalsOrder.push(id)
        }

        return signalsOrder
    }

    // -------------------------------------------------------------------------
    // Dynamically show/hide signals charts
    // -------------------------------------------------------------------------

    /**
     * This function is called in the ngOninit function of the night-report component
     * This is used to control the visibility of the channels
     */
    private initVisibilityControlsOfSignalsCharts(): void {
        const channels: Element[] = Array.from(document.querySelectorAll(".channel-control-name"))

        for (const channel of channels) {
            channel.addEventListener("mouseenter", this.onMouseEnterOrLeaveSignalsChartsVisibilityControl)
            channel.addEventListener("mouseleave", this.onMouseEnterOrLeaveSignalsChartsVisibilityControl)
            channel.addEventListener("click", this.onClickSignalsChartsVisibilityControlAsync.bind(this))
        }
    }

    /**
     *
     * @param event
     */
    private onMouseEnterOrLeaveSignalsChartsVisibilityControl(event: Event): void {
        const index: string = (event.target as Element).id.substr(7)
        const chartContainerId = `chart${index}`
        const chartContainer: HTMLElement = document.querySelector(`#${chartContainerId}`)

        chartContainer.classList.toggle("chart-opaque--active")
    }

    /**
     *
     * @param event
     */
    private async onClickSignalsChartsVisibilityControlAsync(event: Event): Promise<void> {
        const index: string = (event.target as Element).id.substr(7)
        const signalChartContainerId = `chart${index}`
        const signalChartLegendId: string = (event.target as Element).id

        // show or hide signals chart
        this.showOrHideSignalsChart(signalChartContainerId)
        this.showOrHideSignalsChartLegend(signalChartLegendId)

        // replace X axis on current last chart
        this.replaceXAxisOnCurrentLastVisibleSignalChart()

        // save new order in preferences
        await this.saveCurrentSignalsVisibilityAsync()
    }

    /**
     * Shows or hides the given signal chart.
     * @param {string} signalChartContainerId Identifier of the chart
     * @param {boolean} isVisible Boolean indicating whether to show or hide signals chart; specify null to toggle visibility
     */
    private showOrHideSignalsChart(signalChartContainerId: string, isVisible: boolean = null): void {
        const chartContainer: HTMLElement = document.querySelector(`#${signalChartContainerId}`)

        if (isVisible === null) {
            chartContainer.classList.toggle("display-none")
        } else if (isVisible) {
            chartContainer.classList.remove("display-none")
        } else {
            chartContainer.classList.add("display-none")
        }
    }

    /**
     * Shows or hides the given signal chart legend.
     * @param {string} signalChartLegendId Identifier of the chart
     * @param {boolean} isVisible Boolean indicating whether to show or hide signals chart legend; specify null to toggle visibility
     */
    private showOrHideSignalsChartLegend(signalChartLegendId: string, isVisible: boolean = null): void {
        const legend: HTMLElement = document.querySelector(`#${signalChartLegendId}`)
        const legendDot: Element = legend.previousSibling as Element

        if (isVisible === null) {
            legend.classList.toggle("control-name-tint")
            legendDot.classList.toggle("control-circle-tint")
        } else if (isVisible) {
            legend.classList.remove("control-name-tint")
            legendDot.classList.remove("control-circle-tint")
        } else {
            legend.classList.add("control-name-tint")
            legendDot.classList.add("control-circle-tint")
        }
    }

    /**
     * Saves current signals visibility in preferences.
     */
    private async saveCurrentSignalsVisibilityAsync(): Promise<void> {
        const signalsVisibility: boolean[] = this.getCurrentSignalsVisibility()

        this.rawDataService.setSignalsVisibilityPreferencesAsync(signalsVisibility)
    }

    // -------------------------------------------------------------------------
    // Helpers for all raw data charts
    // -------------------------------------------------------------------------

    /**
     * Get the list of all raw data charts.
     * @returns the list of all raw data charts
     */
    private getAllRawDataCharts(): Highcharts.Chart[] {
        const charts: Highcharts.Chart[] = []

        // the first signal chart is to one following the chart of index of
        // {this.indexOfRawDataChartInChartsList} (the prediction chart)
        // the last signal chart is to last chart of {Highchart.charts}
        const indexOfFirstSignalsChart: number = this.indexOfRawDataChartInChartsList

        for (let i = indexOfFirstSignalsChart; i < Highcharts.charts.length; i++) {
            charts.push(Highcharts.charts[i])
        }

        return charts
    }

    /**
     * Synchronize zoom level of all raw data charts (prediction chart and
     * signals charts) to be the same once a single chart is zoomed. This allow
     * to zoom all the charts together when a single chart is zoomed.
     * @param {Object} e Zoom event.
     */
    private synchronizeRawDataChartsOnXAxisZoom(e: any): void {
        if (!this.isSynchronizingChartsZoom) {
            this.isSynchronizingChartsZoom = true

            this.displayZoomButtonIfNeeded(e)

            const xAxisStart: number = e.min
            const xAxisEnd: number = e.max

            const eventChart: Highcharts.Chart = e.target.chart
            let currentChart: Highcharts.Chart = undefined

            // Charts of predictions.
            for (let i = this.indexOfRawDataChartInChartsList; i < this.indexOfRawDataChartInChartsList + 2; i++) {
                currentChart = Highcharts.charts[i]

                if (currentChart !== eventChart) {
                    currentChart.xAxis[0].setExtremes(xAxisStart, xAxisEnd, true, false)
                }
            }

            // Charts of signals.
            for (let i = this.indexOfRawDataChartInChartsList + 2; i < Highcharts.charts.length; i++) {
                currentChart = Highcharts.charts[i]

                if (currentChart && currentChart !== eventChart) {
                    currentChart.xAxis[0].setExtremes(xAxisStart, xAxisEnd)
                }
            }

            this.isSynchronizingChartsZoom = false
        }
    }

    /**
     *
     * @param chartDivId
     * @param zoom
     */
    public setExtremes(chartDivId: string, zoom: boolean): void {
        const targetedChart: Highcharts.Chart = Highcharts.charts
            .filter((chart) => chart != undefined)
            .find((chart) => chart.container.parentElement.id == chartDivId)

        if (targetedChart) {
            const currentMin: number = targetedChart.yAxis[0].min
            const currentMax: number = targetedChart.yAxis[0].max
            const intervalLength: number = currentMax - currentMin
            const zoomRatio: number = intervalLength * 0.1 // 10% of increase or descrease

            let modifiedMin: number
            let modifiedMax: number

            if (zoom) {
                modifiedMin = currentMin + zoomRatio
                modifiedMax = currentMax - zoomRatio
            } else {
                modifiedMin = currentMin - zoomRatio
                modifiedMax = currentMax + zoomRatio
            }

            targetedChart.yAxis[0].setExtremes(modifiedMin, modifiedMax)
            targetedChart.yAxis[0].min = modifiedMin
            targetedChart.yAxis[0].max = modifiedMax
        }
    }

    /**
     * Display the reset zoom button when needed
     * @param {Object} event
     */
    private displayZoomButtonIfNeeded(e: any) {
        const xAxis: any = e.target.chart.xAxis[0]
        const extremes: any = xAxis.getExtremes()

        if (e.min > extremes.dataMin || e.max < extremes.dataMax) {
            if (e.target.chart.resetZoomButton) e.target.chart.resetZoomButton.hide()

            this.canResetAllZoom = true
        } else {
            this.canResetAllZoom = false
        }
    }

    /**
     * Zoom out for all graphics
     */
    public async resetAllZoom() {
        if (this.isSynchronizingChartsZoom) return

        this.isSynchronizingChartsZoom = true

        for (let i = this.indexOfRawDataChartInChartsList; i < Highcharts.charts.length; i++) {
            const chart: any = Highcharts.charts[i]

            if (chart.userOptions.chart) {
                chart.xAxis[0].setExtremes()
                chart.yAxis[0].setExtremes()
            }
        }

        this.canResetAllZoom = false
        this.isSynchronizingChartsZoom = false
    }

    /**
     * This method orders the zoom or the zoom out and the horizontal left-right moving
     * with the keys of the keyboard
     * @param {any} e: envent for get the key of keyboard
     */
    private eventKey(e: any) {
        const xAxis: any = Highcharts.charts[this.indexOfRawDataChartInChartsList].xAxis[0]
        const extremes: any = xAxis.getExtremes()
        const dataMin: number = extremes.dataMin
        const dataMax: number = extremes.dataMax
        const min: number = extremes.min
        const max: number = extremes.max

        // initialzed to actual min and max
        let expectedMin: number = min
        let expectedMax: number = max

        // determine in percentage the ratio for zooming and moving on the graph
        const gap: number = max - min
        const gapCenter: number = gap / 2
        const stepLeftRight: number = gap / 4

        // left key - step to the left
        // do nothing if min is already the minimum value of the chart
        if (e.keyCode == "37" && min != dataMin) {
            expectedMin = min - stepLeftRight
            expectedMax = max - stepLeftRight
        }
        // right key - step to the right
        // do nothing if max is already the maximum value of the chart
        else if (e.keyCode == "39" && max != dataMax) {
            expectedMin = min + stepLeftRight
            expectedMax = max + stepLeftRight
        }
        // up key - zoom in
        else if (e.keyCode == "38") {
            const stepZoomIn: number = gapCenter / 2

            expectedMin = min + stepZoomIn
            expectedMax = max - stepZoomIn
        }
        // down key - zoom out
        else if (e.keyCode == "40") {
            const steptZoomOut: number = gapCenter

            expectedMin = min - steptZoomOut
            expectedMax = max + steptZoomOut
        }

        // check that min and max values are contained in the range of the chart
        // if not, set value to the min or max value of the chart (to avoid overflow)
        const newMin = expectedMin < dataMin ? dataMin : expectedMin
        const newMax = expectedMax > dataMax ? dataMax : expectedMax

        // delete plotlines once the 1_000_000 interval has been exceeded
        if (max - min > 1_000_000) xAxis.removePlotLine("plot")

        xAxis.setExtremes(newMin, newMax)
    }

    // -------------------------------------------------------------------------
    // Helpers for signals charts
    // -------------------------------------------------------------------------

    /**
     * Gets the current signals visibility.
     * @returns the current signals visibility
     */
    private getCurrentSignalsVisibility(): boolean[] {
        // save new order in preferences
        const signalsElements: Element[] = Array.from(document.getElementsByClassName("handle"))
        const signalsVisibility: boolean[] = []

        for (const element of signalsElements) {
            const isVisible = !element.parentElement.parentElement.classList.contains("display-none")

            signalsVisibility.push(isVisible)
        }

        return signalsVisibility
    }

    /**
     * Replace X axis on the current last signal chart.
     */
    private replaceXAxisOnCurrentLastVisibleSignalChart(): void {
        const currentLastSignalChart: Highcharts.Chart = this.getCurrentLastVisibleSignalChart()

        // start at index 4 to skip other charts
        for (let i = this.indexOfRawDataChartInChartsList + 2; i < Highcharts.charts.length; i++) {
            const chart: Highcharts.Chart = Highcharts.charts[i]
            const xAxisOptions: Highcharts.XAxisOptions = (chart.options.xAxis as Highcharts.XAxisOptions[])[0]

            // prevent update if the chart is the current last signal chart or does not already have an X axis
            if (currentLastSignalChart == chart) {
                if (!xAxisOptions.visible) {
                    chart.update({ xAxis: { visible: true } })
                }
            } else if (xAxisOptions.visible) {
                chart.update({ xAxis: { visible: false } })
            }
        }
    }

    /**
     * Gets the current last visible signals chart.
     * @returns the current last visible signals chart
     */
    private getCurrentLastVisibleSignalChart(): Highcharts.Chart {
        const container: HTMLElement = document.querySelector("#list-chart-draggable")
        const chartsContainers: NodeListOf<HTMLElement> = container.querySelectorAll(":scope > *")

        let i: number = chartsContainers.length - 1

        while (i >= 0 && chartsContainers[i].querySelector(":scope > div").classList.contains("display-none")) {
            i--
        }

        let currentLastChart: Highcharts.Chart = null

        if (i >= 0) {
            const currentLastChartContainer: HTMLElement = chartsContainers[i]
            const currentLastChartId: string = currentLastChartContainer.querySelector(".synchronizedGraph").id

            if (Highcharts.charts) {
                currentLastChart = Highcharts.charts.find((chart) => {
                    if (chart && chart.container) chart.container.parentElement.id == currentLastChartId
                })
            }
        }

        return currentLastChart
    }

    // -------------------------------------------------------------------------
    // Add plotLines with zoom
    // -------------------------------------------------------------------------

    /**
     * Method which will add separate lines for the period of 10s
     * @param {Object} event
     */
    private addPlotlineZoom(event: any) {
        if (event.userMin != undefined && event.userMax != undefined) {
            const plotLinesToDraw: any = []

            const xAxis: any = event.target.chart.xAxis[0]
            const gap: any = xAxis.max - xAxis.min

            const plotPositions: any = event.target.tickPositions
            let plotPosition: any = plotPositions[0]

            if (plotPosition.toString().substring(9) == "5000") {
                plotPosition += 5_000
            }

            plotPosition = this.putPlotBeforAfter(plotPosition - 10_000, plotLinesToDraw, -10_000)
            plotPosition = this.putPlotBetween(plotPositions, plotLinesToDraw, 10_000)
            this.putPlotBeforAfter(plotPosition, plotLinesToDraw, 10_000)

            if (gap <= 1_000_000) {
                event.target.update({ plotLines: plotLinesToDraw })
            }
        } else {
            event.target.update({ plotLines: [] })
        }
    }

    /**
     * Method which will add separate lines for the period of 10s
     * @param {Array} plotPositions: array of original plotLine positions of the graph
     * @param {Array} plotLinesToDraw: table of lines to add to the graph
     * @param {Number} jump: jump defining the gap between the data where the lines are displayed
     * @returns {Number} plotPosition: position where the addition of the line is done
     */
    private putPlotBetween(plotPositions: any, plotLinesToDraw: any, jump: number) {
        let plotPosition: any = plotPositions[0]

        if (plotPosition.toString().substring(9) == "5000") plotPosition += 5_000

        while (plotPosition <= plotPositions[plotPositions.length - 1]) {
            plotLinesToDraw.push({
                color: "rgba(0,0,0,.2)",
                width: 2,
                zIndex: 5,
                value: plotPosition,
                id: "plot",
            })

            plotPosition += jump
        }

        return plotPosition
    }

    /**
     * Method which will add separate lines for the period of 10s
     * @param {Number} plotPosition: position where the addition of the line is done
     * @param {Array} plotLinesToDraw: table of lines to add to the graph
     * @param {Number} jump: jump defining the gap between the data where the lines are displayed
     */
    private putPlotBeforAfter(plotPosition: number, plotLinesToDraw: any, jump: number) {
        for (let i = 0; i < 100; i++) {
            plotLinesToDraw.push({
                color: "rgba(0,0,0,.2)",
                width: 2,
                zIndex: 5,
                value: plotPosition,
                id: "plot",
            })

            plotPosition += jump
        }

        return plotPosition
    }

    // ---------------------------------------------------------------------------
    // Other
    // ---------------------------------------------------------------------------

    /**
     * Export raw data in pdf format
     */
    public async exportEdf(): Promise<void> {
        let url: string

        this.isEdfExporting = true

        try {
            if (this.isSampleReport) {
                url = await this.sunriseApiService.getEdfFileUrlSampleReportAsync()
            } else {
                url = await this.sunriseApiService.getEdfFileUrlAsync(this.isAdmin, this.nightId, this.patient.id)
            }

            window.open(url, "_blank")
        } catch (error) {
            console.log(error)

            this.showErrorOccurred()
        }

        this.isEdfExporting = false
    }
}
