import { Component } from "@angular/core"
import { constants } from "../../../../config/constants"

@Component({
    selector: "app-ifu-us",
    templateUrl: "./ifu-us.component.html",
    styleUrls: ["./ifu-us.component.sass"],
})
export class IfuUsComponent {
    public staticFilesBaseUri: string = constants.dataAccess.staticFiles.googleCloudStorage.baseUri

    public effectiveIfuFiles: { name: string; url: string }[] = [
        { name: "HCP-IFU-US-V1.12.pdf", url: "/ifu/HCP-IFU-US-V1.12.pdf" },
    ]

    public obsoleteIfuFiles: { name: string; url: string }[] = [
        { name: "HPC-IFU-US-V1.5-Obsolete.pdf", url: "/ifu/HPC-IFU-US-V1.5.pdf" },
    ]
}
