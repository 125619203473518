import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { DateAdapter } from "@angular/material/core"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "../../../../services/auth.service"
import { getLocalCountryFromLang } from "../../../../utilities/Lang"
import { doesUserPrefersMmddyyyyDateFormat, getDateFormatLabel } from "../../../../utilities/UnitsAndFormatsUtilities"
import { ValidatableObject } from "../../../../validation/ValidatableObject"
import { TranslatableComponent } from "../../../translatable.component"

@Component({
    selector: "app-validatable-date",
    templateUrl: "./validatable-date.component.html",
})
export class ValidatableDateComponent extends TranslatableComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    public maxDate: Date = new Date()
    public useDDMMYYYYFormat: boolean

    public dateFormatLabel: string

    @Input("validatableObject") object: ValidatableObject
    @Input("disabled") disabled = false
    @Input("required") required = false

    @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>()
    @Output() objectChanged: EventEmitter<void> = new EventEmitter<void>()

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected translate: TranslateService,
        protected authService: AuthService,
        private dateAdapter: DateAdapter<any>,
    ) {
        super(translate)

        this.translate.onLangChange.subscribe(() => {
            this.setLangAdapter()
            this.updateDateFormatLabel()
        })

        this.useDDMMYYYYFormat = !doesUserPrefersMmddyyyyDateFormat(authService.getPreferences())
        this.updateDateFormatLabel()
    }

    public ngOnInit(): void {
        this.setLangAdapter()
    }

    // -------------------------------------------------------------------------
    // Lang utils
    // -------------------------------------------------------------------------

    private updateDateFormatLabel(): void {
        this.dateFormatLabel = getDateFormatLabel(this.authService.getPreferences(), this.translate.currentLang)
    }

    private setLangAdapter(): void {
        const currentLang: string = this.getPreferredLanguage()

        this.dateAdapter.setLocale(getLocalCountryFromLang(currentLang))
    }

    // -------------------------------------------------------------------------
    // Event handlers
    // -------------------------------------------------------------------------

    public async keyupHandlerAsync(event: any): Promise<void> {
        if (event.key == "Enter") {
            this.enterPressed.emit()
        } else if (this.object.hasError) {
            await this.validateObjectAsync()
        }
    }

    public async unfocusHandlerAsync(): Promise<void> {
        await this.validateObjectAsync()
    }

    public async dateChangeHandlerAsync(): Promise<void> {
        await this.validateObjectAsync()
    }

    public async validateObjectAsync(): Promise<void> {
        const isValid: boolean = await this.object.validateAsync()

        if (isValid) this.objectChanged.emit()
    }
}
