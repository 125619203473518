<div *ngIf="object" class="field-container" [class.required]="object.required" [class.field-container--errored]="object.hasError">
    <label class="label">{{ object.label | translate }}</label>

    <form [formGroup]="phoneNumberFormGroup">
        <ngx-intl-tel-input [preferredCountries]="['be', 'fr', 'ie', 'lu', 'gb', 'us']"
                            [enablePlaceholder]="true"
                            [phoneValidation]="true"
                            [separateDialCode]="true"
                            [cssClass]="'custom'"
                            [selectedCountryISO]="displayedCountry"
                            (focusout)="unfocusHandlerAsync()"
                            (keyup)="keyupHandlerAsync($event)"
                            formControlName="phoneNumberControl"
        ></ngx-intl-tel-input>
    </form>

    <small *ngIf="object.showHint" class="hint-text">{{ object.hintString }}</small>
    <small *ngIf="object.hasError" class="error-text">{{ object.errorString }}</small>
</div>


