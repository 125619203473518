<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'editPatientAccount_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div [style.display]="isLoading ? 'none' : 'block'" class="row row--guttered row--tiny content">
        <div class="list-row list-row--title list-row--no-border">
            <div class="col">{{ 'createPatientAccount_personalInfo_section' | translate }}</div>
        </div>

        <div class="two-field-container-per-row">
            <app-validatable-input class="validatable-container"
                                   *ngIf="patientIdentifier"
                                   [validatableObject]="patientIdentifier"
                                   (enterPressed)="editPatientAccountAsync()"></app-validatable-input>
        </div>

        <div class="list-row list-row--title list-row--no-border">
            <div class="col">{{ 'createPatientAccount_personalInfo_section' | translate }}</div>
        </div>

        <app-account-fields #personalInformationFields
                            [accountActionType]="eAccountActionType.PatientAccountEdition"
                            (enterPressed)="editPatientAccountAsync()"
                            (componentInit)="afterSignUpFieldsInit()"></app-account-fields>

        <div class="list-row list-row--title list-row--no-border">
            <div class="col">{{ 'createPatientAccount_physicalInfo_section' | translate }}</div>
        </div>

        <div class="two-field-container-per-row">
            <app-validatable-select class="validatable-container"
                                    *ngIf="gender"
                                    [validatableObject]="gender"
                                    [options]="genders"
                                    [selectedVariable]="'name'"
                                    [displayedVariable]="'displayedName'"></app-validatable-select>

            <app-validatable-input class="validatable-container"
                                   *ngIf="collarSize"
                                   [validatableObject]="collarSize"
                                   [useLabelAsPlaceholder]="true"
                                   (enterPressed)="editPatientAccountAsync()"></app-validatable-input>
        </div>

        <div class="two-field-container-per-row">
            <app-validatable-input class="validatable-container"
                                   *ngIf="height"
                                   [validatableObject]="height"
                                   [useLabelAsPlaceholder]="true"
                                   (enterPressed)="editPatientAccountAsync()"></app-validatable-input>

            <app-validatable-input class="validatable-container"
                                   *ngIf="weight"
                                   [validatableObject]="weight"
                                   [useLabelAsPlaceholder]="true"
                                   (enterPressed)="editPatientAccountAsync()"></app-validatable-input>
        </div>

        <app-validatable-checkbox class="validatable-container"
                                  *ngIf="shouldCompleteQuestionnaire"
                                  [validatableObject]="shouldCompleteQuestionnaire">
        </app-validatable-checkbox>

        <div class="list-row list-row--no-border">
            <app-button
                [loading]="isEditingPatient"
                (clicked)="editPatientAccountAsync()"
            >
                {{ 'common_edit' | translate }}
            </app-button>
        </div>
    </div>

    <div *ngIf='isLoading' class="row row--guttered row--tiny content">
        {{ 'common_loading' | translate }}
    </div>
</main>
