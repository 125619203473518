import { Lang } from "./Lang"
import { UnitsAndFormats } from "../models/Formats/UnitsAndFormats"
import { UnitsAndFormatsPreferences } from "../models/UnitsAndFormatsPreferences"

export function doesUserPrefersH12HourFormat(userUnitsAndFormatsPreferences: UnitsAndFormatsPreferences) {
    return userUnitsAndFormatsPreferences.preferredHourFormat == UnitsAndFormats.H12
}

export function doesUserPrefersMmddyyyyDateFormat(userUnitsAndFormatsPreferences: UnitsAndFormatsPreferences) {
    return userUnitsAndFormatsPreferences.preferredDateFormat == UnitsAndFormats.MMDDYYYY
}

export const DefaultUnitsAndFormats: UnitsAndFormatsPreferences = {
    preferredLengthUnit: UnitsAndFormats.Centimeters,
    preferredWeightUnit: UnitsAndFormats.Kilograms,
    preferredHourFormat: UnitsAndFormats.H24,
    preferredDateFormat: UnitsAndFormats.DDMMYYYY,
}

export function getDateFormatLabel(
    userUnitsAndFormatsPreferences: UnitsAndFormatsPreferences,
    lang: string,
    separator = "/",
): string {
    let day: string
    let month: string
    let year: string

    if (lang == Lang.En) {
        day = "dd"
        month = "mm"
        year = "yyyy"
    } else if (lang == Lang.Fr) {
        day = "jj"
        month = "mm"
        year = "aaaa"
    } else if (lang == Lang.Nl) {
        day = "dd"
        month = "mm"
        year = "jjjj"
    }

    if (doesUserPrefersMmddyyyyDateFormat(userUnitsAndFormatsPreferences)) {
        return month + separator + day + separator + year
    } else {
        // Default or dateFormat == DDMMYYYY
        return day + separator + month + separator + year
    }
}

export function getHourFormatLabel(
    userUnitsAndFormatsPreferences: UnitsAndFormatsPreferences,
    lang: string,
    separator = ":",
): string {
    let hour: string
    let minute: string

    if (lang == Lang.En || lang == Lang.Fr) {
        hour = "hh"
        minute = "mm"
    } else if (lang == Lang.Nl) {
        hour = "uu"
        minute = "mm"
    }

    if (doesUserPrefersH12HourFormat(userUnitsAndFormatsPreferences)) {
        return "am/pm"
    } else {
        // Default or hourFormat == H24
        return hour + separator + minute
    }
}

export function formatAMPM(strDate: string): string {
    const totalMinutes: number = parseInt(strDate)
    const totalHours: number = Math.floor(totalMinutes / 60)
    const remainingMinutes: number = Math.round(totalMinutes - totalHours * 60)

    let hours = totalHours
    const minutes = remainingMinutes
    const ampm = hours < 12 ? "am" : "pm"
    hours %= 12
    hours = hours != 0 ? hours : 12
    const hoursStr = `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`
    return hoursStr
}

export function formatDDMMYYYY(strDate: string): string {
    const date = new Date(strDate)
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
}

export function formatMMDDYYYY(strDate: string): string {
    const date = new Date(strDate)
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
}

export function convertInchesToCentimeters(inches: number): number {
    const result = inches * 2.54
    return !inches || isNaN(result) ? null : result
}

export function convertCentimetersToInches(cm: number): number {
    const result = cm / 2.54
    return !cm || isNaN(result) ? null : result
}

export function convertPoundsToKilograms(pounds: number): number {
    const result = pounds / 2.205
    return !pounds || isNaN(result) ? null : result
}

export function convertKilogramsToPounds(kg: number): number {
    const result = kg * 2.205
    return !kg || isNaN(result) ? null : result
}
