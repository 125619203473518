import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { StringsUtilities } from "../../utilities/StringsUtilities"
import { NotEmptyRule } from "../../validation/rules/NotEmptyRule"
import { ValidatableObject } from "../../validation/ValidatableObject"
import { BaseComponent } from "../base.component"
import { DelayButtonComponent } from "../tools/delay-button/delay-button.component"

@Component({
    selector: "app-sign-in",
    templateUrl: "./sign-in.component.html",
    styleUrls: ["./sign-in.component.sass"],
})
export class SignInComponent extends BaseComponent implements OnInit {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------
    public isSigningIn = false
    public isSigningInVerification = false
    public isWaitingForVerification = false

    public resetPasswordRequest = false

    private preFilledEmailAddress: string

    public emailAddress: ValidatableObject
    public password: ValidatableObject
    public verificationCode: ValidatableObject
    public stayConnected: boolean

    public readonly disableButtonTimeInSeconds: number = 60

    @ViewChild("resendConfirmationButton") resendConfirmationButton: DelayButtonComponent
    @ViewChild("resetPasswordButton") resetPasswordButton: DelayButtonComponent
    @ViewChild("resendVerificationCodeButton") resendVerificationCodeButton: DelayButtonComponent
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        protected readonly changeDetector: ChangeDetectorRef,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        if (this.authService.checkUserIsAuthenticated()) {
            this.navigateToMainPage()
        } else {
            this.authService.disconnectUser()

            await this.initValidatableObjectsAsync()

            this.preFilledEmailAddress = this.getQueryParam("email")

            if (this.preFilledEmailAddress) this.emailAddress.value = this.preFilledEmailAddress
        }
    }

    // -------------------------------------------------------------------------
    // Initialize validation
    // -------------------------------------------------------------------------

    private async initValidatableObjectsAsync(): Promise<void> {
        this.emailAddress = new ValidatableObject(this.translate, "signIn_input_email_label")
        this.emailAddress.addRule(new NotEmptyRule(this.translate))

        this.password = new ValidatableObject(this.translate, "signIn_input_password_label")
        this.password.addRule(new NotEmptyRule(this.translate))

        this.verificationCode = new ValidatableObject(this.translate, "signIn_verificationCode")
        this.verificationCode.addRule(new NotEmptyRule(this.translate))
    }

    /**
     * Validate credentiels validatable objects.
     * Force validating all the fields and do not stop at first error.
     * @return true if all the fields are valid, false otherwise
     */
    private async validateCredentialsObjectsAsync(): Promise<boolean> {
        this.emailAddress.value = StringsUtilities.formatAddressEmail(this.emailAddress.value)

        let isValid: boolean
        isValid = await this.emailAddress.validateAsync()
        isValid = (await this.password.validateAsync()) && isValid

        return isValid
    }

    /**
     * Validate verification code validatable objects.
     * Force validating all the fields and do not stop at first error.
     * @return true if all the fields are valid, false otherwise
     */
    private async validateVerificationCodeObjectsAsync(): Promise<boolean> {
        return await this.verificationCode.validateAsync()
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public signUp(): void {
        this.router.navigate(["/signup"])
    }

    public async signIn(): Promise<void> {
        const isValid: boolean = await this.validateCredentialsObjectsAsync()
        if (!isValid) return

        this.isSigningIn = true

        const emailAddress: string = this.getEmailAddress()
        const isAdmin: boolean = this.isAdminPattern()

        try {
            const res: any = await this.sunriseApiService.loginAsync(
                {
                    email: emailAddress,
                    password: this.password.value,
                },
                isAdmin,
            )

            const twoFARequired: boolean = res && res.data && res.data.twoFA_required

            // If tokens are available, connect the user, otherwise ask for verification code
            if (!twoFARequired) {
                const needToSetPassword: boolean = res.data.needsToSetPassword

                await this.connectUserAsync(
                    emailAddress,
                    res["data"]["tokens"],
                    res["data"]["roles"],
                    res["data"]["unitsAndFormatsPreferences"],
                    isAdmin,
                    res["data"]["isUnitedStates"],
                    this.stayConnected,
                )

                needToSetPassword ? this.router.navigate(["/main/set-password"]) : this.navigateToMainPage()
            } else {
                this.isWaitingForVerification = true

                this.changeDetector.detectChanges()

                this.resendVerificationCodeButton.startDelay()
            }
        } catch (error) {
            if (error.status === 401) {
                const jsonResult: any = error.error
                let errorCode: string = undefined

                // get error code from exception
                try {
                    errorCode = jsonResult.errors[0].code
                } catch {
                    // nothing to do, errorCode is undefined
                }

                if (errorCode === "invalid_credentials") {
                    let remainingAttempts: number = undefined
                    // get remainingAttempts from exception
                    try {
                        remainingAttempts = jsonResult.data.remainingAttempts
                    } catch {
                        // nothing to do, remainingAttempts is undefined
                    }

                    await this.showWrongEmailOrPasswordErrorAsync(remainingAttempts)
                } else if (errorCode === "account_blocked") {
                    let timeBeforeUnblock: number = undefined
                    // get timeBeforeUnblock from exception
                    try {
                        timeBeforeUnblock = jsonResult.data.timeBeforeUnblock
                    } catch {
                        // nothing to do, timeBeforeUnblock is undefined
                    }

                    await this.showAccountBlockedAsync(timeBeforeUnblock)
                } else {
                    await this.showErrorOccurredAsync()
                }
            } else {
                await this.showErrorOccurredAsync()
            }
        } finally {
            this.isSigningIn = false
        }
    }

    public async signInVerification(): Promise<void> {
        const isValid: boolean = await this.validateVerificationCodeObjectsAsync()
        if (!isValid) return

        this.isSigningInVerification = true

        const emailAddress: string = this.getEmailAddress()
        const isAdmin: boolean = this.isAdminPattern()

        try {
            const res: any = await this.sunriseApiService.loginVerificationAsync(
                emailAddress,
                this.verificationCode.value,
                isAdmin,
            )

            await this.connectUserAndRedirectToMainPageAsync(
                emailAddress,
                res["data"]["tokens"],
                res["data"]["roles"],
                res["data"]["unitsAndFormatsPreferences"],
                isAdmin,
                res["data"]["isUnitedStates"],
                this.stayConnected,
            )
        } catch (error) {
            if (error.status === 401) {
                await this.showWrongVerificationCodeErrorAsync()
            } else {
                await this.showErrorOccurredAsync()
            }
        } finally {
            this.isSigningInVerification = false
        }
    }

    public async resendVerificationCodeEmail(): Promise<void> {
        try {
            this.resendVerificationCodeButton.startDelay()

            const emailAddress: string = this.getEmailAddress()
            const isAdmin: boolean = this.isAdminPattern()

            await this.sunriseApiService.loginAsync(
                {
                    email: emailAddress,
                    password: this.password.value,
                    resend: true,
                },
                isAdmin,
            )
        } catch (error) {
            await this.resendVerificationCodeButton.resetDelayAsync()
            await this.showErrorOccurredAsync()
        }
    }

    public async resetPassword(): Promise<void> {
        const isValid: boolean = await this.emailAddress.validateAsync()

        if (isValid) {
            try {
                await this.sunriseApiService.requestResetPasswordAsync(
                    this.emailAddress.value,
                    this.resetPasswordRequest,
                )

                this.resetPasswordButton.startDelay()

                if (!this.resetPasswordRequest) this.resetPasswordRequest = true
            } catch (error) {
                await this.showErrorOccurredAsync()
            }
        }
    }

    // -------------------------------------------------------------------------
    // Inputs helpers
    // -------------------------------------------------------------------------

    private isAdminPattern(): boolean {
        return this.emailAddress.value.startsWith("admin:")
    }

    private getEmailAddress(): string {
        if (this.isAdminPattern()) return this.emailAddress.value.replace("admin:", "")

        return this.emailAddress.value
    }

    // -------------------------------------------------------------------------
    // Other helpers
    // -------------------------------------------------------------------------

    public async keyupHandler(event: any, inputObject: ValidatableObject): Promise<void> {
        // If 'Enter' is pressed
        if (event.keyCode == 13) {
            if (!this.isWaitingForVerification) {
                await this.signIn()
            } else {
                await this.signInVerification()
            }
        } else if (inputObject.hasError) {
            await inputObject.validateAsync()
        }
    }

    public async unFocusHandler(inputObject: ValidatableObject): Promise<void> {
        await inputObject.validateAsync()
    }

    private async showWrongEmailOrPasswordErrorAsync(remainingAttempts: number): Promise<void> {
        let errorString: string = await this.__("signIn_input_email_error")

        if (remainingAttempts <= 2) {
            const remainingAttemptsString: string = await this.__("signIn_input_email_remainingAttempts_error", {
                remainingAttempts: remainingAttempts,
            })
            errorString = `${errorString} ${remainingAttemptsString}`
        }

        this.emailAddress.errorString = errorString
        this.emailAddress.hasError = true
    }

    private async showWrongVerificationCodeErrorAsync(): Promise<void> {
        this.verificationCode.errorString = await this.__("signIn_verificationCode_error")
        this.verificationCode.hasError = true
    }

    private async showAccountBlockedAsync(timeBeforeUnblock: number): Promise<void> {
        this.emailAddress.errorString = await this.__("signIn_accountBlocked_error", {
            timeBeforeUnblock: timeBeforeUnblock,
        })
        this.emailAddress.hasError = true
    }

    protected async showErrorOccurredAsync(): Promise<void> {
        let validatableObject: ValidatableObject

        if (!this.isWaitingForVerification) {
            validatableObject = this.emailAddress
        } else {
            validatableObject = this.verificationCode
        }

        validatableObject.errorString = await this.__("common_error")
        validatableObject.hasError = true
    }

    public OnPasswordChange(password: ValidatableObject) {
        this.password = password
    }
}
