import { Injectable } from "@angular/core"
import { Observable, Subject } from "rxjs"
import { PortalNotification, PortalNotificationType } from "../models/PortalNotification"

@Injectable({
    providedIn: "root",
})
export class NotificationsService {
    private notificationSubject: Subject<PortalNotification> = new Subject<PortalNotification>()
    public notification: Observable<PortalNotification> = this.notificationSubject.asObservable()

    public showNotification(
        description: string,
        notificationType: PortalNotificationType = PortalNotificationType.Success,
        title: string | undefined = undefined,
        linkTitle: string | undefined = undefined,
        linkCallback: () => void | undefined = undefined,
        displayTime = 5,
    ): void {
        title ??= this.getDefaultTitleFromNotificationType(notificationType)

        const portalNotification: PortalNotification = {
            description,
            title,
            linkTitle,
            linkCallback,
            notificationType,
            displayTime,
        }

        this.notificationSubject.next(portalNotification)
    }

    private getDefaultTitleFromNotificationType(notificationType: PortalNotificationType): string {
        switch (notificationType) {
            case PortalNotificationType.Warning:
                return "portalNotification_warning_label"
            case PortalNotificationType.Information:
                return "portalNotification_information_label"
            case PortalNotificationType.Success:
                return "portalNotification_success_label"
            case PortalNotificationType.Error:
            default:
                return "portalNotification_error_label"
        }
    }
}
