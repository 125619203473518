import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core"

@Directive({
    selector: "[imgWithViewer]",
})
export class ImageWithViewerDirective {
    constructor(
        renderer: Renderer2,
        private hostElement: ElementRef,
    ) {
        renderer.addClass(hostElement.nativeElement, "pointer-hover")
    }

    @HostListener("click", ["$event"])
    public onClick(evt: PointerEvent): void {
        evt.preventDefault()
        evt.stopPropagation()

        const imageViewer: HTMLElement = document.getElementById("imageViewer")
        const imageElement: Element = imageViewer.lastElementChild

        const src: string = this.hostElement.nativeElement.src

        if (src && imageViewer && imageElement) {
            imageElement["src"] = src
            imageViewer.classList.remove("display-none")
        }
    }
}
