import * as localForage from "localforage"
import { constants } from "../../../config/constants"

/**
 * Instance of LocalForage used for local cache.
 */
export const cacheLocalForage: LocalForage = localForage.createInstance({
    driver: constants.dataAccess.cache.localForage.driver,
    name: constants.dataAccess.cache.localForage.name,
    storeName: constants.dataAccess.cache.localForage.storeName,
    version: constants.dataAccess.cache.localForage.version,
})
