<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'myAccount_personalInfo' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered">
        <a (click)='navigateBack()'>{{ 'account_pageTitle' | translate }}</a> / {{ 'myAccount_personalInfo' | translate }}
    </div>

    <div class="row row--guttered row--tiny content" [style.display]="isLoading ? 'none' : ''">
        <app-account-fields #updateProfileFields
                            [accountActionType]="eAccountActionType.DoctorAccountEdition"
                            (enterPressed)="updateProfile()"
                            (componentInit)="afterSignUpFieldsInit()"></app-account-fields>

        <div class="field-container">
            <app-button
                [loading]="isUpdatingProfile"
                (clicked)="updateProfile()"
            >
                {{ 'account_update_profile_button' | translate }}
            </app-button>
        </div>
    </div>

    <div *ngIf='isLoading' class="row row--guttered row--tiny content">
        {{ 'account_loading' | translate }}
    </div>
</main>
