<main class="home-wrapper">
    <div class="home-wrapper__content-wrapper">
        <div #descriptionWrapper class="content-wrapper__description-wrapper">
            <div *ngIf="currentDescription" class="title__text">
                <span class="title__number">{{ formatTitleNumber(currentDescription.id) }}</span>{{ currentDescription.title | translate }}
            </div>

            <div *ngIf="currentDescription" class="description">{{ currentDescription.description | translate }}</div>
        </div>

        <div class="content-wrapper__connection-wrapper">
            <app-button [routerLink]="['/signup']">{{ 'home_createAccount' | translate }}</app-button>
            <div class="or-text">{{ "common_or" | translate }}</div>
            <div [routerLink]="['/login']" class="connection__login-button">{{ "home_login" | translate }}</div>
        </div>
    </div>

    <div class="home-wrapper__image">
        <owl-carousel-o [options]="customOptions" (changed)="carouselChangeEvent($event)">
            <ng-container *ngFor="let image of images">
                <ng-template carouselSlide>
                    <div class="image_container">
                        <img class="home_image" [src]="image">
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</main>
