import { Component, EventEmitter, Input, Output } from "@angular/core"
import { generateUniqueId } from "../../../../utilities/RandomUtilities"
import { ValidatableObject } from "../../../../validation/ValidatableObject"

@Component({
    selector: "app-validatable-checkbox",
    templateUrl: "./validatable-checkbox.component.html",
})
export class ValidatableCheckboxComponent {
    @Input("validatableObject") object: ValidatableObject
    @Input("objectLabelArgs") args: any

    @Input("disabled") disabled = false
    @Input("required") required = false

    @Output("objectChanged") objectChanged: EventEmitter<void> = new EventEmitter<void>()

    public inputId: string

    constructor() {
        this.inputId = generateUniqueId()
    }

    public async changeHandlerAsync(): Promise<void> {
        const valid: boolean = await this.object.validateAsync()

        if (valid) this.objectChanged.emit()
    }
}
