<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'account_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered row--smaller content">
        <div>
            <div *ngIf="!accountConfirmed" class="list-row list-row--warning" (click)="resendConfirmEmail()">
                <div class="col">{{ 'myAccount_unconfirmedAccount' | translate }}</div>
            </div>
            <div *ngIf="hasDemoRole" class="list-row list-row--clickable" [routerLink]="['/main/account/demo-settings']">
                <div class="col">{{ 'myAccount_demoSettings' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div *ngIf="!isAdmin" class="list-row list-row--clickable" [routerLink]="['/main/account/my']">
                <div class="col">{{ 'myAccount_personalInfo' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div class="list-row list-row--clickable" [routerLink]="['/main/account/update-password']">
                <div class="col">{{ 'myAccount_updatePassword' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div *ngIf="!isAdmin" class="list-row list-row--clickable" [routerLink]="['/main/account/signature']">
                <div class="col">{{ 'myAccount_signatures' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div *ngIf="!isAdmin" class="list-row list-row--clickable" [routerLink]="['/main/account/templates-list']">
                <div class="col">{{ 'myAccount_interpretationTemplates' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div *ngIf="!isAdmin" class="list-row list-row--clickable" [routerLink]="['/main/account/security']">
                <div class="col">{{ 'myAccount_security' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div *ngIf="!isAdmin" class="list-row list-row--clickable" [routerLink]="['/main/account/preferences']">
                <div class="col">{{ 'myAccount_preferences' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div *ngIf="!isAdmin" class="list-row list-row--clickable" [routerLink]="['/main/account/notifications']">
                <div class="col">{{ 'myAccount_notifications' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div *ngIf="!isAdmin" class="list-row list-row--clickable" (click)="startOnBoarding()">
                <div class="col">{{ 'myAccount_onboarding' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
            <div class="list-row list-row--no-border list-row--clickable" (click)="logout()">
                <div class="col">{{ 'myAccount_disconnect' | translate }}</div>
                <div class="col col--see-more"><a class="internal internal-arrow"></a></div>
            </div>
        </div>
    </div>
</main>
