import { Component } from "@angular/core"
import { SimpleModalComponent } from "@looorent/ngx-simple-modal"

export interface PromptModel {
    title: string
    message?: string
}

@Component({
    selector: "prompt",
    template: `
        <div class="modal-content">
            <div class="modal-header">
                <h4>{{ title || "Prompt" }}</h4>
            </div>
            <div class="modal-body">
                <textarea
                    class="modal-entry"
                    style="padding-bottom: 85px; vertical-align: top;"
                    [(ngModel)]="message"
                    name="name"
                ></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="apply()">Envoyer</button>
                <button type="button" style="margin-left: 0.5rem;" (click)="close()">Annuler</button>
            </div>
        </div>
    `,
})
export class PromptComponent extends SimpleModalComponent<PromptModel, string> implements PromptModel {
    title: string
    message = ""

    constructor() {
        super()
    }
    apply() {
        this.result = this.message
        this.close()
    }
}
