<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'studiesList_page_title' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <div class="row row--guttered" [class.display-none]="isMobile">
        <div class="filters-container" *ngIf="!isLoading && displayV2Columns">
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.AllStudies" (click)="updateMainFilterAsync(eFilterType.AllStudies)">
                <a>{{ 'studyList_filter_allStudies' | translate }}</a>
                <span>{{ studiesList.length }}</span>
            </div>
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.ToBeApproved" (click)="updateMainFilterAsync(eFilterType.ToBeApproved)">
                <a>{{ 'studyList_filter_toBeApproved' | translate }}</a>
                <span>{{ nbStudiesToBeApproved }}</span>
            </div>
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.ToBeInterpreted" (click)="updateMainFilterAsync(eFilterType.ToBeInterpreted)">
                <a>{{ 'studyList_filter_toBeInterpreted' | translate }}</a>
                <span>{{ nbStudiesToBeInterpreted }}</span>
            </div>
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.ToBeSigned" (click)="updateMainFilterAsync(eFilterType.ToBeSigned)">
                <a>{{ 'studyList_filter_toBeSigned' | translate }}</a>
                <span>{{ nbStudiesToBeSigned }}</span>
            </div>
            <div class="filter_button" [class.filter_button--active]="currentFilter == eFilterType.Signed" (click)="updateMainFilterAsync(eFilterType.Signed)">
                <a>{{ 'studyList_filter_signed' | translate }}</a>
                <span>{{ nbStudiesSigned }}</span>
            </div>
        </div>
    </div>

    <div class="row row--guttered row--smaller content" [style.padding-top]="'2rem'">
        <div id="filters-container" *ngIf="!isLoading && displayV2Columns">
            <div class="field-container" [class.display-none]="!isMobile">
                <select class="field" [style.padding-bottom]="'1rem'" #selectedFilter (change)="updateMainFilterAsync(selectedFilter.value)">
                    <option [selected]="currentFilter == eFilterType.AllStudies" [value]="eFilterType.AllStudies">{{ 'studyList_filter_allStudies' | translate }} ({{ studiesList.length }})</option>
                    <option [selected]="currentFilter == eFilterType.ToBeApproved" [value]="eFilterType.ToBeApproved">{{ 'studyList_filter_toBeApproved' | translate }} ({{ nbStudiesToBeApproved }})</option>
                    <option [selected]="currentFilter == eFilterType.ToBeInterpreted" [value]="eFilterType.ToBeInterpreted">{{ 'studyList_filter_toBeInterpreted' | translate }} ({{ nbStudiesToBeInterpreted }})</option>
                    <option [selected]="currentFilter == eFilterType.ToBeSigned" [value]="eFilterType.ToBeSigned">{{ 'studyList_filter_toBeSigned' | translate }} ({{ nbStudiesToBeSigned }})</option>
                    <option [selected]="currentFilter == eFilterType.Signed" [value]="eFilterType.Signed">{{ 'studyList_filter_signed' | translate }} ({{ nbStudiesSigned }})</option>
                </select>
            </div>
        </div>

        <div *ngIf="!isLoading" [ngClass]="{'two-field-container-per-row' : doctorOffices?.length > 0}">
            <div class="field-container">
                <input class="field" [(ngModel)]="searchFilter" placeholder="{{ 'studiesList_filter_name' | translate }}"/>
            </div>

            <div *ngIf="doctorOffices?.length > 0" class="field-container">
                <select class="field"
                        [(ngModel)]="filterByOffice">
                    <option value="all" selected>{{ "patientsList_all_offices" | translate }}</option>
                    <option *ngFor="let office of doctorOffices" [value]="office.officeId">{{ office.officeName }}</option>
                    <option value="none">{{ "signUp_input_sharedOffice_none_label" | translate }}</option>
                </select>
            </div>
        </div>

        <div *ngIf="!isLoading" id="studies-container">
            <div class="list-row list-row--header">
                <app-sortable-list-header
                    class="col"
                    [columnName]="'studiesList_patient_name'"
                    [columnValue]="'fullName'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    *ngIf="displayV2Columns"
                    [columnName]="'studiesList_patient_identifier'"
                    [columnValue]="'userIdentifier'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    [columnName]="'studiesList_study_date'"
                    [columnSubtitle]="dateFormatLabel.toUpperCase()"
                    [columnValue]="'nightEndDateTime'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                 </app-sortable-list-header>
                 <app-sortable-list-header
                    class="col"
                    *ngIf="displayV2Columns"
                    [columnName]="'nightsList_ahi_header_label'"
                    [columnValue]="'nightAhi'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <app-sortable-list-header
                    class="col"
                    *ngIf="displayV2Columns"
                    [columnName]="'studiesList_study_status'"
                    [columnValue]="'analysisStatus'"
                    [currentSortingColumnValue]="sortColumnValue"
                    [sortOrder]="sortOrder"
                    (columnClicked)="sortListByColumn($event)">
                </app-sortable-list-header>
                <div class="col col--see-more"></div>
            </div>

            <div *ngForEmpty="let study of studiesList | studyListFilter: searchFilter : currentFilter : filterByOffice | listSort: sortColumnValue : sortOrder; let last = last; else: noStudies">
                <span *ngIf="study.nightIsAnalyzed"
                      class="list-row list-row--clickable"
                      [ngClass]="{'list-row--no-border': last}"
                      (click)="navigateToNight(study.nightId, study.nightVersionId)">
                    <div class="col">{{ study.fullName || "-" }}</div>
                    <div class="col" *ngIf="displayV2Columns">{{ study.userIdentifier || "-" }}</div>
                    <div class="col">{{ formatDate(study.nightEndDateTime) }}</div>
                    <div class="col" *ngIf="displayV2Columns">{{ formatScore(study.nightAhi, study.nightScoringRule) }}</div>
                    <div class="col" *ngIf="displayV2Columns" [ngClass]="study.analysisStatus == 'analysis_status_4_signed' ? 'signed' : ''">
                        {{ study.analysisStatus | translate }}
                        <img *ngIf="study.analysisStatus == 'analysis_status_4_signed'" style="padding-left: 0.5rem;" src="../assets/images/icon_valid_light__green.svg"/>
                    </div>
                    <div class="col col--see-more"><a class="internal">{{ 'common_see_more' | translate }}</a></div>
                </span>

                <span *ngIf="!study.nightIsAnalyzed" class="list-row list-row--disabled" [ngClass]="{'list-row--no-border': last}">
                    <div class="col">{{ study.fullName || "-" }}</div>
                    <div class="col" *ngIf="displayV2Columns">{{ study.userIdentifier || "-" }}</div>
                    <div class="col">{{ formatDate(study.nightEndDateTime) }}</div>
                    <div class="col" *ngIf="displayV2Columns">{{ formatScore(study.nightAhi, study.nightScoringRule) }}</div>
                    <div class="col" *ngIf="displayV2Columns">-</div>
                    <div class="col col--see-more">{{ 'nightsList_processing_label' | translate }}</div>
                </span>
            </div>

            <ng-template #noStudies>
                <span class="list-row list-row--no-border">{{ 'studiesList_no_studies' | translate }}</span>
            </ng-template>
        </div>

        <div *ngIf="isLoading" class="list-row list-row--no-border">{{ 'studiesList_loading' | translate }}</div>
    </div>
</main>
