import { Component, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AccountActionType } from "../../models/AccountActionType"
import { Doctor } from "../../models/Doctor"
import { NotificationType } from "../../models/NotificationType"
import { PortalNotificationType } from "../../models/PortalNotification"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { AccountFieldsComponent } from "../account-fields/account-fields.component"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-create-prefilled-account",
    templateUrl: "./create-prefilled-account.component.html",
})
export class CreatePrefilledAccountComponent extends BaseComponent {
    // -------------------------------------------------------------------------
    // Instance variables declarations
    // -------------------------------------------------------------------------

    public error = ""
    public isCreatingAccount = false

    public subscribeNewsletter = false

    @ViewChild("createPrefilledAccountFields") public doctorAccountFields: AccountFieldsComponent

    /* Enum */
    public eAccountActionType: typeof AccountActionType = AccountActionType

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    // -------------------------------------------------------------------------
    // Command delegates
    // -------------------------------------------------------------------------

    public async createPrefilledAccountAsync(): Promise<void> {
        const isValid: boolean = await this.validateObjectsAsync()

        if (isValid) {
            const notifications: NotificationType[] = [
                NotificationType.NewPatientNight,
                NotificationType.NewNightComment,
            ]

            if (this.subscribeNewsletter) notifications.push(NotificationType.Marketing)

            const doctor: Doctor = {
                firstName: this.doctorAccountFields.firstName.value,
                lastName: this.doctorAccountFields.lastName.value,
                email: this.doctorAccountFields.emailAddress.value,
                phoneNumber: this.doctorAccountFields.phoneNumberWithPrefix.value,
                notifications: notifications,
                countryNumber: this.doctorAccountFields.country.value,
            }

            doctor.unitsAndFormatsPreferences = this.getDefaultPreferencesFromCountry(doctor.countryNumber)

            this.isCreatingAccount = true
            await this.hideErrorOccurredAsync()

            try {
                await this.sunriseApiService.createPrefilledAccountAsync(doctor)

                this.isCreatingAccount = false

                this.showNotification("createPrefilledAccount_confirmation", PortalNotificationType.Success)
                this.navigateToMainPage()
            } catch (error) {
                if (error.status === 409) {
                    await this.showEmailAlreadyUsedErrorAsync()
                } else {
                    await this.showErrorOccurredAsync()
                }

                this.isCreatingAccount = false
            }
        }
    }

    private async validateObjectsAsync(): Promise<boolean> {
        return await this.doctorAccountFields.validateObjectsAsync()
    }

    // -------------------------------------------------------------------------
    // Error helpers
    // -------------------------------------------------------------------------

    private async showEmailAlreadyUsedErrorAsync(): Promise<void> {
        this.doctorAccountFields.emailAddress.errorString = await this.__("signUp_input_emailAddress_error_conflict")
        this.doctorAccountFields.emailAddress.hasError = true
    }

    protected async showErrorOccurredAsync(): Promise<void> {
        this.error = await this.__("common_error")
    }

    private async hideErrorOccurredAsync(): Promise<void> {
        this.error = ""
    }
}
