import { Component, ElementRef, ViewChild } from "@angular/core"

@Component({
    selector: "app-image-viewer",
    templateUrl: "./image-viewer.component.html",
})
export class ImageViewerComponent {
    @ViewChild("imageElement") public imageElement: ElementRef

    public closeViewer(): void {
        document.getElementById("imageViewer").classList.add("display-none")
    }
}
