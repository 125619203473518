import { Component } from "@angular/core"
import { constants } from "../../../config/constants"

@Component({
    selector: "app-privacy-policies",
    templateUrl: "./privacy-policies.component.html",
    styleUrls: ["./privacy-policies.component.sass"],
})
export class PrivacyPoliciesComponent {
    public staticFilesBaseUrl: string = `${constants.dataAccess.staticFiles.googleCloudStorage.baseUri}`
    public cookiesPolicyBaseUrl: string = `${this.staticFilesBaseUrl}/cookies-policy/`
    public privacyPolicyBaseUrl: string = `${this.staticFilesBaseUrl}/privacy-policy/`

    public cookiesPolicyFiles: { lang: string; name: string; url: string }[] = [
        { lang: "EN", name: "Cookies Policy", url: "Cookies-Policy-EN.pdf" },
        { lang: "FR", name: "Politique de cookies", url: "Cookies-Policy-FR.pdf" },
    ]

    public privacyPolicyFiles: { lang: string; name: string; url: string }[] = [
        { lang: "EN", name: "Health data privacy policy", url: "Privacy-Policy-EN.pdf" },
        { lang: "FR", name: "Politique de protection des données de santé", url: "Privacy-Policy-FR.pdf" },
        {
            lang: "DE",
            name: "Datenschutzerklärung zur Verarbeitung von Gesundheitsdaten",
            url: "Privacy-Policy-DE.pdf",
        },
        { lang: "NL", name: "Privacybeleid voor gezondheidsgegevens", url: "Privacy-Policy-NL.pdf" },
    ]
}
