import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.sass"],
})
export class AboutComponent extends BaseComponent {
    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }
}
