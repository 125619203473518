import { Pipe, PipeTransform } from "@angular/core"
import { SortOrder } from "../../models/SortOrder"

@Pipe({
    name: "listSort",
})
export class ListSortPipe<T> implements PipeTransform {
    transform(list: T[], column: string, order: string = SortOrder.Ascending): T[] {
        if (!list || !column || !order) {
            return list
        }

        list.sort((a, b) => {
            // Handle undefined or null values
            if (a[column] == undefined || a[column] == "-" || a[column] == "")
                return order == SortOrder.Ascending ? 1 : -1
            if (b[column] == undefined || b[column] == "-" || b[column] == "")
                return order == SortOrder.Ascending ? -1 : 1

            if (order == SortOrder.Ascending) {
                return a[column] > b[column] ? 1 : -1
            } else {
                return b[column] > a[column] ? 1 : -1
            }
        })

        return list
    }
}
