<button #buttonElement
        class="button"
        (click)="onClick()"
        [classList]="customClasses"
        [disabled]="loading || disabled"
        [ngClass]="{ 'button--is-loading': loading }"
        [style.width.px]="loading ? buttonWidth : null"
>
    <img class="logo_loading"
         src="assets/images/logo_white.svg"
         [style.display]="loading ? 'inline' : 'none'"
    >

    <div [style.display]="loading ? 'none' : 'inline'">
        <ng-content></ng-content>
    </div>
</button>
