<header class="navbar-wrapper">
    <div class="admin-section" *ngIf="isAdmin">{{ 'header_connected_as_admin_label' | translate }}</div>

    <nav class="navbar connected-navbar">
        <div #burgerButton (click)="switchBurgerMenu()" class="navbar__burger">
            <span class="burger__line"></span>
            <span class="burger__line"></span>
            <span class="burger__line"></span>
        </div>

        <div class="navbar__logo-wrapper">
            <div class="navbar__logo" (click)="navigateToMainPage()">
                <div class="logo__sun"></div>
                <div id="logoIcon" class="logo__icon"></div>
                <div class="logo__rise"></div>
            </div>
        </div>

        <div #buttonsWrapper class="navbar__buttons-wrapper">
            <ul class="navbar__links lang-link">
                <li (click)="switchSubLinks($event)" class="link">
                    <span #langLink class="lang"></span>
                    <ul class="link__sub-links">
                        <li *ngFor="let lang of langSubLinksList" (click)="useLanguage(lang)" class="sub-link lang">{{lang}}</li>
                    </ul>
                </li>
            </ul>

            <ul class="navbar__links">
                <li [routerLink]="['/main/patients']" *ngIf="!isAdmin || hasSupportRole" routerLinkActive="link--active" class="link">{{ 'header_patient_menu' | translate }}</li>
                <li [routerLink]="['/main/studies']" *ngIf="!isAdmin" routerLinkActive="link--active" class="link">{{ 'header_studies_menu' | translate }}</li>
                <li [routerLink]="['/main/prescription']" *ngIf="!isAdmin && hasPrescriberRole" id="prescription" routerLinkActive="link--active" class="link">{{ 'header_prescriptions_menu' | translate }}</li>
                <li [routerLink]="['/main/create-patient']" *ngIf="!isAdmin && isUSPractitioner" routerLinkActive="link--active" class="link">{{ 'header_create_patient_menu' | translate }}</li>
                <li [routerLink]="['/main/sharing-management']" *ngIf="hasPatientSharingRole" routerLinkActive="link--active" class="link">{{ 'header_sharing_management_menu' | translate }}</li>
                <li [routerLink]="['/main/admin/support']" *ngIf="hasSupportRole" routerLinkActive="link--active" class="link">Support</li>
                <li [routerLink]="['/main/admin/create-sav-form']" *ngIf="hasSupportAdminRole" routerLinkActive="link--active" class="link">{{ 'header_create_sav_form' | translate }}</li>
                <li [routerLink]="['/main/admin/create-prefilled-account']" *ngIf="hasAccountHandlerRole" routerLinkActive="link--active" class="link">{{ 'header_create_practicien_account' | translate }}</li>
            </ul>

            <ul class="navbar__links">
                <span class="account-link-wrapper">
                    <img *ngIf="!isAccountConfirmed" src="../../../assets/images/warning.png" class="warning-wrapper__icon"/>
                    <li [routerLink]="['/main/account']" routerLinkActive="link--active" id="account" class="link">{{ 'header_account_menu' | translate }}</li>
                </span>
            </ul>
        </div>
    </nav>
</header>
