import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { UnitsAndFormats } from "../../models/Formats/UnitsAndFormats"
import { UnitsAndFormatsPreferences } from "../../models/UnitsAndFormatsPreferences"
import { AuthService } from "../../services/auth.service"
import { NotificationsService } from "../../services/notifications.service"
import { SunriseApiService } from "../../services/sunrise-api.service"
import { RoutesUtilities } from "../../utilities/RoutesUtilities"
import {
    convertCentimetersToInches,
    convertKilogramsToPounds,
    doesUserPrefersMmddyyyyDateFormat,
    formatDDMMYYYY,
    formatMMDDYYYY,
} from "../../utilities/UnitsAndFormatsUtilities"
import { BaseComponent } from "../base.component"

@Component({
    selector: "app-show-patient-details",
    templateUrl: "./show-patient-details.component.html",
})
export class ShowPatientDetailsComponent extends BaseComponent implements OnInit {
    private doctorPreferences: UnitsAndFormatsPreferences

    public isLoading = true

    public initialPatient: any

    public countries: any
    public genders: { name: string; displayedName: string }[] = []
    public dateFormatLabel: string

    public country: string
    public birthdate: string
    public phoneNumber: string
    public emailAddress: string
    public firstName: string
    public lastName: string
    public customIdentifier: string
    public gender: string
    public collarSize: string
    public height: string
    public weight: string

    public collarSizeLabel: string
    public heightLabel: string
    public weightLabel: string

    private readonly placeHolder: string = "-"

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    public constructor(
        protected translate: TranslateService,
        protected router: Router,
        protected authService: AuthService,
        protected sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly route: ActivatedRoute,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)

        this.translate.onLangChange.subscribe(async () => await this.updateLanguage())
    }

    // -------------------------------------------------------------------------
    // Navigation handlers
    // -------------------------------------------------------------------------

    public async ngOnInit(): Promise<void> {
        const patientId: string = RoutesUtilities.getRouteParam(this.route, "id")
        this.doctorPreferences = this.authService.getPreferences()

        this.initPreferencesSpecificLabels()

        this.initialPatient = await this.sunriseApiService.getPatientAsync(patientId)
        await this.fillGendersAsync()
        await this.fillCountriesAsync()

        this.fillPatientInformation(this.initialPatient)

        this.isLoading = false
    }

    // -------------------------------------------------------------------------
    // Translation helpers
    // -------------------------------------------------------------------------

    private async updateLanguage(): Promise<void> {
        await this.fillGendersAsync()
        this.updateCountriesNames()
        this.fillPatientInformation(this.initialPatient)
    }

    // -------------------------------------------------------------------------
    // Initialization
    // -------------------------------------------------------------------------

    private initPreferencesSpecificLabels(): void {
        this.collarSizeLabel = "signUp_input_collarSize_cm_label"
        this.heightLabel = "signUp_input_height_cm_label"
        this.weightLabel = "signUp_input_weight_kg_label"

        if (this.doctorPreferences.preferredLengthUnit != UnitsAndFormats.Centimeters) {
            this.collarSizeLabel = "signUp_input_collarSize_in_label"
            this.heightLabel = "signUp_input_height_in_label"
        }

        if (this.doctorPreferences.preferredWeightUnit != UnitsAndFormats.Kilograms) {
            this.weightLabel = "signUp_input_weight_lb_label"
        }
    }

    private fillPatientInformation(patient: any) {
        if (!patient) return

        this.customIdentifier = patient.customIdentifier ?? this.placeHolder
        this.firstName = patient.firstName ?? this.placeHolder
        this.lastName = patient.lastName ?? this.placeHolder
        this.emailAddress = patient.email ?? this.placeHolder
        this.phoneNumber = patient.phoneNumber ?? this.placeHolder

        this.birthdate = patient.birthDate
            ? doesUserPrefersMmddyyyyDateFormat(this.doctorPreferences)
                ? formatMMDDYYYY(patient.birthDate)
                : formatDDMMYYYY(patient.birthDate)
            : this.placeHolder

        this.country =
            this.countries.find((country) => country.countryNumber == patient.countryNumber)?.translatedName ??
            this.placeHolder

        this.gender = this.genders.find((gender) => gender.name === patient.gender)?.displayedName ?? this.placeHolder

        this.collarSize =
            (this.doctorPreferences.preferredLengthUnit == UnitsAndFormats.Inches
                ? convertCentimetersToInches(patient.collarSize)
                : patient.collarSize) ?? this.placeHolder

        this.height =
            (this.doctorPreferences.preferredLengthUnit == UnitsAndFormats.Inches
                ? convertCentimetersToInches(patient.height)
                : patient.height) ?? this.placeHolder

        this.weight =
            (this.doctorPreferences.preferredWeightUnit == UnitsAndFormats.Pounds
                ? convertKilogramsToPounds(patient.weight)
                : patient.weight) ?? this.placeHolder
    }

    // -------------------------------------------------------------------------
    // Genders helpers
    // -------------------------------------------------------------------------

    private async fillGendersAsync(): Promise<void> {
        this.genders = [
            { name: "male", displayedName: await this.__("signUp_input_gender_male_label") },
            { name: "female", displayedName: await this.__("signUp_input_gender_female_label") },
        ]
    }

    // -------------------------------------------------------------------------
    // Countries helpers
    // -------------------------------------------------------------------------

    private async fillCountriesAsync(): Promise<void> {
        this.countries = await this.sunriseApiService.getCountriesAsync()

        this.updateCountriesNames()
    }

    private updateCountriesNames(): void {
        if (this.countries && this.countries.length) {
            for (let i = 0; i < this.countries.length; i++) {
                this.countries[i]["translatedName"] = this.countries[i]["name"][this.translate.currentLang]
            }
        }
    }
}
