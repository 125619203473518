import { Component, OnDestroy } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { filter } from "rxjs/operators"
import { BaseComponent } from "./base.component"
import { AuthService } from "../services/auth.service"
import { NotificationsService } from "../services/notifications.service"
import { SocketIOService } from "../services/socketio.service"
import { SunriseApiService } from "../services/sunrise-api.service"
import { UrlService } from "../services/url.service"

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.sass"],
})
export class AppComponent extends BaseComponent implements OnDestroy {
    // -------------------------------------------------------------------------
    // Instance variables declaration
    // -------------------------------------------------------------------------

    public readonly title: string = "sunriseplatform"
    public readonly copyrightYear: number = new Date().getUTCFullYear()

    private previousUrl: string = null
    private currentUrl: string = null

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly authService: AuthService,
        protected readonly sunriseApiService: SunriseApiService,
        protected readonly notificationsService: NotificationsService,
        private readonly urlService: UrlService,
        private readonly socketIOService: SocketIOService,
    ) {
        super(translate, router, authService, sunriseApiService, notificationsService)
    }

    public ngOnInit(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl
            this.currentUrl = event.url
            this.urlService.setPreviousUrl(this.previousUrl)
        })

        this.socketIOService.initSocketConnection()
    }

    public ngOnDestroy(): void {
        this.socketIOService.disconnect()
    }
}
