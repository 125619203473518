<main class="main">
    <div class="row row--guttered">
        <h1 class="title-h1">{{ 'signIn_pageTitle' | translate }}</h1>
    </div>

    <div class="row row--guttered-h">
        <div class="separator"></div>
    </div>

    <ng-container *ngIf="!isWaitingForVerification">
        <div class="row row--guttered row--tiny content">

            <div class="field-container" [class.field-container--errored]="emailAddress.hasError">
                <label class="label">{{ emailAddress.label | translate }}</label>
                <input class="field" type="email" placeholder="{{ 'common_input_placeholder' | translate:{'label': emailAddress.label | translate | lowercase} }}" [(ngModel)]="emailAddress.value" (keyup)="keyupHandler($event, emailAddress)" (focusout)="unFocusHandler(emailAddress)">
                <small class="error-text" [innerHTML]="emailAddress.errorString"></small>
            </div>

            <app-password-field class="password-container"
                                *ngIf="password"
                                [validatableObject]="password"
                                (enterPressed)="signIn()"></app-password-field>

            <div class="forgot-password-button">
                <app-delay-button #resetPasswordButton
                                  [defaultText]="'signIn_action_forgotPassword'"
                                  [delayText]="'signIn_action_resendEmail'"
                                  [delayInSeconds]="disableButtonTimeInSeconds"
                                  (buttonClickedEvent)="resetPassword()"
                ></app-delay-button>
            </div>

            <div class="field-container field-container--checkbox stay-connected-checkbox">
                <input id="stayConnected" type="checkbox" [(ngModel)]="stayConnected">
                <label class="label" for="stayConnected" [innerHTML]="'signIn_action_stayConnected' | translate"></label>
                <span class="checkmark"></span>
            </div>

            <div class="field-container buttons-container">
                <app-button
                    [loading]="isSigningIn"
                    (clicked)="signIn()"
                    [customClasses]="'sign-in-button'"
                >
                    {{ 'signIn_action_signIn' | translate }}
                </app-button>

                <div class="create-account-button-container">
                    <a (click)="signUp()" class="button-like create-account-button">{{ 'signIn_action_createAccount' | translate }}</a>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isWaitingForVerification">
        <div class="row row--guttered row--tiny content">
            <p>{{ 'signIn_verificationCode_pageSubtitle' | translate }}</p>

            <div class="field-container" [class.field-container--errored]="verificationCode.hasError">
                <label class="label">{{ verificationCode.label | translate }}</label>
                <input class="field" type="text" placeholder="{{ 'common_input_placeholder' | translate:{'label': verificationCode.label | translate | lowercase} }}" [(ngModel)]="verificationCode.value" (keyup)="keyupHandler($event, verificationCode)" (focusout)="unFocusHandler(verificationCode)">
                <small class="error-text" [innerHTML]="verificationCode.errorString"></small>
                <app-delay-button #resendVerificationCodeButton
                                  [defaultText]="'signIn_action_resendVerificationCode'"
                                  [delayText]="'signIn_action_resendEmail'"
                                  [delayInSeconds]="disableButtonTimeInSeconds"
                                  (buttonClickedEvent)="resendVerificationCodeEmail()"
                ></app-delay-button>
            </div>

            <div class="field-container buttons-container">
                <app-button
                    [loading]="isSigningInVerification"
                    (clicked)="signInVerification()"
                    [customClasses]="'sign-in-button'"
                >
                    {{ 'signIn_action_signIn' | translate }}
                </app-button>
            </div>
        </div>
    </ng-container>
</main>
