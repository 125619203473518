import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { TranslatableComponent } from "../../translatable.component"

@Component({
    selector: "app-disconnected-navbar",
    templateUrl: "./disconnected-navbar.component.html",
})
export class DisconnectedNavbarComponent extends TranslatableComponent implements AfterViewInit {
    // -------------------------------------------------------------------------
    // Variables
    // -------------------------------------------------------------------------

    @ViewChild("buttonsWrapper") public buttonsWrapperElement: ElementRef
    @ViewChild("burgerButton") public burgerButtonElement: ElementRef

    // -------------------------------------------------------------------------
    // Constructor
    // -------------------------------------------------------------------------

    constructor(
        protected readonly translate: TranslateService,
        protected readonly router: Router,
        protected readonly changeDetector: ChangeDetectorRef,
    ) {
        super(translate)
    }

    public ngAfterViewInit(): void {
        this.updateBaseLanguage()
        this.changeDetector.detectChanges()
    }

    // -------------------------------------------------------------------------
    // UI utils
    // -------------------------------------------------------------------------

    public switchBurgerMenu(): void {
        this.burgerButtonElement.nativeElement.classList.toggle("navbar__burger--active")
        this.buttonsWrapperElement.nativeElement.classList.toggle("navbar__buttons-wrapper--active")
    }

    public switchSubLinks(element: any): void {
        const link = element.currentTarget

        link.querySelector(".link__sub-links").classList.toggle("link__sub-links--active")
    }
}
